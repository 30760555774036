import { Cross2Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import { Role } from "../../store/pattern";
import {
  addRole,
  deleteRole,
  setMetadata,
  setRoleTitle,
  setRoleValue,
} from "../../store/patternSlice";
import { RootState } from "../../store/store";

import styles from "./Roles.module.css";
import { useTranslation } from "react-i18next";

interface PropType {
  roles: Role[];
  designer?: string;
}

function Roles(props: PropType) {
  const { roles, designer } = props;

  const dispatch = useDispatch();
  const [roleState, setRoleState] = useState(roles);
  function updateRoleState(index: number, role: Role) {
    let newRoleState = [...roleState];
    newRoleState.splice(index, 1, role);
    setRoleState(newRoleState);
  }

  useEffect(() => setRoleState(roles), [roles]);
  const { t } = useTranslation();

  return (
    <div className={styles.roles}>
      <div className={styles.role}>
        <input className={styles.roleTitle} disabled value="Designer" />
        <input
          className={styles.roleValue}
          placeholder= {t("write_designer")}
          value={designer}
          onChange={(e) => dispatch(setMetadata({ designer: e.target.value }))}
        />
        <button className={styles.removeItem} disabled>
          <Cross2Icon />
        </button>
      </div>

      {roleState.map((role, index) => (
        <div key={index} className={styles.role}>
          <input
            className={styles.roleTitle}
            value={role.title}
            placeholder={t("role_name")}
            onChange={(e) =>
              updateRoleState(index, { ...role, title: e.target.value })
            }
            onBlur={(e) =>
              dispatch(setRoleTitle({ index, title: e.target.value }))
            }
          />
          <input
            className={styles.roleValue}
            value={role.value}
            placeholder={t("role_owner")}
            onChange={(e) =>
              updateRoleState(index, { ...role, value: e.target.value })
            }
            onBlur={(e) =>
              dispatch(setRoleValue({ index, value: e.target.value }))
            }
          />
          <Button
            className={styles.roleValue}
            contrast
            onClick={() => dispatch(deleteRole({ index }))}
          >
            <Cross2Icon />
          </Button>
        </div>
      ))}

      <hr className={styles.spacer} />

      <div>
        <button className={styles.new} onClick={() => dispatch(addRole())}>
          {t("add_role")}
        </button>
      </div>
    </div>
  );
}

function AwareRoles() {
  const { roles, designer } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  return <Roles designer={designer} roles={roles || []} />;
}

export default AwareRoles;
export { Roles };
