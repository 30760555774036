import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { GlobalContent } from "../CommonComponents/GlobalContent";
import { Loading } from "../CommonComponents/Loading";
import { RootState } from "../store/store";
import { fetchMe } from "./components/LoginState";

function PrivateRoute2({ children }: any) {
  let auth = useSelector((state: RootState) => state.loginState);

  const dispatch = useDispatch();

  const loadUserCallback = useCallback(() => dispatch(fetchMe()), [dispatch]);

  useEffect(() => {
    loadUserCallback();
  }, [loadUserCallback, auth.loginAttempted, auth.isLoggedIn]);

  if (!auth.user) {
    if (auth.loginFailed) {
      return <Navigate to={"/landing?s=loginfailed"} />;
    } else if (auth.checkedUserLogin) {
      return <Navigate to={"/landing"} />;
    } else {
      return <Loading />;
    }
  } else {
    if (auth.user.subscriber) {
      return (
        <>
          <GlobalContent />
          {children}
        </>
      );
    } else {
      return <Navigate to={"/landing?s=noaccess"} />;
    }
  }
}

export default PrivateRoute2;
