import React, { FunctionComponent, useState } from "react";
import styles from "./NewPatternModal.module.css";
import { newPattern } from "../store/patternSlice";
import { useDispatch } from "react-redux";
import { Row } from "react-table";
import Select from "../CommonComponents/Select";
import { PatternOverview } from "../store/pattern";
import { useTranslation } from "react-i18next";
import Button from "../ISO/Components/Button";
interface NewPatternModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  options: Row<object>[];
  patterns: PatternOverview[];
  organizationId?: number;
}
export const NewPatternModal: FunctionComponent<NewPatternModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const emptyPattern = { value: "null", label: t("empty pattern") };
  const dispatch = useDispatch();
  const [titleTerm, setTitleTerm] = useState("");
  const [collectionTerm, setCollectionTerm] = useState("");
  const [copiedPatternId, setcopiedPatternId] = useState(emptyPattern);

  //console.log(props.patterns);
  const sorted = [...props.patterns].sort((a, b) => a.id - b.id);
  const organizationId = props.organizationId;

  const patternOptions = sorted.map((p) => ({
    value: `${p.id}`,
    label: `${p.id} - ${p.meta.title}`,
  }));

  const submitForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(
      newPattern({
        title: titleTerm,
        collection: collectionTerm,
        copiedPatternId: parseInt(copiedPatternId.value),
        organizationId: organizationId,
      })
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>{props.message}</div>
      <form className={styles.form}>
        <input
          value={titleTerm}
          onChange={(e) => setTitleTerm(e.target.value)}
          placeholder={t("title")}
          className={styles.input}
          type="text"
        />
        <input
          value={collectionTerm}
          onChange={(e) => setCollectionTerm(e.target.value)}
          list="collections"
          placeholder={t("collection")}
          className={styles.input}
          type="text"
        />
        <datalist id="collections">
          {[
            props.options.map((item, i) => (
              <option key={i} value={item.groupByVal} />
            )),
          ]}
        </datalist>
        <div className={styles.template}>
          <label>{t("use template")}</label>
          <Select
            options={[
              { value: "0", label: t("empty pattern") },
              ...patternOptions,
            ]}
            value={copiedPatternId}
            onChange={(patternVal) => {
              setcopiedPatternId(patternVal ?? emptyPattern);
            }}
          />
        </div>
      </form>
      <div className={styles.confirmationButtons}>
        <Button contrast onClick={props.onCancel}>
          {t("cancel")}
        </Button>
        <Button active type="submit" onClick={submitForm}>
          {t("create")}
        </Button>
      </div>
    </div>
  );
};
