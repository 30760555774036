import React, { useState } from "react";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import YarnSelectorYarn from "./YarnSelectorYarn";
import styles from "./YarnSelector.module.css";
import { useTranslation } from "react-i18next";
import {
  APIYarn,
  APIYarnColor,
  useOrganizationYarnQuery,
  YarnState,
} from "../../services/yarnApi";

function filterBySearchTerm(
  currentYarn: APIYarn,
  currentColor: APIYarnColor,
  searchTerm: string
) {
  if (searchTerm === "") {
    return true;
  }

  const searchTermLower = searchTerm.toLowerCase();

  if (
    currentYarn.name.toLowerCase().includes(searchTermLower) ||
    currentColor.name.toLowerCase().includes(searchTermLower) ||
    currentColor.color_tint.toLowerCase().includes(searchTermLower) ||
    currentYarn.sku.toLowerCase().includes(searchTermLower) ||
    currentColor.sku.toLowerCase().includes(searchTermLower) ||
    currentColor.hex === searchTermLower ||
    currentYarn.brand.toLowerCase().includes(searchTermLower)
  ) {
    return true;
  }

  return false;
}

interface PropType {
  yarn: YarnState;
}

function YarnSelector(props: PropType) {
  const { t } = useTranslation();
  const { yarn } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const filteredYarn = Object.values(yarn)
    .map((yarn) => ({
      ...yarn,
      colors: yarn.colors.filter((color) =>
        filterBySearchTerm(yarn, color, searchTerm)
      ),
    }))
    .filter((yarn) => yarn.colors.length !== 0)
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

  const sizeIndex = Object.fromEntries(
    filteredYarn.map((yarn, index) => [index, 66 + yarn.colors.length * 56])
  );

  const totalHeight = Object.values(sizeIndex).reduce(
    (sum, height) => sum + height,
    0
  );

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <input
          type="text"
          className={styles.search}
          placeholder={t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>
      <div className={styles.colorListContainer}>
        <AutoSizer>
          {({ width, height }) => (
            <VariableSizeList
              key={totalHeight}
              estimatedItemSize={totalHeight / filteredYarn.length}
              itemSize={(index) => sizeIndex[index]}
              height={height}
              itemCount={filteredYarn.length}
              width={width}
            >
              {({ index, style }) => (
                <div key={filteredYarn[index].sku} style={style}>
                  <YarnSelectorYarn yarn={filteredYarn[index]} />
                </div>
              )}
            </VariableSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

function AwareYarnSelector({
  organizationId,
}: {
  organizationId: number | null;
}) {
  const { data: yarn } = useOrganizationYarnQuery(organizationId);

  if (!yarn) {
    return null;
  }

  return <YarnSelector yarn={yarn} />;
}

export default AwareYarnSelector;
export { YarnSelector };
