import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Needle, ALL_NEEDLE_TYPES, NeedleType } from "../../store/pattern";
import { RootState } from "../../store/store";
import {
  addNeedle,
  setNeedle,
  removeNeedle,
  setNeedleOrder,
} from "../../store/patternSlice";
import Select from "../../CommonComponents/Select";
import Cross from "../../CommonComponents/Icons/Cross";
import styles from "./Needles.module.css";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragHandleHorizontalIcon } from "@radix-ui/react-icons";
import { reorder } from "./VariantList";
import { useIsElementInPattern } from "../../utils/canDelete";
import Button from "../../ISO/Components/Button";

interface PropType {
  needles: { [key: string]: Needle };
  needleOrder: string[];
}

function Needles(props: PropType) {
  const { needles, needleOrder } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [needleState, setNeedleState] = useState(needles);

  function updateNeedleState(id: string, needle: Needle) {
    let newNeedleState = { ...needleState };
    newNeedleState[id] = needle;
    setNeedleState(newNeedleState);
  }

  useEffect(() => setNeedleState(needles), [needles]);
  const first = needleOrder[0];

  return (
    <div className={styles.needles}>
      <DragDropContext
        onDragEnd={(res) => {
          if (!res.destination) return;
          const tmp = [...needleOrder];
          dispatch(
            setNeedleOrder(
              reorder(tmp, res.source.index, res.destination.index)
            )
          );
        }}
      >
        <Droppable droppableId="firstNeedleDroppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.gaugeNeedleContainer}
            >
              <p>{t("gauge needle explanation")}</p>
              <Draggable index={0} key={first} draggableId={"gaugeNeedle"}>
                {(dragProvided) => (
                  <div
                    {...dragProvided.dragHandleProps}
                    {...dragProvided.draggableProps}
                    ref={dragProvided.innerRef}
                    key={first}
                    className={styles.needle}
                  >
                    <NeedleRow
                      id={first}
                      needle={needleState[first]}
                      onNeedleChange={updateNeedleState}
                    />
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="needleDroppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {needleOrder.slice(1).map((id, index) => {
                const needle = needleState[id];
                return (
                  <Draggable
                    index={index + 1}
                    key={id}
                    draggableId={`${id}_${index}`}
                  >
                    {(dragProvided) => (
                      <div
                        {...dragProvided.dragHandleProps}
                        {...dragProvided.draggableProps}
                        ref={dragProvided.innerRef}
                        key={id}
                        className={styles.needle}
                      >
                        <NeedleRow
                          id={id}
                          needle={needle}
                          onNeedleChange={updateNeedleState}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <hr className={styles.spacer} />
      <button onClick={() => dispatch(addNeedle())} className={styles.new}>
        {t("add")} {t("needle", { count: 1 }).toLowerCase()}
      </button>
    </div>
  );
}

interface NeedleRowProps {
  id: string;
  needle: Needle;
  onNeedleChange: (id: string, needle: Needle) => void;
}

const NeedleRow: React.FC<NeedleRowProps> = (props) => {
  const { id, needle, onNeedleChange } = props;
  const disabled = useIsElementInPattern(id);
  const dispatch = useDispatch();

  if (!needle) return null;
  return (
    <>
      <DragHandleHorizontalIcon style={{ marginRight: "8px" }} />
      <Select
        options={ALL_NEEDLE_TYPES.map((needleType) => ({
          value: needleType,
          label: needleType.charAt(0).toUpperCase() + needleType.substring(1),
        }))}
        value={{
          value: needle.type,
          label: needle.type.charAt(0).toUpperCase() + needle.type.substring(1),
        }}
        onChange={(e) => {
          if (e && e.value !== null) {
            dispatch(
              setNeedle({
                id,
                needle: { ...needle, type: e.value as NeedleType },
              })
            );
          }
        }}
      />
      {needle.type === "rundpinne" && (
        <>
          <input
            type={"number"}
            className={styles.input}
            value={needle.size}
            step={"10"}
            min={10}
            max={90}
            onChange={(e) => {
              onNeedleChange(id, {
                ...needle,
                size: e.target.valueAsNumber,
              });
            }}
            onBlur={(e) =>
              dispatch(
                setNeedle({
                  id,
                  needle: {
                    ...needle,
                    size: e.target.value === "" ? 0 : e.target.value,
                  },
                })
              )
            }
            onFocus={(e) => {
              onNeedleChange(id, {
                ...needle,
                size: "",
              });
            }}
          />
          <span className={styles.span}>cm</span>
        </>
      )}
      <span className={styles.span}>{" nr. "}</span>
      <input
        type={"number"}
        className={styles.input}
        value={needle.diameter}
        step={"0.5"}
        min={1}
        max={9}
        onChange={(e) => {
          onNeedleChange(id, {
            ...needle,
            diameter: e.target.valueAsNumber,
          });
        }}
        onBlur={(e) =>
          dispatch(
            setNeedle({
              id,
              needle: {
                ...needle,
                diameter: e.target.value === "" ? 0 : e.target.value,
              },
            })
          )
        }
        onFocus={(e) => {
          onNeedleChange(id, {
            ...needle,
            diameter: "",
          });
        }}
      />
      <Button
        disabled={disabled}
        title={disabled ? "Needle is in pattern" : ""}
        className={styles.deleteNeedle}
        onClick={() => dispatch(removeNeedle({ id }))}
        contrast
      >
        <Cross width={16} height={16} fill={disabled ? "#aaa" : "#ff3700"} />
      </Button>
    </>
  );
};

function AwareNeedles() {
  const { needles, needleOrder } = useSelector((state: RootState) => ({
    needles: state.pattern.needles,
    needleOrder: state.pattern.meta.needleOrder,
  }));

  return (
    <Needles
      needles={needles}
      needleOrder={
        needleOrder.length === 0 ? Object.keys(needles) : needleOrder
      }
    />
  );
}

export default AwareNeedles;
export { Needles };
