import { useSelector } from "react-redux";
import { PatternElement } from "../store/pattern";
import { RootState } from "../store/store";

export const notInPattern = (
  patternElementRef: string,
  patternElements: PatternElement[]
) => {
  let status = true;
  for (let index = 0; index < patternElements.length; index++) {
    const patternElement = patternElements[index];

    // @ts-ignore
    if (patternElement.diagram === patternElementRef) {
      status = false;
      break;
    }
    // @ts-ignore
    else if (patternElement.ref === patternElementRef) {
      status = false;
      break;
    }
  }
  return status;
};

export const useIsElementInPattern = (id: string): boolean => {
  const { patternElements } = useSelector((state: RootState) => state.pattern);

  const isIn = patternElements.find((patternElement) => {
    switch (patternElement.type) {
      case "diagramSection":
        return patternElement.diagram === id;
      case "needle":
        return patternElement.ref === id;
      case "figure":
        return patternElement.figureId === id;
      default:
        return false;
    }
  });

  return !!isIn;
};
