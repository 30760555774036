import React, { CSSProperties, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ISO/Components/Button";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { RootState } from "../store/store";
import { setDisableWriterHotkeys } from "../store/writerSlice";
import CtaButton from "./CtaButton";
import { Modal } from "./Modal";

export interface ModalButtonProps {
  style?: CSSProperties;
  className?: string;
  modalContent: JSX.Element;
  headerText: string;
  isShown: boolean;
  toggle: () => void;
  disabled?: boolean;
  active?: boolean;
  contrast?: boolean;
}

export const ModalButton: React.FC<ModalButtonProps> = (props) => {
  const { isShown } = props;
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state: RootState) => state.writer);

  useEffect(() => {
    if (isShown) {
      dispatch(setDisable(true));
      dispatch(setDisableHotkeys(true));
      dispatch(setDisableWriterHotkeys(true));
    } else if (!isShown && !drawerOpen) {
      // Check if drawer isOpen
      dispatch(setDisable(false));
      dispatch(setDisableHotkeys(false));
      dispatch(setDisableWriterHotkeys(false));
    }
  }, [isShown]);

  return (
    <>
      <Button
        style={props.style}
        className={props.className}
        onClick={() => props.toggle()}
        {...props}
      >
        {props.children}
      </Button>
      <Modal
        isShown={props.isShown}
        hide={props.toggle}
        headerText={props.headerText}
        modalContent={props.modalContent}
      />
    </>
  );
};

export const ModalCtaButton: React.FC<ModalButtonProps> = (props) => {
  return (
    <>
      <CtaButton
        style={props.style}
        className={props.className}
        onClick={() => props.toggle()}
        {...props}
      >
        {props.children}
      </CtaButton>
      <Modal
        isShown={props.isShown}
        hide={props.toggle}
        headerText={props.headerText}
        modalContent={props.modalContent}
      />
    </>
  );
};
