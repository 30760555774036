import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Top from "../CommonComponents/Top";
import { fetchMe } from "../Login/components/LoginState";
import { fetchPatternById } from "../store/patternSlice";
import { RootState } from "../store/store";
import DiagramEditor from "./DiagramEditor";
import styles from "./diagram.module.css";
import { clearUndoStack } from "../store/gridSlice";
import { DragSelect } from "./DragSelect";
import { Loading } from "../CommonComponents/Loading";
import Visualizing3D from "../Visualizing3D/Visualizing3D";
import SplitView from "./SplitView";
import DiagramSelector from "./DiagramSelector";
import DiagramViewer from "../Visualizing/DiagramViewer";

const DiagramWrapper = () => {
  const { cellWidth, cellHeight } = useSelector(
    (state: RootState) => state.grid
  );
  const { patternId } = useParams();

  // State
  const { pattern, loginState } = useSelector((state: RootState) => state);

  const { disabled } = useSelector((state: RootState) => state.dragSelect);

  const dispatch = useDispatch();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  const getUserCallback = useCallback(
    () => (loginState.isLoggedIn ? null : dispatch(fetchMe())),
    [dispatch, loginState.isLoggedIn]
  );

  useEffect(() => {
    return () => {
      dispatch(clearUndoStack());
    };
  }, []);

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  useEffect(() => {
    getUserCallback();
  }, [getUserCallback]);

  const { selectedView } = useSelector((state: RootState) => state.grid);

  return (
    <>
      <div style={{ display: "flex" }}>
        <Top transparent />
        <SplitView>
          <>
            <div style={{ position: "relative" }}>
              {!disabled && <DragSelect disabled={disabled} />}
            </div>
            {pattern.id && pattern.id !== -1 ? (
              <div
                style={{
                  overflow: "auto",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    backgroundSize: `${cellWidth}px ${cellHeight}px`,
                    minHeight: "200%",
                    minWidth: "200%",
                  }}
                  className={`${styles.background}`}
                  id={"grid-anchor"}
                >
                  <DiagramEditor />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundSize: `${cellWidth}px ${cellHeight}px` }}
                className={`${styles.background} ${styles.coverScreen}`}
                id={"grid-anchor"}
              >
                <Loading />
              </div>
            )}
          </>
          {selectedView !== "grid" && (
            <>
              {/* Modell */}
              {selectedView === "model" && <Visualizing3D />}
              {/* Strikk sammen*/}
              {selectedView === "fell" && <DiagramViewer />}
            </>
          )}
        </SplitView>
        <DiagramSelector />
      </div>
    </>
  );
};

export default DiagramWrapper;
