import React, { useState } from "react";
import styles from "../Styles/Sections.module.css";
import designStudioScreenshot from "../../Visualizing/LandingPage/designStudioScreenshot.png";
import Card from "./Card";
import yarnIcon from "../../Visualizing/LandingPage/yarnIcon.svg";
import clockIcon from "../../Visualizing/LandingPage/clockIcon.svg";
import calculatorIcon from "../../Visualizing/LandingPage/calculatorIcon.svg";
import webIcon from "../../Visualizing/LandingPage/webIcon.svg";
import { backendApiAddress } from "../../backendApi";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CardSection() {
  const { t } = useTranslation();
  let auth = useSelector((state: RootState) => state.loginState);
  const card1Title: string = "Direkte integrasjon med garndatabaser";
  const card1Description: string =
    "Få tilgang til et stort utvalg av kvalitetsgarn og fargekombinasjoner. Enkelt og raskt å teste nye garnkvaliteter og å sette opp garnpakker for ditt design.";

  const card2Title: string = "Tidsbesparende visualiseringer ";
  const card2Description: string =
    "Spar tid og øk kvaliteten på ditt design. Se hvordan ditt design blir med vår virtuelle prøvelapp.";

  const card3Title: string = "Avansert støtte for beregninger";
  const card3Description: string =
    "Legg bort kalkulatoren og regnearkene, Woolit Design Studio Pro gir deg støtte for fellinger og stort utvalg i størrelser.";

  const card4Title: string = "Samarbeid med dine partnere ";
  const card4Description: string =
    "Få kontroll på modellstrikk, korrektur og garnvalg, alt samlet i ett verktøy for å optimalisere din designprosess.";

  const description: string =
    "Ta kontakt med oss for en hyggelig designprat med eller uten kaffe. Digitalt eller fysisk i Oslo sentrum, velkommen skal du være!";

  const [trialStatus, setTrialStatus] = useState(true);
  const [trial, setTrial] = useState(false);
  const [url, setURL] = useState("");
  const [inTrial, setInTrial] = useState(false);

  const getSubscription = async (): Promise<string> => {
    if (!auth.user) {
      setTrialStatus(false);

      console.log("ikke logget inn");
      return "";
    }

    const res = await fetch(
      `${backendApiAddress}/api/wds/payment/checkout_session`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          subscriptionType: "basic",
        }),
      }
    );

    const { url } = await res.json();
    setURL(url);
    console.log("respons" + url);
    window.open(url);
    return url;

    /* if (res.ok) {
      console.log("respons" + res.url);
      <Navigate to={res.url} />
      
    } */

    //console.log("ikke ok respons");
  };

  const tryTrial = async (): Promise<boolean> => {
    if (!auth.user) {
      setTrialStatus(false);

      console.log("ikke logget inn");
      return false;
    }

    console.log(`${backendApiAddress}/api/wds/onboarding/trial`);

    const res = await fetch(`${backendApiAddress}/api/wds/onboarding/trial`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        subscriptionType: "basic",
      }),
    });

    if (Object.keys(res).length == 0) {
      console.log("----tom response!");
      setInTrial(true);
    } else if (res.ok) {
      console.log("ok respons");

      setTrial(true);

      return true;
    }
    setTrial(true);
    setTrialStatus(false);

    console.log("ikke ok respons");
    return false;
  };

  return (
    <div className={styles.cardSection}>
      
      <div style={{ color: "#8F6D57", fontSize: "20px" }}>Lanseres 2023!</div>
      <div className={styles.headerTitle}>Woolit Design Studio Pro</div>
      <div className={styles.headerDescription}>{description}</div>
      <div style={{ display: "flex", justifyContent: "center", width: "40%" }}>
        {!auth.user && (
          <button
            className={styles.ctaButton}
            onClick={() => {
              tryTrial();
            }}
          >
            Logg inn og Prøv gratis i 7 dager!
          </button>
        )}

        {auth.user && (
          <button
            className={styles.ctaButton}
            onClick={() => {
              tryTrial();
            }}
          >
            Prøv gratis i 7 dager!
          </button>
        )}

        <button
          className={styles.ctaButton}
          onClick={() => {
            getSubscription();
          }}
        >
          Kjøp tilgang til Woolit Design Studio
        </button>

        {/*     {url !="" && <Navigate to={url} /> } */}

        {trial && <Navigate to={"/projects"} />}

        {!trialStatus && !auth.user && (
          <div>
            {" "}
            <Navigate to={"/login"} state={"trial"} />
          </div>
        )}
      </div>

      {auth.user?.usedTrial && (
        <div style={{ marginTop: "30px" }}>
          {" "}
          Du er allerede brukt opp prøveperioden
          <Link
            style={{ color: "#3D8036", margin: "12px", cursor: "pointer" }}
            to="/projects"
          >
            {t("goToWDS")}
          </Link>
        </div>
      )}

      {auth.user?.subscriber && (
        <div style={{ marginTop: "30px" }}>
          {" "}
          Du har startet prøveperioden
          <Link
            style={{ color: "#3D8036", margin: "12px", cursor: "pointer" }}
            to="/projects"
          >
            {t("goToWDS")}
          </Link>
        </div>
      )}
      

     {/*  <img
        src={designStudioScreenshot}
        style={{ height: "auto", width: "100%" }}
        alt="Woolit Design Studio skjermbilde eksempel"
      ></img> */}
      

      <div className={styles.cardDescriptionContainer}>
        <div style={{ display: "flex" }}>
          <Card
            imgIcon={yarnIcon}
            title={card1Title}
            description={card1Description}
          />
          <Card
            imgIcon={clockIcon}
            title={card2Title}
            description={card2Description}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Card
            imgIcon={calculatorIcon}
            title={card3Title}
            description={card3Description}
          />

          <Card
            imgIcon={webIcon}
            title={card4Title}
            description={card4Description}
          />
        </div>
      </div>
    </div>
  );
}

export default CardSection;
