import { backendApiAddress } from "../../backendApi";

export const checkEmailUnique = async (
  email: string | undefined
): Promise<boolean> => {
  const res = await fetch(`${backendApiAddress}/api/auth/check_email`, {
    method: "POST",
    body: JSON.stringify({
      email: email,
    }),
  });
  if (res.ok) {
    return true;
  }
  return false;
};
