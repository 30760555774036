import React from "react";
import { useDispatch } from "react-redux";

import { RelationTableReference } from "./calculations";
import {
  addToRelationTop,
  addToRelationBottom,
  removeFromRelationTop,
  removeFromRelationBottom,
  setScalarTop,
  setScalarBottom,
} from "../store/calculationSlice";

import styles from "./TableRelationModal.module.css";

type PropType = {
  label: string;
  reference?: RelationTableReference;
  tableId?: string;
  dividendMeasure?: "first" | "last";
  relationId: string;
};

function TableRelationEntry(props: PropType) {
  const { label, reference, tableId, relationId, dividendMeasure } = props;

  const dispatch = useDispatch();

  return (
    <div key={reference?.tableId ?? tableId} className={styles.entry}>
      {reference !== undefined ? (
        <>
          <button
            className={`${styles.checkbox} ${styles.checked}`}
            onClick={() => {
              if (reference.dividendMeasure === "first") {
                dispatch(
                  removeFromRelationTop({
                    relationId,
                    tableId: reference.tableId,
                  })
                );
              } else if (reference.dividendMeasure === "last") {
                dispatch(
                  removeFromRelationBottom({
                    relationId,
                    tableId: reference.tableId,
                  })
                );
              }
            }}
          ></button>
          <div>{label}</div>
          <input
            type="number"
            onChange={(e) => {
              if (reference.dividendMeasure === "first") {
                dispatch(
                  setScalarTop({
                    relationId,
                    tableId: reference.tableId,
                    value: e.target.value,
                  })
                );
              } else if (reference.dividendMeasure === "last") {
                dispatch(
                  setScalarBottom({
                    relationId,
                    tableId: reference.tableId,
                    value: e.target.value,
                  })
                );
              }
            }}
            value={reference.scalar}
          />
        </>
      ) : (
        <>
          <button
            className={`${styles.checkbox}`}
            onClick={() => {
              if (dividendMeasure === "first") {
                dispatch(addToRelationTop({ relationId, tableId }));
              } else if (dividendMeasure === "last") {
                dispatch(addToRelationBottom({ relationId, tableId }));
              }
            }}
          ></button>
          <div>{label}</div>
          <input type="text" disabled />
        </>
      )}
    </div>
  );
}

export default TableRelationEntry;
