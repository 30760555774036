import { useEffect, useRef } from "react";

export const useEffectExceptMount: typeof useEffect = (effect, deps) => {
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
    } else {
      return effect();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
