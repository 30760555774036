import React from "react";

import { RelationTableReference, CalculationTable } from "./calculations";
import TableRelationEntry from "./TableRelationEntry";

import styles from "./TableRelationModal.module.css";

type PropType = {
  selectedReferences: RelationTableReference[];
  selectableTableIds: string[];
  tables: { [tableId: string]: CalculationTable };
  relationId: string;
  dividendMeasure: "first" | "last";
};

function TableRelationList(props: PropType) {
  const {
    selectedReferences,
    selectableTableIds,
    tables,
    relationId,
    dividendMeasure,
  } = props;

  return (
    <div className={styles.tableButtonContainer}>
      {selectedReferences.length > 0 &&
        selectedReferences.map((reference) => (
          <TableRelationEntry
            label={tables[reference.tableId].label}
            reference={reference}
            relationId={relationId}
          />
        ))}
      {selectableTableIds.map((tableId) => (
        <TableRelationEntry
          label={tables[tableId].label}
          tableId={tableId}
          relationId={relationId}
          dividendMeasure={dividendMeasure}
        />
      ))}
    </div>
  );
}

export default TableRelationList;
