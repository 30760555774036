import React from "react";

interface PropType {
  color?: string;
}

function Upload(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color ?? "#33251A"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29755 1.103C7.13353 0.938975 6.8676 0.938975 6.70358 1.103L3.90358 3.903C3.73956 4.06702 3.73956 4.33295 3.90358 4.49697C4.0676 4.66099 4.33353 4.66099 4.49755 4.49697L6.58057 2.41395V8.86653C6.58057 9.09849 6.76861 9.28653 7.00057 9.28653C7.23253 9.28653 7.42057 9.09849 7.42057 8.86653V2.41395L9.50358 4.49697C9.6676 4.66099 9.93353 4.66099 10.0976 4.49697C10.2616 4.33295 10.2616 4.06702 10.0976 3.903L7.29755 1.103ZM2.33385 9.33321C2.59159 9.33321 2.80052 9.54214 2.80052 9.79988V11.1999C2.80052 11.7168 3.21646 12.1332 3.73045 12.1332H10.2683C10.7833 12.1332 11.2005 11.7158 11.2005 11.1999V9.79988C11.2005 9.54214 11.4095 9.33321 11.6672 9.33321C11.9249 9.33321 12.1339 9.54214 12.1339 9.79988V11.1999C12.1339 12.2303 11.2997 13.0665 10.2683 13.0665H3.73045C2.69803 13.0665 1.86719 12.2293 1.86719 11.1999V9.79988C1.86719 9.54214 2.07612 9.33321 2.33385 9.33321Z"
      />
    </svg>
  );
}

export default Upload;
