import { PlusIcon } from "@radix-ui/react-icons";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, useTable } from "react-table";
import { Modal } from "../CommonComponents/Modal";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";
import {
  useCreateModelKnitGroupMutation,
  useModelKnittersQuery,
} from "../services/modelknit";
import { useGetOrganizationByIdQuery } from "../services/organization";
import { Knitter } from "../store/pattern";
import AddModelKnitterModal from "./AddModelKnitterModal";
import AddModelKnitterToGroupModal from "./AddModelKnitterToGroupModal";
import Table from "./Table";
import styles from "./Table.module.css";

type Props = {
  orgId: string;
};

type TableProps = {
  orgId: string;
  data?: Knitter[];
  title: string;
  tag: string;
  allKnitters: Knitter[];
  orgTag: string;
};

const ModelKnittersTable: React.FC<TableProps> = ({
  orgId,
  data = [],
  title,
  tag,
  allKnitters,
  orgTag,
}) => {
  const addKnitterToGroupModal = useModal();
  const { t } = useTranslation();

  const columns: Column<Knitter>[] = useMemo(() => {
    const columns: Column<Knitter>[] = [
      { Header: "ID", accessor: "id" },
      { Header: `${t("name")}`, accessor: "name" },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: (value) => (
          <div
            style={{
              border: "1px solid #ddd8d5",
              padding: "4px 8px",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            {value.row.values["tag"]}
          </div>
        ),
      },
    ];
    return columns;
  }, []);
  const instance = useTable({ columns, data });

  return (
    <div>
      <div className={styles.titleContainer}>
        <h3>{title}</h3>
        {orgTag !== tag && (
          <>
            <button onClick={() => addKnitterToGroupModal.setIsShown(true)}>
              <PlusIcon /> Endre strikkere i {title}
            </button>
            <Modal
              hide={() => addKnitterToGroupModal.setIsShown(false)}
              isShown={addKnitterToGroupModal.isShown}
              modalContent={
                <AddModelKnitterToGroupModal
                  orgId={orgId}
                  groupTag={tag}
                  members={data}
                  allKnitters={allKnitters}
                  hide={() => addKnitterToGroupModal.setIsShown(false)}
                />
              }
              headerText=""
            />
          </>
        )}
      </div>
      <Table instance={instance} />
    </div>
  );
};

const ModelKnitters: React.FC<Props> = ({ orgId }) => {
  const createGroupModal = useModal();
  const addKnitterModal = useModal();
  const newModelKnitGroupName = useRef("");

  const { t } = useTranslation();

  const modelKnittersQuery = useModelKnittersQuery(orgId);
  const orgQuery = useGetOrganizationByIdQuery(orgId, {
    skip: orgId === "-1",
  });

  // Main organization tag equals the organization slug
  // it is used for finding all knitters in organization
  const orgTag = orgQuery.data?.slug || "";

  const [createModelKnitGroup] = useCreateModelKnitGroupMutation();

  const handleCreateModelKnitGroup = () => {
    if (
      modelKnittersQuery.data?.find(
        (group) => group.name === newModelKnitGroupName.current
      )
    ) {
      return;
    }
    createModelKnitGroup({
      orgId,
      groupName: newModelKnitGroupName.current,
    }).then(() => {
      modelKnittersQuery.refetch();
      createGroupModal.setIsShown(false);
    });
  };

  const allKnitters = (
    modelKnittersQuery.data?.find((group) => group.tag === orgTag) || {
      knitters: [],
    }
  ).knitters;

  const modalContent = () => {
    return (
      <div style={{ padding: "24px 36px" }}>
        <h1>
          {t("add group to org", { organization: orgQuery.data?.name ?? "" })}
        </h1>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            type="text"
            placeholder={t("name")}
            onChange={(e) => (newModelKnitGroupName.current = e.target.value)}
          />
          <Button active onClick={handleCreateModelKnitGroup}>
            {t("add group")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <h1>
          {t("test knitters in org", {
            organization: orgQuery.data?.name ?? "",
          })}
        </h1>
        <div>
          <button
            style={{ marginRight: "16px" }}
            onClick={() => createGroupModal.setIsShown(true)}
          >
            {t("new group")}
          </button>
          <Modal
            hide={() => createGroupModal.setIsShown(false)}
            isShown={createGroupModal.isShown}
            modalContent={modalContent()}
            headerText=""
          />
          <button onClick={() => addKnitterModal.setIsShown(true)}>
            {t("add knitter")}
          </button>
          <Modal
            hide={() => addKnitterModal.setIsShown(false)}
            isShown={addKnitterModal.isShown}
            modalContent={
              <AddModelKnitterModal
                orgId={orgId}
                hide={() => addKnitterModal.setIsShown(false)}
              />
            }
            headerText=""
          />
        </div>
      </div>
      {modelKnittersQuery.data?.map((group) => (
        <ModelKnittersTable
          orgId={orgId}
          key={group.tag}
          data={group.knitters}
          title={group.name}
          tag={group.tag}
          allKnitters={allKnitters}
          orgTag={orgTag}
        />
      ))}
    </div>
  );
};

export default ModelKnitters;
