import React from "react";
import styles from "./Loading.module.css";

export const Loading = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.spinnerContainer}>
        <img className={styles.spinner} src={"/logo.svg"} alt={""} />
      </div>
    </div>
  );
};
