import { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { BaseEditor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { CommentType } from "../../store/pattern";
import { addPatternComment } from "../../store/patternSlice";
import { RootState } from "../../store/store";
import { toggleCommentPopover } from "../../store/writerSlice";
import { shortUUID } from "../../utils/uuid";
import styles from "./CommentWriter.module.css";

const CommentWriter = ({ editor }: { editor: BaseEditor & ReactEditor }) => {
  const { top } = useSelector(
    (state: RootState) => state.writer.commentPopover
  );

  const dispatch = useDispatch();

  const pattern = useSelector((state: RootState) => state.pattern);
  const patternId = `${pattern.id}`;

  const [comment, setComment] = useState("");
  const [commentType, setCommentType] = useState("comment");
  const { user } = useSelector((state: RootState) => state.loginState);

  if (!user) return null;

  if (!editor) return null;

  const handleClose = () =>
    dispatch(
      toggleCommentPopover({
        top: -1,
        isShown: false,
      })
    );

  const handleAddComment = () => {
    const id = shortUUID();
    dispatch(
      addPatternComment({
        patternId,
        comment: {
          id,
          commentType: commentType as CommentType,
          comment,
          timestamp: new Date(),
          answers: [],
          user,
        },
      })
    );
    Transforms.setNodes(editor, { commentId: id }, { split: true });
    /*
    // Add comment
      state.comments[id] = {
        commentType,
        comment: commentText,
        user: user,
        id,
        timestamp: new Date(),
        answers: [],
      };
      */

    handleClose();
  };

  return (
    <Draggable>
      <div
        style={{ position: "absolute", right: 10, top }}
        className={styles.commentWriter}
      >
        <div className={styles.buttonContainer}>
          <div
            className={commentType === "comment" ? styles.positive : ""}
            onClick={() => setCommentType("comment")}
          >
            Kommentar
          </div>
          <div
            className={commentType === "change" ? styles.positive : ""}
            onClick={() => setCommentType("change")}
          >
            Endring
          </div>
        </div>
        <TextareaAutosize
          autoFocus
          onKeyUp={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleAddComment();
            }
          }}
          className={styles.commentField}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          minRows={3}
          placeholder="Skriv kommentar..."
        />
        <div className={styles.buttonContainer}>
          <div className={styles.positive} onClick={(e) => handleAddComment()}>
            Send
          </div>
          <div onClick={() => handleClose()}>Avbryt</div>
        </div>
      </div>
    </Draggable>
  );
};

export default CommentWriter;
