import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSymbol } from "../store/colorSlice";
import { RootState } from "../store/store";
import styles from "./symbollist.module.css";
import diagramStyles from "./diagram.module.css";
import { Pencil1Icon, PlusIcon } from "@radix-ui/react-icons";
import { SymbolModalButton } from "./SymbolModalButton";
import { useTranslation } from "react-i18next";
import {
  DiagramSymbol,
  DynamicSymbol,
  DynamicSymbols,
} from "@iterate/woolit-components";
import { splitIntoSingleAndDynamicSymbols } from "./Symbols";

const SymbolList = () => {
  const dispatch = useDispatch();
  const { activeSymbol } = useSelector((state: RootState) => state.color);
  const { t } = useTranslation();

  const { symbols } = useSelector((state: RootState) => state.pattern);

  const { singleSymbols, dynamicSymbols } =
    splitIntoSingleAndDynamicSymbols(symbols);

  return (
    <>
      <div
        className={`${diagramStyles.toolContainer} ${diagramStyles.overflowTool} ${styles.overflowY}`}
        style={{
          height: 255 + (singleSymbols.length > 8 ? 15 : 0) + "px",
          // If length > 8, then a custom symbol has been added.
          // In that case, increase height by half a symbol to show
          // that the container is scrollable
        }}
      >
        {singleSymbols.map(({ key, label, listIndex }, i) => (
          <div className={styles.fl} key={i}>
            <button
              style={{ border: 0, padding: 0 }}
              title={`Ctrl+Shift+${i + 1}`}
              className={styles.symbolRow}
              onClick={() => {
                dispatch(setActiveSymbol({ symbol: key, type: "single" }));
              }}
            >
              <div className={styles.symbolContainer}>
                <div className={styles.symbolBox}>
                  <DiagramSymbol
                    symbol={key}
                    iconProps={{ width: 20, height: 20, fill: "black" }}
                  />
                </div>
              </div>
              <p
                className={styles.symbolLabel}
                style={{ color: activeSymbol === key ? "var(--contrast)" : "" }}
              >
                {label}
              </p>
            </button>
            <SymbolModalButton
              symbolType="single"
              action="edit"
              currentSymbols={symbols}
              symbolKey={key}
              symbolLabel={label}
              style={{ border: 0 }}
              index={listIndex}
            >
              <Pencil1Icon aria-label={t("draw.edit symbol")} />
            </SymbolModalButton>
          </div>
        ))}
        {dynamicSymbols.length > 0 && (
          <div style={{ margin: "8px 0" }}>
            <p>Dynamiske symboler</p>
          </div>
        )}
        {dynamicSymbols.map(({ key, label, listIndex }, i) => (
          <div className={styles.fl} key={i}>
            <button
              className={styles.symbolRow}
              style={{ padding: 0, border: 0 }}
              onClick={() =>
                dispatch(setActiveSymbol({ symbol: key, type: "dynamic" }))
              }
            >
              <div className={styles.symbolContainer}>
                <div className={styles.symbolBox}>
                  <DynamicSymbol
                    symbol={key as DynamicSymbols}
                    iconProps={{ width: 120, height: 18 }}
                  />
                </div>
              </div>

              <p
                className={styles.symbolLabel}
                style={{ color: activeSymbol === key ? "var(--contrast)" : "" }}
              >
                {label}
              </p>
            </button>
            <SymbolModalButton
              symbolType="dynamic"
              action="edit"
              currentSymbols={symbols}
              symbolKey={key}
              symbolLabel={label}
              style={{ border: 0 }}
              index={listIndex}
            >
              <Pencil1Icon aria-label={t("draw.edit symbol")} />
            </SymbolModalButton>
          </div>
        ))}
      </div>
      <div className={diagramStyles.toolContainer}>
        <SymbolModalButton
          action="add"
          symbolType="both"
          currentSymbols={symbols}
          className={styles.add}
          index={-1}
        >
          <p>{t("draw.add symbol")}</p>
          <PlusIcon strokeWidth="4" />
        </SymbolModalButton>
      </div>
    </>
  );
};

export default SymbolList;
