import React from "react";
import { useSelector } from "react-redux";
import { MediaSection } from "../CommonComponents/MediaSection";
import { Color, PatternElement, YarnColor } from "../store/pattern";
import { RootState } from "../store/store";
import { shortenedWords, wordsToShorten } from "../utils/words";
import DiagramSection from "./DiagramSection";
import styles from "../ISO.module.css";

interface ProptType {
  patternElements: PatternElement[];
}

const inlineElementTypes = [
  "span",
  "color",
  "diagram",
  "needle",
  "calcFromTable",
  "calculationResult",
  "textlink",
  "sizeFilteredString",
];

const PatternElementList = (props: ProptType) => {
  const { patternElements } = props;

  const ctx = useSelector((state: RootState) => ({
    ...state.pattern,
    yarnColors: state.pattern.meta.variants.find(
      (variant) => variant.id === state.displayState.activeVariant
    )?.colors as Color[],
  }));

  // Join: span, pinne, color, calculation
  // Break: paragraphBreak, overskrift, media, diagram.

  const shortened = useSelector(
    (state: RootState) => state.displayState.showShortened
  );

  const activeSizes = useSelector(
    (state: RootState) => state.displayState.activeSizes
  );
  const allsizes = useSelector(
    (state: RootState) => state.displayState.sizeOrder
  );
  const sizes = activeSizes.length > 0 ? activeSizes : allsizes;

  const renderParagraphElement = (element: PatternElement) => {
    switch (element.type) {
      case "span":
        return (
          <span className={`${element.style ? styles[element.style] : ""}`}>{`${
            shortened
              ? element.markdown.replaceAll(
                  wordsToShorten,
                  (matched: string) => {
                    const lastIsLetter = RegExp(/^\p{L}/, "u").test(
                      matched[matched.length - 1]
                    );
                    return lastIsLetter
                      ? shortenedWords[matched]
                      : `${shortenedWords[matched.slice(0, -1)]}${
                          matched[matched.length - 1]
                        }`;
                  }
                )
              : element.markdown
          }`}</span>
        );
      case "needle":
        const needle = ctx.needles[element.ref];
        if (needle) {
          return (
            <>
              {needle.type} {needle.size ? `${needle.size} cm ` : ""} nr.{" "}
              {needle.diameter}
            </>
          );
        } else {
          return "Mangler pinne";
        }
      case "calculationResult":
        const elem = element;
        const { elements, nodes, edges } = ctx.calculationResults.graph;
        const measure =
          ctx.calculationResults.tables[elem.tableId].measures[elem.measureId];

        let measureString = "";
        let measureUnit = "";
        let measureDirection = "";
        if (measure === undefined) {
          measureString = "BEREGNING SLETTET";
        } else if (measure.kind === "node") {
          measureString = sizes
            .map((size, index) => {
              const element = elements[nodes[measure.nodes[size]].element];
              const value = Math.abs(Math.round(element.displayValue ?? 0));

              if (measureUnit === "") measureUnit = element.unit;
              if (measureDirection === "") measureDirection = element.direction;
              return index % 2 ? `(${value})` : value;
            })
            .join(" ");
        } else {
          measureString = sizes
            .map((size, index) => {
              const edge = edges[measure.edges[size]];
              let element;
              if (elem.edgeKind === "horizontal") {
                element = elements[edge.horizontalChangeElement];
              } else if (elem.edgeKind === "frequency") {
                element = elements[edge.frequencyElement];
              } else {
                element = elements[edge.verticalDistanceElement];
              }

              const value = Math.abs(Math.round(element.displayValue ?? 0));

              if (measureUnit === "") measureUnit = element.unit;
              if (measureDirection === "") measureDirection = element.direction;

              return index % 2 ? `(${value})` : value;
            })
            .join(" ");
        }

        if (measureUnit === "Mask") {
          if (measureDirection === "Horizontal") {
            measureUnit = "masker";
          } else {
            measureUnit = "omganger";
          }
        } else if (measureUnit === "Cm") {
          measureUnit = "cm";
        }

        return `${measureString} ${measureUnit}`;
      case "color":
        const color = ctx.yarnColors[element.ref] as YarnColor;
        let displayName = "";
        if (!color) {
          displayName = `Farge ${element.ref + 1}`;
        } else if (!color.name) {
          displayName = `Farge ${element.ref + 1}`;
        } else if (color.name && color.yarn) {
          displayName = `${color.yarn.name} - ${color.name}`;
        } else if (color.name) {
          // Hex colors can now also have custom names
          displayName = `${color.name} (${color.hex})`;
        } else {
          displayName = "FORSVUNNET FARGE";
        }
        //style="background-color:${color.hex}; width:14px; height: 14px; border: 1px solid black; display: inline-block
        return (
          <>
            <p
              style={{
                backgroundColor: color.hex ?? "#fff",
                height: "14px",
                width: "14px",
                border: "1px solid black",
                display: "inline-block",
              }}
            ></p>{" "}
            <strong>{displayName}</strong>
          </>
        );
      case "diagram":
        const diagram = ctx.diagrams[element.ref];
        return <a href={`#${diagram.id}`}>{diagram.name}</a>;

      case "textlink":
        return <a href={`#${element.link}`}>{element.markdown}</a>;

      case "sizeFilteredString":
        return sizes
          .map((size, index) =>
            index % 2
              ? `(${element.text[size] ?? "MANGLER"})`
              : element.text[size] ?? "MANGLER"
          )
          .join(" ");

      default:
        console.log("Failed to draw: ", element);
        return "";
    }
  };

  const renderSectionElement = (element: PatternElement) => {
    switch (element.type) {
      case "diagramSection":
        return <DiagramSection sectionData={element} />;

      case "figure":
        return <MediaSection element={element} showOption={false} />;

      case "paragraphBreak":
        return <div style={{ width: "100%", height: "16px" }} />;

      case "heading":
        return <h2>{element.markdown}</h2>;
      case "subheading":
        return <h3>{element.markdown}</h3>;
      default:
        break;
    }
  };

  const renderSections = () => {
    let sections = [];
    let sectionString = [];
    for (let patternElement of patternElements) {
      // Construct a string that equals a paragprah
      if (inlineElementTypes.indexOf(patternElement.type) >= 0) {
        const elementString = renderParagraphElement(patternElement);
        sectionString.push(elementString);
      }
      // A parahraph is broken up when a DiagramSection, Figure or Parahraph break or heading is encountered
      else {
        sections.push(sectionString);
        sectionString = [];
        sections.push(renderSectionElement(patternElement));
      }
    }

    // Append any stragglers
    if (sectionString.length > 0) {
      sections.push(sectionString);
    }
    return sections;
  };

  return <div>{renderSections().map((item) => item ?? <></>)}</div>;
};

export default PatternElementList;
