import { useState } from "react";
import GridButton from "./components/GridButton";

function SplitViewMiniature(props: any) {
  const fullscreenStyle = [
    {
      position: "relative",
      height: "100%",
    },
    {
      position: "absolute",
      right: "30px",
      top: "20px",
      height: "25%",
      width: "25%",
      zIndex: 100,
      border: "1px solid #ede9e6",
    },
  ] as const;
  const fullscreenButton = () => (
    <div
      style={{
        margin: "5px",
        position: "absolute",
        zIndex: 200,
      }}
    >
      <GridButton
        customStyle={{
          height: "35px",
          minWidth: "35px",
          maxWidth: "35px",
        }}
        onClick={() => {
          props.hasSwitched(!props.switched);
        }}
        small
      >
        <img
          src="/3d/ui/fullscreen.png"
          style={{ width: "25px", height: "25px" }}
        />
      </GridButton>
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "var(--whiteish)",
        paddingBottom: "0vh",
        position: "relative",
        flexDirection: "column",
        height: "100%", // Would really like a flex: 1 solution, but I couldnt find one
      }}
    >
      <div style={fullscreenStyle[+props.switched]}>
        {props.switched && fullscreenButton()}
        {props.children[0]}
      </div>
      <div style={fullscreenStyle[+!props.switched]}>
        {!props.switched && fullscreenButton()}
        {props.children[1]}
      </div>
    </div>
  );
}

export default SplitViewMiniature;
