import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from "./components/LoginState";

function LogoutButton() {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  return <button onClick={() => dispatch(logout())} style={{ maxWidth: "102px",
    background: "var(--lightGreen)",
    borderRadius: "4px",
    border: "none",
    color: "var(--darkest-brown)",
    margin: "10px",
    }}>{t("logout")}</button>
}

export default LogoutButton;
