import React, { useState } from "react";
import { EyeDropper } from "react-eyedrop";

export const ColorPicker = () => {
  const [eyeDrop, setEyeDrop] = useState("#00FF00");

  return (
    <div style={{ position: "fixed", right: 22, top: "50%" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <EyeDropper onChange={(e) => setEyeDrop(e.hex)} />
        <div style={{ height: 22, width: 22, backgroundColor: eyeDrop }} />
        <p>{eyeDrop}</p>
      </div>
    </div>
  );
};
