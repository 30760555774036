import React from "react";
import { useLocation } from "react-router";
import styles from "../Styles/Sections.module.css";
import LogoutButton from "../../Login/LogoutButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";


function useQuery() {
  const { search } = useLocation();


  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StatusSection() {
  let query = useQuery();
  let auth = useSelector((state: RootState) => state.loginState);

  const response = query.get("s");

  if (auth.user && !auth.user.subscriber) {
    return <div className={styles.statusSection}> Du har ikke tilgang til Woolit Design Studio Pro <LogoutButton></LogoutButton> </div>;
  }

  if (!response) {
    return null;
  }
  
  /* if (response === "noaccess") {
    return <div className={styles.statusSection}> Du har ikke tilgang til Woolit Design Studio Pro <LogoutButton></LogoutButton></div>;
  } */ else if (response === "loginfailed") {
    return <div className={styles.statusSection}> Inlogging feilet</div>;
  }
  return null;
}

export default StatusSection;
