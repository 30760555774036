import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { openMediaSubDrawer } from "../../store/writerSlice";
import styles from "./MediaList.module.css";
import { RootState } from "../../store/store";

interface PropType {
  media: String[];
}

function MediaList(props: PropType) {
  // const { media } = props;
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      {/* <div className={styles.listContainer}>
        {Object.keys(media).map((image, index) => (
          <Imgix
            key={index}
            src={`${imgixUrl}/${image}`}
            width={96}
          />
        ))}
      </div>
      <hr className={styles.spacer} /> */}
      <button
        className={styles.new}
        onClick={() => dispatch(openMediaSubDrawer())}
      >
        Velg media
      </button>
    </div>
  );
}

function AwareMediaList() {
  const media = useSelector((state: RootState) => state.pattern.media);

  return <MediaList media={Object.keys(media)} />;
}

export default AwareMediaList;
export { MediaList };
