import React from "react";
import Imgix from "react-imgix";
import { useDispatch, useSelector } from "react-redux";
import { imgixUrl } from "../../imgixUrl";
import Button from "../../ISO/Components/Button";
import { Image, YarnProfileVariant } from "../../store/pattern";
import { removeVariantImage } from "../../store/patternSlice";
import { RootState } from "../../store/store";
import styles from "./VariantImageList.module.css";

interface PropType {
  variantIndex?: number;
  variantId?: string;
  variant?: YarnProfileVariant;
}

const VariantImageList = (props: PropType) => {
  const { variantIndex, variantId, variant } = props;

  const dispatch = useDispatch();
  const images = useSelector((state: RootState) => {
    if (variant) {
      return variant.images;
    } else if (variantIndex) {
      return state.pattern.meta.variants[variantIndex].images;
    } else if (variantId) {
      const index = state.pattern.meta.variants.findIndex(
        (v) => v.id === variantId
      );
      return state.pattern.meta.variants[index].images;
    }
  });

  return (
    <div className={styles.container}>
      {images?.map((image: Image, index: number) => (
        <div className={styles.imageContainer} key={`${variantIndex}:${index}`}>
          <Imgix src={`${imgixUrl}/${image.src}`} className={styles.image} />
          <p className={styles.altText}>{image.alt}</p>
          <Button
            className={styles.removeButton}
            contrast
            onClick={() =>
              dispatch(
                removeVariantImage({
                  variantIndex,
                  variantId: variant?.id ?? variantId,
                  imageIndex: index,
                })
              )
            }
          >
            Fjern
          </Button>
        </div>
      ))}
    </div>
  );
};

export default VariantImageList;
