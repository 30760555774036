import { RootState } from "../store/store";
import {
  Document,
  ExternalHyperlink,
  HeadingLevel,
  PageBreak,
  Paragraph,
  ParagraphChild,
  TextRun,
} from "docx";
import {
  Color,
  DiagramData,
  Meta,
  Needle,
  PatternElement,
  Span,
  YarnColor,
  YarnProfileVariant,
} from "../store/pattern";
import {
  CalculationSliceType,
  GlobalGauge,
} from "../CalculationGraph/calculations";
import { User } from "../Login/components/LoginState";

const inlineElementTypes = [
  "span",
  "color",
  "diagram",
  "needle",
  "calcFromTable",
  "calculationResult",
  "textlink",
  "sizeFilteredString",
  "paragraphBreak",
];

const constructOptions = (element: PatternElement) => {
  const elem = element as Span;
  const options: any = {};

  if (elem.style === "bold") {
    options["bold"] = true;
  }
  if (elem.style === "italic") {
    options["italic"] = true;
  }

  return options;
};

const renderElement = (
  element: PatternElement,
  sizes: string[],
  variants: YarnProfileVariant[],
  needles: { [key: string]: Needle },
  diagrams: { [key: string]: DiagramData },
  calculationResults: CalculationSliceType,
  exportSizes: string[],
  exportVariant: string
) => {
  switch (element.type) {
    case "span":
      return new TextRun({
        text: element.markdown,
        ...constructOptions(element),
      });
    case "needle":
      const needle = needles[element.ref];
      return new TextRun({
        text: `${needle.type} ${needle.size ? `${needle.size} cm ` : ""} nr. ${
          needle.diameter
        }`,
      });
    case "calculationResult":
      const elem = element;
      const { elements, nodes, edges } = calculationResults.graph;
      const measure =
        calculationResults.tables[elem.tableId].measures[elem.measureId];

      let measureString = "";
      let measureUnit = "";
      let measureDirection = "";
      if (measure === undefined) {
        measureString = "BEREGNING SLETTET";
      } else if (measure.kind === "node") {
        measureString = sizes
          .map((size, index) => {
            const element = elements[nodes[measure.nodes[size]].element];
            const value = Math.abs(Math.round(element.displayValue ?? 0));

            if (measureUnit === "") measureUnit = element.unit;
            if (measureDirection === "") measureDirection = element.direction;
            return index % 2 ? `(${value})` : value;
          })
          .join(" ");
      } else {
        measureString = sizes
          .map((size, index) => {
            const edge = edges[measure.edges[size]];
            let element;
            if (elem.edgeKind === "horizontal") {
              element = elements[edge.horizontalChangeElement];
            } else if (elem.edgeKind === "frequency") {
              element = elements[edge.frequencyElement];
            } else {
              element = elements[edge.verticalDistanceElement];
            }

            const value = Math.abs(Math.round(element.displayValue ?? 0));

            if (measureUnit === "") measureUnit = element.unit;
            if (measureDirection === "") measureDirection = element.direction;

            return index % 2 ? `(${value})` : value;
          })
          .join(" ");
      }
      if (measureUnit === "Mask") {
        if (measureDirection === "Horizontal") {
          measureUnit = "masker";
        } else {
          measureUnit = "omganger";
        }
      } else if (measureUnit === "Cm") {
        measureUnit = "cm";
      }
      return new TextRun({ text: `${measureString} ${measureUnit}` });
    case "color":
      const color = variants[variants.findIndex((v) => v.id === exportVariant)]
        .colors[element.ref] as YarnColor;
      let displayName = "";
      if (!color) {
        displayName = `Farge ${element.ref + 1}`;
      } else if (!color.name) {
        displayName = `Farge ${element.ref + 1}`;
      } else if (color.name && color.yarn) {
        displayName = `${color.yarn.name} - ${color.name}`;
      } else if (color.name) {
        // Hex colors can now also have custom names
        displayName = `${color.name} (${color.hex})`;
      } else {
        displayName = "FORSVUNNET FARGE";
      }
      return new TextRun({ text: displayName });
    case "diagram":
      const diagram = diagrams[element.ref];
      return new TextRun({ text: diagram.name });
    case "textlink":
      return new ExternalHyperlink({
        children: [
          new TextRun({
            text: element.markdown,
            style: "Hyperlink",
          }),
        ],
        link: element.link,
      });
    case "sizeFilteredString":
      return new TextRun({
        text: exportSizes
          .map((size, index) =>
            index % 2 ? `(${element.text[size]})` : element.text[size]
          )
          .join(" "),
      });
    case "paragraphBreak":
      return new TextRun({ break: 2 });
    default:
      return new TextRun({ text: "FEIL I EKSPORT" });
  }
};

const renderParagraphs = (
  patternElements: PatternElement[],
  sizes: string[],
  variants: YarnProfileVariant[],
  needles: { [key: string]: Needle },
  diagrams: { [key: string]: DiagramData },
  calculationResults: CalculationSliceType,
  exportSizes: string[],
  exportVariant: string
) => {
  const paragraphs: Paragraph[] = [];
  let paragraphString: ParagraphChild[] = [];

  const appendParagraphString = () => {
    //Add all inline elements to paragraph
    const p = new Paragraph({
      children: paragraphString,
    });
    //Clear string
    paragraphString = [];
    paragraphs.push(p);
  };

  for (let element of patternElements) {
    // Construct a string that equals a paragprah
    if (inlineElementTypes.indexOf(element.type) !== -1) {
      const elementString = renderElement(
        element,
        sizes,
        variants,
        needles,
        diagrams,
        calculationResults,
        exportSizes,
        exportVariant
      );
      paragraphString.push(elementString);
    } else if (element.type === "heading" || element.type === "subheading") {
      // Append paragraph if it exists
      appendParagraphString();

      //Add new heading
      paragraphs.push(
        new Paragraph({
          text: element.markdown,
          heading:
            element.type === "heading"
              ? HeadingLevel.HEADING_1
              : HeadingLevel.HEADING_2,
        })
      );
    } else {
      appendParagraphString();
    }
  }

  if (paragraphString.length > 0) {
    appendParagraphString();
  }
  return paragraphs;
};

const renderKeyValue = (key: string, value: string) => {
  return [new TextRun(`${key}: ${value}`), new TextRun({ break: 2 })];
};

const renderMetaInfo = (
  meta: Meta,
  { horizontal, vertical }: GlobalGauge,
  needles: { [key: string]: Needle },
  exportSizes: string[],
  exportVariant: string
) => {
  const {
    variants,
    title,
    description,
    roles,
    clothingMetaData,
    design,
    designer,
    collection,
    sizeCategory,
    needleOrder,
    gaugeNeedle,
    yarnProducer,
  } = meta;

  const t = new Paragraph({
    text: title,
    heading: HeadingLevel.HEADING_1,
  });

  const desc = description
    ? new Paragraph({
        children: [new TextRun(description), new TextRun({ break: 2 })],
      })
    : new Paragraph({});

  const activeVariant =
    variants.find((v) => v.id === exportVariant) ?? variants[0];

  const filteredAmonts = Object.entries(activeVariant.amounts)
    .filter(([size, amounts]) => exportSizes.includes(size))
    .sort((a, b) => exportSizes.indexOf(a[0]) - exportSizes.indexOf(b[0]));

  const exportAmounts = activeVariant.colors.map((color, i) => {
    return [
      new TextRun(
        `Farge ${i + 1}: ${filteredAmonts
          .map(([size, amounts]) => amounts[i])
          .join(" ")}`
      ),
      new TextRun({ break: 2 }),
    ];
  });

  const patternNeedles = Object.entries(needles)
    .sort((a, b) => needleOrder.indexOf(a[0]) - needleOrder.indexOf(b[0]))
    .map(([key, needle], i) => {
      return [
        new TextRun(
          needle.type === "rundpinne"
            ? `${needle.type} ${needle.size}cm, nr. ${needle.diameter}`
            : `${needle.type} nr. ${needle.diameter}`
        ),
        new TextRun({ break: 2 }),
      ];
    });

  const yarnInfo = activeVariant.colors.map((c, i) => {
    const color = c as YarnColor;
    return [
      new TextRun(
        color.yarn
          ? `Farge ${i + 1}: ${color.yarn.name} - ${color.name}`
          : `Farge ${i + 1}: ${color.hex}`
      ),
      new TextRun({ break: 2 }),
    ];
  });

  const metaData = new Paragraph({
    children: [
      new TextRun("Roller:"),
      new TextRun({ break: 2 }),
      ...renderKeyValue("Designer", designer),
      ...roles.map((role) => renderKeyValue(role.title, role.value)).flat(),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun("Plagginformasjon:"),
      new TextRun({ break: 2 }),
      ...renderKeyValue("Design", design),
      ...renderKeyValue("Kolleksjon", collection),
      ...clothingMetaData
        .map((meta) => renderKeyValue(meta.title, meta.value))
        .flat(),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun(sizeCategory ?? ""),
      ...renderKeyValue(
        "   Størrelser",
        exportSizes.map((size, i) => (i % 2 ? `(${size})` : size)).join(" ")
      ),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun("Veiledende pinnevalg:"),
      new TextRun({ break: 2 }),
      ...patternNeedles.flat(),
      new TextRun({ break: 2 }),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun("Garn:"),
      new TextRun({ break: 2 }),
      ...(yarnProducer ? renderKeyValue("Produsent", yarnProducer) : []),
      ...yarnInfo.flat(),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun("Garnforbruk: "),
      new TextRun({ break: 2 }),
      ...exportAmounts.flat(),
      new TextRun({ break: 2 }),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      new TextRun("Strikkefasthet:"),
      new TextRun({ break: 2 }),
      ...renderKeyValue(
        "I bredden",
        `${horizontal.stitches}m på ${horizontal.length}cm`
      ),
      ...renderKeyValue(
        "I høyden",
        `${vertical.stitches}m på ${vertical.length}cm`
      ),
      ...(gaugeNeedle
        ? renderKeyValue(
            "På pinne",
            `${needles[gaugeNeedle].type} nr. ${needles[gaugeNeedle].diameter}`
          )
        : []),
      new TextRun({ break: 2 }),
      new TextRun("----"),
      new TextRun({ break: 2 }),
      // ==============
      new PageBreak(),
    ],
  });

  return [t, desc, metaData];
};

export const exportToDocx = (
  meta: Meta,
  needles: { [key: string]: Needle },
  patternElements: PatternElement[],
  user: User | undefined,
  gauge: GlobalGauge,
  diagrams: { [key: string]: DiagramData },
  calculationResults: CalculationSliceType,
  exportSizes: string[],
  exportVariant: string
) => {
  const doc = new Document({
    creator: `${user?.firstName} ${user?.lastName}`,
    description: meta.description || "",
    title: meta.title,
    sections: [
      {
        children: [
          ...renderMetaInfo(meta, gauge, needles, exportSizes, exportVariant),
          ...renderParagraphs(
            patternElements,
            meta.sizes,
            meta.variants,
            needles,
            diagrams,
            calculationResults,
            exportSizes,
            exportVariant
          ),
        ],
      },
    ],
    styles: {
      paragraphStyles: [
        {
          id: "Heading1",
          name: "Heading 1",
          run: {
            size: 32,
            color: "#8f6d57",
          },
        },
        {
          id: "Heading2",
          name: "Heading 2",
          run: {
            size: 28,
            color: "#8f6d57",
          },
        },
      ],
    },
  });
  return doc;
};
