import React, { useEffect, useState } from "react";
import Button from "../../ISO/Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setDisableModel } from "../../store/modelSlice";
import { RootState } from "../../store/store";
import { setDiagramView } from "../../store/gridSlice";

export const FormCard = (props: {
  disableModel: boolean;
  text: string;
  src: string;
}) => {
  const dispatch = useDispatch();
  const { disableModel } = useSelector((state: RootState) => state.model);
  return (
    <div>
      <Button
        active={disableModel === props.disableModel}
        onClick={() => {
          dispatch(setDiagramView(props.disableModel ? "grid" : "model"));
          dispatch(setDisableModel(props.disableModel));
        }}
        style={{
          height: "60px",
          padding: "0px",
        }}
        contrast
      >
        <img
          src={props.src}
          style={{
            boxSizing: "border-box",
            borderRadius: "2px",
            height: "56px",
          }}
        ></img>
      </Button>
      <p
        style={{
          marginTop: "4px",
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        {" "}
        {props.text}
      </p>
    </div>
  );
};
