import { Symbols } from "@iterate/woolit-components";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import { GridSymbol, DynamicGridSymbol } from "../store/pattern";

interface SymbolListItem extends GridSymbol {
  listIndex: number;
}

interface DynamicSymbolListItem extends DynamicGridSymbol {
  listIndex: number;
}

export const splitIntoSingleAndDynamicSymbols = (
  symbols: (GridSymbol | DynamicGridSymbol)[]
) => {
  const singleSymbols: SymbolListItem[] = [];
  const dynamicSymbols: DynamicSymbolListItem[] = [];
  symbols.forEach(({ key, label }, i) => {
    if (isDynamic(key)) {
      dynamicSymbols.push({
        key,
        label,
        listIndex: i,
      });
    } else {
      singleSymbols.push({ key: key as Symbols, label, listIndex: i });
    }
  });

  return { singleSymbols, dynamicSymbols };
};
