import React from "react";

import FacebookIconRound from "../../icons/FacebookIconRound";
import { backendApiAddress } from "../../../../backendApi";

type Props = {
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  className?: string;
};

const FacebookButton: React.FC<React.PropsWithChildren<Props>> = ({
  target = "_blank",
  className,
}) => {
  return (
    <a
      href={`https://www.facebook.com/v8.0/dialog/oauth?client_id=352549798431521&scope=public_profile,email&redirect_uri=${backendApiAddress}/api/login/facebook_return_ok`}
      target={target}
      style={{
        display: "flex",
        padding: "1rem",
        color: "#ffffff",

        lineHeight: "1",
        whiteSpace: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        columnGap: "0.5rem",
        background: "#1877F2",
      }}
    >
      <FacebookIconRound />
      <span>Continue with Facebook</span>
    </a>
  );
};

export default FacebookButton;
