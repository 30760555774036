import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import { adminFetchAllPatterns } from "../store/adminSlice";

import PatternSection from "./PatternSection";

function PatternAdmin() {
  const { remote: patterns } = useSelector(
    (state: RootState) => state.admin.pattern
  );

  const dispatch = useDispatch();

  const fetchPatterns = useCallback(() => {
    dispatch(adminFetchAllPatterns());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(patterns).length === 0) {
      fetchPatterns();
    }
  }, [patterns, fetchPatterns]);
  return (
    <div>
      <h3>All patterns</h3>
      <PatternSection
        patterns={Object.values(patterns).sort(
          (pattern1, pattern2) => pattern1.id - pattern2.id
        )}
      />
    </div>
  );
}

export default PatternAdmin;
