import React from "react";
import styles from "./StatusButton.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Status } from "../store/pattern";
import { Modal } from "../CommonComponents/Modal";
import { ChangeStatusModal } from "./ChangeStatusModal";
import { useModal } from "../CommonComponents/useModal";
import { ChangeStatusButton } from "./ChangeStatusButton";
import { useTranslation } from "react-i18next";

type PropType = {
  status?: Status;
  patternId: number;
};

const StatusButton = (props: PropType) => {
  const { isShown, toggle } = useModal();
  const onConfirm = () => toggle();
  const onCancel = () => toggle();
  const { t } = useTranslation();



  const status: Status = props.status ?? {
    statusMsg: "Utkast",
  };

  const user = useSelector((state: RootState) => state.loginState.user);

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText="Endre oppskriftsstatus"
          modalContent={
            <ChangeStatusModal
              onConfirm={onConfirm}
              onCancel={onCancel}
              message="Sett status:"
              user={user!}
              status={status}
              patternId={props.patternId}
            />
          }
        />
        <ChangeStatusButton showModal={toggle} statusMsg={status.statusMsg} />
      </div>
    </div>
  );
};
export default StatusButton;
