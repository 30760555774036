import { useD3 } from "./useD3";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as d3 from "d3";

import { updateCell } from "../store/gridSlice";
import styles from "./diagramViewer.module.css";
import imgurl from "./stitchOverlay6.png";

import generateLinks from "./linkGenerator";
import { Modal } from "../CommonComponents/Modal";
import { useModal } from "../CommonComponents/useModal";

function DiagramViewer() {
  const { isShown, toggle, setIsShown } = useModal();
  const [stateNodes, setStateNodes] = useState({});
  const dispatch = useDispatch();
  const diagram = useSelector((state) => state.grid.grid);
  const activeDiagram = useSelector((state) => state.grid.activeDiagram);
  const { gridWidth, gridHeight } = useSelector((state) => state.grid.grid);
  const dupeDiag = diagram;
  const repetitions = 3;
  const dupeResult = {
    grid: [],
    gridHeight: 1,
    gridWidth: 1,
    colors: {},
  };
  function duplicateDiagram(diagram, repetitions) {
    // gå gjennom hver index i 1d array
    var temp = [];
    if (diagram.gridHeight * diagram.gridWidth > 4000) {
      repetitions = 1;
    }
    for (let i = 0; i < diagram.gridHeight; i++) {
      //legg til array for hver repetisjon
      for (let j = 0; j < repetitions; j++) {
        temp[i] = diagram.grid[i].concat(dupeDiag.grid[i]).concat(temp[i]); //dupliser innholdet i 2d
      }
    }
    dupeResult.grid = temp;
    dupeResult.gridHeight = diagram.gridHeight; //høyde er høyde
    dupeResult.gridWidth = diagram.gridWidth * repetitions; //ganger med antall repetisjoner
    dupeResult.colors = diagram.colors;
    dupeResult.actualGridWidth = diagram.gridWidth;
  }
  duplicateDiagram(diagram, repetitions);
  let width = dupeResult.gridWidth * 100,
    height = dupeResult.gridHeight * 100;
  console.log(width, height);
  // var svg = d3
  //   .select("#viz")
  //   .attr("viewBox", "0 0 " + width + " " + height)
  //   .attr("perserveAspectRatio", "xMidYMin meet");
  // .attr("perserveAspectRatio", "xMinYMin meet");

  const importData = generateLinks(dupeResult);
  const colors = useSelector((state) => state.color);
  const activeColors = useRef();
  useEffect(() => {
    activeColors.current = colors;
    createStitchDefs(colors);
  }, [colors.colors]);

  let activeColorIndex = useSelector((state) => state.color.activeIndex);
  const colorRef = useRef();
  useEffect(() => {
    colorRef.current = activeColorIndex;
  });

  let gridColors = useSelector((state) => state.grid.grid.grid);
  const cellColorRef = useRef();
  useEffect(() => {
    cellColorRef.current = gridColors;
  }, [gridColors, colorRef.current]);

  function createStitchDefs(colors) {
    var join = d3
      .selectAll("svg#viz")
      .selectAll("defs")
      .data([colors.activeColor])
      .join(
        (enter) => enter.append("defs").attr("id", "stitch"),
        (update) => update,
        (exit) => exit.remove
      );
    var defs = join;

    defs
      .selectAll("pattern")
      .data(colors.colors)
      .enter()
      .append("svg:pattern")
      .attr("id", (d, i) => {
        return "stitch-" + i;
      })
      .attr("class", "stitchDef")
      .attr("width", 1)
      .attr("height", 1)

      .append("svg:image")
      .attr("xlink:href", imgurl)
      .attr("width", 52)
      .attr("height", 52);

    defs
      .selectAll("defs")
      .data([colors.colors])
      .join(
        (enter) =>
          enter
            .selectAll("pattern")
            .selectAll("rect")
            .data((d) => [d])
            .join("rect")
            .attr("width", 52)
            .attr("height", 52)
            .style("mix-blend-mode", "multiply")
            .attr("id", (d) => "rect-" + d.sku)
            .attr("fill", (d) => {
              return d.hex;
            }),
        (update) => update,
        (exit) => exit.remove
      );
  }

  var color;
  if (colors.colors) {
    let colorMap = Object.values(colors.colors).map((e) => e.hex);
    color = colorMap;
  } else {
    console.log("no active colors");
    color = d3.scaleOrdinal(["#41521F", "#F5C396", "#DB3069 ", "#D0B17A"]);
  }

  function sum(array){
    return array.reduce((a, b) => a + b, 0)
  }

  // Update simulation if holes are added or removed
  function countHoles(){
    return sum(diagram.grid.map(row => sum(row.map(cell => cell.color === -1 ? 1 : 0))))
  }

  useEffect(() => {
    setIsShown(true);
    console.time("Rendering");
  }, [activeDiagram, gridWidth, gridHeight, countHoles()]);

  //*********************************************************************** */
  const svgRef = useD3(renderDiagram, [activeDiagram, gridWidth, gridHeight, countHoles()]);
  function renderDiagram(svg) {
    svg
      .selectAll("*")
      .remove()
      .select("div#svgContainer")
      .append("svg")
      .attr("id", "viz");
    d3.selectAll(".node").remove();
    if (importData.nodes.length === 0) {
      return;
    }
    const data = importData;
    let simulation, voronoi, node;

    function colorByGroup(d) {
      return color[d.color];
    }

    createStitchDefs(colors);

    //*!SaveSVG code under here
    //*!*!*
    // when called, will open a new tab with the SVG
    // which can then be right-clicked and 'save as...'
    // function saveSVG() {
    //   // get styles from all required stylesheets
    //   // http://www.coffeegnome.net/converting-svg-to-png-with-canvg/
    //   var style = "\n";
    //   for (var i = 0; i < document.styleSheets.length; i++) {
    //     var sheet = document.styleSheets[i];
    //     if (sheet.href) {
    //     }
    //   }

    //   var svgImg = d3.select("wrapper"),
    //     img = new Image(),
    //     serializer = new XMLSerializer();

    //   // prepend style to svg
    //   svgImg.insert("defs", ":first-child");
    //   d3.select("svg defs")
    //     .append("style")
    //     .attr("type", "text/css")
    //     .html(style);

    //   // generate IMG in new tab
    //   var svgStr = serializer.serializeToString(svg.node());
    //   img.src =
    //     "data:image/svg+xml;base64," +
    //     window.btoa(unescape(encodeURIComponent(svgStr)));
    //   window.open().document.write('<img src="' + img.src + '"/>');
    // }
    // // save button
    // d3.select("body")
    //   .append("button")
    //   .on("click", saveSVG)
    //   .attr("class", "btn btn-success");
    // !*!*

    var zoomable = svg
      .append("g")
      .attr("class", "wrapper")
      .attr("id", "wrapper");

    const zoomCheap = d3
      .zoom()
      .scaleExtent([0.5, 8])
      .on("zoom", zooming)
      .on("end", dragended, zoomend);
    const zoom = d3.zoom().scaleExtent([0.5, 8]).on("zoom", zoomed);

    d3.select("#zoom_in").on("click", function () {
      console.log("Zoomed in");
      svg.call(zoom.scaleBy, 1.5);
    });
    d3.select("#zoom_out").on("click", function () {
      console.log("Zoomed out");
      svg.call(zoom.scaleBy, 0.5);
    });
    function zoomed({ transform }) {
      d3.select("g.wrapper").attr("transform", transform);
    }

    function dragended({ transform }) {
      if (data.nodes.length < 5000) {
        node
          .selectAll("circle")
          .attr("cursor", "default")
          .attr("fill", (d) => "url(#stitch-" + d.color + ")");
      }
      if (data.nodes.length > 5000) {
        d3.select("g.wrapper").attr("transform", transform);
      }
    }

    function zoomend({ transform }) {
      console.log("zoomend");
      if (data.nodes.length > 5000) {
        d3.select("g.wrapper").attr("transform", transform);
      }
    }

    function zooming({ transform }) {
      if (data.nodes.length > 5000) {
      } else {
        d3.select("g.wrapper").attr("transform", transform);
        node.selectAll("circle").attr("fill", colorByGroup);
      }
    }

    data.colors = color;

    //*Set id and default stitch angle
    data.nodes.forEach(function (d, i) {
      d.id = i;
      d.angle = 0;
    });
    //*Filter away casted nodes */
    data.nodes = data.nodes
      .filter(function (node) {
        return node.value !== 1;
      })
      .map((node, i) => {
        node.index = i;
        return node;
      });

    var maxX = 0;
    var maxY = 0;
    maxX = d3.max(data.nodes, (d) => d.sourceX);
    maxY = d3.max(data.nodes, (d) => d.sourceY);

    const nodesByRow = d3.group(data.nodes, (d) => d.sourceY);
    for (let i = 0; i < maxY; i++) {
      if (nodesByRow.get(i) === undefined) {
        console.error("Ugyldig diagram! Sjekk rad " + (maxY + 1 - i));
        alert("Ugyldig diagram! Sjekk rad " + (maxY + 1 - i));
        return;
      }
    }
    const bottomNodes = nodesByRow.get(nodesByRow.size - 1);
    const topNodes = nodesByRow.get(0);

    //*Initial positioning

    var posY = d3
      .scaleLinear()
      .domain([0, maxY])
      // .range([0, height / 2 - window.innerHeight]);
      .range([window.innerHeight / 2 - 200, window.innerHeight / 2 + 200]);
    var posX = d3
      .scaleLinear()
      .domain([0, maxX])
      .range([window.innerWidth / 2 - 200, window.innerWidth / 2 + 200]);
    // .range([0, width / 2 - window.innerWidth]);

    //* Update links with correct sources and targets */
    data.links = data.links.map((link) => {
      //* Hent source og target fra data.nodes etter id
      const source = data.nodes.find((node) => link.source === node.id);
      const target = data.nodes.find((node) => link.target === node.id);

      const newSource = source.index;
      const newTarget = target.index;
      const newDirection = link.direction;
      return {
        source: newSource,
        target: newTarget,
        direction: newDirection,
      };
    });
    //* sourceX and sourceY is the row and columns of each node (for pre-sim positioning)
    maxX = d3.max(data.nodes, (d) => d.sourceX);
    maxY = d3.max(data.nodes, (d) => d.sourceY);

    //* Set initial coordinates for better orientation of nodes
    data.nodes.forEach(function (d) {
      d.x = posX((d.sourceX * maxX) / 10);
      d.y = posY((d.sourceY * maxY) / 10);
    });

    //* Stitch direction
    const linksByDir = d3.group(data.links, (d) => d.direction);
    const yLinks = linksByDir.get("y");
    if (yLinks === undefined) {
      console.log("yLinks undefined");
      return;
    }

    // //* Set initial voronoi tesselation
    // voronoi = d3.Delaunay.from(
    //   data.nodes,
    //   (d) => d.x,
    //   (d) => d.y
    // ).voronoi([5, 5, width - 5, height - 5]);

    //* Cell construction, is clipped from voronoi
    const cell = zoomable
      .append("defs")
      .selectAll("clipPath")
      .data(data.nodes)
      .join("clipPath")
      .attr("id", (d, i) => (d.id = "cell-" + i))
      .append("path");
    // .attr("d", (d, i) => voronoi.renderCell(i));

    //* Create nodes inside the clip path from cell
    node = zoomable
      .append("g")
      .attr("class", "zoomable")
      .attr("id", "zoomable")
      .selectAll("g")
      .data(data.nodes)
      .join("g")
      .attr("clip-path", (d) => "url(#cell-" + d.index + ")")

      .append("g")
      .attr("class", "node")
      .attr("id", function (d) {
        return "node-" + d.id;
      })

      .on("click", function (event, d) {
        const e = node.nodes();
        let index = e.indexOf(this);
        console.log(index);
        let thisRowIndex = d.sourceY;
        let rowLength = node
          .filter(function (d) {
            return d.sourceY === thisRowIndex;
          })
          .size();
        rowLength = rowLength / repetitions;
        index = index % rowLength;
        //* Filter to selected row and equal cells
        node
          .filter(function (d) {
            return d.sourceY === thisRowIndex;
          })
          .filter(function (d) {
            return d.index % rowLength === index;
          })
          .select(".stitch")
          .attr("fill", function (d) {
            d.color = colorRef.current;
            if (data.nodes.length < 5000) {
              return "url(#stitch-" + colorRef.current + ")";
            } else {
              return colorByGroup;
            }
          });

        const cellData = { color: colorRef.current };
        dispatch(
          updateCell({
            row: d.sourceY,
            col: d.sourceX % d.origGridWidth,
            cellData,
          })
        );
      });

    // * Coloring the nodes here during render

    node.append("circle").attr("class", "stitch").attr("r", 25);
    // .attr("fill", colorByGroup) //* performance increase?
    // .attr("stroke", "grey");

    simulation = d3
      .forceSimulation()
      .force("center", d3.forceCenter(width / 2, height / 2));

    // ! On end /////////////////////////////////
    simulation.on("end", function () {
      console.log("Simulation ended, appending.. ");
      setIsShown(false);

      // update voronoi calculation
      voronoi = d3.Delaunay.from(
        data.nodes,
        (d) => d.x,
        (d) => d.y
      ).voronoi([10, 10, width - 10, height - 10]);
      // Update clip cell path from voronoi
      cell.attr("d", (d, i) => voronoi.renderCell(i));
      svg
        .attr("width", window.innerWidth * 2)
        .attr("height", window.innerHeight * 2); //TODO OVERFLOW SCROLL PÅ EN AV GREIENE ELLER AUTO IDK
      // .style("transform", "translateY(50%)");
      // .attr("padding-left", window.innerWidth);
      // svg.attr("height", newHeight);
      yLinks.forEach(function (d) {
        const targetNode = data.nodes[d.target.index];
        const sourceNode = data.nodes[d.source.index];
        let angle =
          91 +
          (Math.atan2(
            targetNode.y - sourceNode.y,
            targetNode.x - sourceNode.x
          ) *
            180) /
            Math.PI;
        d.source.angle = angle;
      });

      node.attr("transform", function (d) {
        if (d.angle === undefined) {
          d.angle = 1;
        }
        // return `translate3d(${d.x}, ${d.y},0)`;
        // return `translate(${d.x}, ${d.y})`;
        return `translate(${d.x}, ${d.y}) rotate(${d.angle})`;
      });

      //Guesses. Uncertain on how rotations and physics affect their positions.
      d3.zoomIdentity.x = -data.nodes[0].x + window.innerWidth / 8;
      d3.zoomIdentity.y =
        topNodes[0].y - bottomNodes[0].y + window.innerHeight / 4; //
      svg.call(zoomCheap).call(zoom.transform, d3.zoomIdentity); // Reset zoom after activeDiagram changes

      //* rotate graph to correct orientation
      d3.select(".zoomable")
        .attr("transform", function () {
          let midTopNum = Math.round((topNodes.length - 1) / 2);
          let midBottomNum = Math.round((bottomNodes.length - 1) / 2);
          let midTopNode = data.nodes[midTopNum];
          let midBottomNode = data.nodes[data.nodes.length - midBottomNum];
          let svgAngle =
            (Math.atan2(
              midTopNode.y - midBottomNode.y,
              midTopNode.x - midBottomNode.x
            ) *
              180) /
            Math.PI;
          svgAngle = (svgAngle + 360) % 360;
          // return `rotate3d(0,0,1,${270 - svgAngle}deg)`;
          return `rotate(${270 - svgAngle},${width / 2},${height / 2})`;
        })
        // Offset the window by the first node´s position
        .on("end", function () {
          //* Add correct stitch color to diagram
          if (data.nodes.length < 5000) {
            node
              .selectAll("circle")
              .attr("class", "stitch")
              .attr("r", 25)
              .attr("fill", (d) => "url(#stitch-" + d.color + ")");
          }
        });

      console.timeEnd("Rendering");
    });

    // distanceMax: helps "unbalanced" decreases, avoids bends
    // manyBody:repel or attract nodes from each other
    // collission: prevents overlapping nodes, distance between a and b is at least radius
    // link iterations: increasing the number of iterations greatly increases the rigidity of the constraint
    //! ///////////////
    //*   FORCE /////

    simulation
      .nodes(data.nodes)
      .force("charge", d3.forceManyBody().strength(-205).distanceMax(200))
      .force(
        "link",
        d3.forceLink(data.links).distance(20).strength(1).iterations(10)
      )
      .force("collision", d3.forceCollide().radius(1))
      .force("center", d3.forceCenter(width / 2, height / 2))
      // .on("tick", update);
      .on("tick", update);
    // .stop();
    // *  The simulation’s internal timer stops when the current alpha
    //*   is less than the minimum alpha.
    simulation.alphaMin(0.1);

    //! ///////////////

    function update() {
      var ticksPerRender = 2;
      //
      requestAnimationFrame(function render() {
        for (var i = 0; i < ticksPerRender; i++) {
          simulation.tick();
        }

        if (simulation.alpha() > 0.1) {
          requestAnimationFrame(render);
        }
      });
      console.log("Updating..");
    }
    setStateNodes(node);
  }
  let filterImportData = {};
  filterImportData.nodes = importData.nodes
    .filter(function (node) {
      return node.value !== 1;
    })
    .map((node, i) => {
      node.index = i;
      return node;
    });
  if (stateNodes) {
    d3.selectAll(".node")
      .select(".stitch")
      .data(filterImportData.nodes)
      .attr("fill", function (d, i) {
        d.color = filterImportData.nodes[i].color;
        return "url(#stitch-" + filterImportData.nodes[i].color + ")";
      });
  }

  return (
    <div
      id="svgContainer"
      className={styles.svgContainer}
      style={{
        backgroundColor: "var(--white-ish)",
        pointerEvents: "auto",
        // width: `${window.innerWidth}px`,
        // height: `${window.innerHeight}px`,
        width: "100%",
        height: "100%",
      }}
    >
      <svg className={styles.viz} ref={svgRef} id="viz"></svg>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText=""
        modalContent={
          <div className={styles.spinnerContainer}>
            <img className={styles.spinner} src={"/logo.svg"} alt={""} />
          </div>
        }
      />
    </div>
  );
}
export default DiagramViewer;
