import React from "react";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce } from "react-table";

interface CustomFilterProps {
  globalFilter: string;
  setGlobalFilter: (filter: string | undefined) => void;
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }: CustomFilterProps) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 400);
  const { t } = useTranslation();
  return (
    <span>
      {/* Søk:{" "} */}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${t("search")}...`}
        style={{
          fontSize: "1.1rem",
          padding: "4px",
          width: "fit-content",
          border: "1px solid #EEECEA",
          boxSizing: "border-box",
          borderRadius: "4px",
          color: "var(--neutral-40)",
          backgroundColor: "#fff",
        }}
      />
    </span>
  );
};
export default GlobalFilter;
