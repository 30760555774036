import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import React from "react";
import { useDispatch } from "react-redux";
import Button from "../ISO/Components/Button";
import { toggleMenuDrawer } from "../store/writerSlice";

export const MenuDrawerButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(toggleMenuDrawer());
      }}
    >
      <HamburgerMenuIcon />
    </Button>
  );
};
