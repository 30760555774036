import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { enablePatches } from "immer";
import loginState from "../Login/components/LoginState";
import { patternSlice } from "./patternSlice";
import { displayStateSlice } from "../ISO/displayState";
import { writerSlice } from "./writerSlice";
import { adminSlice } from "./adminSlice";
import { colorSlice } from "./colorSlice";
import { gridSlice } from "./gridSlice";
import { calculationSlice } from "./calculationSlice";
import { dragSelectSlice } from "./dragSelectSlice";
import { organizationApi } from "../services/organization";
import { patternApi } from "../services/patternApi";
import { modelknit } from "../services/modelknit";
import { salesOverviewApi } from "../services/salesOverview";
import { registerInterest } from "../services/registerInterest";
import { yarnApi } from "../services/yarnApi";
import { useDispatch } from "react-redux";
import { modelSlice } from "./modelSlice";

enablePatches();

const rootReducer = combineReducers({
  [organizationApi.reducerPath]: organizationApi.reducer,
  [registerInterest.reducerPath]: registerInterest.reducer,
  [salesOverviewApi.reducerPath]: salesOverviewApi.reducer,
  [patternApi.reducerPath]: patternApi.reducer,
  [modelknit.reducerPath]: modelknit.reducer,
  [yarnApi.reducerPath]: yarnApi.reducer,
  pattern: patternSlice.reducer,
  writer: writerSlice.reducer,
  loginState: loginState.reducer,
  displayState: displayStateSlice.reducer,
  admin: adminSlice.reducer,
  color: colorSlice.reducer,
  grid: gridSlice.reducer,
  calculations: calculationSlice.reducer,
  dragSelect: dragSelectSlice.reducer,
  model: modelSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      organizationApi.middleware,
      patternApi.middleware,
      modelknit.middleware,
      registerInterest.middleware,
      salesOverviewApi.middleware,
      yarnApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

export { rootReducer };

export type RootState = ReturnType<typeof rootReducer>;
