import React, { useState, useEffect } from "react";
import { useEffectExceptMount } from "../utils/useEffectExceptMount";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import {
  setElementValue,
  acceptChange,
  updateCalculations,
} from "../store/calculationSlice";

import { Modal } from "../CommonComponents/Modal";
import { useModal } from "../CommonComponents/useModal";

import styles from "./CalculationElementInput.module.css";

type PropType = { elementId: string; disabled: boolean };

function CalculationElementInput(props: PropType) {
  const { elementId, disabled } = props;

  const element = useSelector(
    (state: RootState) => state.calculations.graph.elements[elementId]
  );

  const [value, setValue] = useState(element.value?.toFixed(2) || "");
  useEffect(() => setValue(element.value?.toFixed(2) || ""), [element]);

  const changes = useSelector((state: RootState) =>
    Object.values(state.calculations.changes).filter(
      (change) =>
        change.elementId === element.id &&
        !isNaN(change.newValue) &&
        isFinite(change.newValue)
    )
  );

  const dispatch = useDispatch();

  const [shouldSave, setShouldSave] = useState(false);

  useEffectExceptMount(() => {
    // If element has changed, we should save it
    setShouldSave(true);
  }, [element]);

  useEffect(() => {
    // Perform save when appropriate
    if (shouldSave) {
      dispatch(updateCalculations());
      setShouldSave(false);
    }
  });

  const { isShown, toggle } = useModal();

  return (
    <div className={styles.container}>
      {disabled || changes.length === 0 ? (
        <input
          className={`${disabled ? styles.locked : ""} ${styles.input}`}
          type="text"
          placeholder="–"
          // Do a parseFloat to get rid of superfluous zeroes after comma.
          // Allow minus to be written before number. Replace falsy values
          // ("", 0, NaN and so on) with empty string to clean up UI
          value={
            parseFloat(value) || ["-", ",", "."].includes(value) ? value : ""
          }
          disabled={disabled}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => {
            if (value !== (element.value?.toFixed(2) || "")) {
              dispatch(
                setElementValue({
                  key: element.id,
                  value: value.replace(",", "."),
                })
              );
            }
          }}
        />
      ) : (
        <>
          <button key={"button"} className={styles.changed} onClick={toggle}>
            {parseFloat(element.value?.toFixed(2)) || "–"}
          </button>
          <Modal
            key={"modal"}
            isShown={isShown}
            hide={toggle}
            headerText={"Forslag"}
            modalContent={
              <div className={styles.changes}>
                {[
                  // Take all the changes, map them onto new buttons, but keep the value easily accessible.
                  // Unpack the list of changes into a new list that also contains the current value
                  // with a slightly different button styling. Sort this list in ascending order.
                  // Then map it into the buttons only (({value, element}) => element).
                  ...changes.map((change) => ({
                    value: change.newValue,
                    element: (
                      <button
                        className={styles.choice}
                        onClick={() => {
                          dispatch(acceptChange({ changeId: change.id }));
                          toggle();
                        }}
                      >
                        {change.newValue}
                      </button>
                    ),
                  })),
                  {
                    value: element.value,
                    element: (
                      <button className={styles.changed} onClick={toggle}>
                        {parseFloat(element.value?.toFixed(2)) || "–"}
                      </button>
                    ),
                  },
                ]
                  .sort((a, b) => a.value || 0 - b.value || 0)
                  .map(({ element }) => element)}
              </div>
            }
          />
        </>
      )}
    </div>
  );
}

export default CalculationElementInput;
