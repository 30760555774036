import React, { useEffect, useState } from "react";
import styles from "./diagramComment.module.css";
interface PropType {
  index: number;
  text: string;
  setText: Function;
  setTo: Function;
  relation: "upper" | "lower" | "middle";
  setRelation: Function;
  rows: number;
}

export const AnnotationSelectRow = ({
  index,
  text,
  setText,
  setTo,
  setRelation,
}: PropType) => {
  const [upperBound, toggleUpperBound] = useState<boolean>(false);
  const [lowerBound, toggleLowerBound] = useState<boolean>(false);
  const [lowerText, setLowerText] = useState<number | null>(null);
  const [upperText, setUpperText] = useState<number | null>(null);

  useEffect(() => {
    if (lowerBound === false) {
      setLowerText(null);
      setTo(0);
      setRelation("middle");
    }
  }, [lowerBound]);

  useEffect(() => {
    if (upperBound === false) {
      setUpperText(null);
      setTo(0);
      setRelation("middle");
    }
  }, [upperBound]);

  useEffect(() => {
    if (!upperBound && !lowerBound) {
      setRelation("middle");
    } else if (upperBound && !lowerBound) {
      setRelation("upper");
    } else if (!upperBound && lowerBound) {
      setRelation("lower");
    }
  }, [upperBound, lowerBound]);

  useEffect(() => {
    if (!upperBound && !lowerBound && !upperText && !lowerText) {
      setText(`Merke på rader ${index}`);
    } else if (lowerText) {
      setText(`Merke på rader ${index} - ${lowerText}`);
    } else if (upperText) {
      setText(`Merke på rader ${upperText} - ${index}`);
    } else if (upperBound && !lowerText) {
      setText(`Merke etter rad ${index}`);
    } else if (lowerBound && !upperText) {
      setText(`Merke før rad ${index}`);
    }
  }, [lowerText, upperText, upperBound, lowerBound]);

  useEffect(() => {
    setTo(upperText);
  }, [upperText]);

  useEffect(() => {
    setTo(lowerText);
  }, [lowerText]);

  return (
    <div className={styles.intervalContainer}>
      <div>{text}</div>
      <div className={styles.interval}>
        <div className={styles.rowGroup}>
          <div
            onClick={() => {
              toggleUpperBound(!upperBound);
              toggleLowerBound(false);
            }}
            className={`${styles.row} ${upperBound && styles.rowActive}`}
          />
          <div className={styles.middle}>{index}</div>
          <div
            onClick={() => {
              toggleLowerBound(!lowerBound);
              toggleUpperBound(false);
            }}
            className={`${styles.row} ${lowerBound && styles.rowActive}`}
          />
        </div>
        <div className={styles.rowGroup}>
          <div>
            <input
              disabled={lowerBound || upperBound}
              placeholder={`${index + 1}`}
              className={styles.intervalInput}
              value={lowerText ?? 0}
              type="number"
              onChange={(e) =>
                setLowerText(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
            />
            <div className={`${styles.row} ${lowerText && styles.rowActive}`} />
          </div>
        </div>
      </div>
    </div>
  );
};
