import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

import CalculationElementInput from "./CalculationElementInput";

import styles from "./Cell.module.css";

interface PropType {
  nodeId: string;
}

function NodeCell(props: PropType) {
  const { nodeId } = props;

  const node = useSelector(
    (state: RootState) => state.calculations.graph.nodes[nodeId]
  );

  return (
    <div className={styles.cell}>
      <div className={styles.element}>
        <CalculationElementInput
          elementId={node.element}
          disabled={node.locked}
        />
      </div>
    </div>
  );
}

export default NodeCell;
