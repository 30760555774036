import { Image, YarnProfileVariant, Needle } from "../store/pattern";

export const checkSizeVariantYarnConsumption = (
  variants: YarnProfileVariant[],
  sizes: string[]
) => {
  for (const variant of variants) {
    const sizesMatchVariantSizes = checkSizesMatchVariantSizes(variant, sizes);
    if (!sizesMatchVariantSizes) {
      return false;
    }
  }
  return true;
};

export const checkAmountsHasAllColorsForVariants = (
  variants: YarnProfileVariant[],
  sizes: string[]
) => {
  for (const variant of variants) {
    const amountsHasAllColors = checkAmountsHasAllColors(variant, sizes);
    if (!amountsHasAllColors) {
      return false;
    }
  }
  return true;
};

const checkAmountsHasAllColors = (
  variant: YarnProfileVariant,
  sizes: string[]
) => {
  const colorsIndexArray = variant.colors.map((_, index) => {
    return index.toString();
  }); // ["0", "1"]

  const filteredEntries = Object.entries(variant.amounts).filter(([key, _]) =>
    sizes.includes(key)
  );

  const variantAmountsFiltered = Object.fromEntries(filteredEntries);

  for (const sizesObject of Object.values(variantAmountsFiltered)) {
    const sizeKeys = Object.keys(sizesObject); // ["0", "1"]

    const missingColor = colorsIndexArray.some(
      (color) => !sizeKeys.includes(color)
    );

    if (missingColor) {
      return false;
    }
  }
  return true;
};

const checkSizesMatchVariantSizes = (
  variant: YarnProfileVariant,
  sizes: string[]
) => {
  const { amounts: variantAmounts } = variant;
  const variantAmountSizes = Object.keys(variantAmounts);
  const intersectionSizesAndVariantAmountSizes = sizes.filter((size) =>
    variantAmountSizes.includes(size)
  );
  return intersectionSizesAndVariantAmountSizes.length === sizes.length;
};

export const checkPatternVariant = (variants: YarnProfileVariant[]) => {
  return variants.length !== 0;
};

export const checkPatternTitle = (title: string) => {
  return title !== "";
};

export const checkPatternDescription = (description: string) => {
  return description !== "" && description != null;
};

export const checkPatternMedia = (media: { [key: string]: Image }) => {
  return Object.keys(media).length !== 0;
};

export const checkPatternMediaVariantProportion = (
  variants: YarnProfileVariant[],
  media: { [key: string]: Image }
) => {
  return Object.keys(media).length === variants.length;
};

export const checkOrganizationID = (organizationId: number | null) => {
  return organizationId != null;
};

export const checkNeedles = (needles: { [key: string]: Needle }) => {
  return Object.keys(needles).length !== 0;
};

export const buttonDisabled = (
  title: string,
  variants: YarnProfileVariant[],
  description: string,
  sizes: string[]
) => {
  return !(
    checkPatternTitle(title) &&
    checkPatternVariant(variants) &&
    checkPatternDescription(description) &&
    checkSizeVariantYarnConsumption(variants, sizes) &&
    checkAmountsHasAllColorsForVariants(variants, sizes)
  );
};

export const warningPresent = (
  media: { [key: string]: Image },
  variants: YarnProfileVariant[],
  organizationId: number | null,
  needles: { [key: string]: Needle }
) => {
  return !(
    checkPatternMedia(media) &&
    checkPatternMediaVariantProportion(variants, media) &&
    !checkOrganizationID(organizationId) &&
    checkNeedles(needles)
  );
};
