import { Popover } from "@headlessui/react";
import React from "react";
import { Info } from "./Icons/Info";
import styles from "./Tooltip.module.css";

interface PropType {
  icon?: JSX.Element | string;
}

export const Tooltip: React.FC<PropType> = ({ children, icon }) => {
  return (
    <Popover className={styles.popover}>
      {({ open }) => (
        <>
          <Popover.Button className={styles.btn}>
            {icon ?? <Info />}
          </Popover.Button>
          <Popover.Panel className={styles.panel}>{children}</Popover.Panel>
        </>
      )}
    </Popover>
  );
};
