export const commands: { [key: string]: string } = {
  span: "Tekst",
  heading: "Overskrift",
  subheading: "Underoverskrift",
  calculationResult: "Beregning",
  needle: "Pinne",
  color: "Farge",
  diagramSection: "Diagram",
  diagram: "Diagramreferanse",
  sizeFilteredString: "Tekst etter størrelse",
  media: "Media",
  snippet: "Info",
};
