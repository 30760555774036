import React from "react";
import HeaderSection from "./LandingPage/Sections/HeaderSection";
import NavigationSection from "./LandingPage/Sections/NavigationSection";
import ProcessSection from "./LandingPage/Sections/ProcessSection";
import CardSection from "./LandingPage/Sections/CardSection";
import WoolitShopSection from "./LandingPage/Sections/WoolitShopSection";
import EndSection from "./LandingPage/Sections/EndSection";
import StatusSection from "./LandingPage/Sections/StatusSection";

function App() {
  return (
    <div>
      <StatusSection />

      <NavigationSection />
 <HeaderSection />
<ProcessSection />
      <CardSection />
      
      
      
      <WoolitShopSection />
      <EndSection />
    </div>
  );
}

export default App;
