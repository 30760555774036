import React from "react";
import { BBox } from "./utils/aabb";

interface PropType {
  bbox: BBox;
}

export const DragSelectionBox = (props: PropType) => {
  const { bbox } = props;
  return (
    <div
      style={{
        position: "absolute",
        ...bbox,
        border: "2px solid #DE5900",
        zIndex: 16,
      }}
    ></div>
  );
};
