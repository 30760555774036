import React from "react";
import styles from "./Dropdown.module.css";
import { Menu } from "@headlessui/react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

type Props = {
  options?: string[];
  onOptionSelect?: (optionIndex: number) => void;
};

const Dropdown: React.FC<Props> = ({
  options = [],
  onOptionSelect = (i) => console.log(i),
}) => {
  return (
    <Menu as="div" className={styles.container}>
      <Menu.Button as="div" className={styles.buttonContainer}>
        <DotsHorizontalIcon />
      </Menu.Button>
      <Menu.Items as="div" className={styles.optionContainer}>
        {options.map((option, i) => (
          <Menu.Item
            as="span"
            className={styles.option}
            onClick={() => onOptionSelect(i)}
          >
            {option}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default Dropdown;
