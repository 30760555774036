import React from "react";

interface PropType {
  color?: string;
}

function ChangeEvenly(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0664062 14.1328L13.9331 14.1328V15.7328L0.0664062 15.7328V14.1328Z"
        fill={color ?? "#33251A"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19922 7.2002L11.7992 7.2002V8.8002L2.19922 8.80019V7.2002Z"
        fill={color ?? "#33251A"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33398 0.799805L9.66732 0.799808V2.3998L4.33398 2.3998V0.799805Z"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="5.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="5.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="3.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="3.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="7.4668"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="4.33398"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="2.19922"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="8.59961"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="10.7324"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="9.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="9.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="11.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="11.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="5.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="3.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="7.4668"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="1.06641"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="9.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="11.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
    </svg>
  );
}

export default ChangeEvenly;
