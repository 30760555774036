import { useDispatch } from "react-redux";

import { setTableMarks } from "../store/calculationSlice";

import styles from "./TableMarkSelector.module.css";

type PropType = {
  tableId: string;
  marks: number;
};

const DEFAULT_MARKS = [0, 1, 2, 4];

function TableMarkSelector(props: PropType) {
  const { tableId, marks } = props;

  const dispatch = useDispatch();

  return (
    <div className={styles.buttonContainer}>
      <button
        className={marks === 0 ? styles.active : undefined}
        onClick={() => dispatch(setTableMarks({ tableId, marks: 0 }))}
      >
        0
      </button>
      <button
        className={marks === 1 ? styles.active : undefined}
        onClick={() => dispatch(setTableMarks({ tableId, marks: 1 }))}
      >
        1
      </button>
      <button
        className={marks === 2 ? styles.active : undefined}
        onClick={() => dispatch(setTableMarks({ tableId, marks: 2 }))}
      >
        2
      </button>
      <button
        className={marks === 4 ? styles.active : undefined}
        onClick={() => dispatch(setTableMarks({ tableId, marks: 4 }))}
      >
        4
      </button>
      <input
        className={DEFAULT_MARKS.includes(marks) ? undefined : styles.active}
        type={"number"}
        value={DEFAULT_MARKS.includes(marks) ? "" : marks.toString()}
        onChange={(e) => {
          dispatch(
            setTableMarks({ tableId, marks: parseInt(e.target.value || "0") })
          );
        }}
      />
    </div>
  );
}

export default TableMarkSelector;
