import { useCallback, useEffect, useRef, useState } from "react";
import {
  DrawTypes,
  KnitabilityProblem,
  RepeatMode,
  SweaterPartAreaGroup,
} from "../enums";
import {
  getSweaterParts,
  getSweaterPartsExceptCollar,
  runAfterLoadCanvasPush,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { createCanvas } from "../knittingpreview/texturecanvas";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import { SweaterPart } from "../SweaterPart";
import React from "react";
import { Util } from "../static/util";
import GridButton from "../components/GridButton";
import { Global } from "../static/global";

import { RootState } from "../../store/store";
import { GridCell } from "../../store/pattern";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "../../ISO/Components/Button";
import { Eraser } from "../../CommonComponents/Icons/Eraser";

import styles from "../../Diagram/diagram.module.css";
import { CursorArrowIcon } from "@radix-ui/react-icons";
import { Save } from "../SaveLoad";
import { useTranslation } from "react-i18next";

function GridOverlay(props: any) {
  const overlayStyle: any = {
    display: "flex",
    margin: "20px",
    marginLeft: "30px",
    width: "calc(100% - 60px)",
    height: "calc(100% - 40px)",
    position: "absolute",
    pointerEvents: "none",
  };

  const left: any = {
    justifyContent: "flex-start",
    display: "flex",
    gap: "8px",
  };
  const right: any = {
    justifyContent: "flex-end",
    width: "265px",
    display: "flex",
    gap: "8px",
  };

  const { t } = useTranslation();

  return (
    <>
      <div style={{ ...overlayStyle, alignItems: "flex-end" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            gap: "5px calc(100% - 650px)", // on wrap: spaceBetween -> center
          }}
        >
          <div style={{ ...left }}>
            <div
              style={{
                display: "flex",
                pointerEvents: "auto",
              }}
            >
              <p
                style={{
                  margin: "auto",
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: 300,
                }}
              >
                {t("draw.view")}:
              </p>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.sweaterPart?.index ?? 0}
                  style={{
                    height: "30px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "white",
                    fontWeight: 300,
                    pointerEvents: "auto",
                  }}
                  onChange={(e: any) => {
                    const sweaterPart =
                      getSweaterParts()[e.target.value as number];
                    props.setSelectedSweaterPart(sweaterPart);
                  }}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#bdd1b9",
                    },
                  }}
                >
                  {(getSweaterParts() ?? ["", "", "", "", ""]).map((v, i) => (
                    <MenuItem value={i} key={i}>
                      {v.name ?? ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p
                style={{
                  margin: "auto",
                  marginLeft: "15px",
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: 300,
                }}
              >
                {t("draw.repeat")}:
              </p>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.repeat}
                  style={{
                    height: "30px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "white",
                    fontWeight: 300,
                  }}
                  onChange={(e: any) => {
                    props.setRepeat(e.target.value as number);
                    props.setRepeatMemo(e.target.value as number);
                  }}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#bdd1b9",
                    },
                  }}
                >
                  {props.repeatOptions.map((v: any, i: any) => (
                    <MenuItem value={i} key={i}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ ...right }}>
            <div>
              <Button
                title={"Pointer"}
                className={styles.stateButton}
                active={props.pointer}
                onClick={() => {
                  props.setPointer(!props.pointer);
                }}
                style={{ pointerEvents: "auto" }}
              >
                <CursorArrowIcon />
              </Button>
            </div>
            <div>
              <button
                title={"Undo"}
                className={styles.stateButton}
                disabled={!props.undoButtonState()}
                onClick={() => props.undoButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img src="/undo-arrow.svg" alt="undo" />
              </button>
              <button
                title={"Redo"}
                className={styles.stateButton}
                disabled={!props.redoButtonState()}
                onClick={() => props.redoButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img src="/redo-arrow.svg" alt="redo" />
              </button>
            </div>
            <div>
              <Button
                title={"Zoom In"}
                active={false}
                onClick={() => {
                  props.zoom(1);
                }}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <img height="16" src="/zoom-in.svg" alt="Zoom in" />
              </Button>
              <Button
                title={"Zoom Out"}
                active={false}
                onClick={() => {
                  props.zoom(-1);
                }}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <img height="16" src="/zoom-out.svg" alt="Zoom out" />
              </Button>
            </div>
            <div>
              <button
                title={"Delete"}
                className={styles.stateButton}
                disabled={!props.trashButtonState()}
                onClick={() => props.trashButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img
                  src="/3D/ui/delete2.png"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="delete"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GridOverlay;
