import { SweaterPart } from "../SweaterPart";
import { SweaterPartArea } from "../enums";
import { Util } from "../static/util";

export class Isydd {
    shirt = {
        "Left Arm": [
            1304.4473684210527,
            740,
            1826.5526315789473,
            1883
        ],
        "Right Arm": [
            2204.4473684210525,
            740,
            2726.5526315789475,
            1883
        ],
        "Collar": [
            1129.1842105263158,
            1905,
            2981.815789473684,
            1998
        ],
        "Back Torso": [
            1156.2894736842104,
            2030,
            1964.7105263157896,
            3202
        ],
        "Front Torso": [
            2112.2894736842104,
            2023,
            2920.7105263157896,
            3193
        ]
    } as const

    parts() {
        let leftArm = new SweaterPart(
          "Left Arm",
          SweaterPartArea.LeftArm,
          Util.makeGrid(256),
          ...(this.shirt["Left Arm"]),
          1090
        );
        let rightArm = new SweaterPart(
          "Right Arm",
          SweaterPartArea.RightArm,
          Util.makeGrid(256),
          ...(this.shirt["Right Arm"]),
          1090
        );
        let back = new SweaterPart(
          "Back Torso",
          SweaterPartArea.BackTorso,
          Util.makeGrid(256),
          ...(this.shirt["Back Torso"]),
          2560
        );
    
        let front = new SweaterPart(
          "Front Torso",
          SweaterPartArea.FrontTorso,
          Util.makeGrid(256),
          ...(this.shirt["Front Torso"]),
          2560
        );
    
        let collar = new SweaterPart(
          "Collar",
          SweaterPartArea.Collar,
          Util.makeGrid(256),
          ...(this.shirt["Collar"]),
          -1,
          true //is_inverted
        );

        console.log ("Beware that draw() -> 'isOverlap' doesnt consider crossAlign. Which means in this case that it will work badly across arms/torso. Contact for more info.")
        //^ bottom works with raglan. Must change a bit, like considering armConnectY for calculating isOverlapOther
  
        const sweaterParts = [leftArm, rightArm, front, back, collar]
        sweaterParts.forEach((v: SweaterPart, i: number) => v.index = i)
        return sweaterParts
      }
}