import { SaleMonth } from "react-table";
import { PatternSales } from "../types/backend-generated/PatternSales";
import { PatternSale } from "../types/backend-generated/PatternSale";
import { isWithinInterval } from "date-fns";

export const findMonthYearCombinations = (data: PatternSales[]) => {
  let allMonths: SaleMonth[] = [];
  data.forEach((patternSales) =>
    patternSales.sales.forEach((sale) => {
      const saleMonth = new Date(sale.created).getMonth();
      const saleYear = new Date(sale.created).getFullYear();

      if (
        allMonths.some((element) => {
          if (element.month === saleMonth && element.year === saleYear) {
            return true;
          }
          return false;
        })
      ) {
      } else {
        allMonths.push({
          month: saleMonth,
          year: saleYear,
        });
      }
    })
  );
  return allMonths.sort(dateComparison);
};

const dateComparison = (a: SaleMonth, b: SaleMonth) => {
  const date1: any = new Date(a.year, a.month);
  const date2: any = new Date(b.year, b.month);
  return date2 - date1;
};

export const calculateTotalSum = (data: PatternSales[]) => {
  return data?.reduce(
    (total, pattern) => total + findTotalPatternSaleSum(pattern.sales),
    0
  );
};

export const filterDesignerSales = (
  sales: PatternSales[],
  month: number,
  year: number
): [Set<PatternSales>, number] => {
  const salesSet = new Set<PatternSales>();
  let monthsTotalSum = 0;
  sales.forEach((sale) => {
    sale.sales.forEach((pattern) => {
      const saleMonth = new Date(pattern.created).getMonth();
      const saleYear = new Date(pattern.created).getFullYear();
      if (saleMonth === month && saleYear === year) {
        salesSet.add(sale);
        monthsTotalSum += pattern.totalAmountExclVat;
      }
    });
  });
  return [salesSet, monthsTotalSum];
};

export const filterPatternSales = (
  sales: PatternSale[],
  month: number,
  year: number
): [PatternSale[], number] => {
  const theseSales: PatternSale[] = [];
  sales.forEach((sale) => {
    const saleMonth = new Date(sale.created).getMonth();
    const saleYear = new Date(sale.created).getFullYear();
    if (saleMonth === month && saleYear === year) {
      theseSales.push(sale);
    }
  });
  return [theseSales, theseSales.length];
};

export const findTotalPatternSaleSum = (theseSales: PatternSale[]) => {
  return theseSales.reduce((sum, sale) => sum + sale.totalAmountExclVat, 0);
};

const calculateSalesLast30_60Days = (data: PatternSales[]) => {
  const today = new Date();
  const last30Days = new Date(new Date().setDate(today.getDate() - 30));
  const last60Days = new Date(new Date().setDate(today.getDate() - 60));

  let thisMonthsSum = 0;
  let thisMonthsSales = 0;
  data.forEach((sale) => {
    sale.sales.forEach((pattern) => {
      const saleDate = new Date(pattern.created);

      if (isWithinInterval(saleDate, { start: last60Days, end: last30Days })) {
        thisMonthsSum += pattern.totalAmountExclVat;
        thisMonthsSales += 1;
      }
    });
  });
  return [thisMonthsSum, thisMonthsSales];
};

export const calculateSalesLast30Days = (data: PatternSales[]) => {
  const today = new Date();
  const last30Days = new Date(new Date().setDate(today.getDate() - 30));

  let thisMonthsSum = 0;
  let thisMonthsSales = 0;
  data.forEach((sale) => {
    sale.sales.forEach((pattern) => {
      const saleDate = new Date(pattern.created);
      if (isWithinInterval(saleDate, { start: last30Days, end: today })) {
        thisMonthsSum += pattern.totalAmountExclVat;
        thisMonthsSales += 1;
      }
    });
  });
  return [thisMonthsSum, thisMonthsSales];
};

export const calculateSalesDifference = (data: PatternSales[]) => {
  const [thisMonthsSum] = calculateSalesLast30Days(data);
  const [prevMonthsSum] = calculateSalesLast30_60Days(data);
  const diff = prevMonthsSum
    ? ((thisMonthsSum - prevMonthsSum) / prevMonthsSum) * 100
    : 0;

  return diff;
};
