import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { RootState } from "./store/store";
import { addTable, traverseFullGraph } from "./store/calculationSlice";
import { fetchPatternById } from "./store/patternSlice";
import { fetchMe } from "./Login/components/LoginState";

import { setDisable } from "./store/dragSelectSlice";
import { setDisableHotkeys } from "./store/gridSlice";
import { toggleDrawer } from "./store/writerSlice";

import useTableRelationIndex from "./utils/useTableRelationIndex";

import Top from "./CommonComponents/Top";
import CalculationTable from "./CalculationGraph/CalculationTable";
import { Loading } from "./CommonComponents/Loading";

function Calculations() {
  // URL parameter
  const { patternId } = useParams();

  // State
  const { pattern, loginState } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const tableOrder = useSelector(
    (state: RootState) => state.calculations.tableOrder
  );

  const relationIndex = useTableRelationIndex();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  const getUserCallback = useCallback(
    () => (loginState.isLoggedIn ? null : dispatch(fetchMe())),
    [dispatch, loginState.isLoggedIn]
  );

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  useEffect(() => {
    getUserCallback();
  }, [getUserCallback]);

  // Traversal logic to find changes
  const shouldTraverseFullGraph = useSelector(
    (state: RootState) => state.calculations.graph.shouldTraverseFullGraph
  );
  const sizes = useSelector((state: RootState) => state.pattern.meta.sizes);
  useEffect(() => {
    if (shouldTraverseFullGraph) {
      dispatch(traverseFullGraph({ sizes }));
    }
  }, [shouldTraverseFullGraph, dispatch, sizes]);

  return (
    <>
      <Top />
      {pattern.id && pattern.id !== -1 ? (
        <div style={{ marginTop: "128px" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              {tableOrder.map((tableId) => (
                <CalculationTable
                  key={tableId}
                  tableId={tableId}
                  relations={relationIndex[tableId]}
                />
              ))}
              {sizes.length > 0 ? (
                <button
                  style={{
                    marginLeft: "calc(50% - 50px)",
                    marginBottom: "192px",
                    padding: "8px 16px",
                    fontWeight: "bold",
                  }}
                  onClick={() => dispatch(addTable())}
                >
                  Ny tabell
                </button>
              ) : (
                <div
                  style={{
                    margin: "0 auto",
                    width: "600px",
                    display: "flex",
                    flexFlow: "column nowrap",
                    marginTop: "35%",
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      width: "50ch",
                      fontSize: "18px",
                      fontFamily: "var(--text-font-family)",
                      color: "var(--light-brown)",
                      textAlign: "center",
                    }}
                  >
                    Denne oppskriften mangler størrelser, velg størrelser før du
                    begynner på beregningene.
                  </div>
                  <button
                    style={{
                      backgroundColor: "var(--positive)",
                      color: "var(--whiteish)",
                      padding: "16px",
                      border: "none",
                      margin: "20px auto",
                      width: "200px",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      dispatch(setDisableHotkeys(true));
                      dispatch(toggleDrawer());
                      dispatch(setDisable(true));
                    }}
                  >
                    Åpne informasjonsskuff
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Calculations;
