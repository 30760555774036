import React from "react";

interface Props {
  fill?: string;
}

export const Eraser: React.FC<Props> = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5.64706L10.3529 0L9.14286 1.21008L8.60504 0.672269L1.07563 8.33613L1.61345 8.87395L0.806723 9.41177C-0.268908 10.4874 -0.268908 12.1008 0.806723 13.1765L2.68908 15.0588C3.76471 16.1345 5.37815 16.1345 6.45378 15.0588L7.12605 14.3866L7.66387 14.9244L15.3277 7.2605L14.7899 6.85714L16 5.64706ZM10.3529 0.941177L15.0588 5.64706L14.2521 6.45378L9.54622 1.61345L10.3529 0.941177ZM6.31933 14.3866C5.37815 15.3277 3.89916 15.3277 2.95798 14.3866L1.47899 12.9076C0.537815 11.9664 0.537815 10.4874 1.47899 9.54622L2.01681 9.27731L6.72269 13.9832L6.31933 14.3866Z"
        fill={fill ?? "currentColor"}
      />
    </svg>
  );
};
