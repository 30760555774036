import { SerializedError } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import React from "react";
import { Loading } from "./Loading";

interface PropType {
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  withLoadingAnimation?: boolean;
}

export const Wrapper: React.FC<PropType> = ({
  isLoading,
  children,
  error,
  withLoadingAnimation,
}) => {
  return (
    <div>
      {error ? (
        "Klarte ikke å hente data"
      ) : isLoading ? (
        withLoadingAnimation ? (
          <Loading />
        ) : (
          "Loading..."
        )
      ) : children ? (
        <>{children}</>
      ) : null}
    </div>
  );
};
