export interface BBox {
  left: number;
  top: number;
  height: number;
  width: number;
}

export const aabb = (rect1: BBox, rect2: BBox) => {
  if (
    rect1.left < rect2.left + rect2.width &&
    rect1.left + rect1.width > rect2.left &&
    rect1.top < rect2.top + rect2.height &&
    rect1.top + rect1.height > rect2.top
  ) {
    return true;
  }
  return false;
};
