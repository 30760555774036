import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";

import {
  CalculationSliceType,
  Direction,
  EdgeTableMeasure,
  emptyCalculationSlice,
  FlatCalculationGraph,
  FlatGraphEdge,
  FlatGraphNode,
  GlobalGauge,
  MeasureElement,
  NodeTableMeasure,
  TableMeasure,
  Unit,
} from "../CalculationGraph/calculations";

import { normaliseByUnit } from "../utils/unitNormalisation";

import { Pattern } from "./pattern";

import {
  addSizeToState,
  fetchPatternById,
  removeSizeFromState,
} from "./patternSlice";

import { backendApiAddress } from "../backendApi";
import { shortUUID } from "../utils/uuid";
import {
  findEdgeChanges,
  findNodeChanges,
  findRelationChanges,
  getTraversableTables,
} from "./graphTraversal";
import { RootState } from "./store";

const updateCalculations = createAsyncThunk(
  "calculation/updateCalculations",
  async (_, thunkAPI) => {
    const {
      pattern: { id: patternId },
    } = thunkAPI.getState() as RootState;

    const calculations: CalculationSliceType = (
      thunkAPI.getState() as RootState
    ).calculations;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/calculation`,
      {
        method: "PUT",
        body: JSON.stringify({ calculationResults: calculations }),
        credentials: "include",
      }
    );
    return await response.json();
  }
);

const setGaugeStitchesHorizontal = createAsyncThunk(
  "calculation/setGaugeStitchesHorizontal",
  async (stitches: number, thunkAPI) => {
    const {
      calculations: calculationResults,
      pattern: { id: patternId },
    } = thunkAPI.getState() as RootState;

    const { gauge } = calculationResults;

    const updatedGauge = {
      ...gauge,
      horizontal: {
        ...gauge.horizontal,
        stitches,
        manual: true,
      },
    };

    const updatedCalculationResults = {
      ...calculationResults,
      gauge: updatedGauge,
    };

    thunkAPI.dispatch(setGauge(updatedGauge));

    await fetch(`${backendApiAddress}/api/pattern/${patternId}/calculation`, {
      method: "PUT",
      body: JSON.stringify({ calculationResults: updatedCalculationResults }),
      credentials: "include",
    });
  }
);

const setGaugeLengthHorizontal = createAsyncThunk(
  "calculation/setGaugeLengthHorizontal",
  async (length: number, thunkAPI) => {
    const {
      calculations: calculationResults,
      pattern: { id: patternId },
    } = thunkAPI.getState() as RootState;

    const { gauge } = calculationResults;

    const updatedGauge = {
      ...gauge,
      horizontal: {
        ...gauge.horizontal,
        length,
        manual: true,
      },
    };

    const updatedCalculationResults = {
      ...calculationResults,
      gauge: updatedGauge,
    };

    thunkAPI.dispatch(setGauge(updatedGauge));

    await fetch(`${backendApiAddress}/api/pattern/${patternId}/calculation`, {
      method: "PUT",
      body: JSON.stringify({ calculationResults: updatedCalculationResults }),
      credentials: "include",
    });
  }
);

const setGaugeStitchesVertical = createAsyncThunk(
  "calculation/setGaugeStitchesVertical",
  async (stitches: number, thunkAPI) => {
    const {
      calculations: calculationResults,
      pattern: { id: patternId },
    } = thunkAPI.getState() as RootState;

    const { gauge } = calculationResults;

    const updatedGauge = {
      ...gauge,
      vertical: {
        ...gauge.vertical,
        stitches,
        manual: true,
      },
    };

    const updatedCalculationResults = {
      ...calculationResults,
      gauge: updatedGauge,
    };

    thunkAPI.dispatch(setGauge(updatedGauge));

    await fetch(`${backendApiAddress}/api/pattern/${patternId}/calculation`, {
      method: "PUT",
      body: JSON.stringify({ calculationResults: updatedCalculationResults }),
      credentials: "include",
    });
  }
);

const setGaugeLengthVertical = createAsyncThunk(
  "calculation/setGaugeLengthVertical",
  async (length: number, thunkAPI) => {
    const {
      calculations: calculationResults,
      pattern: { id: patternId },
    } = thunkAPI.getState() as RootState;

    const { gauge } = calculationResults;

    const updatedGauge = {
      ...gauge,
      vertical: {
        ...gauge.vertical,
        length,
        manual: true,
      },
    };

    const updatedCalculationResults = {
      ...calculationResults,
      gauge: updatedGauge,
    };

    thunkAPI.dispatch(setGauge(updatedGauge));

    await fetch(`${backendApiAddress}/api/pattern/${patternId}/calculation`, {
      method: "PUT",
      body: JSON.stringify({ calculationResults: updatedCalculationResults }),
      credentials: "include",
    });
  }
);

const calculationSlice = createSlice({
  name: "calculations",
  initialState: emptyCalculationSlice,
  reducers: {
    setGauge(state, action: PayloadAction<GlobalGauge>) {
      const gauge = action.payload;

      state.gauge = gauge;
      state.graph.shouldTraverseFullGraph = true;
    },

    setGraph(state, action) {
      const graph: FlatCalculationGraph = action.payload;
      state.graph = graph;
    },

    setElementValue(state, action) {
      const { key, value } = action.payload;
      const element = state.graph.elements[key];

      const valueNum = parseFloat(value) || 0;

      element.value = valueNum;
      element.changed = true;

      switch (element.shouldRound) {
        case "RoundUp":
          element.displayValue = Math.ceil(element.value);
          break;
        case "RoundDown":
          element.displayValue = Math.floor(element.value);
          break;
        case "RoundClosest":
          element.displayValue = Math.round(element.value);
          break;
        default:
          element.displayValue = element.value;
      }

      // Force traversal
      state.graph.shouldTraverseFullGraph = true;
    },

    setElementRounded(state, action) {
      const { key, rounded } = action.payload;

      const element = state.graph.elements[key];

      if (element.shouldRound === rounded) {
        element.shouldRound = null;
        element.displayValue = element.value;
      } else {
        element.shouldRound = rounded;

        switch (rounded) {
          case "RoundUp":
            if (element.direction === "Vertical") {
              const decimals = element.value - Math.floor(element.value);
              if (decimals < 0.5) {
                element.displayValue = Math.floor(element.value) + 0.5;
                break;
              }
            }
            element.displayValue = Math.ceil(element.value);
            break;
          case "RoundDown":
            if (element.direction === "Vertical") {
              const decimals = element.value - Math.floor(element.value);
              if (decimals >= 0.5) {
                element.displayValue = Math.floor(element.value) + 0.5;
                break;
              }
            }
            element.displayValue = Math.floor(element.value);
            break;
          case "RoundClosest":
          default:
            // If we decide to round closest again, it needs logic for rounding to 0.5 when appropriate
            element.displayValue = Math.round(element.value);
            break;
        }
      }
    },

    toggleNodeLocked(state, action) {
      const { key } = action.payload;

      const node = state.graph.nodes[key];
      node.locked = !node.locked;
    },

    toggleEdgeLocked(state, action) {
      const { key } = action.payload;

      const edge = state.graph.edges[key];
      edge.locked = !edge.locked;
    },

    toggleMeasureLocked(state, action) {
      const { tableId, measureId } = action.payload;

      const measure = state.tables[tableId].measures[measureId];

      if (measure.kind === "node") {
        for (const nodeId of Object.values(measure.nodes)) {
          const node = state.graph.nodes[nodeId];
          node.locked = !node.locked;
        }
      } else {
        for (const edgeId of Object.values(measure.edges)) {
          const edge = state.graph.edges[edgeId];
          edge.locked = !edge.locked;
        }
      }
    },

    setTableLocked(state, action) {
      const { tableId } = action.payload;

      const table = state.tables[tableId];

      for (const measureId of table.order) {
        const measure = state.tables[tableId].measures[measureId];

        if (measure.kind === "node") {
          for (const nodeId of Object.values(measure.nodes)) {
            const node = state.graph.nodes[nodeId];
            node.locked = true;
          }
        } else {
          for (const edgeId of Object.values(measure.edges)) {
            const edge = state.graph.edges[edgeId];
            edge.locked = true;
          }
        }
      }
    },

    setTableUnlocked(state, action) {
      const { tableId } = action.payload;

      const table = state.tables[tableId];

      for (const measureId of table.order) {
        const measure = state.tables[tableId].measures[measureId];

        if (measure.kind === "node") {
          for (const nodeId of Object.values(measure.nodes)) {
            const node = state.graph.nodes[nodeId];
            node.locked = false;
          }
        } else {
          for (const edgeId of Object.values(measure.edges)) {
            const edge = state.graph.edges[edgeId];
            edge.locked = false;
          }
        }
      }
    },

    addNode: {
      reducer: (
        state,
        action: PayloadAction<{
          parentId: string;
          newEdgeId: string;
          newEdgeChangeId: string;
          newEdgeFrequencyId: string;
          newEdgeVerticalId: string;
          newNodeId: string;
          newNodeElementId: string;
        }>
      ) => {
        const {
          parentId,
          newEdgeId,
          newEdgeChangeId,
          newEdgeFrequencyId,
          newEdgeVerticalId,
          newNodeId,
          newNodeElementId,
        } = action.payload;

        // Attach new edge to parent node
        const parent = state.graph.nodes[parentId];
        parent.edges.push(newEdgeId);

        // Create node and its element, add to graph
        state.graph.elements[newNodeElementId] = {
          ...state.graph.elements[parent.element],
          id: newNodeElementId,
        };

        state.graph.nodes[newNodeId] = {
          id: newNodeId,
          element: newNodeElementId,
          edges: [],
          locked: false,
        };

        // Create edge and its elements, add to graph
        state.graph.elements[newEdgeChangeId] = {
          id: newEdgeChangeId,
          value: 0,
          unit: "Cm",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        state.graph.elements[newEdgeFrequencyId] = {
          id: newEdgeFrequencyId,
          value: 0,
          unit: "Cm",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        state.graph.elements[newEdgeVerticalId] = {
          id: newEdgeVerticalId,
          value: 0,
          unit: "Cm",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        state.graph.edges[newEdgeId] = {
          id: newEdgeId,
          horizontalChangeElement: newEdgeChangeId,
          frequencyElement: newEdgeFrequencyId,
          verticalDistanceElement: newEdgeVerticalId,
          parentNode: parentId,
          childNode: newNodeId,
          locked: false,
        };
      },
      prepare: (parentId: string) => {
        return {
          payload: {
            parentId,
            newEdgeId: shortUUID(),
            newEdgeChangeId: shortUUID(),
            newEdgeFrequencyId: shortUUID(),
            newEdgeVerticalId: shortUUID(),
            newNodeId: shortUUID(),
            newNodeElementId: shortUUID(),
          },
        };
      },
    },

    setMeasureLabel(state, action) {
      const { tableId, measureId, label } = action.payload;

      state.tables[tableId].measures[measureId].label = label;
    },

    setMeasureElementKind(state, action) {
      const {
        tableId,
        measureId,
        unit,
        edgeElementKind = undefined,
      }: {
        tableId: string;
        measureId: string;
        unit: Unit;
        edgeElementKind?: "change" | "frequency" | "vertical" | undefined;
      } = action.payload;

      const measure = state.tables[tableId].measures[measureId];

      let elementIds;
      if (measure.kind === "node") {
        const nodeIds = Object.values(measure.nodes);

        elementIds = Object.values(state.graph.nodes)
          .filter((node) => nodeIds.includes(node.id))
          .map((node) => node.element);
      } else {
        const edgeIds = Object.values(measure.edges);

        elementIds = Object.values(state.graph.edges)
          .filter((edge) => edgeIds.includes(edge.id))
          .map((edge) => {
            switch (edgeElementKind) {
              case "vertical":
                return edge.verticalDistanceElement;
              case "frequency":
                return edge.frequencyElement;
              case "change":
              default:
                return edge.horizontalChangeElement;
            }
          });
      }

      const gauge = state.gauge;

      for (let id of elementIds) {
        const element = state.graph.elements[id];

        const newValue = normaliseByUnit(
          element,
          element.direction === "Horizontal"
            ? gauge.horizontal
            : gauge.vertical,
          unit
        );

        element.unit = unit;
        element.value = newValue;
        element.displayValue = newValue;
        element.shouldRound = null;
      }

      // Force traversal
      state.graph.shouldTraverseFullGraph = true;
    },

    addMeasure(state, action) {
      const { tableId, sizes, orderIndex } = action.payload;

      if (sizes.length === 0) {
        // No branches to modify
        return;
      }

      const table = state.tables[tableId];

      if (orderIndex === 0 && table.order.length !== 0) {
        // Adding new node between root and first measure is not allowed.
        // If we want to allow this, we need to add a special case all the way
        // down to iterate over the edges out of root and find the ones pointing
        // to the previously first row.
        // Also, we're going to need new design drawings for it.
        return;
      }

      // Create node/edge measures
      const edgeMeasureId = shortUUID();
      table.measures[edgeMeasureId] = {
        id: edgeMeasureId,
        label: "",
        kind: "edge",
        edges: {},
        changeKind: "Vertical",
      };

      const nodeMeasureId = shortUUID();
      table.measures[nodeMeasureId] = {
        id: nodeMeasureId,
        label: "",
        kind: "node",
        nodes: {},
      };

      // Add only node to order. We'll add the edge later if parent isn't root.
      table.order.splice(orderIndex, 0, nodeMeasureId);

      let shouldAddEdge = true;

      // Create edges and nodes one size at a time and populate the measures

      // If the new node will not be the last one in the subtree, we need to do some
      // splitting on the existing graph
      const shouldSplitEdge = orderIndex !== table.order.length;

      for (const size of sizes) {
        // Determine parent node.
        // If this will be the first node in the graph branch (except for the root),
        // use the root as parent in all cases.
        // Otherwise, get the previous measure from the order, and then get the node
        // for the current size from that measure
        const parentNode =
          orderIndex === 0 || table.order.length === 0
            ? state.graph.nodes[state.graph.root]
            : state.graph.nodes[
                (
                  table.measures[
                    table.order[orderIndex - 1]
                  ] as NodeTableMeasure
                ).nodes[size]
              ];
        const parentElement = state.graph.elements[parentNode.element];

        // Create new node and node element
        const nodeElementId = shortUUID();
        state.graph.elements[nodeElementId] = {
          ...parentElement,
          id: nodeElementId,
          changed: false,
        };

        const nodeId = shortUUID();
        // If we should split the edge, attach existing edges to new node and detach
        // them from the parentNode. Otherwise, the new node should have no outgoing
        // edges (it will be the last element in the subgraph).
        if (shouldSplitEdge) {
          // We can assume that parentNode will never be the root node if shouldSplitEdge
          // is true, because of the data consistency check at the beginnning of the reducer
          state.graph.nodes[nodeId] = {
            id: nodeId,
            element: nodeElementId,
            edges: [...parentNode.edges],
            locked: false,
          };

          for (const edgeId of state.graph.nodes[nodeId].edges) {
            const edge = state.graph.edges[edgeId];
            edge.parentNode = nodeId;
          }

          parentNode.edges = [];
        } else {
          state.graph.nodes[nodeId] = {
            id: nodeId,
            element: nodeElementId,
            edges: [],
            locked: false,
          };
        }

        // Create new edge and edge element, attach to parent and new node
        const edgeChangeId = shortUUID();
        state.graph.elements[edgeChangeId] = {
          id: edgeChangeId,
          value: 0,
          unit: "Mask",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        const edgeFrequencyId = shortUUID();
        state.graph.elements[edgeFrequencyId] = {
          id: edgeFrequencyId,
          value: 0,
          unit: "Mask",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        const edgeVerticalId = shortUUID();
        state.graph.elements[edgeVerticalId] = {
          id: edgeVerticalId,
          value: 0,
          unit: "Cm",
          direction: "Vertical",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        const edgeId = shortUUID();
        state.graph.edges[edgeId] = {
          id: edgeId,
          horizontalChangeElement: edgeChangeId,
          frequencyElement: edgeFrequencyId,
          verticalDistanceElement: edgeVerticalId,
          parentNode: parentNode.id,
          childNode: nodeId,
          locked: false,
        };

        // Attach parent to new edge
        parentNode.edges.push(edgeId);

        // Insert new edge and node into their respective measures
        (table.measures[edgeMeasureId] as EdgeTableMeasure).edges[size] =
          edgeId;
        (table.measures[nodeMeasureId] as NodeTableMeasure).nodes[size] =
          nodeId;

        // If parent is root, mark edge measure for deletion
        if (parentNode.id === state.graph.root) {
          shouldAddEdge = false;
        }
      }

      // If the edge emerges from root, delete the measure. Otherwise,
      // add it to the order — before the node measure — so it is shown
      // in the table.
      if (shouldAddEdge) {
        table.order.splice(orderIndex, 0, edgeMeasureId);
      } else {
        // If the edge emerges from root, trigger calculations in case there is a relation to follow.
        state.graph.shouldTraverseFullGraph = true;
        delete table.measures[edgeMeasureId];
      }
    },

    setMeasureChangeKind(state, action) {
      const { tableId, measureId, changeKind } = action.payload;
      const measure = state.tables[tableId].measures[measureId];

      if (measure.kind === "edge") {
        measure.changeKind = changeKind;
        state.graph.shouldTraverseFullGraph = true;
      }
    },

    setTableLabel(state, action) {
      const { tableId, label } = action.payload;

      state.tables[tableId].label = label;
    },

    setTableMarks(state, action) {
      const { tableId, marks } = action.payload;

      state.tables[tableId].marks = marks;
      state.graph.shouldTraverseFullGraph = true;
    },

    setTableOrder(state, action) {
      const { tableId, newIndex } = action.payload;

      const oldIndex = state.tableOrder.findIndex(tableId);

      if (oldIndex === -1 || oldIndex === newIndex) {
        // No change to order
        return;
      }

      const newOrder = state.tableOrder.filter((id) => tableId !== id);

      if (oldIndex > newIndex) {
        // The array is one element shorter at this point, which in this
        // case means we have to shift the new index down by one to hit
        // the correct space.
        newOrder.splice(newIndex - 1, tableId);
      } else {
        newOrder.splice(newIndex, tableId);
      }

      state.tableOrder = newOrder;
    },

    addTable: {
      reducer: (
        state,
        action: PayloadAction<{
          tableId: string;
        }>
      ) => {
        const { tableId } = action.payload;
        state.tables[tableId] = {
          id: tableId,
          label: "Uten navn",
          marks: 0,
          order: [],
          measures: {},
        };
        state.tableOrder.push(tableId);
      },
      prepare: () => {
        return { payload: { tableId: shortUUID() } };
      },
    },

    addTableRelation: {
      reducer: (
        state,
        action: PayloadAction<{
          relationId: string;
          firstBottom?: string;
          firstTop?: string;
        }>
      ) => {
        const { relationId, firstBottom, firstTop } = action.payload;

        if (firstBottom !== undefined) {
          state.relations[relationId] = {
            id: relationId,
            kind: "linearEquality",
            top: [],
            bottom: [
              { tableId: firstBottom, scalar: 1, dividendMeasure: "last" },
            ],
          };
        }

        if (firstTop !== undefined) {
          state.relations[relationId] = {
            id: relationId,
            kind: "linearEquality",
            top: [{ tableId: firstTop, scalar: 1, dividendMeasure: "first" }],
            bottom: [],
          };
        }
      },
      prepare: (payload: { firstBottom?: string; firstTop?: string }) => {
        return { payload: { ...payload, relationId: shortUUID() } };
      },
    },

    addToRelationTop(state, action) {
      const { relationId, tableId } = action.payload;

      state.relations[relationId]?.top.push({
        tableId,
        scalar: 1,
        dividendMeasure: "first",
      });
    },

    addToRelationBottom(state, action) {
      const { relationId, tableId } = action.payload;

      state.relations[relationId]?.bottom.push({
        tableId,
        scalar: 1,
        dividendMeasure: "last",
      });
    },

    removeFromRelationTop(state, action) {
      const { relationId, tableId } = action.payload;

      const relations = state.relations[relationId].top;

      state.relations[relationId].top = relations.filter(
        (relation) => relation.tableId !== tableId
      );
    },

    removeFromRelationBottom(state, action) {
      const { relationId, tableId } = action.payload;

      const relations = state.relations[relationId].bottom;

      state.relations[relationId].bottom = relations.filter(
        (relation) => relation.tableId !== tableId
      );
    },

    setScalarTop(state, action) {
      const { relationId, tableId, value } = action.payload;

      const relation = state.relations[relationId].top.find(
        (relation) => relation.tableId === tableId
      );

      if (relation !== undefined) {
        relation.scalar = parseInt(value);
      }
    },

    setScalarBottom(state, action) {
      const { relationId, tableId, value } = action.payload;

      const relation = state.relations[relationId].bottom.find(
        (relation) => relation.tableId === tableId
      );

      if (relation !== undefined) {
        relation.scalar = parseInt(value);
      }
    },

    deleteRelation(state, action) {
      const { relationId } = action.payload;

      delete state.relations[relationId];
    },

    deleteMeasure(state, action) {
      const { tableId, measureId } = action.payload;

      const table = state.tables[tableId];
      const measure = table.measures[measureId];

      // Delete buttons only exist on nodes; we can simplify this function
      if (measure.kind === "edge") return;

      // This will be the adjacent measure whose graph elements are also necessarily deleted.
      let collateralMeasure: TableMeasure | null = null;

      // Detach the measure from the graph
      for (const nodeId of Object.values(measure.nodes)) {
        // Delete the node from the measure itself, and the edge leading into it.
        // Connect next edges, if any, to parent node to avoid orphaning the rest of the graph.
        // Update parentNodes of the new next edges.
        // Then, cleanup. Delete first measureElements, then orphaned node and edge.
        const node = state.graph.nodes[nodeId];

        const parentEdge = Object.values(state.graph.edges).find(
          (edge) => edge.childNode === node.id
        );

        if (parentEdge === undefined) {
          console.warn(
            `Could not delete node ${nodeId} from measure ${measureId} (table ${tableId}) because it has no parent edge`
          );
          continue;
        }

        // Measure containing the collateral edges
        if (collateralMeasure === null) {
          collateralMeasure =
            Object.values(table.measures).find((measure) => {
              if (measure.kind === "edge") {
                return Object.values(measure.edges).includes(parentEdge.id);
              }
              return false;
            }) ?? null;
        }

        const parentNode = state.graph.nodes[parentEdge.parentNode];

        parentNode.edges = [
          ...parentNode.edges.filter((edgeId) => edgeId !== parentEdge.id),
          ...node.edges,
        ];

        // Join the childEdge with the parentEdge value
        for (const edgeId of node.edges) {
          const childEdge = state.graph.edges[edgeId];
          childEdge.parentNode = parentNode.id;

          const horizontalEdgeElement =
            state.graph.elements[childEdge.horizontalChangeElement];
          horizontalEdgeElement.value +=
            state.graph.elements[parentEdge.horizontalChangeElement].value;
          horizontalEdgeElement.shouldRound = null;
          horizontalEdgeElement.displayValue = horizontalEdgeElement.value;

          const frequencyElement =
            state.graph.elements[childEdge.frequencyElement];
          frequencyElement.value +=
            state.graph.elements[parentEdge.frequencyElement].value;
          frequencyElement.shouldRound = null;
          frequencyElement.displayValue = frequencyElement.value;

          const verticalEdgeElement =
            state.graph.elements[childEdge.verticalDistanceElement];
          verticalEdgeElement.value +=
            state.graph.elements[parentEdge.verticalDistanceElement].value;
          verticalEdgeElement.shouldRound = null;
          verticalEdgeElement.displayValue = verticalEdgeElement.value;
        }

        // Delete elements
        delete state.graph.elements[node.element];
        delete state.graph.elements[parentEdge.frequencyElement];
        delete state.graph.elements[parentEdge.horizontalChangeElement];
        delete state.graph.elements[parentEdge.verticalDistanceElement];

        // Delete node and edge
        delete state.graph.nodes[node.id];
        delete state.graph.edges[parentEdge.id];
      }

      // Remove measures from table and delete them.
      table.order = table.order.filter(
        (measureId) =>
          measureId !== measure.id && measureId !== collateralMeasure?.id
      );
      delete table.measures[measure.id];
      if (collateralMeasure !== null)
        delete table.measures[collateralMeasure.id];
    },

    traverseFullGraph(state, action) {
      // Sizes are not stored in this part of the state, so we have to pass them
      const { sizes } = action.payload as { sizes: string[] };

      state.changes = {};

      if (sizes.length === 0) {
        // No selected sizes means no graph to traverse
        return;
      }

      // Get tables without a relation pointing to their first row;
      // they're starting points in the dependency graph
      let traversableTables = getTraversableTables(
        state.tableOrder,
        state.relations
      );

      // Initially, all relations between tables are checkable
      let checkableRelations = [...Object.values(state.relations)];

      // Keep track of traversed tables for relation dependency checking
      let traversedTables: string[] = [];

      // While there are traversable tables in the dependency graph, traverse one at a time in fifo order.
      while (traversableTables.length > 0) {
        const { tableId, relationId } = traversableTables.shift()!;

        const table = state.tables[tableId];

        if (table.order.length === 0) {
          // If the table is empty, skip it
          continue;
        }

        const firstMeasure = table.measures[table.order[0]] as NodeTableMeasure;
        const lastMeasure = table.measures[
          table.order[table.order.length - 1]
        ] as NodeTableMeasure;

        // Traverse one size/column at a time
        for (const size of sizes) {
          const firstNodeId = firstMeasure.nodes[size];
          const lastNodeId = lastMeasure.nodes[size];

          // Handle relation changes
          state.changes = {
            ...state.changes,
            ...findRelationChanges(state, relationId, size),
          };

          // Traverse the table, one cell at a time
          let nodeId = firstNodeId;
          while (nodeId !== lastNodeId) {
            // Get current node + nodeElement
            const node = state.graph.nodes[nodeId];
            const nodeElement = state.graph.elements[node.element];

            // Data consistency check; stop traversing if graph ends unexpectedly
            if (node.edges.length === 0) {
              break;
            }

            // Get first edge leading out of current node + its elements
            const edge = state.graph.edges[node.edges[0]];
            const horizontalEdgeElement =
              state.graph.elements[edge.horizontalChangeElement];
            const verticalEdgeElement =
              state.graph.elements[edge.verticalDistanceElement];
            const frequencyEdgeElement =
              state.graph.elements[edge.frequencyElement];

            // Get child node of edge
            const nextNode = state.graph.nodes[edge.childNode];
            const nextNodeElement = state.graph.elements[nextNode.element];

            // ChangeKind is stored on the measure, fetch it by the current edgeId
            const changeKind = (
              Object.values(table.measures).find(
                (measure) =>
                  measure.kind === "edge" && measure.edges[size] === edge.id
              ) as EdgeTableMeasure
            ).changeKind;

            // Find all edge and node changes and put them into state
            state.changes = {
              ...state.changes,
              ...findEdgeChanges(
                {
                  node: nodeElement,
                  nextNode: nextNodeElement,
                  horizontal: horizontalEdgeElement,
                  frequency: frequencyEdgeElement,
                  vertical: verticalEdgeElement,
                },
                state.gauge,
                table.marks,
                changeKind
              ),
              ...findNodeChanges(
                {
                  node: nodeElement,
                  nextNode: nextNodeElement,
                  horizontal: horizontalEdgeElement,
                },
                state.gauge
              ),
            };

            // set nodeId := nextNodeId to keep traversal going
            nodeId = edge.childNode;
          }
        } // Table fully traversed

        traversedTables.push(tableId);

        // Check remaining relations for dependency fulfilment (if all tables in relation.bottom
        // are traversed, we can add all tables in relation.top to the traversable list).

        // Check whether any relations contain the newly traversed table in dependencies/bottom
        const checkableRelationIndex = checkableRelations.findIndex(
          (relation) =>
            relation.bottom
              .map((relationElement) => relationElement.tableId)
              .includes(tableId)
        );

        // If there is a relation that contains this table, check whether all its dependencies
        // are fully traversed. If they are, add all tables in its top to traversableTables
        if (checkableRelationIndex !== -1) {
          const relation = checkableRelations[checkableRelationIndex];

          let done = true;
          for (const entry of relation.bottom) {
            if (!traversedTables.includes(entry.tableId)) {
              // There exists a dependency table that has not yet been traversed
              done = false;
              break;
            }
          }

          if (done) {
            // Once a relation is resolved, remove it from checkables.
            checkableRelations.splice(checkableRelationIndex, 1);

            // Add the tables that depend on the relation to traversableTables, along with the relationId
            traversableTables = [
              ...traversableTables,
              ...relation.top.map((entry) => ({
                tableId: entry.tableId,
                relationId: relation.id,
              })),
            ];
          }
        }
      }

      state.graph.shouldTraverseFullGraph = false;
    },

    deleteTable(state, action) {
      const { tableId } = action.payload;

      const table = state.tables[tableId];

      if (table.order.length !== 0) {
        // First, delete the edges from root (for which no measure exists).
        // This orphans the entire table subtree from the graph, which means we
        // don't have to worry about splicing.
        {
          // Doing this in a block to not pollute the rest of the function
          const firstMeasureNodeIds = Object.values(
            (table.measures[table.order[0]] as NodeTableMeasure).nodes
          );
          const rootNode = state.graph.nodes[state.graph.root];
          const deletedEdges: string[] = [];
          for (const edgeId of rootNode.edges) {
            const edge = state.graph.edges[edgeId];
            if (firstMeasureNodeIds.includes(edge.childNode)) {
              delete state.graph.elements[edge.frequencyElement];
              delete state.graph.elements[edge.horizontalChangeElement];
              delete state.graph.elements[edge.verticalDistanceElement];

              delete state.graph.edges[edgeId];
              deletedEdges.push(edgeId);
            }
          }

          rootNode.edges = rootNode.edges.filter(
            (edgeId) => !deletedEdges.includes(edgeId)
          );
        }

        // Then, delete all nodes, edges and elements for all measures.
        // Since the table is a full subtree, no splicing is necessary here.
        for (const measure of Object.values(table.measures)) {
          if (measure.kind === "node") {
            for (const nodeId of Object.values(measure.nodes)) {
              const node = state.graph.nodes[nodeId];

              delete state.graph.elements[node.element];
              delete state.graph.nodes[nodeId];
            }
          } else {
            for (const edgeId of Object.values(measure.edges)) {
              const edge = state.graph.edges[edgeId];

              delete state.graph.elements[edge.frequencyElement];
              delete state.graph.elements[edge.horizontalChangeElement];
              delete state.graph.elements[edge.verticalDistanceElement];

              delete state.graph.edges[edgeId];
            }
          }
        }
      }

      // Remove table from any table relations; delete the relation if the
      // relation would otherwise be completely empty.
      for (const relation of Object.values(state.relations)) {
        relation.top = relation.top.filter(
          (relationEntry) => relationEntry.tableId !== tableId
        );
        relation.bottom = relation.bottom.filter(
          (relationEntry) => relationEntry.tableId !== tableId
        );
        if (relation.top.length === 0 && relation.bottom.length === 0)
          delete state.relations[relation.id];
      }

      // Finally, delete the table itself and remove it from the order.
      state.tableOrder = state.tableOrder.filter(
        (tableId) => tableId !== table.id
      );
      delete state.tables[tableId];
    },

    forceNewTraverse(state) {
      state.graph.shouldTraverseFullGraph = true;
      state.changes = {};
    },

    acceptChange(state, action) {
      const { changeId } = action.payload;

      const change = state.changes[changeId];

      //! TODO: Delete this once we're good
      if (change.elementId === undefined) return;

      const element = state.graph.elements[change.elementId];
      element.value = change.newValue;
      element.displayValue = change.newValue;
      element.shouldRound = null;
      element.changed = false;

      state.changes = {};
      state.graph.shouldTraverseFullGraph = true;
    },
  },

  extraReducers: {
    [fetchPatternById.fulfilled.type](_state, action) {
      const pattern: Pattern = action.payload;

      // TODO: Before launch we should do a wipe of old patterns and clean up this function.
      // It's a mess, and going to get worse
      const calculations = { ...(pattern.calculationResults ?? {}) };

      if (Object.keys(calculations).length === 0) {
        return emptyCalculationSlice;
      }

      // @ts-expect-error
      if (calculations.table !== undefined) {
        // Update from single-table version. Didn't keep old type around, so expecting type errors on relevant lines
        const tableId = shortUUID();
        const tables = {
          [tableId]: {
            // @ts-expect-error
            ...calculations.table,
            id: tableId,
            label: "Uten navn",
          },
        };
        calculations.tables = tables;
        calculations.tableOrder = [tableId];
        // @ts-expect-error
        delete calculations.table;
      }

      if (calculations.tableOrder === undefined) {
        calculations.tableOrder = Object.keys(calculations.tables);
      }

      if (calculations.relations === undefined) {
        calculations.relations = {};
      }

      if (
        calculations.changes === undefined ||
        Object.keys(calculations.changes).length !== 0
      ) {
        calculations.changes = {};
      }

      if (calculations.gauge === undefined) {
        calculations.gauge = { ...emptyCalculationSlice.gauge };
      }
      // @ts-expect-error
      if (calculations.gauge.stitches !== undefined) {
        // In this case, gauge type looks like {stitches: int, length: int}
        calculations.gauge = {
          // @ts-expect-error
          horizontal: { ...calculations.gauge, manual: false },
          // @ts-expect-error
          vertical: { ...calculations.gauge, manual: false },
        };
      }
      if (calculations.gauge.horizontal.manual === undefined) {
        calculations.gauge = {
          horizontal: { ...calculations.gauge.horizontal, manual: false },
          vertical: { ...calculations.gauge.vertical, manual: false },
        };
      }

      if (
        // @ts-expect-error
        Object.values(calculations.graph.elements)[0]?.type !== undefined
      ) {
        calculations.graph = { ...calculations.graph };
        calculations.graph.elements = Object.fromEntries(
          Object.entries(calculations.graph.elements).map(([id, element]) => {
            const { value, shouldRound, displayValue, changed } = element;
            let unit: Unit = "Mask";
            let direction: Direction = "Horizontal";
            // @ts-expect-error
            switch (element.type) {
              case "MaskHorizontal":
                unit = "Mask";
                direction = "Horizontal";
                break;
              case "CmHorizontal":
                unit = "Cm";
                direction = "Horizontal";
                break;
              case "MaskVertical":
                unit = "Mask";
                direction = "Vertical";
                break;
              case "CmVertical":
                unit = "Cm";
                direction = "Vertical";
                break;
            }

            return [
              id,
              {
                id,
                value,
                unit,
                direction,
                shouldRound,
                displayValue,
                changed,
              },
            ];
          })
        );
      }

      if (
        // @ts-expect-error
        Object.values(calculations.graph.elements)[0]?.unit === "" || // @ts-expect-error
        Object.values(calculations.graph.elements)[0]?.direction === ""
      ) {
        calculations.graph = { ...calculations.graph };
        calculations.graph.elements = Object.fromEntries(
          Object.entries(calculations.graph.elements).map(([id, element]) => {
            const { value, shouldRound, displayValue, changed } = element;

            return [
              id,
              {
                id,
                value,
                shouldRound,
                displayValue,
                changed,
                unit: "Mask",
                direction: "Horizontal",
              },
            ];
          })
        );
      }

      if (calculations.graph.shouldTraverseFullGraph !== true) {
        calculations.graph = {
          ...calculations.graph,
          shouldTraverseFullGraph: true,
        };
      }

      return calculations;
    },
    [removeSizeFromState.type](state, action) {
      const { removeSize: size } = action.payload;

      for (const tableId of state.tableOrder) {
        const table = state.tables[tableId];

        for (const measureId of table.order) {
          const measure = table.measures[measureId];

          if (measure.kind === "node") {
            const nodeId = measure.nodes[size];

            const elementId = state.graph.nodes[nodeId].element;

            // Delete node and its element, remove its reference from the measure
            delete state.graph.elements[elementId];
            delete state.graph.nodes[nodeId];
            delete measure.nodes[size];
          } else {
            const edgeId = measure.edges[size];

            if (!edgeId) {
              return;
            }

            const horizontalElementId =
              state.graph.edges[edgeId].horizontalChangeElement;
            const frequencyElementId =
              state.graph.edges[edgeId].frequencyElement;
            const verticalElementId =
              state.graph.edges[edgeId].verticalDistanceElement;

            // Delete edge and its elements, remove its reference from the measure
            delete state.graph.elements[horizontalElementId];
            delete state.graph.elements[frequencyElementId];
            delete state.graph.elements[verticalElementId];
            delete state.graph.edges[edgeId];
            delete measure.edges[size];
          }
        }
      }
    },
    [addSizeToState.type](state, action) {
      const { newSize: size } = action.payload;

      for (const tableId of state.tableOrder) {
        const table = state.tables[tableId];

        // Keep previous node in memory to iteratively attach edges as they are made
        let previousNode = state.graph.nodes[state.graph.root];

        // Add an initial edge from root leading into table
        const prevEdgeHorizId = shortUUID();
        const prevEdgeFreqId = shortUUID();
        const prevEdgeVertId = shortUUID();
        state.graph.elements[prevEdgeHorizId] = {
          id: prevEdgeHorizId,
          value: 0,
          unit: "Mask",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };
        state.graph.elements[prevEdgeFreqId] = {
          id: prevEdgeFreqId,
          value: 0,
          unit: "Mask",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };
        state.graph.elements[prevEdgeVertId] = {
          id: prevEdgeVertId,
          value: 0,
          unit: "Cm",
          direction: "Horizontal",
          shouldRound: null,
          displayValue: 0,
          changed: false,
        };

        // Keep previous edge in memory to iteratively attach childNodes as they are made.
        // childNode: "" is an illegal state; all leaves should be nodes. This is fixed in
        // the loop below
        let previousEdge: FlatGraphEdge = {
          id: shortUUID(),
          horizontalChangeElement: prevEdgeHorizId,
          frequencyElement: prevEdgeFreqId,
          verticalDistanceElement: prevEdgeVertId,
          parentNode: previousNode.id,
          childNode: "",
          locked: false,
        };

        state.graph.edges[previousEdge.id] = previousEdge;

        // Iterate over all measures in table, add nodes and edges as necessary and attach to
        // graph before them
        for (const measureId of table.order) {
          const measure = table.measures[measureId];

          if (measure.kind === "node") {
            // Find out whether node should be locked and which unit it should have to match the rest
            let unit: Unit = "Mask";
            let direction: Direction = "Horizontal";
            let locked = false;
            if (Object.keys(measure.nodes).length !== 0) {
              unit =
                state.graph.elements[
                  state.graph.nodes[Object.values(measure.nodes)[0]].element
                ].unit;
              direction =
                state.graph.elements[
                  state.graph.nodes[Object.values(measure.nodes)[0]].element
                ].direction;
              locked =
                state.graph.nodes[Object.values(measure.nodes)[0]].locked;
            }

            const element: MeasureElement = {
              id: shortUUID(),
              value: 0,
              unit,
              direction,
              shouldRound: null,
              displayValue: 0,
              changed: false,
            };

            state.graph.elements[element.id] = element;

            const node: FlatGraphNode = {
              id: shortUUID(),
              element: element.id,
              edges: [],
              locked,
            };

            // Add new node properly to graph
            state.graph.nodes[node.id] = node;
            previousEdge.childNode = node.id;

            // Add new node properly to measure, and update previousNode for next iteration
            measure.nodes[size] = node.id;
            previousNode = node;
          } else {
            // Find out whether edge should be locked and which units it should have to match the rest
            let horizontalUnit: Unit = "Mask";
            let frequencyUnit: Unit = "Mask";
            let frequencyDirection: Direction = "Horizontal";
            let verticalUnit: Unit = "Cm";
            let locked = false;
            if (Object.keys(measure.edges).length !== 0) {
              const edge = state.graph.edges[Object.values(measure.edges)[0]];
              horizontalUnit =
                state.graph.elements[edge.horizontalChangeElement].unit;
              frequencyUnit = state.graph.elements[edge.frequencyElement].unit;
              frequencyDirection =
                state.graph.elements[edge.frequencyElement].direction;
              verticalUnit =
                state.graph.elements[edge.verticalDistanceElement].unit;

              locked = edge.locked;
            }

            // Create elements for the edge
            const horizontalElementId = shortUUID();
            const frequencyElementId = shortUUID();
            const verticalElementId = shortUUID();
            state.graph.elements[horizontalElementId] = {
              id: horizontalElementId,
              value: 0,
              unit: horizontalUnit,
              direction: "Horizontal",
              shouldRound: null,
              displayValue: 0,
              changed: false,
            };
            state.graph.elements[frequencyElementId] = {
              id: frequencyElementId,
              value: 0,
              unit: frequencyUnit,
              direction: frequencyDirection,
              shouldRound: null,
              displayValue: 0,
              changed: false,
            };
            state.graph.elements[verticalElementId] = {
              id: verticalElementId,
              value: 0,
              unit: verticalUnit,
              direction: "Vertical",
              shouldRound: null,
              displayValue: 0,
              changed: false,
            };

            const edge: FlatGraphEdge = {
              id: shortUUID(),
              horizontalChangeElement: horizontalElementId,
              frequencyElement: frequencyElementId,
              verticalDistanceElement: verticalElementId,
              parentNode: previousNode.id,
              childNode: "",
              locked,
            };

            // Add new edge properly to graph
            state.graph.edges[edge.id] = edge;
            previousNode.edges.push(edge.id);

            // Attach new edge to measure, update previousEdge for next iteration
            measure.edges[size] = edge.id;
            previousEdge = edge;
          }
        }
      }
    },
  },
});

export const {
  setGauge,
  setGraph,
  toggleEdgeLocked,
  toggleNodeLocked,
  toggleMeasureLocked,
  setTableLocked,
  setTableUnlocked,
  setElementRounded,
  setElementValue,
  addNode,
  setMeasureLabel,
  addMeasure,
  setMeasureElementKind,
  setMeasureChangeKind,
  setTableLabel,
  setTableMarks,
  setTableOrder,
  addTable,
  addTableRelation,
  addToRelationTop,
  addToRelationBottom,
  removeFromRelationTop,
  removeFromRelationBottom,
  setScalarTop,
  setScalarBottom,
  deleteTable,
  deleteMeasure,
  deleteRelation,
  traverseFullGraph,
  forceNewTraverse,
  acceptChange,
} = calculationSlice.actions;

export {
  updateCalculations,
  setGaugeStitchesHorizontal,
  setGaugeLengthHorizontal,
  setGaugeLengthVertical,
  setGaugeStitchesVertical,
  calculationSlice,
};
