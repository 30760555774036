import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SizeCategory, SizeGroup } from "../../store/pattern";
import {
  addSize,
  addSizeCategory,
  removeSize,
  setCategory,
} from "../../store/patternSlice";
import { RootState } from "../../store/store";
import styles from "./SizeSelector.module.css";
import { Listbox } from "@headlessui/react";
import {
  PlusIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";
import Button from "../../ISO/Components/Button";
import { EditSizeGroupModal } from "./EditSizeGroupModal";
import { useTranslation } from "react-i18next";

interface PropType {
  category: SizeCategory | null;
  sizes: string[];
  sizeGroups: SizeGroup[];
}

function SizeSelector(props: PropType) {
  const { category, sizes, sizeGroups } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newCategory, setNewCategory] = useState<string>("");

  const allSizesInGroup = sizeGroups.find(
    (group) => group.label === category
  ) ?? { label: "", sizes: [] };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.select}>
          <Listbox
            value={category}
            onChange={(selected) => dispatch(setCategory(selected))}
          >
            {({ open }) => (
              <>
                <Listbox.Button className={styles.listboxButton}>
                  {category} {open ? <TriangleUpIcon /> : <TriangleDownIcon />}
                </Listbox.Button>
                <Listbox.Options className={styles.options}>
                  {sizeGroups.map((category, i) => (
                    <Listbox.Option key={i} value={category.label}>
                      {({ active }) => (
                        <div
                          className={`${active ? styles.active : ""} ${
                            styles.option
                          }`}
                        >
                          {category.label}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                  <div className={styles.newSizeGroupContainer}>
                    <input
                      className={styles.newSizeGroupInput}
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      placeholder={t("new")}
                    />
                    <Button
                      active
                      disabled={newCategory === ""}
                      className={styles.newSizeGroupButton}
                      onClick={() => {
                        dispatch(addSizeCategory({ name: newCategory }));
                        setNewCategory("");
                      }}
                    >
                      <PlusIcon />
                    </Button>
                  </div>
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>
        {allSizesInGroup.sizes.map((size, i) => (
          <Button
            key={i}
            className={styles.size}
            active={sizes.includes(size)}
            onClick={() =>
              sizes.includes(size)
                ? dispatch(removeSize({ size }))
                : dispatch(addSize({ size, allSizes: allSizesInGroup.sizes }))
            }
            contrast
          >
            {size}
          </Button>
        ))}
        <EditSizeGroupModal category={category} sizeGroup={allSizesInGroup} />
      </div>
    </>
  );
}

function AwareSizeSelector() {
  const { sizes, sizeCategory, sizeGroups } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  return (
    <SizeSelector
      category={sizeCategory}
      sizes={sizes}
      sizeGroups={sizeGroups}
    />
  );
}

export default AwareSizeSelector;
export { SizeSelector };
