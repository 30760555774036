import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../ISO/Components/Button";
import { Organization, useGetAllOrgsQuery } from "../services/organization";
import { Wrapper } from "./Wrapper";
import styles from "./MenuDrawer.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleMenuDrawer } from "../store/writerSlice";

export const OrgDrawer = () => {
  const { data, isLoading, error } = useGetAllOrgsQuery();
  return (
    <div>
      <Wrapper isLoading={isLoading} error={error}>
        {data && <OrgList organizations={data} />}
      </Wrapper>
    </div>
  );
};

interface Orgs {
  organizations: Organization[];
}

const OrgList: React.FC<Orgs> = ({ organizations }) => {
  const [open, toggle] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleMenuDrawer());
  };

  return (
    <>
      <Button className={styles.menuButton} onClick={() => toggle(!open)}>
        <h4>{t("organization", { count: organizations.length })}</h4>{" "}
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
      {open && (
        <>
          {organizations.map((org, i) => (
            <Link key={i} to={`/organization/${org.id}`}>
              <Button onClick={handleClose} className={styles.org}>
                {org.name}
              </Button>
            </Link>
          ))}
          <div className={styles.borderBottom} />
        </>
      )}
    </>
  );
};
