import React from "react";
import { CommentDrawer } from "../Writer/Comment/CommentDrawer";
import { MetadataDrawer } from "../Metadata/MetadataDrawer";
import { MenuDrawer } from "./MenuDrawer";

export const GlobalContent = () => {
  return (
    <>
      <MetadataDrawer />
      <CommentDrawer />
      <MenuDrawer />
    </>
  );
};
