import React, { useRef } from "react";

import { useEffect, useState } from "react";
import {
  KnitabilityProblem,
  DrawTypes,
  RepeatMode,
  SweaterPartArea,
  SweaterPartAreaGroup,
} from "../enums";
import {
  colorsScene,
  getSweaterParts,
  makeScene,
  runAfterLoadSweaterSet,
  setColorsScene,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import { SweaterPart } from "../SweaterPart";
import Grid from "./Grid";
import { Util } from "../static/util";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { Raglan } from "../data/raglan";
import { Isydd } from "../data/isydd";
import { Global } from "../static/global";
import { fixColor } from "../knittingpreview/colorutil";
import { hasLoadedImages, loadGrid, setHasLoadedImages } from "./gridcanvas";

// Must be outside KnittingEditor, or else it wont be affected by loadPatternGrids()
let patternGrids: { [name: string]: number[][] } = {};

function KnittingEditor(props: any) {
  const [diagram, setDiagram] = useState<Pattern | undefined>(undefined);
  const [pointer, _setPointer] = useState<boolean>(false);
  const [pointerPattern, setPointerPattern] = useState<Pattern | undefined>(
    undefined
  );

  const [patterns, setPatterns] = useState<any>({});

  const [colors, setColors] = useState<string[]>([]);
  const [repeatOptions, setRepeatOptions] = useState([
    "None",
    "None",
    "None",
    "None",
  ]); // Is updated after selecting props.selectedPart

  const repeatModes = [
    RepeatMode.NONE,
    RepeatMode.ONE,
    RepeatMode.BOTH,
    RepeatMode.ALL,
  ];
  const [repeat, setRepeat] = useState(3);
  const [repeatMemo, setRepeatMemo] = useState(3);

  const [loadModel, setLoadModel] = useState<boolean>(false);

  const [hasInited, setHasInited] = useState(false);

  const color = useSelector((state: RootState) => state.color);
  const { knittingMethod } = useSelector((state: RootState) => state.model);

  const { diagrams, id } = useSelector((state: RootState) => state.pattern);

  useEffect(() => {
    if (
      color.colors.length === 3 &&
      color.colors[0].hex === "#eee" &&
      color.colors[1].hex === "#E0A320" &&
      color.colors[2].hex === "#EAE414"
    ) {
      if (colors.length !== 0) return;
      // Seems to get a specific color at startup. Wierd.
    }
    if (color.colors.length === 0) {
      return;
    }
    const colorsFixed = color.colors.map((it: any) => fixColor(it.hex));
    setColorsScene(colorsFixed);
    setColors(colorsFixed);
  }, [color]);

  useEffect(() => {
    if (colors.length === 0 || hasInited || id === -1) return;
    setHasInited(true);
    init();
  }, [colors, hasInited, id]);

  useEffect(() => {
    if (!hasInited) return;
    init();
  }, [knittingMethod]);

  useEffect(() => {
    setHasInited(false);
  }, []);

  useEffect(() => {
    if (props.selectedSweaterPart === undefined) return;
    updateUI(props.selectedSweaterPart);
  }, [props.selectedSweaterPart]);

  useEffect(() => {
    setPointer(false);
  }, [props.isMiniature]);

  function setPointer(value: boolean) {
    setPointerPattern(undefined);
    _setPointer(value);
  }

  function makeModelScene(colors: any) {
    // 2128, 1986, 3826, 4025

    let element = document.getElementById("canvas")!!;

    let parts: { [key: string]: Raglan | Isydd } = {
      raglan: new Raglan(),
      stitched_sleeves: new Isydd(),
    };

    makeScene(
      element,
      parts[knittingMethod].parts(),
      colors,
      props.setSelectedSweaterPart,
      knittingMethod
    );
  }

  function updateUI(sweaterPart: SweaterPart) {
    if (sweaterPart.isCollar()) {
      if (repeatMemo > 1) {
        setRepeat(1);
      }
      setRepeatOptions(["None", sweaterPart.name]);
    } else {
      const thirdRepeatOption = sweaterPart.isArm() ? "Arms" : "Torso";
      setRepeat(repeatMemo);
      setRepeatOptions([
        "None",
        sweaterPart.name,
        thirdRepeatOption,
        "Arms & Torso",
      ]);
    }
  }

  function init() {
    makeModelScene(colors);
    setLoadModel(true);
    setHasLoadedImages(false);

    const frontTorso = getSweaterParts().find(
      (v: SweaterPart) => v.area === SweaterPartArea.FrontTorso
    );
    props.setSelectedSweaterPart(frontTorso);
  }

  /*function changeMaskSize(size: string) {
    const oldMasksPer10Cm = Settings.masksPer10Cm
    switch (size) {
        case "S": Settings.masksPer10Cm = 28; break;
        case "M": Settings.masksPer10Cm = 18; break;
        case "L": Settings.masksPer10Cm = 12; break;
    }
    if (oldMasksPer10Cm === Settings.masksPer10Cm) return;
    for (let sweaterPart of getSweaterParts()) {
        sweaterPart.setDirty()
    }
    Settings.updateCanvasDimensions()
    resetCanvas()
    unCacheDraw()
    loadGrid(props.selectedSweaterPart, setGrid) //Re-select sweaterPart
}*/

  return (
    <div
      onContextMenu={(e) => {
        //Right click
        e.preventDefault();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "100%",
        backgroundColor: "var(--whiteish)",
        maxWidth: "100%",
      }}
    >
      <Grid
        isSweaterPart={true}
        sweaterPart={props.selectedSweaterPart}
        setSelectedSweaterPart={props.setSelectedSweaterPart}
        useZoom={true}
        selectedEditPatternDone={props.selectedEditPattern == undefined}
        SavePattern={undefined}
        sendUndoRedoTrashRef={false}
        setEditPatternHeight={undefined}
        setSweaterHasLoaded={props.setSweaterHasLoaded}
        loadModel={loadModel}
        colors={colors}
        repeat={repeat}
        setRepeat={setRepeat}
        setRepeatMemo={setRepeatMemo}
        setRepeatOptions={setRepeatOptions}
        repeatOptions={repeatOptions}
        repeatModes={repeatModes}
        patternGrids={patternGrids}
        diagram={diagram}
        setDiagram={setDiagram}
        isMiniature={props.isMiniature}
        pointer={pointer}
        setPointer={setPointer}
        pointerPattern={pointerPattern}
        setPointerPattern={setPointerPattern}
        patterns={patterns}
        setPatterns={setPatterns}
      />
    </div>
  );
}

export default KnittingEditor;
