import React from "react";

interface PropType {
  color?: string;
}

function Plus(props: PropType) {
  const { color = "#3d8036" } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50026 2.93311C8.50026 2.65696 8.2764 2.43311 8.00026 2.43311C7.72412 2.43311 7.50026 2.65696 7.50026 2.93311V7.49977H2.93359C2.65745 7.49977 2.43359 7.72363 2.43359 7.99977C2.43359 8.27591 2.65745 8.49977 2.93359 8.49977H7.50026V13.0664C7.50026 13.3426 7.72412 13.5664 8.00026 13.5664C8.2764 13.5664 8.50026 13.3426 8.50026 13.0664V8.49977H13.0669C13.3431 8.49977 13.5669 8.27591 13.5669 7.99977C13.5669 7.72363 13.3431 7.49977 13.0669 7.49977H8.50026V2.93311Z"
        fill={color}
      />
    </svg>
  );
}

export default Plus;
