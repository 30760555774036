import { DiagramData, GridCell } from "./pattern";

const emptyCell: GridCell = { color: 0, symbol: "stitch" };
const row = Array(10).fill(emptyCell);


export const gridArray: GridCell[][] = new Array(10).fill(row);

export const createGridArray = (width = 10, height = 10) => {
  const row = new Array(width).fill(emptyCell);
  return new Array(height).fill(row);
};

export const grid: DiagramData = {
  centerX: null,
  grid: gridArray,
  gridHeight: 10,
  gridWidth: 10,
  name: "New Diagram",
  owner: { name: "" },
  id: "#test",
  symbols: [],
};
