import { Listbox } from "@headlessui/react";
import {
  MinusIcon,
  PlusIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CtaButton from "../../CommonComponents/CtaButton";
import Button from "../../ISO/Components/Button";
import { useUpdateModelKnitAssignmentsMutation } from "../../services/modelknit";
import {
  NewModelKnitAssignment,
  YarnProfileVariant,
} from "../../store/pattern";
import { RootState } from "../../store/store";
import { KnitGroupSelect } from "./KnitGroupSelect";
import { KnitterSelect } from "./KnitterSelect";
import styles from "./modelknit.module.css";
import { PriceInput } from "./Price";

interface PropType {
  id: number;
  variants: YarnProfileVariant[];
  abort: Function;
  toggle: () => void;
  organizationId: number | null;
}

export const AddModalKnit = (props: PropType) => {
  const { variants, abort, toggle } = props;
  const { sizes } = useSelector((state: RootState) => state.pattern.meta);
  const orgId = props.organizationId;

  const noVariant: YarnProfileVariant = {
    name: "Ingen variant",
    id: "no_variant",
    images: [],
    colors: [],
    amounts: {},
  };

  const [knitGroup, setKnitGroup] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [variantIndex, setVariantIndex] = useState(0);
  const [customYarnAmounts, setCustomYarnAmounts] = useState<{
    [key: string]: number;
  }>({});

  const [activeSize, setSize] = useState<string | null>(null);

  const [postAssignment] = useUpdateModelKnitAssignmentsMutation();

  const initialAssignmentData = {
    priceOre: 0,
    size: null,
    variantId:
      variantIndex !== variants.length
        ? variants[variantIndex].id
        : "no_variant",
    organizationId: orgId,
    yarn: undefined,
    deadline: undefined,
    comment: "",
  };

  const [newKnitAssignment, setAssignment] = useState<NewModelKnitAssignment>(
    initialAssignmentData
  );

  const set = (key: string, value: any) => {
    setAssignment({ ...newKnitAssignment, [key]: value });
  };

  useEffect(() => {
    if (variantIndex === variants.length) {
      set("variantId", "no_variant");
    } else {
      set("variantId", variants[variantIndex].id);
    }
    if (activeSize !== null) {
      setCustomYarnAmounts(variants[variantIndex].amounts[activeSize] || 0);
    }
  }, [variantIndex]);

  useEffect(() => {
    set("size", activeSize);
    if (activeSize !== null) {
      setCustomYarnAmounts(variants[variantIndex].amounts[activeSize] || 0);
    }
  }, [activeSize]);

  useEffect(() => {
    set("knitterTag", knitGroup);
  }, [knitGroup]);

  if (orgId === null) {
    toggle();
    return null;
  }

  return (
    <div>
      {page === 1 && (
        <>
          <div className={styles.variantRow}>
            <div className={styles.firstCol}>
              <h4>Fargevariant:</h4>
              <hr className={styles.hr} />
              <VariantSelect
                variants={[...variants, noVariant]}
                activeVariant={variantIndex}
                setter={(index) => {
                  setVariantIndex(index);
                }}
              />
            </div>
            <div className={styles.secondCol}>
              <h4>Størrelse:</h4>
              <hr className={styles.hr} />
              <SizeSelect
                activeSize={activeSize}
                sizes={sizes}
                setter={(size) => {
                  setSize(size);
                }}
              />
            </div>
          </div>
          <hr className={styles.hr} />
          <div>
            <h4>Forespørsel til:</h4>
            <div className={styles.knitterRow}>
              <KnitGroupSelect
                orgId={orgId}
                setKnitGroup={(tag) => {
                  setKnitGroup(tag);
                }}
              />
              <KnitterSelect
                orgId={orgId}
                tag={knitGroup}
                setKnitters={(value) => {
                  set("knitterId", value);
                }}
              />
            </div>
            <hr className={styles.hr} />
            <div>
              <div className={styles.bottomButton}>
                <Button
                  className={styles.button}
                  contrast
                  onClick={() => {
                    // Reset
                    setAssignment(initialAssignmentData);
                    abort();
                  }}
                >
                  Avbryt
                </Button>
                <CtaButton className={styles.button} onClick={() => setPage(2)}>
                  Neste
                </CtaButton>
              </div>
            </div>
          </div>
        </>
      )}
      {page === 2 && (
        <div>
          <div className={styles.yarnAmountTitle}>
            <h4>
              {[...variants, noVariant][variantIndex].name} - Garnkvalitet
            </h4>
            <p>{activeSize ?? "Ingen størrelse"}</p>
          </div>
          {[...variants, noVariant][variantIndex].id !== "no_variant" &&
            [...variants, noVariant][variantIndex].colors.map(
              (color, i, all) => (
                <div key={i}>
                  <div className={styles.yarnAmountRow}>
                    <div className={styles.yarnColor}>
                      <div
                        className={styles.color}
                        style={{
                          width: "18px",
                          height: "18px",
                          background: color.hex ?? "",
                        }}
                      />
                      <p>{color.name}</p>
                    </div>
                    <div className={styles.yarnAmountControl}>
                      <Button
                        active
                        disabled={customYarnAmounts[i] === 0}
                        onClick={() => {
                          setCustomYarnAmounts({
                            ...customYarnAmounts,
                            [i]: customYarnAmounts[i] - 1 ?? 0,
                          });
                        }}
                      >
                        <MinusIcon />
                      </Button>
                      <div className={styles.yarnAmount}>
                        {customYarnAmounts[i] ?? 0} nøste
                        {customYarnAmounts[i] === 1 ? "" : "r"}
                      </div>
                      <Button
                        active
                        onClick={() => {
                          setCustomYarnAmounts({
                            ...customYarnAmounts,
                            [i]: customYarnAmounts[i] + 1 ?? 1,
                          });
                        }}
                      >
                        <PlusIcon />
                      </Button>
                    </div>
                  </div>
                  {i + 1 !== all.length && <hr className={styles.hr} />}
                </div>
              )
            )}
          <hr className={styles.hr} />
          <div className={styles.noteRow}>
            <h4>Notat</h4>
            <textarea
              className={styles.textarea}
              value={newKnitAssignment.comment ?? ""}
              onChange={(e) => set("comment", e.target.value)}
            />
          </div>
          <hr className={styles.hr} />
          <div className={styles.priceRow}>
            <h4>Sett pris</h4>
            <PriceInput
              setter={(value: number) => {
                set("priceOre", value);
              }}
              orgId={orgId}
              id={props.id}
            />
          </div>
          <hr className={styles.hr} />
          <div className={styles.submit}>
            <div className={styles.deadline}>
              <p>Deadline:</p>
              <input
                value={newKnitAssignment.deadline}
                onChange={(e) => set("deadline", e.target.value)}
                pattern=""
                type="date"
              />
            </div>
            <div>
              <Button
                className={styles.button}
                contrast
                onClick={() => setPage(1)}
              >
                Gå tilbake
              </Button>
              <CtaButton
                className={styles.button}
                onClick={() => {
                  const skuAmounts: { [key: string]: number } = {};
                  [...variants, noVariant][variantIndex].colors.forEach(
                    (color, i) => {
                      if (color.sku) {
                        skuAmounts[`${color.sku}`] = customYarnAmounts[i];
                      } else {
                        skuAmounts[`${i}`] = customYarnAmounts[i];
                      }
                    }
                  );

                  const body: NewModelKnitAssignment = {
                    ...newKnitAssignment,
                    yarn: skuAmounts,
                  };

                  if (newKnitAssignment.deadline) {
                    body.deadline = new Date(
                      newKnitAssignment.deadline
                    ).toISOString();
                  }
                  postAssignment({ body, patternId: `${props.id}` });
                  setPage(3);

                  // Reset
                  setAssignment(initialAssignmentData);
                }}
              >
                Send forespørsel
              </CtaButton>
            </div>
          </div>
        </div>
      )}
      {page === 3 && (
        <div>
          <h2 style={{ textAlign: "center" }}>Forespørsel opprettet!</h2>
          <hr className={styles.hr} />
          <div className={styles.bottomButton}>
            <Button className={styles.button} contrast onClick={() => toggle()}>
              Avslutt
            </Button>
            <CtaButton
              className={styles.button}
              onClick={() => {
                setPage(1);
              }}
            >
              Ny forespørsel
            </CtaButton>
          </div>
        </div>
      )}
    </div>
  );
};

interface SizeProps {
  activeSize: string | null;
  sizes: string[];
  setter: (size: string | null) => void;
}

const SizeSelect: React.FC<SizeProps> = ({ activeSize, sizes, setter }) => {
  return (
    <>
      {sizes.map((size, i) => (
        <button
          className={`${activeSize === size ? styles.activeSize : null}`}
          key={i}
          onClick={() => setter(activeSize === size ? null : size)}
        >
          {size}
        </button>
      ))}
    </>
  );
};

interface VariantProps {
  variants: YarnProfileVariant[];
  activeVariant: number;
  setter: (index: number) => void;
}

const VariantSelect: React.FC<VariantProps> = ({
  variants,
  activeVariant,
  setter,
}) => {
  return (
    <div style={{ position: "relative", marginRight: "24px" }}>
      <Listbox value={activeVariant} onChange={(value) => setter(value)}>
        {({ open }) => (
          <>
            <Listbox.Button className={styles.listBoxButton}>
              <div className={styles.variantRow}>
                <div className={styles.colorGroup}>
                  {variants[activeVariant].colors.map((color, i) => (
                    <div
                      className={styles.color}
                      key={i}
                      style={{
                        background: color.hex ?? "",
                        width: "9px",
                        height: "9px",
                      }}
                    />
                  ))}
                </div>
                {variants[activeVariant].name}
              </div>
              {open ? <TriangleUpIcon /> : <TriangleDownIcon />}
            </Listbox.Button>
            <Listbox.Options className={styles.listBoxOptions}>
              {variants.map((variant, i) => (
                <Listbox.Option key={i} value={i}>
                  {({ selected, active }) => (
                    <div
                      className={`
                        ${active ? styles.listBoxActive : ""}
                        ${styles.variantRow}`}
                    >
                      <div className={styles.colorGroup}>
                        {variant.colors.map((color, k) => (
                          <div
                            key={`${i}_${k}`}
                            className={styles.color}
                            style={{
                              height: "9px",
                              width: "9px",
                              background: color.hex ?? "",
                            }}
                          />
                        ))}
                      </div>
                      <div className={styles.vl} />
                      <p>{variant.name}</p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};
