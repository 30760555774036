import React, { useState } from "react";
import { Cross1Icon } from "@radix-ui/react-icons";
import { useSelector } from "react-redux";
import CtaButton from "../../CommonComponents/CtaButton";
import { Wrapper } from "../../CommonComponents/Wrapper";
import Button from "../../ISO/Components/Button";
import { useModelKnitaAssignmentsQuery } from "../../services/modelknit";
import { RootState } from "../../store/store";
import { AddModalKnit } from "./AddModelKnit";
import { ModelAssignmentList } from "./ModelAssignmentList";
import styles from "./modelknit.module.css";

interface PropType {
  toggle: () => void;
  id: number;
  organizationId: number | null;
}

export const ModelKnitModal = (props: PropType) => {
  const { id, organizationId } = props;
  const { data, error, isLoading } = useModelKnitaAssignmentsQuery(`${id}`);
  const [newModelKnit, toggleNewModelKnit] = useState<boolean>(false);
  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  // Sort assignments into: ongoing and finished
  // ongoing: deadline > today | finished: today > deadline
  // TODO: Use other metric than deadline to sort

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h3>{newModelKnit ? "Nytt modellstrikk" : "Modellstrikk"}</h3>
        <Button onClick={() => props.toggle()}>
          <Cross1Icon />
        </Button>
      </div>
      <hr className={styles.hr} />
      {!newModelKnit && (
        <Wrapper isLoading={isLoading} error={error}>
          <ModelAssignmentList data={data} variants={variants} />
        </Wrapper>
      )}
      {newModelKnit ? (
        <AddModalKnit
          organizationId={organizationId}
          toggle={props.toggle}
          abort={() => toggleNewModelKnit(false)}
          variants={variants}
          id={id}
        />
      ) : (
        <div className={styles.bottomButton}>
          <CtaButton
            className={styles.button}
            onClick={() => toggleNewModelKnit(true)}
          >
            Nytt modellstrikk
          </CtaButton>
        </div>
      )}
    </div>
  );
};
