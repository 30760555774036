import React, { useEffect, useRef } from "react";
import ColorList from "./ColorList";
import ChangeDiagramView from "./ChangeDiagramView";
import Grid from "./Grid";
import GridButtons from "./GridButtons";
import SymbolList from "./SymbolList";

import styles from "./diagram.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { redo, setActiveDiagram, undo, updateGrid } from "../store/gridSlice";
import { StateButtons } from "./StateButtons";
import DiagramViewer from "../Visualizing/DiagramViewer";
import { RepeatMenu } from "./RepeatMenu";
import { Menu } from "./TopMenu";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useModal } from "../CommonComponents/useModal";
import { NewDiagram } from "./NewDiagram";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useTranslation } from "react-i18next";
import { Global } from "../Visualizing3D/static/global";
import DiagramDelete from "./DiagramDelete";

export const DiagramEditor = () => {
  const dispatch = useDispatch();
  const { diagrams, id } = useSelector((state: RootState) => state.pattern);
  const { isShown, toggle } = useModal();
  const { t } = useTranslation();

  const { selectedView, showMenuLeft, disableHotKeys, activeDiagram } =
    useSelector((state: RootState) => state.grid);

  const first =
    activeDiagram !== ""
      ? diagrams[activeDiagram]
      : Object.values(diagrams).sort((a, b) => a.name.localeCompare(b.name))[0];

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (first) {
      dispatch(setActiveDiagram({ id: first.id }));
      dispatch(updateGrid({ grid: first }));
    }
  }, []);

  useHotkeys(["Meta+z", "Control+z"], (e) => {
    if (Global.hoveringVisualizing3D) return;
    if (disableHotKeys) return;
    e.preventDefault();
    dispatch(undo());
  });

  useHotkeys(["Meta+shift+z", "Control+shift+z"], (e) => {
    if (Global.hoveringVisualizing3D) return;
    if (disableHotKeys) return;
    e.preventDefault();
    dispatch(redo());
  });

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  }, [scrollRef.current]);

  return (
    <div className={styles.editorContainer}>
      <div className={styles.menuLeft}>
        {showMenuLeft && (
          <>
            <StateButtons />
            <ColorList />
            <SymbolList />
            <GridButtons />
            <RepeatMenu />
            <ChangeDiagramView />
            <DiagramDelete />
          </>
        )}
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        {Object.keys(diagrams).length > 0 ? (
          <Grid />
        ) : (
          <div className={styles.centralNewDiagramButtonContainer}>
            <ModalButton
              headerText={t("draw.create diagram")}
              style={{ border: "none", textAlign: "left", color: "gray" }}
              modalContent={<NewDiagram toggle={toggle} patternId={id} />}
              toggle={toggle}
              isShown={isShown}
            >
              <div className={styles.centralNewDiagramButton}>
                <div
                  // This is used to get a reference of the left side of the grid
                  // And is scrolled into view at the start
                  ref={scrollRef}
                  style={{
                    position: "absolute",
                  }}
                ></div>
                {t("draw.create diagram")}
              </div>
            </ModalButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiagramEditor;
