import { Listbox } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { usePriceListQuery } from "../../services/modelknit";
import styles from "./modelknit.module.css";

interface PropType {
  setter: (value: number) => void;
  orgId: number;
  id: number;
}

export const PriceInput: React.FC<PropType> = (props) => {
  const { setter, orgId } = props;
  const { data, isLoading } = usePriceListQuery(`${orgId}`);

  const [listedPriceIndex, setListedPrice] = useState<number>(-1);
  const [value, set] = useState<string>("");

  useEffect(() => {
    set(`${data?.[listedPriceIndex]?.priceOre ?? ""}`);
    setter(data?.[listedPriceIndex]?.priceOre ?? 0);
  }, [listedPriceIndex]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <div className={styles.price}>
      <div style={{ display: "relative" }}>
        <Listbox
          value={listedPriceIndex}
          onChange={(selected) => setListedPrice(selected)}
        >
          {({ open }) => (
            <>
              <Listbox.Button className={styles.listBoxButton}>
                {listedPriceIndex === -1
                  ? "Velg pris"
                  : data[listedPriceIndex].name}
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Listbox.Button>
              <Listbox.Options className={styles.listBoxOptions}>
                {data.map((price, i) => (
                  <Listbox.Option
                    value={i}
                    key={i}
                    className={styles.listBoxOption}
                  >
                    {({ active }) => (
                      <div className={`${active ? styles.active : ""} `}>
                        {price.name}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </div>
      <input
        value={value}
        onChange={(e) => set(e.target.value)}
        onBlur={(e) => {
          const parsed = parseInt(e.target.value);
          if (isNaN(parsed)) {
            set("");
          }
          setter(parsed);
        }}
        className={styles.priceInput}
      />
      øre
    </div>
  );
};
