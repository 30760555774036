import React, { CSSProperties } from "react";
import styles from "./CtaButton.module.css";

interface PropType {
  href?: string;
  style?: CSSProperties;
  target?: string;
  onClick?: any;
  disabled?: boolean;
  active?: boolean;
  contrast?: boolean;
  className?: string;
}

const CtaButton: React.FC<PropType> = (props) =>
  props.href ? (
    <a
      style={props.style}
      className={`${styles.ctaButton} ${styles.a}`}
      href={props.href}
      target={props.target}
    >
      <span className={styles.ctaButtonContent}>{props.children}</span>
    </a>
  ) : (
    <button
      style={props.style}
      disabled={props.disabled}
      onClick={props.onClick}
      className={`${styles.ctaButton} ${props.active && styles.positive} ${
        props.contrast && styles.contrast
      } ${props.className}`}
    >
      <span className={styles.ctaButtonContent}>{props.children}</span>
    </button>
  );

export default CtaButton;
