import React from "react";
import { IconProps } from "./lookup";

function Cross(props: IconProps) {
  const { fill, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 16}
      height={height ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={fill ?? "black"}
        fillRule="evenodd"
        d="M12.54 4.273a.575.575 0 10-.813-.813L8 7.187 4.274 3.46a.575.575 0 00-.814.813L7.187 8 3.46 11.727a.575.575 0 00.814.813L8 8.813l3.727 3.727a.575.575 0 00.813-.813L8.814 8l3.726-3.727z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Cross;
