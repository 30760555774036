import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import { toggleShortened } from "./displayState";

import styles from "./ShortenedSelector.module.css";

interface PropType {
  shortened: boolean;
}

const ShortenedSelector = (props: PropType) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.buttonContainer}>
      <input
        type="checkbox"
        checked={props.shortened}
        onChange={() => dispatch(toggleShortened())}
      />
      <span>Bruk forkortelser</span>
    </div>
  );
};

const AwareShortenedSelector = () => {
  const shortened = useSelector(
    (state: RootState) => state.displayState.showShortened
  );

  return <ShortenedSelector shortened={shortened} />;
};

export default ShortenedSelector;

export { AwareShortenedSelector };
