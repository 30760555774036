import React from "react";
import { TableInstance } from "react-table";
import styles from "./Table.module.css";

type Props = {
  instance: TableInstance<any>;
  title?: string;
};

const Table: React.FC<Props> = ({ instance, title }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      {title && (
        <div className={styles.titleContainer}>
          <h1>{title}</h1>
        </div>
      )}
      <table {...getTableProps()} className={styles.container}>
        <thead className={styles.headerRow}>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th className={styles.headerSpacing} />
              {headerGroup.headers.map((column) => (
                <th className={styles.headerCell}>{column.render("Header")}</th>
              ))}
              <th className={styles.headerSpacing} />
            </tr>
          ))}
          <th className={styles.headerSpacing} />
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <th className={styles.headerSpacing} />
                {row.cells.map((cell) => (
                  <td className={styles.dataCell}>{cell.render("Cell")}</td>
                ))}
                <th className={styles.headerSpacing} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
