export function lighten_color(hex: string) {
  let color = hexToRgb(hex)!!;
  let colorLightened = {
    r: 127 + color.r / 2,
    g: 127 + color.g / 2,
    b: 127 + color.b / 2,
  };
  return rgbToHex(colorLightened);
}

export function nameHexToHex(color: string) {
  if (color.startsWith("#")) return color;
  // I use this solution, rather than the style.color="white"; return div.style.color. As it is faster
  switch (color) {
    case "white":
      return "#FFFFFF";
    case "silver":
      return "#C0C0C0";
    default:
      throw new Error("Color name not found. Add it to colorutil.ts");
  }
}

export function rgbToHex(rgb: any) {
  return (
    "#" +
    ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1)
  );
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error("Could not parse hex: " + hex);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export function fixColor(hex: string){
  if (hex.length === 4){
    hex = hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3]
  }
  return hex.toLowerCase()
}

export function mixColors(colorsHex: string[]) {
  let rgbs = [];
  for (let color of colorsHex) {
    rgbs.push(hexToRgb(color));
  }
  let avgColor = { r: 0, g: 0, b: 0 };
  for (let color of rgbs) {
    avgColor.r += color.r;
    avgColor.g += color.g;
    avgColor.b += color.b;
  }
  avgColor.r /= rgbs.length;
  avgColor.g /= rgbs.length;
  avgColor.b /= rgbs.length;
  return rgbToHex(avgColor);
}

export function RGBStringToRGB(text: string) {
  const array = text
    .split("(")[1]
    .split(")")[0]
    .split(",")
    .map((it) => Number(it));
  return {
    r: array[0],
    g: array[1],
    b: array[2],
  };
}

export function RGBToRGBString(rgb: any) {
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
}
