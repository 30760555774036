import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../ISO/Components/Button";
import SizeSelector from "../ISO/SizeSelector";
import YarnVariantSelector from "../ISO/YarnVariantSelector";
import { RootState } from "../store/store";
import { Packer } from "docx";
import styles from "./Export.module.css";
import { saveAs } from "file-saver";
import ReactSelect from "react-select";
import { notInPattern } from "../utils/canDelete";
import { generateDiagramSvg } from "./ExportDiagram";
import JSZip from "jszip";
import { exportToDocx } from "./ExportDocument";
import { generateDiagramLegendSvg } from "./ExportDiagramLegend";

export const ExportModal = () => {
  const meta = useSelector((state: RootState) => state.pattern.meta);
  const { variants, sizes, title } = meta;

  const { patternElements, diagrams, needles, calculationResults, symbols } =
    useSelector((state: RootState) => state.pattern);

  const gauge = useSelector((state: RootState) => state.calculations.gauge);

  const { user } = useSelector((state: RootState) => state.loginState);

  const { t } = useTranslation();

  const [exportSizes, setExportSizes] = useState<string[]>(sizes);
  const [exportVariant, setExportVariant] = useState<string>(variants[0].id);

  // Set default state as diagrams included in pattern
  const [exportDiagrams, setExportDiagrams] = useState<string[]>(
    Object.keys(diagrams).filter((key) => !notInPattern(key, patternElements))
  );

  const toggleSize = (size: string) => {
    if (exportSizes.indexOf(size) !== -1) {
      exportSizes.filter((s) => s !== size);
    } else {
      setExportSizes([...exportSizes, size]);
    }
  };

  const exportZip = async () => {
    const zip = new JSZip();
    const doc = await Packer.toBlob(
      exportToDocx(
        meta,
        needles,
        patternElements,
        user,
        gauge,
        diagrams,
        calculationResults,
        exportSizes,
        exportVariant
      )
    );

    const activeVariant =
      variants.find((v) => v.id === exportVariant) ?? variants[0];
    exportDiagrams.forEach((diagramId) => {
      const diagram = diagrams[diagramId];
      zip.file(
        `${diagram.name}.svg`,
        generateDiagramSvg(diagram, activeVariant)
      );
      zip.file(
        `${diagram.name}-legend.svg`,
        generateDiagramLegendSvg(diagram, symbols, activeVariant)
      );
    });

    zip.file(`${title}.docx`, doc);
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `project.zip`);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.exportOptionRow}>
        <div className={styles.firstOption}>
          <label className={styles.label}>Velg størrelser</label>
          <SizeSelector
            allSizes={sizes}
            selectedSizes={exportSizes}
            onSizeToggle={toggleSize}
          />
        </div>
        <div>
          <label className={styles.label}>Velg variant</label>
          <YarnVariantSelector
            variants={variants}
            activeVariantId={exportVariant}
            onVariantChange={setExportVariant}
          />
        </div>
      </div>
      <label className={styles.label}>
        Velg diagrammer som skal ekspoteres
      </label>
      <ReactSelect
        isMulti
        name="diagrams"
        options={Object.entries(diagrams).map(([key, diagram], i) => ({
          value: key,
          label: diagram.name,
        }))}
        value={exportDiagrams.map((d) => ({
          value: d,
          label: diagrams[d].name,
        }))}
        onChange={(diagrams) => {
          setExportDiagrams(diagrams.map((d) => d.value));
        }}
      />
      <hr className={styles.hr} />
      <div className={styles.buttonRow}>
        <Button onClick={() => exportZip()} active>
          {t("export.export")}
        </Button>
      </div>
    </div>
  );
};
