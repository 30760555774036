import { useCallback, useEffect } from "react";
import styles from "../Styles/Sections.module.css";
import woolitIcon from "../../Visualizing/LandingPage/woolitIcon.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMe } from "../../Login/components/LoginState";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import LogoutButton from "../../Login/LogoutButton";

function LoginButton() {
  let auth = useSelector((state: RootState) => state.loginState);
  const dispatch = useDispatch();
  const loadUserCallback = useCallback(() => dispatch(fetchMe()), [dispatch]);
  const { t } = useTranslation();
  useEffect(() => {
    loadUserCallback();
  }, [loadUserCallback, auth.loginAttempted, auth.isLoggedIn]);

  if (auth.user && !auth.user.subscriber) {
    return <div style={{display:"flex", alignItems:"center"}}>Du er logget inn som {auth.user.firstName} <div style={{marginLeft: "1rem", marginRight: "1rem"}}><LogoutButton></LogoutButton></div></div>

  } else if (auth.user && auth.user.subscriber) {
    return (
      <div style={{display:"flex"}}>
      <Link
        style={{ color: "#3D8036", margin: "12px", cursor: "pointer" }}
        to="/projects"
      >
       {t("goToWDS")}
      </Link>
      <LogoutButton></LogoutButton>
      </div>
    );
  }

  return (
    <Link to="/login">
      <button className={styles.loginBtn}>{t("login")}</button>
    </Link>
  );
}

function NavigationSection() {
  return (
    <nav style={{ display: "flex" }}>
      <div
        style={{ width: "50%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >

        <div className={styles.navLinkContainer}>
        <Link
          to="https://shop.woolit.no"
          className={styles.navButton}
        >
          Designs
        </Link>
        <Link
          to="https://shop.woolit.no/product/yarn"
          className={styles.navButton}
        >
          Garn
        </Link>
        <Link
          to="https://shop.woolit.no/product/needle"
          className={styles.navButton}
        >
          Pinner
        </Link>
        <Link
          to="/"
          className={styles.designStudioNavButton}
        >
          Woolit Design Studio Pro
        </Link>
        <Link
          to="/"
          className={styles.wdsNavButton}
        >
          WDS
        </Link>
        </div>
        <img
          src={woolitIcon}
          className={styles.woolitIcon}
          alt="Woolit ikon"
        ></img>
      </div>
      <div
        style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
      >
        <LoginButton />
      </div>
    </nav>
  );
}

export default NavigationSection;
