import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { deleteTable, setTableLabel } from "../store/calculationSlice";

import TableMarkSelector from "./TableMarkSelector";

import styles from "./TableLabel.module.css";

type PropType = {
  tableId: string;
};

function TableLabel(props: PropType) {
  const { tableId } = props;

  const { label, marks } = useSelector(
    (state: RootState) => state.calculations.tables[tableId]
  );

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <button
        className={styles.deleteTable}
        onClick={() => {
          if (
            window.confirm(
              "Er du sikker på at du vil slette tabellen? Dette kan ikke reverseres."
            )
          )
            dispatch(deleteTable({ tableId }));
        }}
      >
        Slett
      </button>
      <div className={styles.labelContainer}>
        {label.length > 0 && (
          <div className={styles.label}>Tittel på tabell</div>
        )}
        <input
          className={styles.input}
          type="text"
          value={label}
          onChange={(e) =>
            dispatch(setTableLabel({ tableId, label: e.target.value }))
          }
          placeholder={"Tittel på tabell..."}
        />
      </div>

      <div className={styles.markContainer}>
        <div className={styles.markLabel}>Antall merker:</div>
        <TableMarkSelector tableId={tableId} marks={marks} />
      </div>
    </div>
  );
}

export default TableLabel;
