import React, { useState } from "react";
import { useModelKnittersQuery } from "../../services/modelknit";
import { ModelKnitGroup } from "../../store/pattern";
import { Listbox } from "@headlessui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import styles from "./modelknit.module.css";

interface PropType {
  orgId: number;
  setKnitGroup: (tag: string) => void;
}

export const KnitGroupSelect = (props: PropType) => {
  const { orgId, setKnitGroup } = props;
  const { data, isLoading } = useModelKnittersQuery(`${orgId}`);
  const [selectedGroup, setSelectedGroup] = useState<ModelKnitGroup>({
    name: "Velg",
    tag: "no",
    knitters: [],
  });

  if (isLoading || !data) {
    return <select />;
  }

  return (
    <div style={{ position: "relative", marginRight: "24px" }}>
      <Listbox
        value={selectedGroup}
        onChange={(selected) => {
          setSelectedGroup(selected);
          setKnitGroup(selected.tag);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Button className={styles.listBoxButton}>
              {selectedGroup.name}
              {open ? <TriangleUpIcon /> : <TriangleDownIcon />}
            </Listbox.Button>
            <Listbox.Options className={styles.listBoxOptions}>
              {data.map((group: ModelKnitGroup, i: number) => (
                <Listbox.Option key={i} value={group}>
                  {({ selected, active }) => (
                    <div
                      className={`${active ? styles.listBoxActive : ""} ${
                        styles.listBoxOption
                      }`}
                    >
                      <p>
                        {group.name} {group.knitters.length} tilgjengelig
                      </p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};
