import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DiagramData,
  DiagramSection as DiagramSectionType,
  DynamicGridSymbol,
  GridSymbol,
  isDynamicGridSymbol,
  YarnColor,
} from "../store/pattern";
import { RootState } from "../store/store";

import ColorPreview from "../CommonComponents/ColorPreview";

import { DiagramSymbol, DynamicSymbol } from "@iterate/woolit-components";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import { InfoItem } from "../CommonComponents/InfoItem";
import { useGridVariables } from "../Diagram/utils/hooks";
import Button from "./Components/Button";
import styles from "./DiagramSection.module.css";

interface PropType {
  sectionData: DiagramSectionType;
}

const MAX_CELL_SIZE = 40;
const MIN_CELL_SIZE = 8;
/*
 * How to implement filtering:
 * Find all rows that should be excluded based on activeSizes
 * Save relevant (y,x) coords in list
 * Filter away rows based on list.
 */

function DiagramSection(props: PropType) {
  const { sectionData } = props;

  const diagram = useSelector(
    (state: RootState) => state.pattern.diagrams[sectionData.diagram]
  );

  const { windowWidth, windowHeight } = useGridVariables();

  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const [H, setH] = useState<number>(0);
  const [W, setW] = useState<number>(0);

  useEffect(() => {
    if (!fullscreen) {
      setH(windowHeight - 192);
    }
  }, [windowHeight]);

  useEffect(() => {
    if (!fullscreen) {
      setW(windowWidth - 420);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!fullscreen) {
      setH(windowHeight - 192);
      setW(windowWidth - 420);
    } else {
      setH(1920);
      setW(2400);
    }
  }, [fullscreen]);

  const gH = diagram.gridHeight + 4;
  const gW = diagram.gridWidth + 4;

  const colors = useSelector((state: RootState) => {
    const activeVariant = state.displayState.activeVariant;
    const colors = state.pattern.meta.variants.find(
      (variant) => variant.id === activeVariant
    )?.colors;
    return colors || [];
  });

  const cellHeight = clamp(
    MIN_CELL_SIZE,
    MAX_CELL_SIZE,
    Math.trunc(Math.min(H / gH, W / (gW * 1.25)))
  );

  const cellWidth = cellHeight * 1.25;
  const fontSize = "75%";

  const handleBorder = (x: number, y: number) => {
    const borderStyles: CSSProperties = {};
    if (diagram.lines?.[`${y}_row`]) {
      borderStyles["borderBottom"] = `2px solid ${
        diagram.lines?.[`${y}_row`].color ?? "black"
      }`;
    }
    if (diagram.lines?.[`${x}_col`]) {
      borderStyles["borderRight"] = `2px solid ${
        diagram.lines?.[`${x}_col`].color ?? "black"
      }`;
    }
    return borderStyles;
  };

  return (
    <>
      <h2 className={styles.title} id={diagram.id}>
        {diagram.name}
      </h2>
      <div className={styles.btnGroupContainer}>
        <div className={styles.btnGroup}></div>
        <div>
          <Button
            active={fullscreen}
            onClick={() => setFullscreen(!fullscreen)}
          >
            Vis større
          </Button>
        </div>
      </div>
      <hr />
      <div
        className={`${styles.diagram} ${
          fullscreen ? styles.fullscreen : styles.fitted
        }`}
      >
        <Legend diagram={diagram} />
        <div
          style={{
            marginLeft: fullscreen
              ? lowerClamp(0.5 * (windowWidth - diagram.gridWidth * cellWidth))
              : 0,
            display: "grid",
            gridTemplateColumns: `${`${cellWidth}px [grid-col]`.repeat(
              diagram.gridWidth
            )} ${cellWidth}px [right-legend] ${cellHeight}px [comment-right] 32px [comment-right-text] 64px`,
            gridTemplateRows: `${`${cellHeight}px [grid-row]`.repeat(
              diagram.gridHeight
            )} ${cellHeight}px [bottom-legend] ${cellWidth}px [comment-bottom]`,
          }}
        >
          {diagram.grid.map((row, y) =>
            row.map((cell, x) => {
              let color = colors[cell.color];
              if (cell.color === -1) {
                color = { hex: "#ffffff" } as YarnColor;
              }

              if (color === undefined) {
                console.warn("Color is undefined");
              }

              return (
                <div
                  className={styles.diagramCell}
                  key={`${x}-${y}`}
                  data-x={x}
                  data-y={y}
                  style={{
                    height: cellHeight,
                    width: cellWidth,
                    gridRow: `${y + 1} / span 1`,
                    gridColumn: `${x + 1} / span 1`,
                    ...handleBorder(x, y),
                  }}
                >
                  <ColorPreview
                    color={color}
                    symbol={cell.symbol ?? "stitch"}
                  />
                </div>
              );
            })
          )}
          {/* Right-legend */}
          {new Array(diagram.gridHeight).fill("_").map((_, i) => (
            <div
              className={styles.number}
              key={i}
              style={{
                gridColumn: `right-legend / span 1`,
                gridRow: `${i + 1}`,
                height: cellHeight,
                width: cellWidth,
                fontSize,
              }}
            >
              {cellHeight <= 14
                ? i % 2 === 0
                  ? diagram.gridHeight - i
                  : ""
                : diagram.gridHeight - i}
            </div>
          ))}
          {/* Right-comments */}
          {Object.entries(diagram.patterns?.rowPatterns ?? {}).map(
            ([index, { start, end, comment }], i) => (
              <>
                <div
                  key={i}
                  className={styles.rightPatternItem}
                  style={{
                    maxWidth: 200,
                    gridRow: `${start + 1} / ${end + 2}`,
                    gridColumn: `comment-right / span 1`,
                    fontSize,
                  }}
                />
                <p
                  style={{
                    gridRow: `${start + 1} / ${end + 2}`,
                    gridColumn: `comment-right-text / span 1`,
                    marginLeft: "4px",
                  }}
                >
                  {comment}
                </p>
              </>
            )
          )}
          {Object.entries(diagram.diagramComments?.rowComments ?? {}).map(
            ([index, { comment }], i) => (
              <div
                style={{
                  gridRow: `${parseInt(index) + 1} / span 1`,
                  gridColumn: `comment-right / span 1`,
                  fontSize,
                  display: "flex",
                }}
                key={i}
              >
                <InfoItem comment={comment} />
              </div>
            )
          )}
          {Object.entries(diagram.excluded ?? {}).map(([key, exclusion], i) => {
            const [direction, index] = key.split("_");
            if (direction === "row") {
              return (
                <div
                  key={i}
                  style={{
                    fontSize,
                    gridRow: `${parseInt(index) + 1} / span 1`,
                    gridColumn: `comment-right / span 1`,
                    display: "flex",
                  }}
                >
                  <InfoItem comment={`Hopp over for ${exclusion.join(", ")}`} />
                </div>
              );
            } else {
              return null;
            }
          })}
          {/* Bottom-legend */}
          {new Array(diagram.gridWidth).fill("_").map((_, i) => (
            <div
              className={styles.number}
              key={i}
              style={{
                gridColumn: `${i + 1}`,
                gridRow: `bottom-legend / span 1`,
                height: cellHeight,
                width: cellWidth,
                fontSize,
              }}
            >
              {cellHeight <= 14
                ? i % 2 === 0
                  ? diagram.gridWidth - i
                  : ""
                : diagram.gridWidth - i}
            </div>
          ))}
          {/* Bottom comments */}
          {Object.entries(diagram.patterns?.colPatterns ?? {}).map(
            ([index, comment], i) => (
              <div
                key={i}
                style={{
                  gridRow: `comment-bottom / span 1`,
                  gridColumn: `${comment.start + 1} / ${comment.end + 2}`,
                  fontSize,
                }}
              >
                <div
                  style={{ height: 12 }}
                  className={styles.bottomPatternItem}
                />
                <p>{comment.comment}</p>
              </div>
            )
          )}
          {Object.entries(diagram.diagramComments?.colComments ?? {}).map(
            ([index, { comment, relation }], i) => {
              return (
                <div
                  key={i}
                  style={{
                    gridRow: `comment-bottom / span 1`,
                    gridColumn: `${parseInt(index) + 1} / span 1`,

                    textAlign:
                      relation === "upper"
                        ? "left"
                        : relation === "lower"
                        ? "right"
                        : "center",
                    fontSize,
                  }}
                >
                  <InfoItem comment={comment} />
                </div>
              );
            }
          )}
          {Object.entries(diagram.excluded ?? {}).map(([key, exclusion], i) => {
            const [direction, index] = key.split("_");
            if (direction === "col") {
              return (
                <div
                  key={i}
                  style={{
                    fontSize,
                    gridRow: `comment-bottom / span 1`,
                    gridColumn: `${parseInt(index) + 1} / span 1`,
                    textAlign: "center",
                  }}
                >
                  <InfoItem comment={`Hopp over for ${exclusion.join(", ")}`} />
                </div>
              );
            } else {
              return null;
            }
          })}
          {/* Dynamic symbols */}
          {Object.entries(diagram.dynamicSymbols ?? {}).map(
            ([key, data], i) => {
              const [x1, y1, x2, y2] = key.split("_").map((n) => parseInt(n));
              return (
                <div
                  key={key}
                  className={styles.dynamicSymbol}
                  style={{
                    gridRow: `${y1 + 1} / span ${y2 - y1 + 1}`,
                    gridColumn: `${x1 + 1} / span ${x2 - x1 + 1}`,
                  }}
                >
                  <DynamicSymbol
                    symbol={data.symbol}
                    iconProps={{
                      width: (x2 - x1 + 1) * cellWidth,
                      height: (y2 - y1 + 1) * cellHeight,
                      fill: "black",
                    }}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
}

export const Legend = ({ diagram }: { diagram: DiagramData }) => {
  const [yarnLegend, setYarnLegend] = useState<number[]>([]);
  const [symbolLegend, setSymbolLegend] = useState<
    (GridSymbol | DynamicGridSymbol)[]
  >([]);

  const colors = useSelector((state: RootState) => {
    const activeVariant = state.displayState.activeVariant;
    const colors = state.pattern.meta.variants.find(
      (variant) => variant.id === activeVariant
    )?.colors;
    return colors || [];
  });

  const allGridSymbols = useSelector(
    (state: RootState) => state.pattern.symbols
  );

  // Create legend
  useEffect(() => {
    const symbols: string[] = [];
    const yarn: number[] = [];
    const gridSymbols: (GridSymbol | DynamicGridSymbol)[] = [];

    diagram.grid.forEach((row) => {
      row.forEach((cell) => {
        if (!symbols.includes(cell.symbol) && cell.symbol !== "stitch") {
          const symbol = allGridSymbols.find(
            (symOjb) => cell.symbol === symOjb.key
          );
          if (symbol && !isDynamic(symbol.key)) {
            symbols.push(symbol.key);
            gridSymbols.push(symbol);
          }
        }
        if (!yarn.includes(cell.color) && cell.color !== -1) {
          yarn.push(cell.color);
        }
      });
    });

    Object.values(diagram.dynamicSymbols ?? {}).forEach((data) => {
      const length = data.end.x - data.start.x + 1;
      const isIncluded = gridSymbols.find(
        (d) => d.key === data.symbol && (!d.length || d.length === length)
      );
      if (isIncluded) return;
      const { label: labelTemplate } = allGridSymbols.find(
        (d) => d.key === data.symbol
      ) ?? {
        label: "",
      };

      const isLabelDynamic =
        labelTemplate.includes("{%}") || labelTemplate.includes("{%2}");
      const label = labelTemplate
        .replaceAll("{%}", length.toString() ?? "")
        .replaceAll("{%2}", Math.round(length / 2).toString() ?? "");

      gridSymbols.push({
        label,
        key: data.symbol,
        length: isLabelDynamic ? length : null,
      });
    });

    setYarnLegend(yarn);
    setSymbolLegend(
      gridSymbols.sort((a, b) => {
        if (!isDynamicGridSymbol(a) || !a.length) {
          return -1;
        }
        if (!isDynamicGridSymbol(b) || !b.length) {
          return 1;
        }
        return a.length > b.length ? 1 : -1;
      })
    );
  }, [diagram, allGridSymbols]);

  return (
    <div className={styles.legendContainer}>
      <div className={styles.legend}>
        {yarnLegend.sort().map((i) => {
          const color = colors[i];
          return (
            <div className={styles.legendItem} key={i}>
              <div className={`${styles.legendValue} ${styles.legendColor}`}>
                <ColorPreview fillParent color={color} symbol={"stitch"} />
              </div>
              <p>{color?.name ?? `Farge ${i + 1}`}</p>
            </div>
          );
        })}
        {symbolLegend.map((symbol, i) => {
          const { key, label } = symbol;

          const length = isDynamicGridSymbol(symbol)
            ? (symbol.length || 6) * 10
            : 60;

          return (
            <div key={i} className={styles.legendItem}>
              {isDynamic(key) ? (
                <div className={styles.dynamicLegendSymbol}>
                  <DynamicSymbol
                    symbol={key}
                    iconProps={{
                      width: Math.min(60, Math.max(length, 20)),
                      height: 20,
                      fill: "black",
                    }}
                  />
                </div>
              ) : (
                <div className={`${styles.legendValue} ${styles.legendSymbol}`}>
                  <DiagramSymbol
                    symbol={key}
                    iconProps={{ height: 18, width: 18, fill: "black" }}
                  />
                </div>
              )}
              <p>{label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const clamp = (lower: number, upper: number, n: number) => {
  if (n <= lower) {
    return MIN_CELL_SIZE;
  } else if (n >= upper) {
    return MAX_CELL_SIZE;
  } else {
    return n;
  }
};

const lowerClamp = (number: number) => {
  if (number < 0) {
    return 0;
  }
  return number;
};

export default DiagramSection;
