import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SweaterDesigner.css";

import KnittingPreview from "./knittingpreview/KnittingPreview";
import KnittingEditor from "./knittingeditor/KnittingEditor";
import { SweaterPart } from "./SweaterPart";
import { Pattern } from "./Pattern";
import SplitViewMiniature from "./SplitViewMiniature";

function SweaterDesigner() {
  const [selectedSweaterPart, setSelectedSweaterPart] = useState<
    SweaterPart | undefined
  >(undefined);
  const [selectedEditPattern, setSelectedEditPattern] = useState<
    Pattern | undefined
  >(undefined);

  const [sweaterHasLoaded, setSweaterHasLoaded] = useState<Boolean>(false);
  const [saving, setSaving] = useState<Boolean>(false);
  const [startMenuOpen, setStartMenuOpen] = useState<Boolean>(true);

  const [switched, hasSwitched] = useState(false);

  const knittingPreview = () => (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      {!sweaterHasLoaded && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "50%",
            display: "flex",
            zIndex: 1,
          }}
        >
          <div
            style={{
              margin: "auto",
              fontSize: "30px",
              color: "black",
              fontWeight: 100,
            }}
          >
            Loading 3D model...
          </div>
        </div>
      )}
      <KnittingPreview saving={saving} setSaving={setSaving} />
    </div>
  );

  const knittingEditor = () => (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      {!selectedSweaterPart && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "black",
            opacity: 0.6,
            borderRadius: "3px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              margin: "auto",
              fontSize: "28px",
              color: "white",
              fontWeight: 100,
            }}
          >
            Click on any part of the sweater to start
          </div>
        </div>
      )}
      <KnittingEditor
        selectedSweaterPart={selectedSweaterPart}
        selectedEditPattern={selectedEditPattern}
        setSelectedEditPattern={setSelectedEditPattern}
        setSelectedSweaterPart={setSelectedSweaterPart}
        sweaterHasLoaded={sweaterHasLoaded}
        setSweaterHasLoaded={setSweaterHasLoaded}
        startMenuOpen={startMenuOpen}
        isMiniature={switched}
      />
    </div>
  );

  return (
    <>
      <SplitViewMiniature switched={switched} hasSwitched={hasSwitched}>
        {knittingEditor()}
        {knittingPreview()}
      </SplitViewMiniature>
    </>
  );
}

export default SweaterDesigner;
