import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../ISO/Components/Button";
import {
  NewKnitter,
  useAddKnitterToOrgMutation,
  useAddNewKnitterToOrgMutation,
  useFindKnitterMutation,
  useModelKnittersQuery,
} from "../services/modelknit";
import { useGetOrganizationByIdQuery } from "../services/organization";
import { Knitter, ModelKnitGroup } from "../store/pattern";
import styles from "./AddModelKnitterModal.module.css";

type Props = {
  orgId: string;
  hide: () => void;
};

const removeEmptyStrings = (obj: NewKnitter): NewKnitter => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== "")
  ) as NewKnitter;
};

const knitterIsInOrg = (
  groups?: ModelKnitGroup[],
  knitter?: Knitter
): boolean => {
  if (!groups || !knitter) {
    return false;
  }
  for (const group of groups) {
    if (
      group.knitters.find((knitterInGroup) => knitterInGroup.id === knitter.id)
    ) {
      return true;
    }
  }
  return false;
};

const AddModelKnitterModal: React.FC<Props> = ({ orgId, hide }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const orgQuery = useGetOrganizationByIdQuery(orgId, {
    skip: orgId === "-1",
  });
  const modelKnittersQuery = useModelKnittersQuery(orgId);
  const [findKnitter] = useFindKnitterMutation();
  const [addKnitterToOrg] = useAddKnitterToOrgMutation();
  const [addNewKnitterToOrg] = useAddNewKnitterToOrgMutation();

  const [errorMsg, setErrorMsg] = useState<string>();

  const [newKnitter, setNewKnitter] = useState<NewKnitter>({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    postnumber: "",
    postplace: "",
    phone: "",
  });
  const [existingKnitter, setExistingKnitter] = useState<Knitter>();

  const handleFindKnitter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    findKnitter({ orgId, email: newKnitter.email })
      .unwrap()
      .then((knitter) => {
        if (knitterIsInOrg(modelKnittersQuery.data, knitter)) {
          setErrorMsg(
            `${knitter.name} er allerede strikker for ${orgQuery.data?.name}`
          );
        } else {
          setExistingKnitter(knitter);
          setStep(2);
        }
      })
      .catch(() => setStep(1));
  };

  const addExistingKnitter = () => {
    existingKnitter &&
      addKnitterToOrg({ orgId, userId: `${existingKnitter.id}` });
    hide();
  };

  const addNewKnitter = (e: React.FormEvent) => {
    e.preventDefault();
    const newKnitterObject = removeEmptyStrings(newKnitter);
    addNewKnitterToOrg({ orgId, newKnitter: newKnitterObject });
    hide();
  };

  return (
    <div className={styles.wrapper}>
      <h1>
        {t("add knitter to org", { organization: orgQuery.data?.name ?? "" })}
      </h1>
      {step === 0 && (
        <>
          <form onSubmit={handleFindKnitter}>
            <div className={styles.formElement}>
              <label>{t("email")}: </label>
              <input
                required
                type="email"
                placeholder="ola@gmail.com"
                value={newKnitter.email}
                onChange={(e) => {
                  setNewKnitter((knitter) => ({
                    ...knitter,
                    email: e.target.value,
                  }));
                  setErrorMsg(undefined);
                }}
              />
            </div>
            {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
            <div className={styles.buttonContainerSingle}>
              <button type="submit">Neste</button>
            </div>
          </form>
        </>
      )}
      {step === 1 && (
        <>
          <h3>{t("not a previous user")}</h3>
          <form autoComplete="on" onSubmit={addNewKnitter}>
            <div className={styles.formElement}>
              <p>{`${t("email")}: ${newKnitter.email}`}</p>
            </div>
            <div className={styles.nameContainer}>
              <div className={styles.formElement}>
                <label>{t("form.given-name")}</label>
                <input
                  type="text"
                  name="given-name"
                  autoComplete="given-name"
                  autoCapitalize="on"
                  value={newKnitter.firstName}
                  onChange={(e) =>
                    setNewKnitter((knitter) => ({
                      ...knitter,
                      firstName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.formElement}>
                <label>{t("form.family-name")}</label>
                <input
                  name="family-name"
                  autoComplete="family-name"
                  autoCapitalize="on"
                  type="text"
                  value={newKnitter.lastName}
                  onChange={(e) =>
                    setNewKnitter((knitter) => ({
                      ...knitter,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className={styles.formElement}>
              <label>{t("form.adress")}</label>
              <input
                autoComplete="street-address"
                name="street-address"
                type="text"
                value={newKnitter.address}
                onChange={(e) =>
                  setNewKnitter((knitter) => ({
                    ...knitter,
                    address: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formElement}>
              <label>{t("form.zip-code")}</label>
              <input
                autoComplete="postal-code"
                name="postal-code"
                type="number"
                value={newKnitter.postnumber}
                onChange={(e) =>
                  setNewKnitter((knitter) => ({
                    ...knitter,
                    postnumber: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formElement}>
              <label>{t("form.postplace")}</label>
              <input
                type="text"
                value={newKnitter.postplace}
                onChange={(e) =>
                  setNewKnitter((knitter) => ({
                    ...knitter,
                    postplace: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formElement}>
              <label>{t("form.phonenr")}</label>
              <input
                name="tel-national"
                autoComplete="tel-national"
                type="number"
                value={newKnitter.phone}
                onChange={(e) => {
                  e.target.checkValidity();
                  e.target.reportValidity();
                  setNewKnitter((knitter) => ({
                    ...knitter,
                    phone: e.target.value,
                  }));
                }}
                pattern="^(0047|\+47|47)?[2-9]\d{7}$"
              />
            </div>
            <div className={styles.buttonContainerDouble}>
              <button onClick={() => setStep(0)}>
                {t("navigation.previous")}
              </button>
              <button type="submit">Legg til strikker</button>
            </div>
          </form>
        </>
      )}
      {step === 2 && (
        <>
          <p>
            {t("add named knitter to org", {
              knitter: existingKnitter?.name ?? "",
              organization: orgQuery.data?.name,
            })}
          </p>
          <div className={styles.buttonContainerDouble}>
            <button
              onClick={() => {
                setExistingKnitter(undefined);
                setStep(0);
              }}
            >
              {t("navigation.previous")}
            </button>
            <Button active onClick={addExistingKnitter}>
              {t("add knitter")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddModelKnitterModal;
