import React from "react";
import { useState } from "react";
import { useGridVariables } from "./utils/hooks";

interface PropType {
  operation: "add" | "remove";
  left: number;
  top: number;
}

const FILL = {
  add: "#3D8036",
  remove: "#FF7300",
};

export const PreviewSquare = (props: PropType) => {
  const { cellHeight, cellWidth } = useGridVariables();
  const { top, left } = props;

  const [backgroundColor] = useState(FILL[props.operation]);

  return (
    <div
      style={{
        backgroundColor,
        opacity: 0.8,
        width: cellWidth,
        height: cellHeight,
        position: "absolute",
        border: "1px #000000 solid",
        top,
        left,
      }}
    />
  );
};
