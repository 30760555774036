import { ArrowRightIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import Imgix from "react-imgix";
import { useSwipeable } from "react-swipeable";
import { imgixUrl } from "../imgixUrl";
import { Image, YarnProfileVariant } from "../store/pattern";
import Button from "./Components/Button";
import styles from "./Hero.module.css";
import { SingleYarnVariant } from "./SingleYarnVariant";

interface PropType {
  images?: Image[];
  variant: YarnProfileVariant;
}

// TODO: Consider using Imigix
const Hero = (props: PropType) => {
  const { images, variant } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const imageCount = props.images?.length ?? 1;

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setCurrentImage((currentImage + 1) % imageCount);
    },
    onSwipedRight: () => {
      setCurrentImage(currentImage - 1 < 0 ? imageCount - 1 : currentImage - 1);
    },
  });

  if (props.images) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.sticky}>
          {images && (
            <div>
              <div {...swipeHandlers}>
                <Imgix
                  className={styles.imgix}
                  src={`${imgixUrl}/${images[currentImage].src}`}
                  imgixParams={{ ar: "2:3", fit: "crop" }}
                />
                <div className={styles.button}>
                  <Button
                    onClick={() =>
                      setCurrentImage((currentImage + 1) % images.length)
                    }
                    active
                  >
                    <ArrowRightIcon />
                  </Button>
                </div>
              </div>
              <div className={styles.bottom}>
                <SingleYarnVariant variant={variant} />
                <div className={styles.counter}>
                  {currentImage + 1} av {images.length}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Hero;
