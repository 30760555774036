import React from "react";

import { useDispatch, useSelector } from "react-redux";
import CaretRight from "../../CommonComponents/Icons/CaretRight";
import ImageAsColorPreview from "../../CommonComponents/ImageAsColorPreview";

import { YarnColor, YarnProfileVariant } from "../../store/pattern";
import { RootState } from "../../store/store";
import { openSubdrawer } from "../../store/writerSlice";
import constructYarnString from "../../utils/constructYarnString";
import { Draggable } from "react-beautiful-dnd";
import styles from "./Variant.module.css";

interface PropType {
  variant: YarnProfileVariant;
  index: number;
}

function DrawerVariant(props: PropType) {
  const { variant, index } = props;

  const dispatch = useDispatch();

  const subdrawerContent = useSelector(
    (state: RootState) => state.writer.subdrawerContent
  );

  return (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided, snapshot) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style }}
          onClick={() => {
            dispatch(
              openSubdrawer({ content: { type: "variant", id: variant.id } })
            );
          }}
          className={`${styles.variant} ${
            subdrawerContent.type === "variant" &&
            subdrawerContent.id === variant.id
              ? styles.active
              : ""
          }`}
        >
          <div className={styles.colors}>
            {variant.colors.map((color, i) => (
              <div key={i} className={styles.colorContainer}>
                {color.hex ? (
                  <div
                    className={styles.colorPreview}
                    style={{ backgroundColor: color.hex }}
                  />
                ) : (
                  <ImageAsColorPreview color={color as YarnColor} />
                )}
              </div>
            ))}
          </div>
          <div className={styles.text}>
            <h4 className={styles.name}>{variant.name}</h4>
            <h3 className={styles.colorString}>
              {constructYarnString(variant.colors)}
            </h3>
          </div>
          <div className={styles.icon}>
            <CaretRight />
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default DrawerVariant;
