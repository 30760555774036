import React from "react";

function KnittingPreview(props: any) {
  return (
    <div>
      <div
        id="canvas"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default KnittingPreview;
