import Button from "../ui/Button/Button";
import FacebookButton from "../ui/Button/FacebookButton";

import { yupResolver } from "@hookform/resolvers/yup";

import React, { useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoginUserCredentials, LoginUserSchema } from "../../types/auth";
import Input from "../ui/Input/Input";
import { useTranslation } from "react-i18next";

type Props = {
  onSubmit: (loginCredentials: LoginUserCredentials) => void;
  loading?: boolean;
  defaultValues?: Omit<Partial<LoginUserCredentials>, "password">;
  className?: string;
};

type LoginFieldErrors = {
  email?: FieldError | undefined;
  password?: FieldError | undefined;
};

const LoginForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  loading,
  defaultValues,
  className,
}) => {
  const { handleSubmit, control } = useForm<LoginUserCredentials>({
    defaultValues,
    resolver: yupResolver(LoginUserSchema),
  });

  const [errors, setErrors] = useState<LoginFieldErrors>();

  const handleValid = (credentials: LoginUserCredentials) =>
    onSubmit(credentials);

  const handleInvalid = (fieldErrors: LoginFieldErrors) =>
    setErrors(fieldErrors);

    const { t } = useTranslation();

  return (
    <div style={{ width: "100%", alignSelf: "center", alignContent: "center" }}>
      <p style={{ textAlign: "center" }}>{t("loginWoolit")}</p>
      <form
        onSubmit={handleSubmit(handleValid, handleInvalid)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "1.75rem",
        }}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange, name } }) => (
            <Input
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.email?.message}
              label={t("email")}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, name } }) => (
            <Input
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.password?.message}
              type="password"
              label={t("password")}
            />
          )}
        />
        <Button
          type="submit"
          style={{ marginTop: "0.25rem", width: "100%" }}
          loading={loading}
        >
          {t("login")}
        </Button>
      </form>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          style={{
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            backgroundColor: "#ffffff",
          }}
        >
         {t("or")}
        </span>
      </div>
      <FacebookButton />
    </div>
  );
};

export default LoginForm;
