import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DrawTypes,
  KnitabilityProblem,
  RepeatMode,
  SweaterPartArea,
  SweaterPartAreaGroup,
} from "../enums";
import {
  getSweaterParts,
  getSweaterPartsExceptCollar,
  runAfterLoadCanvasPush,
  runAfterLoadSweaterSet,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import {
  loadGrid,
  drawSelection,
  drawGrid,
  hasLoadedImages,
  prevSetupSweaterPart,
  drawSelectionProps,
  _clearPreview,
  crossAlign,
} from "./gridcanvas";
import { SweaterPart } from "../SweaterPart";
import { Util } from "../static/util";
import GridCell from "./GridCell";
import { Global } from "../static/global";
import React from "react";
import { useSelector } from "react-redux";
import GridOverlay from "./GridOverlay";
import PlacementLine from "./PlacementLine";
import SelectBox from "./SelectBox";
import { Vector2 } from "three";
import { useHotkeys } from "react-hotkeys-hook";
import { RootState } from "../../store/store";
import { Load, Save } from "../SaveLoad";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

let scrollInfo: number[] = [];
let occupiedPosDelayed: any;
const setOccupiedPosDelayed = (it: any) => (occupiedPosDelayed = it);

function Grid(props: any) {
  let mousePos: number[] = [];
  function setMousePos(value: any) {
    mousePos = value;
    setWarningPos(value);
  }
  const [warningPos, setWarningPos] = useState([]);
  const [_gridSize, setGridSize] = useState<number>(Global.gridSize);
  const gridSizeY = () => _gridSize;
  const gridSizeX = () => Math.round((_gridSize * 23) / 18);

  const _gridHTML = useRef(null);
  const _gridContainer = useRef(null);

  const [grid, setGrid] = useState(
    Util.make2DArray(Global.gridSizeX, Global.gridSizeY)
  );

  const [stateIsEmpty, setStateIsEmpty] = useState<boolean>(true);

  // UndoStack contains prev and current state
  const [undoStack, setUndoStack] = useState<any[]>([]);
  // RedoStack contains next state
  const [redoStack, setRedoStack] = useState<any[]>([]);

  const [emptyStateJSON, setEmptyStateJSON] = useState<any>(undefined);

  const [snap, setSnap] = useState<boolean>(false);

  const [placementLines, setPlacementLines] = useState<any[]>([]);

  const [occupiedPos, setOccupiedPos] = useState<any>();

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const moveRef = useRef<(it: Vector2) => boolean>((_: Vector2) => false);
  const deleteRef = useRef<() => void>(() => {});

  const { diagrams, id } = useSelector((state: RootState) => state.pattern);
  const { activeDiagram } = useSelector((state: RootState) => state.grid);
  const { knittingMethod } = useSelector((state: RootState) => state.model);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleClose = () => {
    setAlertOpen(false);
  };

  function getGridHTML(sweaterPart?: SweaterPart) {
    if (sweaterPart && sweaterPart !== props.sweaterPart) return undefined;
    return _gridHTML.current! as HTMLDivElement;
  }

  function getGridContainer() {
    return _gridContainer.current! as HTMLDivElement;
  }

  function getScrollInfo() {
    const gridContainer = getGridContainer();
    const clientWidth = gridContainer.clientWidth;
    const clientHeight = gridContainer.clientHeight;
    const scrollTop = gridContainer.scrollTop;
    const scrollHeight = gridContainer.scrollHeight;
    const scrollLeft = gridContainer.scrollLeft;
    const scrollWidth = gridContainer.scrollWidth;
    return [
      clientWidth,
      clientHeight,
      scrollTop,
      scrollHeight,
      scrollLeft,
      scrollWidth,
    ];
  }

  function zoom(direction: number) {
    scrollInfo = getScrollInfo();
    let amount = Math.ceil(_gridSize / 5) - 1;
    if (_gridSize < 10) {
      amount = 1;
    }
    const newGridSize = _gridSize + amount * direction;
    setGridSize(Util.clamp(newGridSize, 2, 100));
  }

  useHotkeys(["Meta+z", "Control+z"], (e: any) => {
    if (!Global.hoveringVisualizing3D) return;
    e.preventDefault();
    if (undoStack.length > 1) {
      performUndo();
    }
  });

  useHotkeys(
    ["Meta+shift+z", "Control+shift+z", "Meta+y", "Control+y"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      if (redoStack.length > 0) {
        performRedo();
      }
    }
  );

  useHotkeys(
    ["ArrowUp"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      const moved = moveRef.current(new Vector2(0, -1));
      if (moved) {
        updateUndo();
      }
    },
    [moveRef, props.sweaterPart, undoStack, redoStack]
  );

  useHotkeys(
    ["ArrowDown"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      const moved = moveRef.current(new Vector2(0, 1));
      if (moved) {
        updateUndo();
      }
    },
    [moveRef, props.sweaterPart, undoStack, redoStack]
  );

  useHotkeys(
    ["ArrowLeft"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      const moved = moveRef.current(new Vector2(-1, 0));
      if (moved) {
        updateUndo();
      }
    },
    [moveRef, props.sweaterPart, undoStack, redoStack]
  );

  useHotkeys(
    ["ArrowRight"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      const moved = moveRef.current(new Vector2(1, 0));
      if (moved) {
        updateUndo();
      }
    },
    [moveRef, props.sweaterPart, undoStack, redoStack]
  );

  useHotkeys(
    ["Backspace", "Delete"],
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      deleteRef?.current();
      updateUndo();
    },
    [deleteRef, props.sweaterPart, undoStack, redoStack]
  );

  useHotkeys(
    "Escape",
    (e: any) => {
      if (!Global.hoveringVisualizing3D) return;
      e.preventDefault();
      clearPreview();
      props.setPointer(!props.pointer || props.pointerPattern);
    },
    [props.pointer, props.diagram, props.sweaterPart, props.pointerPattern]
  );

  // Create the ref,
  const onMouseUpRef = useRef(onMouseUp);
  // and then update it on each re-render.
  onMouseUpRef.current = onMouseUp;

  function loadStateFromLocalStorage() {
    const stateJSON = localStorage.getItem(Global.getSweaterStorageName());
    if (!stateJSON) {
      return;
    }

    const state = JSON.parse(stateJSON);
    setState(state, undoStack, redoStack);
  }

  function getState() {
    return getSweaterParts().map((part: SweaterPart) => part.copyState());
  }

  function undoButtonClick() {
    if (undoButtonState()) {
      performUndo();
    }
  }

  function redoButtonClick() {
    if (redoButtonState()) {
      performRedo();
    }
  }

  function trashButtonClick() {
    if (trashButtonState()) {
      setAlertOpen(true);
    }
  }

  function undoButtonState() {
    return undoStack.length > 1;
  }

  function redoButtonState() {
    return redoStack.length > 0;
  }

  function trashButtonState() {
    return (props.sweaterPart && !stateIsEmpty) || !props.isSweaterPart;
  }

  // When they move a pattern.
  function onMouseUp(event: any) {
    if (!props.sweaterPart) return;
    updateUndo();
    if (occupiedPosDelayed) {
      setOccupiedPos(occupiedPosDelayed);
      setOccupiedPosDelayed(undefined);
    } else {
      setOccupiedPos(undefined);
    }
  }

  function updateSweater(newPatterns: any) {
    clearPreview();
    const sweaterParts = getSweaterParts();
    if (!sweaterParts) return;
    for (let sweaterPart of sweaterParts) {
      const isSelected = sweaterPart == props.sweaterPart;
      let removedPatterns: Pattern[] = [];
      for (let pattern of sweaterPart.patterns) {
        const pos = pattern.pos;
        sweaterPart.clearPattern(
          pattern,
          isSelected ? getGridHTML() : undefined
        );
        if (pattern.id in newPatterns) {
          const updatedPattern = pattern;
          updatedPattern.grid = newPatterns[pattern.id].copyGrid();

          const patternIndex = sweaterPart.patterns.indexOf(pattern);
          sweaterPart.patterns[patternIndex] = updatedPattern;
          sweaterPart.drawPattern(
            pos,
            updatedPattern,
            false,
            isSelected ? getGridHTML() : undefined
          );
        } else {
          removedPatterns.push(pattern);
        }
      }
      sweaterPart.patterns = sweaterPart.patterns.filter(
        (it) => removedPatterns.indexOf(it) == -1
      );
    }
    render();
  }

  // Maybe rerun this if knitting method is changed
  function init() {
    // Any sweater part will do. Just necessary to initialize
    // imageData.data for "knit it" or saving.
    runAfterLoadCanvasPush(() => {
      setEmptyStateJSON(JSON.stringify(getState()));
      Load(`${id}`, (load: any) => {
        if (load && load["design"]) {
          const state = load["design"].map((part: any) => {
            return part.map((patternJSON: any) => {
              const pattern = new Pattern(patternJSON.grid);
              pattern.groupID = patternJSON.groupID;
              pattern.id = patternJSON.id;
              pattern.name = patternJSON.name;
              pattern.pos = new Vector2(patternJSON.pos.x, patternJSON.pos.y);
              return pattern;
            });
          });
          setState(state, undoStack, redoStack);
        }
        updateUndo();
        setHasLoaded(true);
      });
    }, false);
    runAfterLoadSweaterSet(() => {
      props.setSweaterHasLoaded(true);
      setUpdateCanvasNextFrame();
    });
  }

  useEffect(() => {
    const mouseUp = (e: any) => onMouseUpRef.current(e);
    document.addEventListener("mouseup", mouseUp);
    return () => {
      document.removeEventListener("mouseup", mouseUp);
    };
  }, []);

  useEffect(() => {
    setUndoStack([]);
  }, [knittingMethod]);

  useEffect(() => {
    const patterns = getPatterns();
    props.setPatterns(patterns);
    props.setDiagram(patterns[activeDiagram]);
    updateSweater(patterns);
  }, [diagrams]);

  useEffect(() => {
    updateSweater(props.patterns);
    //Both for refreshing pattern if you reopen
    //And for rendering it at open/reopen
  }, [hasLoaded]);

  useEffect(() => {
    const patterns = getPatterns();
    props.setDiagram(patterns[activeDiagram]);
    props.setPointer(false);
  }, [activeDiagram]);

  useEffect(() => {
    if (!props.pointerPattern) moveRef.current = (it: any) => false;
  }, [props.pointerPattern]);

  useEffect(() => {
    if (!props.loadModel) return;
    init();
  }, [props.loadModel]);

  useEffect(() => {
    props.setPointerPattern(undefined);
    gridForceUpdate();
  }, [props.sweaterPart]);

  useEffect(() => {
    if (!props.sweaterPart) {
      setGrid([]);
      return;
    }
    updateGrid();
  }, [props.sweaterPart, props.selectedEditPatternDone, undoStack]);

  useEffect(() => {
    if (!hasLoaded) return;
    const jsonData = {
      key: `${id}${id}`,
      name: "sweater", // Unused at design studio
      yarnQuantities: {}, // Unused at design studio
      symbols: {}, // Unused at design studio
      design: getState(),
      colors: {}, // Unused at design studio
      size: "undefined",
      knittingMethod: knittingMethod,
    };
    Save(`${id}`, jsonData);
  }, [undoStack]);

  // Used to fix the grid scroll after zooming
  useEffect(() => {
    if (scrollInfo.length === 0) return;
    const [
      clientWidth,
      clientHeight,
      scrollTop,
      scrollHeight,
      scrollLeft,
      scrollWidth,
    ] = scrollInfo;
    const [_0, _1, _2, scrollHeightNew, _3, scrollWidthNew] = getScrollInfo();
    const dx =
      ((scrollLeft + clientWidth / 2) * scrollWidthNew) / scrollWidth -
      (scrollLeft + clientWidth / 2);
    const dy =
      ((scrollTop + clientHeight / 2) * scrollHeightNew) / scrollHeight -
      (scrollTop + clientHeight / 2);
    getGridContainer().scrollBy(dx, dy);
  }, [_gridSize]);

  function setState(
    state: any,
    undoStack: any,
    redoStack: any,
    onlySelected?: boolean
  ) {
    clearPreview();

    const sweaterParts = getSweaterParts();
    for (let n = 0; n < getSweaterParts().length; n++) {
      if (onlySelected && sweaterParts[n].name !== props.sweaterPart.name) {
        continue;
      }
      const oldPatterns = sweaterParts[n].copyPatterns();

      sweaterParts[n].patterns = state[n];

      //Remove patterns that are missing
      oldPatterns.forEach((pattern) => {
        const match = sweaterParts[n].patterns.find(
          (it) => it.pos === pattern.pos
        );
        if (!match) {
          sweaterParts[n].clearPattern(pattern, getGridHTML(sweaterParts[n]));
        }
      });

      //Update patterns
      sweaterParts[n].patterns = sweaterParts[n].patterns
        .filter((it) => it.id in props.patterns)
        .map((pattern) => {
          const updatedPattern = pattern.copyEmpty();
          updatedPattern.grid = props.patterns[pattern.id].copyGrid();
          sweaterParts[n].drawPattern(
            pattern.pos,
            updatedPattern,
            false,
            getGridHTML(sweaterParts[n])
          );
          return updatedPattern;
        });

      sweaterParts[n].setDirty(); //NB
    }
    render();
    if (props.sweaterPart) drawGrid(props.sweaterPart); //NB
  }

  function getPatterns() {
    const _patternsDict: any = {};
    Object.entries(diagrams)
      .sort(([_, diagram1], [__, diagram2]) =>
        diagram1.name.localeCompare(diagram2.name)
      )
      .forEach(([_, diagram]) => {
        const pattern = Util.diagramToPattern(diagram);
        _patternsDict[pattern.id] = pattern;
      });
    return _patternsDict;
  }

  function gridForceUpdate() {
    //React doesnt update cells modified by cell.style.backgroundColor = newValue,
    //therefore we need to perform this manual work
    const gridHTML = getGridHTML();
    if (!gridHTML) return;
    for (let y = 0; y < gridHTML.children.length; y++) {
      //"for .. in" did not work for some reason? gave "98", "99", "length" ??
      for (let x = 0; x < gridHTML.children[y].children.length; x++) {
        let gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
        gridCell.style.backgroundColor = gridCell.style.color;
      }
    }
  }

  function resetState(onlySelected: boolean) {
    const emptyState = JSON.parse(emptyStateJSON);
    setState(emptyState, undoStack, redoStack, onlySelected);
    updateUndo();
  }

  function updateUndo() {
    clearPreview();

    const state = getState();
    const stateJSON = JSON.stringify(state);

    setStateIsEmpty(stateJSON == emptyStateJSON);

    let prevState = undoStack[undoStack.length - 1];
    prevState = prevState?.map((_patterns: Pattern[]) =>
      _patterns.filter((it) => it.id in props.patterns)
    );
    const prevStateJSON = JSON.stringify(prevState);
    if (stateJSON === prevStateJSON) {
      return;
    }
    const newUndoStack = [...undoStack, state];
    setUndoStack(newUndoStack);
    setRedoStack([]);
  }

  function performUndo() {
    props.setPointerPattern(undefined);

    const newUndoStack = [...undoStack];
    const currentState = newUndoStack.pop();
    setUndoStack(newUndoStack);
    const newRedoStack = [...redoStack, currentState];
    setRedoStack(newRedoStack);

    const prevState = newUndoStack[newUndoStack.length - 1];
    setState(prevState, newUndoStack, newRedoStack);
  }

  function performRedo() {
    props.setPointerPattern(undefined);

    const newRedoStack = [...redoStack];
    const newState = newRedoStack.pop();
    setRedoStack(newRedoStack);
    const newUndoStack = [...undoStack, newState];
    setUndoStack(newUndoStack);

    setState(newState, newUndoStack, newRedoStack);
  }
  function select(y: number) {
    props.setPointerPattern(props.sweaterPart.getPattern(y));
  }

  function onMouseOver(endX: any, endY: any, end: boolean, mouseDown: boolean) {
    if (!hasLoadedImages) return;
    if (!props.sweaterPart) return;
    if (mouseDown && mousePos.length === 0) return;
    if (prevSetupSweaterPart !== props.sweaterPart) {
      updateGrid();
      return;
    }
    const preview = !mouseDown;
    if (props.pointer) {
      if (!preview) select(endY);
    } else {
      if (props.diagram) draw(endX, endY, !end || preview);
    }
  }

  function updateGrid() {
    loadGrid(
      props.sweaterPart,
      (it: any) => {
        setGrid(it);
      },
      knittingMethod
    );
  }

  function makePlacementLines() {
    if (!props.sweaterPart) return [];
    const _placementLines = [];
    for (let y = 0; y < props.sweaterPart.sizeY; y++) {
      _placementLines.push(props.sweaterPart.getPlacementLineType(y));
    }
    return _placementLines;
  }

  function sweaterPartsFromRepeatMode(preview: boolean) {
    let sweaterParts: SweaterPart[] = [];
    let repeat = props.repeat;
    if (preview) {
      repeat = Math.min(props.repeat, 1);
    }
    let repeatMode = props.repeatModes[repeat];
    switch (repeatMode) {
      case RepeatMode.NONE:
      case RepeatMode.ONE:
        sweaterParts = [props.sweaterPart];
        break;
      case RepeatMode.BOTH:
        sweaterParts = getSweaterParts(props.sweaterPart.areaGroup());
        break;
      case RepeatMode.ALL:
        sweaterParts = getSweaterPartsExceptCollar();
        break;
    }
    return sweaterParts;
  }

  function draw(endX: any, endY: any, preview: boolean) {
    const gridHTML = getGridHTML()!;

    let pattern = props.diagram;

    let isOverlap = false;
    if (!preview) {
      clearPreview();
      let sweaterParts = sweaterPartsFromRepeatMode(false);
      const sweaterPartOverlap = Util.sortPriority(
        sweaterParts,
        props.sweaterPart
      )
        .map((it: SweaterPart) => {
          const anchor = pattern.anchor();
          const [dxCross, dyCross] = crossAlign(it, props.sweaterPart);

          pattern.pos = new Vector2(endX - anchor[0], endY - anchor[1]);
          pattern.pos.x += dxCross;
          pattern.pos.y += dyCross;

          let overlap = it.getOverlap(pattern);
          if (overlap) {
            const [_pattern, _isOutside] = overlap;
            return [_pattern, _isOutside, it];
          }
          return undefined;
        })
        .find((it) => it); // find first with overlap

      isOverlap = sweaterPartOverlap !== undefined;
      if (isOverlap) {
        const [_pattern, _isOutside, sweaterPart] = sweaterPartOverlap!;
        if (sweaterPart !== props.sweaterPart) {
          Util.makeSetOccupiedPos(
            setOccupiedPosDelayed,
            sweaterPartOverlap,
            props.sweaterPart,
            grid,
            endY
          );
        }
      }
    }
    const _preview = preview || isOverlap;

    let sweaterParts = sweaterPartsFromRepeatMode(_preview);
    //console.log("preview", preview);
    //console.log(_preview);

    const selectionProps = {
      sweaterParts: sweaterParts,
      pattern: pattern,
      x: endX,
      y: endY,
      gridHTML: gridHTML,
      drawType: DrawTypes.Brush,
      sweaterPartInGrid: props.sweaterPart,
      mirror: props.isSweaterPart ? props.mirror : 0,
      preview: _preview,
      gap: 0,
      repeat: props.repeat > 0,
    };
    //drawInterpolate(pattern, endX, endY, _preview, selectionProps);
    setMousePos([endX, endY]);
    drawSelection(selectionProps);
    render();
  }

  function clearPreview() {
    _clearPreview();
    setPlacementLines(makePlacementLines());
    setOccupiedPos(undefined);
  }

  function render() {
    setUpdateCanvasNextFrame();
    setPlacementLines(makePlacementLines());
  }

  function calculateBlackBorders(
    grid: any,
    x: number,
    y: number,
    isSweaterPart: boolean
  ) {
    const borders = [false, false, false, false]; //top right bottom left
    if (!props.sweaterPart || grid[y][x] === -2) return borders;
    if (isSweaterPart) {
      const sweaterPartGridIsEmpty = props.sweaterPart.grid[0].length === 0;
      const sweaterPartGridIsEven = props.sweaterPart.grid[0].length % 2 === 0;
      if (!sweaterPartGridIsEmpty && sweaterPartGridIsEven) {
        if (x === grid[y].length / 2 - 1) {
          borders[1] = true;
        }
        if (x === grid[y].length / 2) {
          borders[3] = true;
        }
      }
    } else {
      if (y === 0 || grid[y - 1][x] === -2) {
        borders[0] = true;
      }
      if (x === grid[y].length - 1 || grid[y][x + 1] === -2) {
        borders[1] = true;
      }
      if (y === grid.length - 1 || grid[y + 1][x] === -2) {
        borders[2] = true;
      }
      if (x === 0 || grid[y][x - 1] === -2) {
        borders[3] = true;
      }
    }
    return borders;
  }

  const gridMemo = useMemo(
    () => (
      <div
        ref={_gridHTML}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseLeave={() => {
          setSnap(false);
          clearPreview();
        }}
      >
        {props.sweaterPart &&
          grid.map((gridY: any, y: number) => (
            <div style={{ display: "flex" }} key={y}>
              {gridY.map((colorIndex: number, x: number) => (
                <GridCell
                  blackBorders={calculateBlackBorders(grid, x, y, true)}
                  sizeX={props.isMiniature ? 1 : gridSizeX()}
                  sizeY={props.isMiniature ? 1 : gridSizeY()}
                  noBorders={props.isMiniature}
                  drawBox={!props.isSweaterPart}
                  snap={snap}
                  colors={props.colors}
                  colorIndex={colorIndex}
                  x={x}
                  y={y}
                  key={x + "," + y}
                  onMouseOver={onMouseOver}
                  setMousePos={setMousePos}
                  updateUndo={updateUndo}
                />
              ))}
            </div>
          ))}
      </div>
    ),
    [
      grid,
      props.colors,
      _gridSize,
      props.diagram,
      props.isMiniature,
      props.pointerPattern,
      props.pointer,
      props.repeat,
    ]
  );

  const warningType = (lines: any[]) => lines.find((it) => it[1]); // it[1] == illegal

  const warningOverlay = () => {
    const _warningType = warningType(placementLines);
    if (!_warningType || warningPos.length == 0 || !props.diagram) {
      return <></>;
    }
    const [_, isOutside] = _warningType[1];
    const patternBottomY =
      (props.diagram as Pattern).sizeY() / 2 + warningPos[1];
    const displayAtTop = patternBottomY >= grid.length;
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            top:
              gridSizeY() * patternBottomY + (displayAtTop ? -70 : 10) + "px",
            left: gridSizeX() * warningPos[0] + "px",
            borderRadius: "5px",
            backgroundColor: "red",
            padding: "5px",
            transform: "translateX(-50%)",
          }}
        >
          <p
            style={{
              color: "white",
              whiteSpace: "nowrap",
            }}
          >
            {isOutside ? "Out of bounds" : "Overlapping diagram"}
          </p>
        </div>
      </div>
    );
  };

  const selectOverlay = () => {
    if (!props.pointerPattern) {
      return <></>;
    }
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            marginTop: props.pointerPattern.pos.y * gridSizeY() + "px",
          }}
        >
          <SelectBox
            moveRef={moveRef}
            deleteRef={deleteRef}
            gridSizeY={gridSizeY()}
            gridHTML={getGridHTML()}
            render={render}
            sweaterPart={props.sweaterPart}
            pointerPattern={props.pointerPattern}
            setPointerPattern={props.setPointerPattern}
            height={props.pointerPattern.sizeY() * gridSizeY()}
            width={grid[0].length * gridSizeX()}
            setOccupiedPos={setOccupiedPos}
            patterns={props.patterns}
            updateSweater={updateSweater}
            grid={grid}
          />
        </div>
      </div>
    );
  };

  const blockedOverlay = () => {
    if (!occupiedPos) {
      return <></>;
    }
    const [topY, bottomY, text] = occupiedPos;
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            marginTop: topY * gridSizeY() + "px",
          }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              backgroundColor: "var(--negative)",
              width: grid[0].length * gridSizeX() + "px",
              height: (bottomY - topY + 1) * gridSizeY() + "px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: "auto",
                color: "var(--neutral-10)",
                whiteSpace: "pre-line",
              }}
            >
              {text}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const placementOverlay = () => (
    <div>
      {grid
        .map(
          (_: any, y: number) =>
            placementLines[y] && (
              <PlacementLine
                key={y}
                gridSizeY={grid?.length ?? 0}
                y={y}
                placementLineType={placementLines[y][0]}
                illegal={placementLines[y][1]}
                small={placementLines[y][2]}
                gridSize={gridSizeY()}
              />
            )
        )
        .filter((it) => it)}
    </div>
  );

  const deleteModal = () => (
    <div>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <p
            style={{
              fontSize: "18px",
            }}
          >
            Choose what to delete:
          </p>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} fullWidth={true}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              resetState(true);
            }}
            fullWidth={true}
          >
            {props.sweaterPart?.name}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              resetState(false);
            }}
            fullWidth={true}
            autoFocus
          >
            Whole sweater
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        position: "relative",
        flexDirection: "row",
        overflowY: "auto",
      }}
    >
      {deleteModal()}
      {!props.isMiniature && (
        <GridOverlay
          sweaterPart={props.sweaterPart}
          setSelectedSweaterPart={props.setSelectedSweaterPart}
          useZoom={props.useZoom}
          zoom={zoom}
          patternID={props.patternID}
          colors={props.colors}
          undoButtonState={undoButtonState}
          undoButtonClick={undoButtonClick}
          redoButtonState={redoButtonState}
          redoButtonClick={redoButtonClick}
          trashButtonState={trashButtonState}
          trashButtonClick={trashButtonClick}
          diagram={props.diagram}
          setDiagram={props.setDiagram}
          updateSweater={updateSweater}
          pointer={props.pointer}
          setPointer={props.setPointer}
          repeat={props.repeat}
          setRepeat={props.setRepeat}
          setRepeatMemo={props.setRepeatMemo}
          repeatOptions={props.repeatOptions}
        />
      )}
      <div
        id="OutsideGrid"
        ref={_gridContainer}
        className="showScrollbar"
        style={{
          display: "flex",
          flex: "1",
          overflowY: "auto",
          marginBottom: props.isMiniature ? "0px" : "60px", //Space for gridOverlay
        }}
        onMouseDown={(e: any) => {
          if (e.target.id !== "OutsideGrid") return;
          if (props.pointer) {
            props.setPointerPattern(undefined);
          }
        }}
      >
        <div
          id="OutsideGrid"
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            backgroundColor: Util.calculateGridColor(-1, -1, undefined, -2),
          }}
        >
          <div
            id="OutsideGrid"
            style={{
              display: "flex",
              padding: props.isMiniature ? "0px" : "60px 60px 0px 60px", //60px is space for placement lines, no padding bottom since its done at parents
            }}
          >
            {!props.isMiniature && warningOverlay()}
            {!props.isMiniature && selectOverlay()}
            {!props.isMiniature && blockedOverlay()}
            {!props.isMiniature && placementOverlay()}
            {gridMemo}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Grid;
