const between = (start: number, end: number, n: number): boolean =>
  n >= start && n <= end;

// Detect overlap
// Returns the new interval
export const overlap = (
  symbolStart: number,
  symbolEnd: number,
  deleteStart: number,
  deleteEnd: number,
  shift: boolean
): [number, number] => {
  let interval: [number, number] = [symbolStart, symbolEnd];
  if (deleteStart <= symbolStart && deleteEnd >= symbolEnd) {
    // Entire symbol deleted
    interval = [-1, -1];
  } else if (deleteStart > symbolStart && deleteEnd < symbolEnd) {
    // Delete middle of symbol
    interval = [symbolStart, symbolEnd - (deleteEnd + 1 - deleteStart)];
  } else if (
    between(symbolStart, symbolEnd, deleteStart) &&
    deleteEnd >= symbolEnd
  ) {
    // Delete end of symbol
    interval = [symbolStart, deleteStart - 1];
  } else if (
    between(deleteStart, deleteEnd, symbolStart) &&
    symbolEnd > deleteEnd
  ) {
    // Delete start of symbol
    interval = [deleteEnd + 1, symbolEnd];
  } else {
    // Untouched symbol
    interval = [symbolStart, symbolEnd];
  }

  if (shift && interval[0] !== -1) {
    const shiftDistance = deleteEnd + 1 - deleteStart;
    interval = [interval[0] - shiftDistance, interval[1] - shiftDistance];
    return interval;
  }
  return interval;
};
