import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./MetadataDrawer.module.css";
import { setMetadata } from "../store/patternSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { RootState } from "../store/store";
import { useTranslation } from "react-i18next";

const DrawerDescription: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { description } = useSelector((state: RootState) => state.pattern.meta);
  const { drawerOpen } = useSelector((state: RootState) => state.writer);
  const [descriptionInput, setDescriptionInput] = useState(description);
  const [colorStyle, setColorStyle] = useState("var(--neutral-40)");
  const dispatch = useDispatch();

  if (drawerOpen) {
    setDisableHotkeys(true);
  }

  useEffect(() => {
    setDescriptionInput(description);
  }, [description]);

  // Handling input onChange event
  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionInput(event.target.value);
  };

  // Handling input onFocus event
  const focusHandler = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setColorStyle("var(--positive)");
  };

  // Handling input onBlur event
  const blurHandler = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    dispatch(setMetadata({ description: descriptionInput }));
    descriptionInput
      ? setColorStyle("var(--darkest-brown)")
      : setColorStyle("var(--neutral-40)");
  };

  const textArea = document.querySelector("textarea");
  const textRowCount = textArea ? textArea.value.split("\n").length : 0;
  const rows = textRowCount + 1;

  return (
    <div className="container" style={{ width: "100%", maxWidth: "50vw" }}>
      {description ? (
        <p className={styles.smallSubtitle}>
          {t("datadrawer.pattern description")}
        </p>
      ) : (
        <></>
      )}
      <textarea
        rows={rows}
        onFocus={focusHandler}
        onBlur={blurHandler}
        value={descriptionInput}
        onChange={changeHandler}
        style={{
          color: colorStyle,
          overflowWrap: "break-word",
        }}
        className={styles.drawerDescription}
        placeholder={t("datadrawer.pattern description")}
      />
    </div>
  );
};

export default DrawerDescription;
