import React from "react";

export enum Fill {
  //eslint-disable-next-line no-unused-vars
  CONTRAST = "contrast",
  //eslint-disable-next-line no-unused-vars
  NEUTRAL = "neutral",
  //eslint-disable-next-line no-unused-vars
  BOLD = "bold",
}

interface PropType {
  fill?: Fill;
  direction?: "upper" | "middle" | "lower";
  orientation?: "bottom" | "right";
}

//eslint-disable-next-line no-unused-vars
const fillToHex: { [key in Fill]: string } = {
  contrast: "#DE5900",
  neutral: "#AA9E95",
  bold: "#563E2C",
};

export const Info = (props: PropType) => {
  const { direction, orientation } = props;
  const fill = `${fillToHex[props.fill ?? Fill.NEUTRAL]}`;

  const combined = `${direction}_${orientation}`;

  switch (combined) {
    case "upper_right":
      return (
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00009 3.78711C5.56876 3.78711 2.78711 6.56876 2.78711 10.0001C2.78711 13.4314 5.56876 16.2131 9.00009 16.2131C12.4314 16.2131 15.2131 13.4314 15.2131 10.0001C15.2131 6.56876 12.4314 3.78711 9.00009 3.78711ZM3.73711 10.0001C3.73711 7.09343 6.09343 4.73711 9.00009 4.73711C11.9067 4.73711 14.2631 7.09343 14.2631 10.0001C14.2631 12.9067 11.9067 15.2631 9.00009 15.2631C6.09343 15.2631 3.73711 12.9067 3.73711 10.0001ZM9.7001 7.20033C9.7001 7.58693 9.3867 7.90033 9.0001 7.90033C8.6135 7.90033 8.3001 7.58693 8.3001 7.20033C8.3001 6.81373 8.6135 6.50033 9.0001 6.50033C9.3867 6.50033 9.7001 6.81373 9.7001 7.20033ZM7.56687 8.567H8.06687H9.00021C9.27635 8.567 9.50021 8.79085 9.50021 9.067V12.3003H9.93354H10.4335V13.3003H9.93354H9.00021H8.06687H7.56687V12.3003H8.06687H8.50021V9.567H8.06687H7.56687V8.567Z"
            fill={fill}
          />
          <line
            x1="5.59664"
            y1="5.30375"
            x2="0.646895"
            y2="0.354001"
            stroke={fill}
          />
        </svg>
      );
    case "middle_right":
      return (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0079 1.78711C7.57657 1.78711 4.79492 4.56876 4.79492 8.00009C4.79492 11.4314 7.57657 14.2131 11.0079 14.2131C14.4392 14.2131 17.2209 11.4314 17.2209 8.00009C17.2209 4.56876 14.4392 1.78711 11.0079 1.78711ZM5.74492 8.00009C5.74492 5.09343 8.10124 2.73711 11.0079 2.73711C13.9145 2.73711 16.2709 5.09343 16.2709 8.00009C16.2709 10.9067 13.9145 13.2631 11.0079 13.2631C8.10124 13.2631 5.74492 10.9067 5.74492 8.00009ZM11.7079 5.20033C11.7079 5.58693 11.3945 5.90033 11.0079 5.90033C10.6213 5.90033 10.3079 5.58693 10.3079 5.20033C10.3079 4.81373 10.6213 4.50033 11.0079 4.50033C11.3945 4.50033 11.7079 4.81373 11.7079 5.20033ZM9.57469 6.567H10.0747H11.008C11.2842 6.567 11.508 6.79085 11.508 7.067V10.3003H11.9414H12.4414V11.3003H11.9414H11.008H10.0747H9.57469V10.3003H10.0747H10.508V7.567H10.0747H9.57469V6.567Z"
            fill={fill}
          />
          <path d="M1 7.96094H5" stroke={fill} />
          <path d="M1 16L1 0" stroke={fill} />
        </svg>
      );
    case "lower_right":
      return (
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0001 1.78711C6.56876 1.78711 3.78711 4.56876 3.78711 8.00009C3.78711 11.4314 6.56876 14.2131 10.0001 14.2131C13.4314 14.2131 16.2131 11.4314 16.2131 8.00009C16.2131 4.56876 13.4314 1.78711 10.0001 1.78711ZM4.73711 8.00009C4.73711 5.09343 7.09343 2.73711 10.0001 2.73711C12.9067 2.73711 15.2631 5.09343 15.2631 8.00009C15.2631 10.9067 12.9067 13.2631 10.0001 13.2631C7.09343 13.2631 4.73711 10.9067 4.73711 8.00009ZM10.7001 5.20033C10.7001 5.58693 10.3867 5.90033 10.0001 5.90033C9.6135 5.90033 9.3001 5.58693 9.3001 5.20033C9.3001 4.81373 9.6135 4.50033 10.0001 4.50033C10.3867 4.50033 10.7001 4.81373 10.7001 5.20033ZM8.56687 6.567H9.06687H10.0002C10.2763 6.567 10.5002 6.79085 10.5002 7.067V10.3003H10.9335H11.4335V11.3003H10.9335H10.0002H9.06687H8.56687V10.3003H9.06687H9.50021V7.567H9.06687H8.56687V6.567Z"
            fill={fill}
          />
          <line
            x1="5.30375"
            y1="11.3536"
            x2="0.354001"
            y2="16.3033"
            stroke={fill}
          />
        </svg>
      );
    case "upper_bottom":
      return (
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00009 3.78711C4.56876 3.78711 1.78711 6.56876 1.78711 10.0001C1.78711 13.4314 4.56876 16.2131 8.00009 16.2131C11.4314 16.2131 14.2131 13.4314 14.2131 10.0001C14.2131 6.56876 11.4314 3.78711 8.00009 3.78711ZM2.73711 10.0001C2.73711 7.09343 5.09343 4.73711 8.00009 4.73711C10.9067 4.73711 13.2631 7.09343 13.2631 10.0001C13.2631 12.9067 10.9067 15.2631 8.00009 15.2631C5.09343 15.2631 2.73711 12.9067 2.73711 10.0001ZM8.7001 7.20033C8.7001 7.58693 8.3867 7.90033 8.0001 7.90033C7.6135 7.90033 7.3001 7.58693 7.3001 7.20033C7.3001 6.81373 7.6135 6.50033 8.0001 6.50033C8.3867 6.50033 8.7001 6.81373 8.7001 7.20033ZM6.56687 8.567H7.06687H8.00021C8.27635 8.567 8.50021 8.79085 8.50021 9.067V12.3003H8.93354H9.43354V13.3003H8.93354H8.00021H7.06687H6.56687V12.3003H7.06687H7.50021V9.567H7.06687H6.56687V8.567Z"
            fill={fill}
          />
          <line
            x1="16.3037"
            y1="0.353553"
            x2="11.354"
            y2="5.3033"
            stroke={fill}
          />
        </svg>
      );

    case "middle_bottom":
      return (
        <svg
          width="17"
          height="19"
          viewBox="0 0 17 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.92294 4.78711C5.49161 4.78711 2.70996 7.56876 2.70996 11.0001C2.70996 14.4314 5.49161 17.2131 8.92294 17.2131C12.3542 17.2131 15.1359 14.4314 15.1359 11.0001C15.1359 7.56876 12.3542 4.78711 8.92294 4.78711ZM3.65996 11.0001C3.65996 8.09343 6.01628 5.73711 8.92294 5.73711C11.8296 5.73711 14.1859 8.09343 14.1859 11.0001C14.1859 13.9067 11.8296 16.2631 8.92294 16.2631C6.01628 16.2631 3.65996 13.9067 3.65996 11.0001ZM9.62295 8.20033C9.62295 8.58693 9.30955 8.90033 8.92295 8.90033C8.53635 8.90033 8.22295 8.58693 8.22295 8.20033C8.22295 7.81373 8.53635 7.50033 8.92295 7.50033C9.30955 7.50033 9.62295 7.81373 9.62295 8.20033ZM7.48973 9.567H7.98973H8.92306C9.1992 9.567 9.42306 9.79085 9.42306 10.067V13.3003H9.85639H10.3564V14.3003H9.85639H8.92306H7.98973H7.48973V13.3003H7.98973H8.42306V10.567H7.98973H7.48973V9.567Z"
            fill={fill}
          />
          <path d="M8.92285 5L8.92285 1" stroke={fill} />
          <path d="M16.9922 1L0.992188 1" stroke={fill} />
        </svg>
      );

    case "lower_bottom":
      return (
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00009 3.78711C5.56876 3.78711 2.78711 6.56876 2.78711 10.0001C2.78711 13.4314 5.56876 16.2131 9.00009 16.2131C12.4314 16.2131 15.2131 13.4314 15.2131 10.0001C15.2131 6.56876 12.4314 3.78711 9.00009 3.78711ZM3.73711 10.0001C3.73711 7.09343 6.09343 4.73711 9.00009 4.73711C11.9067 4.73711 14.2631 7.09343 14.2631 10.0001C14.2631 12.9067 11.9067 15.2631 9.00009 15.2631C6.09343 15.2631 3.73711 12.9067 3.73711 10.0001ZM9.7001 7.20033C9.7001 7.58693 9.3867 7.90033 9.0001 7.90033C8.6135 7.90033 8.3001 7.58693 8.3001 7.20033C8.3001 6.81373 8.6135 6.50033 9.0001 6.50033C9.3867 6.50033 9.7001 6.81373 9.7001 7.20033ZM7.56687 8.567H8.06687H9.00021C9.27635 8.567 9.50021 8.79085 9.50021 9.067V12.3003H9.93354H10.4335V13.3003H9.93354H9.00021H8.06687H7.56687V12.3003H8.06687H8.50021V9.567H8.06687H7.56687V8.567Z"
            fill={fill}
          />
          <line
            x1="5.59664"
            y1="5.30375"
            x2="0.646895"
            y2="0.354001"
            stroke={fill}
          />
        </svg>
      );

    default:
      return (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00009 0.786743C3.56876 0.786743 0.787109 3.56839 0.787109 6.99972C0.787109 10.431 3.56876 13.2127 7.00009 13.2127C10.4314 13.2127 13.2131 10.431 13.2131 6.99972C13.2131 3.56839 10.4314 0.786743 7.00009 0.786743ZM1.73711 6.99972C1.73711 4.09306 4.09343 1.73674 7.00009 1.73674C9.90672 1.73674 12.2631 4.09306 12.2631 6.99972C12.2631 9.90635 9.90672 12.2627 7.00009 12.2627C4.09343 12.2627 1.73711 9.90635 1.73711 6.99972ZM7.7001 4.19996C7.7001 4.58656 7.3867 4.89996 7.0001 4.89996C6.6135 4.89996 6.3001 4.58656 6.3001 4.19996C6.3001 3.81336 6.6135 3.49996 7.0001 3.49996C7.3867 3.49996 7.7001 3.81336 7.7001 4.19996ZM5.56687 5.56663H6.06687H7.00021C7.27635 5.56663 7.50021 5.79049 7.50021 6.06663V9.29996H7.93354H8.43354V10.3H7.93354H7.00021H6.06687H5.56687V9.29996H6.06687H6.50021V6.56663H6.06687H5.56687V5.56663Z"
            fill={fill}
          />
        </svg>
      );
  }
};
