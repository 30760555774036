import React from "react";

interface PropType {
  color?: string;
}

function Document(props: PropType) {
  const { color = "#AA9E95" } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33331 3V17C3.33331 17.5523 3.78103 18 4.33331 18H15.6666C16.2189 18 16.6666 17.5523 16.6666 17V6.41421C16.6666 6.149 16.5613 5.89464 16.3738 5.70711L12.9595 2.29289C12.772 2.10536 12.5176 2 12.2524 2H4.33331C3.78103 2 3.33331 2.44772 3.33331 3ZM6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7H10C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5H6ZM6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9H6ZM6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15H14C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13H6Z"
        fill={color}
      />
    </svg>
  );
}

export default Document;
