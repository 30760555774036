import React, { useEffect, useState } from "react";
import styles from "./diagram.module.css";
import { addRowsOrCols, removeRowsOrCols } from "../store/gridSlice";
import { useGridVariables } from "./utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setDisable } from "../store/dragSelectSlice";
import { PreviewBar } from "./PreviewBar";
import { RootState } from "../store/store";

type Directions = "top" | "left" | "bottom" | "right";

interface ButtonProps {
  top: number;
  left: number;
  direction: Directions;
  axisDirection: "row" | "col";
  previewAnchor: {
    add: { top: number; left: number };
    remove: { top: number; left: number };
  };
  shiftContents?: boolean;
}

export const AddButtons = () => {
  const { offsetX, offsetY, cellHeight, cellWidth, gridHeight, gridWidth } =
    useGridVariables();

  const { zoomCount } = useSelector((state: RootState) => state.grid);
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [activeDirection, setActiveDirection] = useState<Directions | "">("");

  useEffect(() => {
    if (activeDirection === "") {
      dispatch(setDisable(false));
    } else {
      dispatch(setDisable(true));
    }
  }, [activeDirection]);

  const calculateButtonPos = (direction: Directions, c: number): number => {
    if (direction !== activeDirection) return 0;
    switch (direction) {
      case "top":
        return c * -cellHeight;
      case "bottom":
        return c * cellHeight;
      case "left":
        return c * -cellWidth;
      case "right":
        return c * cellWidth;
    }
  };

  const handleDrag = (e: React.DragEvent, buttonProps: ButtonProps) => {
    const { x, y } = e.currentTarget.getBoundingClientRect();
    const screenAdjustedY = y + window.scrollY;
    if (e.pageX === 0 || e.pageY === 0) return;
    const offset = 16 + zoomCount * 2;
    switch (buttonProps.direction) {
      case "top":
        if (e.pageY < screenAdjustedY - offset) {
          setCount(count + 1);
        } else if (e.pageY > screenAdjustedY + offset) {
          setCount(count - 1);
        }
        return;
      case "bottom":
        if (e.pageY > screenAdjustedY + offset) {
          setCount(count + 1);
        } else if (e.pageY < screenAdjustedY - offset) {
          setCount(count - 1);
        }
        return;
      case "left":
        if (e.pageX < x - offset) {
          setCount(count + 1);
        } else if (e.pageX > x + offset) {
          setCount(count - 1);
        }
        return;
      case "right":
        if (e.pageX > x + offset) {
          setCount(count + 1);
        } else if (e.pageX < x - offset) {
          setCount(count - 1);
        }
        return;
    }
  };

  const buttons: ButtonProps[] = [
    {
      direction: "top",
      axisDirection: "row",
      top: 3,
      left: offsetX + (gridWidth / 2) * cellWidth - 6,
      previewAnchor: {
        add: { top: cellHeight, left: offsetX },
        remove: { left: offsetX, top: cellHeight * 2 },
      },
      shiftContents: true,
    },
    {
      direction: "right",
      axisDirection: "col",
      top: offsetY + (gridHeight / 2) * cellHeight,
      left: offsetX + cellWidth * (gridWidth + 1),
      previewAnchor: {
        add: {
          top: offsetY,
          left: offsetX + cellWidth * gridWidth,
        },
        remove: {
          top: offsetY,
          left: offsetX + cellWidth * (gridWidth - 1),
        },
      },
    },
    {
      direction: "bottom",
      axisDirection: "row",
      top: offsetY + cellHeight * (gridHeight + 1) + 3,
      left: offsetX + (gridWidth / 2) * cellWidth - 6,
      previewAnchor: {
        add: { top: offsetY + cellHeight * gridHeight, left: offsetX },
        remove: { left: offsetX, top: offsetY + cellHeight * (gridHeight - 1) },
      },
    },
    {
      direction: "left",
      axisDirection: "col",
      top: offsetY + (gridHeight / 2) * cellHeight,
      left: 3,
      previewAnchor: {
        add: { top: offsetY, left: cellWidth },
        remove: { top: offsetY, left: cellWidth * 2 },
      },
      shiftContents: true,
    },
  ];

  return (
    <>
      {buttons.map((buttonProps, i) => {
        const {
          top,
          left,
          direction,
          axisDirection,
          previewAnchor,
          shiftContents,
        } = buttonProps;

        const pos = count > 0 ? previewAnchor.add : previewAnchor.remove;

        return (
          <div key={i}>
            {activeDirection === direction && (
              <PreviewBar
                {...pos}
                direction={direction}
                operation={count > 0 ? "add" : "remove"}
                count={count}
              />
            )}
            <button
              id={direction}
              className={styles.addButton}
              draggable={true}
              style={{
                position: "absolute",
                top:
                  axisDirection === "row"
                    ? calculateButtonPos(direction, count) + top
                    : top,
                left:
                  axisDirection === "col"
                    ? calculateButtonPos(direction, count) + left
                    : left,
                cursor: axisDirection === "col" ? "ew-resize" : "ns-resize",
              }}
              onMouseDown={() => setActiveDirection(direction)}
              onDragEnd={() => {
                let startIndex;
                if (direction === "top" || direction === "left") startIndex = 0;
                else if (direction === "bottom") startIndex = gridHeight;
                else startIndex = gridWidth;
                if (count > 0) {
                  dispatch(
                    addRowsOrCols({
                      direction: axisDirection,
                      index: startIndex,
                      count,
                      shiftContents,
                    })
                  );
                } else if (count < 0) {
                  dispatch(
                    removeRowsOrCols({
                      direction: axisDirection,
                      index:
                        direction === "top" || direction === "left"
                          ? startIndex
                          : startIndex - Math.abs(count),
                      count: Math.abs(count),
                      shiftContents,
                    })
                  );
                }

                setCount(0);
                setActiveDirection("");
              }}
              onDrag={(e) => handleDrag(e, buttonProps)}
            >
              +
            </button>
          </div>
        );
      })}
    </>
  );
};
