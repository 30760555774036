import React from "react";
import EmailInputForm from "./EmailInputForm";
import styles from "../Styles/Sections.module.css";
import norway_chess from "../../Visualizing/LandingPage/norway_chess.png";
import { Link } from "react-router-dom";

function EndSection() {
  return (
    <div className={styles.endSection}>
      <div
        className={styles.pictureContainer}
      >
        <img
          style={{ borderRadius: "4px", width:"100%" }}
          
          src={norway_chess}
          alt="Illustrasjon av Woolit Design Studio Pro"
        />

        <div
          style={{
            position: "absolute",
            bottom: "5%",
            right: "30%",
            left: "18px",
          }}
        >
          <div style={{ color: "white", fontSize: "20px", fontWeight: 500 }}>
            Som sett på Norway Chess!
          </div>
          <div style={{ color: "white" }}>
            Carlsen, Nakamura, Gukesh og fler designet sin genser og fikk deres
            design strikket!
          </div>
        </div>
      </div>

      <div className={styles.endSectionContent}>
        <div style={{ fontSize: "16px", color: "#7E6A5B", marginBottom:"2%", fontFamily:"forma-djr-display"  }}>
          Woolit Design Studio
        </div>

        <div style={{ fontSize: "30px", color: "black", marginBottom:"2%", fontFamily:"forma-djr-display" }}>
          Design gratis for privat bruk!
        </div>

        <div style={{ fontSize: "16px", color: "black",  marginBottom:"2%", fontFamily:"forma-djr-display"  }}>
          Vi har også et design program for de som rett og slett ønsker å
          designe en enkelt genser med mulighet å få den strikket - enten av en
          Woolit strikker eller strikket selv med en garnpakke! Woolit Design
          Studio er gratis og åpen for alle.
        </div>

        <Link
          style={{ color: "#33251a", cursor: "pointer", marginTop: "2%" }}
          to="https://3d.woolit.no/"
        >
          <text style={{ fontSize: "18px", color: "var(--contrast)", fontFamily:"forma-djr-display" }}>
            Gå til{" "}
            <text >Woolit Design Studio! ➚</text>
          </text>
        </Link>
      </div>
    </div>
  );
}

export default EndSection;
