import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { redo, undo, zoomIn, zoomOut } from "../store/gridSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import { CursorMode } from "./TopMenu/CursorMode";
import { useTranslation } from "react-i18next";

export const StateButtons = () => {
  const { t } = useTranslation();

  const { undoStackPointer, undoStack } = useSelector(
    (state: RootState) => state.grid
  );

  const dispatch = useDispatch();
  return (
    <div className={styles.stateButtons}>
      <div>
        <CursorMode />
      </div>
      <div>
        <button
          title={t("draw.menu.undo")}
          className={styles.stateButton}
          disabled={undoStackPointer < 0 || undoStack.length === 0}
          onClick={() => dispatch(undo())}
        >
          <img src="/undo-arrow.svg" alt="undo" />
        </button>
        <button
          title={t("draw.menu.redo")}
          disabled={
            undoStackPointer === undoStack.length - 1 || undoStack.length === 0
          }
          className={styles.stateButton}
          onClick={() => dispatch(redo())}
        >
          <img src="/redo-arrow.svg" alt="redo" />
        </button>
      </div>
      <div>
        <button
          title={t("draw.menu.zoomin")}
          id="zoom_in"
          onClick={() => dispatch(zoomIn())}
        >
          <img height="16" src="/zoom-in.svg" alt="Zoom in" />
        </button>
        <button
          title={t("draw.menu.zoomout")}
          id="zoom_out"
          onClick={() => dispatch(zoomOut())}
        >
          <img height="16" src="/zoom-out.svg" alt="Zoom out" />
        </button>
      </div>
    </div>
  );
};
