import { Vector2 } from "three";
import { SweaterPart } from "../SweaterPart";
import { Pattern } from "../Pattern";
import { useEffect } from "react";
import { getSweaterParts } from "../knittingpreview/scene";
import { Util } from "../static/util";

let mousePos: Vector2;
let lastDiff: Vector2 = new Vector2(0, 0);
function SelectBox(props: any) {
  useEffect(() => {
    props.moveRef.current = move;
    props.deleteRef.current = doDelete;
  }, [props.pointerPattern]);

  const setMousePos = (e: any) => {
    mousePos = new Vector2(e.screenX, e.screenY);
  };

  const handleDrag = (e: React.DragEvent) => {
    const { x, y } = mousePos;
    const offset = props.gridSizeY; // + props.gridSize * 2
    const yDiff = Math.trunc((e.screenY - y) / offset);
    const xDiff = Math.trunc((e.screenX - x) / offset);
    const newDiff = new Vector2(xDiff, yDiff);
    if (xDiff === 0 && yDiff === 0) return;
    if (newDiff.equals(lastDiff)) return;
    lastDiff = newDiff;
    //const isWarning = props.sweaterPart.getOverlap(props.pointerPattern);
    let moved = move(newDiff);
    if (!moved) {
      return;
    }
    /*if (isWarning) {
      props.updateSweater(props.patterns);
    }*/
    lastDiff = new Vector2(0, 0);
    mousePos = mousePos.add(newDiff.multiplyScalar(offset));
  };
  const move = (posDiff: Vector2) => {
    // Thought I had to move it out, to make it available elsewhere.
    // It wasn't necessary, but it doesnt hurt, so I'll let it stay.
    return Util.move(
      posDiff,
      props.sweaterPart,
      props.pointerPattern,
      props.gridHTML,
      props.render,
      props.setOccupiedPos,
      props.grid
    );
  };

  const doDelete = () => {
    const isWarning = props.sweaterPart.getOverlap(props.pointerPattern);
    props.sweaterPart.deleteGroup(props.pointerPattern, props.gridHTML);
    props.setPointerPattern(undefined);
    if (isWarning) {
      props.updateSweater(props.patterns);
    }
    props.render();
  };

  return (
    <div id="SelectBox">
      <div
        style={{
          position: "absolute",
          display: "flex",
          border: "2px solid rgb(222, 89, 0)",
          boxSizing: "border-box",
          width: props.width + "px",
          height: props.height + "px",
        }}
      >
        {[
          {
            top: "-15px",
            left: props.width / 2 + 0.5 + "px",
            dir: "↑",
            posDiff: new Vector2(0, -1),
          },
          {
            top: props.height / 2 + 0.5 + "px",
            left: "-15px",
            dir: "←",
            posDiff: new Vector2(-1, 0),
          },
          {
            top: props.height + 15 + "px",
            left: props.width / 2 + 0.5 + "px",
            dir: "↓",
            posDiff: new Vector2(0, 1),
          },
          {
            top: props.height / 2 + 0.5 + "px",
            left: props.width + 15 + "px",
            dir: "→",
            posDiff: new Vector2(1, 0),
          },
        ].map((it) => (
          <div
            className="addButton"
            style={{
              top: it.top,
              left: it.left,
              cursor: "pointer",
              backgroundColor: "var(--whiteish)",
              pointerEvents: "auto",
            }}
            key={it.dir}
            onClick={() => move(it.posDiff)}
          >
            <p
              style={{
                paddingLeft: "0.5px",
                color: "var(--positive)",
                fontSize: "10px",
                margin: "auto",
                display: "flex",
              }}
            >
              {it.dir}
            </p>
          </div>
        ))}
        <div
          className="addButton"
          style={{
            top: "-20px",
            left: props.width - 14.5 + "px",
            cursor: "pointer",
            backgroundColor: "var(--whiteish)",
            pointerEvents: "auto",
            borderColor: "red",
            width: "24px",
            height: "24px",
          }}
          onClick={() => {
            doDelete();
          }}
        >
          <img
            src="/3D/ui/delete2.png"
            style={{
              margin: "auto",
              width: "20px",
              height: "20px",
            }}
            alt="delete"
          ></img>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          width: props.width + "px",
          height: props.height + "px",
          pointerEvents: "auto",
          cursor: "move",
          opacity: 0,
        }}
        draggable
        onMouseDown={(e: any) => {
          setMousePos(e);
        }}
        onDrag={(e: any) => {
          if (e.buttons !== 0) handleDrag(e);
        }}
      ></div>
    </div>
  );
}

export default SelectBox;
