import React from "react";

interface PropType {
  color?: string;
}

function LockOpen(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99785 0.0332031C4.75429 0.0332031 3.73633 0.445798 3.07906 1.29347C2.55128 1.97415 2.29935 2.86647 2.29935 3.87534H3.29935C3.29935 3.02718 3.51074 2.36871 3.86933 1.90623C4.2914 1.36189 4.98011 1.0332 5.99785 1.0332C6.92042 1.0332 7.57698 1.31832 8.00602 1.77571C8.43943 2.23777 8.69935 2.93837 8.69935 3.87828V6.4332H1.16602C0.613731 6.4332 0.166016 6.88092 0.166016 7.4332V13.8999C0.166016 14.4522 0.613732 14.8999 1.16602 14.8999H10.8327C11.385 14.8999 11.8327 14.4522 11.8327 13.8999V7.4332C11.8327 6.88092 11.385 6.4332 10.8327 6.4332H9.69935V3.87828C9.69935 2.75928 9.38798 1.78733 8.73538 1.09158C8.07839 0.39117 7.1342 0.0332031 5.99785 0.0332031ZM1.16602 7.4332H10.8327L10.8327 13.8999H1.16602V7.4332Z"
        fill={color ?? "#33251A"}
      />
    </svg>
  );
}

export default LockOpen;
