import { PlacementLineType, SweaterPartArea, SweaterPartAreaGroup } from "./enums";
import { Settings } from "./static/settings";
import { Global } from "./static/global";
import { colorsScene, getSweaterParts } from "./knittingpreview/scene";
import { Pattern } from "./Pattern";
import { Vector2, Vector3 } from "three";
import { crossAlign, updateGridHTML } from "./knittingeditor/gridcanvas";
import { Util } from "./static/util";

export class SweaterPart {
  name: string;
  area: SweaterPartArea;
  grid: number[][];
  patterns: Pattern[]
  patternsIndexPreview: number[]
  dirtyPositionsGrid: boolean[][];
  corner1X: number;
  corner1Y: number;
  corner2X: number;
  corner2Y: number;
  connectY: number;
  inverted: boolean;
  dx: number;
  dy: number;
  startXPixel: number;
  startYPixel: number;
  endXPixel: number;
  endYPixel: number;
  scaleX: number;
  scaleY: number;
  sizeX: number;
  sizeY: number;
  index: number;
  //corner1      corner
  //
  //corner       corner2
  constructor(
    name: string,
    area: SweaterPartArea,
    grid: number[][],
    corner1X: number,
    corner1Y: number,
    corner2X: number,
    corner2Y: number,
    _connectY: number,
    inverted: boolean = false
  ) {
    this.name = name;
    this.area = area;
    this.grid = grid;
    this.index = -1;
    this.patterns = []
    this.patternsIndexPreview = []
    this.corner1X = corner1X / 4096;
    this.corner1Y = corner1Y / 4096;
    this.corner2X = corner2X / 4096;
    this.corner2Y = corner2Y / 4096;
    let connectY = _connectY;
    if (_connectY === -1) {
      connectY = this.corner1Y;
      connectY *= 4096;
    }
    this.dirtyPositionsGrid = [];
    this.inverted = inverted;
    this.setDirty();

    // const minGridSizeX = 138;
    // const minGridSizeY = 116;

    this.startXPixel = this.corner1X * 4096;
    this.startYPixel = this.corner1Y * 4096;
    this.endXPixel = this.corner2X * 4096;
    this.endYPixel = this.corner2Y * 4096;

    this.scaleX = 4096 / Global.canvasWidth;
    this.scaleY = 4096 / Global.canvasHeight;
    //NB BUG: if endXPixel is too large, mirror will not work.
    //Should probably use calculateGridSize for calculation of sizeX
    this.sizeX = Math.round(
      (this.endXPixel - this.startXPixel) / (Settings.maskWidth * this.scaleX)
    );
    this.sizeX += this.sizeX % 2
    this.sizeY = Math.round(
      (this.endYPixel - this.startYPixel) / (Settings.maskHeight * this.scaleY)
    );
    this.sizeY += this.sizeY % 2
    this.connectY = Math.round(
      (connectY - this.startYPixel) / (Settings.maskHeight * this.scaleY)
    );

    if (this.sizeX % 2 == 1 && area !== SweaterPartArea.Collar){
      throw new Error(`Odd number (${this.sizeX}) is not allowed for sizeX.\nTo fix: Change corner1X/corner2X accordingly.`)
    }

    this.dx = 0; //Math.max(Math.ceil((minGridSizeX - this.sizeX) / 2), 10)
    this.dy = 0; //Math.max(Math.ceil((minGridSizeY - this.sizeY) / 2), 0)
  }

  // Fully redraw the SweaterPart inside the scene.
  setDirty() {
    function make2DArray(x: number, y: number, fillWith: any) {
      return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
    }
    this.dirtyPositionsGrid = make2DArray(
      this.grid[0].length,
      this.grid.length,
      true
    );
  }

  areaGroup() {
    switch (this.area) {
      case SweaterPartArea.LeftArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.RightArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.FrontTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.BackTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.Collar:
        return SweaterPartAreaGroup.Collar;
    }
  }

  areaGroupName() {
    return SweaterPartAreaGroup[this.areaGroup()!];
  }

  isArm() {
    return this.areaGroup() === SweaterPartAreaGroup.Arms;
  }

  isTorso() {
    return this.areaGroup() === SweaterPartAreaGroup.Torso;
  }

  isCollar() {
    return this.areaGroup() === SweaterPartAreaGroup.Collar;
  }

  updateGrid(x: number, y: number, newValue: number) {
    if (this.grid[y][x] !== newValue) {
      this.grid[y][x] = newValue;
      this.dirtyPositionsGrid[y][x] = true;
    }
  }
  copyPatterns(){
    return this.patterns.map(it => it.copy())
  }

  copyState(){
    return this.copyPatterns()
  }

  copyGrid() {
    const shallowGrid = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  isSweaterPart() {
    return true;
  }

  findOppositePart(allParts: SweaterPart[]) {
    return allParts.find(
      (it) => it.areaGroup() === this.areaGroup() && it !== this
    );
  }

  findOppositeGroup(allParts: SweaterPart[]){
    return allParts.find(
      (it) => it.areaGroup() !== this.areaGroup() && it.areaGroup() !== SweaterPartAreaGroup.Collar
    );
  }

  isMask(x: number, y: number, _dx?: number, _dy?: number) {
    const dx = _dx ?? this.dx;
    const dy = _dy ?? this.dy;
    const sizeX = this.sizeX;
    const sizeY = this.sizeY;
    const scaleX = this.scaleX;
    const scaleY = this.scaleY;
    const startXPixel = this.startXPixel;
    const startYPixel = this.startYPixel;
    const endXPixel = this.endXPixel;
    const endYPixel = this.endYPixel;

    let minLimitXDraw = dx;
    let maxLimitXDraw = sizeX + dx;
    let minLimitYDraw = dy;
    let maxLimitYDraw = sizeY + dy;

    if (!(x >= minLimitXDraw && x < maxLimitXDraw)) {
      return false;
    }
    if (!(y >= minLimitYDraw && y < maxLimitYDraw)) {
      return false;
    }
    const midXPixel = (startXPixel + endXPixel) / 2;
    const midYPixel = (startYPixel + endYPixel) / 2;

    let xPixel = Math.round(
      (x - dx - sizeX/2) * scaleX * Settings.maskWidth + midXPixel
    );
    let yPixel = Math.round(
      (y - dy - sizeY/2) * scaleY * Settings.maskHeight + midYPixel
    );


    let xPixelNext = Math.round((x + 1 - dx - sizeX/2) * scaleX * Settings.maskWidth + midXPixel)
    let yPixelNext = Math.round((y + 1 - dy - sizeY/2) * scaleY * Settings.maskHeight + midYPixel)
    //let xPixelNext = xPixel + Math.round(Settings.maskWidth * scaleX);
    //let yPixelNext = yPixel + Math.round(Settings.maskHeight * scaleY);
    // Can consider merging the math.round (first commented),
    // but doing so results in unsymmetry, which is why this is done instead

    //NB: Consider caching this
    let NW = this.pixelData(Global.imageData, xPixel, yPixel) >= 128;
    let NE = this.pixelData(Global.imageData, xPixelNext, yPixel) >= 128;
    let SW = this.pixelData(Global.imageData, xPixel, yPixelNext) >= 128;
    let SE = this.pixelData(Global.imageData, xPixelNext, yPixelNext) >= 128;
    return NW || NE || SW || SE; //Do four corner checks, and adjust output depending
  }

  pixelData(imageData: any, x: number, y: number) {
    let index = x * 4 + y * 4 * 4096;
    if (index >= 4096 * 4096 * 4) throw new Error("out of index");
    return imageData.data[index];
  }

  savePattern(x: number, y:number, pattern: Pattern, preview: boolean) {
    const _pattern = pattern.copy();
    _pattern.pos = new Vector2(x, y);
    this.patterns.push(_pattern);
    if (preview) this.patternsIndexPreview.push(this.patterns.length - 1)
    _pattern.sweaterPart = this;
    return _pattern
  }
  
  getOverlap(
    pattern: Pattern
  ) {
    if (this.isOutside(pattern.bottomY()) || this.isOutside(pattern.topY())){
      return [undefined, true]
    } 
    for (let n = this.patterns.length - 1; n >= 0; n--){
      const _pattern = this.patterns[n];
      if (_pattern === pattern) continue;
      const meInsideIt = _pattern.isOverlappingY(pattern.bottomY()) || _pattern.isOverlappingY(pattern.topY())
      const itInsideMe = pattern.isOverlappingY(_pattern.bottomY()) || pattern.isOverlappingY(_pattern.topY())
      if (meInsideIt || itInsideMe){
        return [_pattern, false];
      }
    }
    return undefined;
  }

  getOverlapMove(
    pattern: Pattern,
    direction: Vector2
  ){
    pattern.pos = pattern.pos.add(direction)
    const overlap = this.getOverlap(pattern)
    pattern.pos = pattern.pos.add(direction.multiplyScalar(-1))
    direction.multiplyScalar(-1)
    return overlap
  }

  _movePattern(
    pattern: Pattern,
    direction: Vector2,
    gridHTML?: HTMLDivElement,
  ) {
    this.clearPattern(pattern, gridHTML)
    pattern.pos = pattern.pos.add(direction)
    this.drawPattern(pattern.pos, pattern, false, gridHTML);
    return true
  }

  // Prioritize this sweaterPart 
  getGroupPatterns(groupID: string){
    return Util.sortPriority(getSweaterParts(), this)
    .map((it) => ({
      sweaterPart: it,
      pattern: it.getPatternByGroupID(groupID),
    }))
    .filter((it) => it.pattern) 
  }

  getOverlapMoveGroup(pattern: Pattern, direction: Vector2){
    let groupPatterns = this.getGroupPatterns(pattern.groupID)
    for (let { sweaterPart, pattern } of groupPatterns) {
      const overlapMove = sweaterPart.getOverlapMove(pattern, direction)
      if (overlapMove){
        const [pattern, isOutside] = overlapMove
        return [pattern, isOutside, sweaterPart];
      }
    }
    return undefined
  }

  moveGroup(pattern: Pattern, direction: Vector2, gridHTML?: HTMLDivElement){
    let groupPatterns = this.getGroupPatterns(pattern.groupID)
    const overlap = this.getOverlapMoveGroup(pattern, direction)
    if (overlap){
      return false;
    }
    for (let { sweaterPart, pattern } of groupPatterns) {
      sweaterPart._movePattern(
        pattern!,
        direction,
        sweaterPart === this ? gridHTML : undefined
      );
    }
    return true
  }

  clearPattern(
    pattern: Pattern,
    gridHTML: HTMLDivElement | undefined
  ) {
    const drawPos = pattern.pos
    const startY = drawPos.y
    const endY = drawPos.y + pattern.sizeY()
    for (let y = startY; y < endY; y += 1) {
      for (let x = 0; x < this.sizeX; x++){
        if (this.isMask(x, y)) {
          this.updateGrid(x, y, 0);
          if (!gridHTML) continue
          updateGridHTML(
            x,
            y,
            0,
            gridHTML,
            false,
            [false, false, false, false],
            false
          );
        }
      }
    }
  }

  _deletePattern(
    pattern: Pattern,
    gridHTML?: HTMLDivElement
  ){
    this.clearPattern(pattern, gridHTML)
    this.patterns.splice(this.patterns.indexOf(pattern), 1)
  }

  deleteGroup(
    pattern: Pattern,
    gridHTML?: HTMLDivElement
  ) {
    let groupPatterns = this.getGroupPatterns(pattern.groupID)
    for (let { sweaterPart, pattern } of groupPatterns) {
      sweaterPart._deletePattern(pattern!, sweaterPart === this ? gridHTML : undefined)
    }
  }

  drawPattern(
    drawPos: Vector2,
    pattern: Pattern,
    preview: boolean,
    gridHTML?: HTMLDivElement,
  ){
    const sweaterPart = this
  
    let [startX, startY] = [drawPos.x, drawPos.y]
    let [endX, endY] = [drawPos.x + pattern.sizeX(), drawPos.y + pattern.sizeY()]
  
    let xMod = startX;
    let xLen = endX - startX;
    
    startX = 0;
    endX = sweaterPart.grid[0].length;
  
    endY = Math.min(sweaterPart.sizeY, endY);
  
    for (let x = startX; x < endX; x += 1) {
      for (let y = startY; y < endY; y += 1) {
        if (y < 0) {
          continue;
        }
        const patternY = y - startY;
        let patternX = Util.mod(x - xMod, xLen);
        let drawColor = sweaterPart.grid[y][x];
        const brushColor = pattern!!.grid[patternY][patternX];
        if (brushColor !== -1) {
          if (preview && sweaterPart.isMask(x, y)) {
            drawColor = brushColor;
          } else if (!preview) {
            sweaterPart.updateGrid(x, y, brushColor);
            drawColor = brushColor;
          }
          if (gridHTML && sweaterPart.isMask(x, y)) {
            updateGridHTML(
              x,
              y,
              drawColor,
              gridHTML,
              preview,
              [false, false, false, false],
              false
            );
          }
        }
      }
    }
  }

  clearPreview(){
    for (let index of this.patternsIndexPreview.reverse()){
      this.patterns.splice(index, 1)
    }
    this.patternsIndexPreview = []
  }

  isOutside(y: number){
    return y < 0 || y >= this.sizeY
  }

  getPatternByGroupID(groupID: string){
    return this.patterns.find((pattern) => pattern.groupID === groupID)
  }

  getPattern(y:number, ignoreThisPattern?: Pattern){
    if (this.isOutside(y)){
      return null;
    }
    for (let n = this.patterns.length - 1; n >= 0; n--){
      const pattern = this.patterns[n];
      if (pattern === ignoreThisPattern) continue;
      if (pattern.isOverlappingY(y)){
        return pattern
      }
    }
    return null
  }

  isMultiplePatterns(y:number){
    if (this.isOutside(y)){
      return false;
    }
    let foundOne = false;
    for (let n = this.patterns.length - 1; n >= 0; n--){
      const pattern = this.patterns[n];
      if (pattern.isOverlappingY(y)){
        if (foundOne){
          return true
        }
        foundOne = true
      }
    }
    return false
  }

  getPlacementLineType(y:number){
    if (this.isOutside(y)){
      return [PlacementLineType.Center, false]; // To tell that this spot is occupied
    }
    const pattern = this.getPattern(y);
    if (pattern){
      const illegal = this.getOverlap(pattern)
      if (y == pattern.topY()){
        return [PlacementLineType.Top, illegal, pattern.sizeY() < 5]
      }
      if (y == pattern.bottomY()){
        return [PlacementLineType.Bottom, illegal, pattern.sizeY() < 5]
      }
      return [PlacementLineType.Center, illegal, pattern.sizeY() < 5]
    }
    return [PlacementLineType.None, false]
  }
}
