import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { addMeasure } from "../store/calculationSlice";

import Plus from "../CommonComponents/Icons/Plus";

import styles from "./NewRowBetween.module.css";

type PropType = { tableId: string; measureIndex: number };

function NewRowBetween(props: PropType) {
  const { tableId, measureIndex } = props;

  const sizes = useSelector((state: RootState) => state.pattern.meta.sizes);

  const dispatch = useDispatch();

  return (
    <div
      className={styles.clickableDivider}
      onClick={() =>
        dispatch(addMeasure({ tableId, orderIndex: measureIndex, sizes }))
      }
    >
      <div className={styles.dividerLine} />
      <button
        className={styles.newButton}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(addMeasure({ tableId, orderIndex: measureIndex, sizes }));
        }}
      >
        <Plus />
      </button>
    </div>
  );
}

export default NewRowBetween;
