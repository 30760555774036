import { ArrowLeftIcon } from "@radix-ui/react-icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import { RootState } from "../../store/store";
import {
  closeCommentDrawer,
  setHighlightedComment,
} from "../../store/writerSlice";
import CommentList from "./CommentList";
import styles from "./CommentDrawer.module.css";

export const CommentDrawer = () => {
  const { commentDrawerOpen } = useSelector((state: RootState) => state.writer);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeCommentDrawer());
    dispatch(setHighlightedComment(null));
  };

  if (!commentDrawerOpen) return null;

  return (
    <div
      className={`${styles.drawerContainer} ${
        commentDrawerOpen ? "" : styles.hidden
      }`}
    >
      <div className={styles.drawerBackground} onClick={() => handleClose()} />
      <div className={styles.drawer}>
        <div className={styles.drawerTop}>
          <h3>Kommentarer</h3>
          <Button onClick={() => handleClose()}>
            <ArrowLeftIcon />
          </Button>
        </div>
        <hr className={styles.divider} />
        <div className={styles.drawerContent}>
          <CommentList />
        </div>
      </div>
    </div>
  );
};
