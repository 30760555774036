import { useEffect, useRef } from "react";
import { Util } from "../static/util";
function PatternCanvas(props: any) {
  const canvasRef = useRef<any>(null);

  function height() {
    return numSize(Math.min(props.pattern.sizeY(), 36)) * 3 + 1;
  }

  function width() {
    return numSize(Math.min(props.pattern.sizeX(), 36)) * 3 + 1;
  }

  function numSize(num: number) {
    return numMod() * num;
  }

  function numMod() {
    const height = props.pattern.sizeY();
    const width = props.pattern.sizeX();
    const max = Math.max(height, width);
    return Math.max(Math.floor(36 / max), 1);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas!!.getContext("2d");
    context.clearRect(0, 0, width(), height());
    const grid = props.pattern.grid;
    const _numMod = numMod() * 3;
    const patternIsMonocolored = false;
    const _height = Math.min(grid.length, 36);
    const _width = Math.min(grid[0].length, 36);
    for (let y = 0; y < _height; y++) {
      for (let x = 0; x < _width; x++) {
        if (grid[y][x] !== -1) {
          //Our first draw
          context.fillStyle = props.colors[grid[Math.floor(y)][Math.floor(x)]];
          context.fillRect(x * _numMod + 1, y * _numMod + 1, _numMod, _numMod);
          context.fillStyle = "#000000";
          context.fillRect(x * _numMod, y * _numMod, 1, _numMod);
          context.fillRect(x * _numMod, y * _numMod, _numMod, 1);
          context.fillRect((x + 1) * _numMod, y * _numMod, 1, _numMod + 1);
          context.fillRect(x * _numMod, (y + 1) * _numMod, _numMod + 1, 1);
        }
      }
    }
  }, [props.pattern, props.colors]);

  return (
    <canvas
      ref={canvasRef}
      width={width() + ""}
      height={height() + ""}
      style={{ height: height() + "px", width: width() + "px" }}
    />
  );
}

export default PatternCanvas;
