import React from "react";
import styles from "./Buttons.module.css";

import Spinner from "../../icons/Spinner";

type Props = {
  onClick?: () => void;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  disabled?: boolean;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  children?: any;
  style?: any;
  noPadding?: boolean;
};

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  onClick = () => {},
  variant: variant = "primary",
  disabled = false,
  loading = false,
  type,
  className,
  children,
  style = {},
  noPadding = false,
}) => {
  return (
    <button
      className={styles.ctaButton}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      
    >
      {loading ? (
        <Spinner
          
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
