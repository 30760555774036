import { Vector2 } from "three";
import { Settings } from "./static/settings";
import { Util } from "./static/util";
import { patternApi } from "../services/patternApi";
import { SweaterPart } from "./SweaterPart";

export class Pattern {
  grid: number[][];
  pos: Vector2;
  name: string;
  id: string;
  groupID: string;
  sweaterPart: SweaterPart | undefined;
  constructor(grid: number[][]) {
    this.grid = grid;
    this.pos = new Vector2(-1,-1);
    
    this.name = ""
    this.id = "";
    this.groupID = "";
    this.sweaterPart = undefined;
  }

  anchor() {
    return [
      Math.floor((this.grid[0].length - 1) / 2),
      Math.floor((this.grid.length - 1) / 2),
    ];
  }
  length() {
    return [this.grid[0].length, this.grid.length];
  }

  isSweaterPart() {
    return false;
  }

  sizeX(){
    return this.grid[0].length
  }

  sizeY(){
    return this.grid.length
  }

  updateGrid(x: number, y: number, newValue: number) {
    this.grid[y][x] = newValue;
  }

  copy() {
    const pattern = this.copyEmpty();
    pattern.grid = this.copyGrid();
    return pattern
  }
  
  copyEmpty(){
    const pattern = new Pattern([[]])
    pattern.name = this.name
    pattern.id = this.id
    pattern.pos = new Vector2(this.pos.x, this.pos.y)
    pattern.groupID = this.groupID
    return pattern
  }

  copyGrid() {
    const shallowGrid: number[][] = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  copyGridBySize() {
    const shallowGrid: number[][] = [];
    for (let y = 0; y < this.sizeY(); y++) {
      shallowGrid.push([] as number[]);
      for (let x = 0; x < this.sizeX(); x++) {
        const innerGrid = this.grid[y][x];
        shallowGrid[y].push(innerGrid);
      }
    }
    return shallowGrid;
  }

  // Instead of sizeY argument; could update this.sizeY immediately instead of with useState.
  calculateEditPatternHeight(sizeY: number | undefined) {
    return (
      Math.round(
        Settings.defaultPatternHeightVH *
          Math.max(1, (sizeY ?? this.sizeY()) / 12)
      ) + 0.1
    );
  }

  isMask(x: number, y: number) {
    const drawX = x >= 0 && x < this.sizeX();
    const drawY = y >= 0 && y < this.sizeY();
    return drawX && drawY;
  }

  // Unused, but could be useful if people lose track of their colors/mouse
  // and therefore needs outlines as indicators
  getOutlines(x: number, y: number) {
    if (x < 0) {
      return [false, false, true, false];
    }
    if (x > this.sizeX()) {
      return [false, true, false, false];
    }
    if (y < 0) {
      return [true, false, false, false];
    }
    if (y > this.sizeY()) {
      return [false, false, false, true];
    }
    const up = (this.grid[y][x] === -1) !== (this.grid[y - 1][x] === -1);
    const right = (this.grid[y][x] === -1) !== (this.grid[y][x - 1] === -1);
    const down = (this.grid[y][x] === -1) !== (this.grid[y + 1][x] === -1);
    const left = (this.grid[y][x] === -1) !== (this.grid[y][x + 1] === -1);
    return [up, right, down, left];
  }

  topY(){
    return this.pos.y
  }

  bottomY(){
    return this.pos.y + this.sizeY() - 1
  }

  middleY() {
    return (this.topY() + this.bottomY()) / 2
  }

  isOverlappingY(y: number){
    return y <= this.bottomY() && y >= this.topY()
  }  
}
