import { DiagramData, YarnProfileVariant } from "../store/pattern";
import { select } from "d3-selection";
import * as ReactDOMServer from "react-dom/server";
import { symbolLookup } from "../CommonComponents/Icons/lookup";
import { useDarkColor } from "../utils/colorContrast";
import { path } from "d3-path";
import { DynamicSymbol } from "@iterate/woolit-components";
import React from "react";

// Take in diagramData genereate svg
export const generateDiagramSvg = (
  diagram: DiagramData,
  variant: YarnProfileVariant
) => {
  const {
    gridHeight,
    gridWidth,
    grid,
    lines,
    excluded,
    patterns,
    diagramComments,
    dynamicSymbols,
  } = diagram;

  const { colors } = variant;

  const cellHeight = 24;
  const cellWidth = cellHeight * 1.25;

  const body = select(window.document.getElementById("diagram-export-root"));

  const svg = body
    .append("svg")
    .attr("width", `${gridWidth * cellWidth + 400}px`)
    .attr("height", `${gridHeight * cellHeight + 400}px`)
    .attr("xmlns", "http://www.w3.org/2000/svg");

  // Cells
  grid.forEach((row, y) => {
    row.forEach((cell, x) => {
      const cellColor = cell.color !== -1 ? colors[cell.color].hex : "white";
      const square = svg
        .append("g")
        .attr("transform", `translate(${x * cellWidth}, ${y * cellHeight})`);

      square
        .append("rect")
        .attr("width", `${cellWidth}`)
        .attr("height", `${cellHeight}`)
        .attr("fill", cellColor)
        .attr("stroke", "white")
        .attr("stroke-width", "0.5");

      if (cell.symbol !== "stitch") {
        const icon = ReactDOMServer.renderToString(
          symbolLookup({
            key: cell.symbol,
            props: {
              fill: useDarkColor(cellColor ?? "#fff") ? "black" : "white",
              height: cellHeight,
              width: cellWidth,
            },
          })
        );
        square.append("g").html(icon);
      }
    });
  });

  // Grid
  for (let x = 0; x <= gridHeight; x++) {
    const l = svg
      .append("line")
      .attr("stroke-width", "1")
      .attr("stroke", "black");

    l.attr("x1", 0)
      .attr("x2", `${gridWidth * cellWidth}`)
      .attr("y1", x * cellHeight)
      .attr("y2", x * cellHeight);
  }

  for (let y = 0; y <= gridWidth; y++) {
    const l = svg
      .append("line")
      .attr("stroke-width", "1")
      .attr("stroke", "black");

    l.attr("x1", y * cellWidth)
      .attr("x2", y * cellWidth)
      .attr("y1", 0)
      .attr("y2", `${gridHeight * cellHeight}`);
  }

  // Axis
  const xAxis = svg
    .append("g")
    .attr("transform", `translate(0, ${(gridHeight + 1) * cellHeight})`);

  for (let x = 0; x < gridWidth; x++) {
    xAxis
      .append("text")
      .text(gridWidth - x)
      .attr("font-family", "Arial, Helvetica, sans-serif")
      .attr("font-size", "12px")
      .attr("height", cellHeight)
      .attr("width", cellWidth)
      .attr("text-anchor", "middle")
      .attr("x", `${x * cellWidth + cellWidth / 2}`);
  }

  const yAxis = svg
    .append("g")
    .attr(
      "transform",
      `translate(${(gridWidth + 1) * cellWidth}, ${cellHeight / 4})`
    );

  for (let y = 0; y < gridHeight; y++) {
    yAxis
      .append("text")
      .text(gridHeight - y)
      .attr("font-family", "Arial, Helvetica, sans-serif")
      .attr("font-size", "12px")
      .attr("height", cellHeight)
      .attr("width", cellWidth)
      .attr("text-anchor", "middle")
      .attr("y", `${y * cellHeight + cellHeight / 2}`);
  }

  //Diagram Lines
  Object.entries(lines ?? {}).forEach(([key, line]) => {
    const [indexStr, direction] = key.split("_");

    const index = parseInt(indexStr);
    const l = svg
      .append("line")
      .attr("stroke-width", "3")
      .attr("stroke", line.color ?? "black");

    if (direction === "col") {
      const x = (index + 1) * cellWidth - 1;
      l.attr("x1", x)
        .attr("x2", x)
        .attr("y1", `0`)
        .attr("y2", `${gridHeight * cellHeight}`);
    } else if (direction === "row") {
      const y = (index + 1) * cellHeight - 1;
      l.attr("x1", 0)
        .attr("x2", `${gridWidth * cellWidth}`)
        .attr("y1", y)
        .attr("y2", y);
    }
  });

  // Dynamic Symbols
  Object.entries(dynamicSymbols ?? {}).forEach(([key, data], i) => {
    const [x1, y1, x2, y2] = key.split("_").map((n) => parseInt(n));

    const w = (x2 - x1 + 1) * cellWidth;
    const h = (y2 - y1 + 1) * cellHeight;

    const rds = React.createElement(DynamicSymbol, {
      symbol: data.symbol,
      iconProps: { height: h, width: w, fill: "black" },
    });

    const symbol = ReactDOMServer.renderToString(rds);
    svg
      .append("g")
      .attr("transform", `translate(${x1 * cellWidth},${y1 * cellHeight})`)
      .html(symbol);
  });

  // Comments and exclusions
  Object.entries(diagramComments?.colComments ?? {}).forEach(
    ([key, { comment, relation }]) => {
      const index = parseInt(key);

      const node = svg
        .append("g")
        .attr(
          "transform",
          `translate(${index * cellWidth + 4}, ${
            (gridHeight + 2) * cellHeight
          })rotate(45)`
        );
      node
        .append("text")
        .text(comment)
        .attr("font-size", "12px")
        .attr("font-family", "Arial, Helvetica, sans-serif");
    }
  );

  Object.entries(diagramComments?.rowComments ?? {}).forEach(
    ([key, { comment }]) => {
      const index = parseInt(key);
      const node = svg
        .append("g")
        .attr(
          "transform",
          `translate(${(gridWidth + 2) * cellWidth}, ${
            (index + 1) * cellHeight - cellHeight / 4
          })`
        );
      node
        .append("text")
        .text(comment)
        .attr("font-size", "12px")
        .attr("font-family", "Arial, Helvetica, sans-serif");
    }
  );

  Object.entries(excluded ?? {}).forEach(([key, exclusion]) => {
    const [direction, indexStr] = key.split("_");
    const index = parseInt(indexStr);

    const node = svg.append("g");

    if (direction === "col") {
      node.attr(
        "transform",
        `translate(${index * cellWidth + 4}, ${
          (gridHeight + 2) * cellHeight
        })rotate(45)`
      );

      node
        .append("text")
        .text(`Hopp over for ${exclusion.join(", ")}`)
        .attr("font-size", "12px")
        .attr("font-family", "Arial, Helvetica, sans-serif");
    } else if (direction === "row") {
      const node = svg
        .append("g")
        .attr(
          "transform",
          `translate(${(gridWidth + 2) * cellWidth}, ${
            (index + 1) * cellHeight - cellHeight / 4
          })`
        );
      node
        .append("text")
        .text(`Hopp over for ${exclusion.join(", ")}`)
        .attr("font-size", "12px")
        .attr("font-family", "Arial, Helvetica, sans-serif");
    }
  });

  Object.entries(patterns?.colPatterns ?? {}).forEach(([key, pattern]) => {
    const { comment, start, end } = pattern;
    const p = path();
    p.moveTo(0, 0);
    p.lineTo(0, 6);
    p.lineTo((end - start + 1) * cellWidth, 6);
    p.lineTo((end - start + 1) * cellWidth, 0);

    const node = svg
      .append("g")
      .attr(
        "transform",
        `translate(${start * cellWidth}, ${(gridHeight + 2) * cellHeight})`
      );

    node
      .append("path")
      .attr("d", p.toString())
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", "1");

    node
      .append("text")
      .text(comment)
      .attr("transform", "translate(4, 18)rotate(45)")
      .attr("font-size", "12px")
      .attr("font-family", "Arial, Helvetica, sans-serif");
  });

  Object.entries(patterns?.rowPatterns ?? {}).forEach(([key, pattern]) => {
    const { comment, start, end } = pattern;

    const p = path();
    p.moveTo(0, 0);
    p.lineTo(6, 0);
    p.lineTo(6, (end - start + 1) * cellHeight);
    p.lineTo(0, (end - start + 1) * cellHeight);

    const node = svg
      .append("g")
      .attr(
        "transform",
        `translate(${(cellWidth + 6) * gridWidth}, ${start * cellHeight})`
      );

    node
      .append("path")
      .attr("d", p.toString())
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", "1");

    node.append("line").attr("x1", 0).attr("x2", 0).attr("y1", 0).attr("y2", 0);

    node
      .append("text")
      .text(comment)
      .attr("transform", `translate(8, ${cellHeight / 2})`)
      .attr("font-size", "12px")
      .attr("font-family", "Arial, Helvetica, sans-serif");
  });

  const html = body.html().slice();
  body.selectAll("*").remove();

  return new Blob([html], { type: "image/svg+xml" });
};
