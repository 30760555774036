import React from "react";

interface PropType {
  color?: string;
}

function ChangeFirstRow(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0664062 13.1328L13.9331 13.1328V14.7328L0.0664062 14.7328V13.1328Z"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="4.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="4.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="2.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="2.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="6.4668"
        width="1.06667"
        height="1.06667"
        rx="0.533334"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="6.4668"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="4.33398"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="2.19922"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="8.59961"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="10.7324"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="8.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="8.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="6.4668"
        y="10.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="12.8672"
        y="10.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="4.33301"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="2.2002"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="6.4668"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="0.0664062"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="8.59961"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
      <rect
        x="0.0664062"
        y="10.7334"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        fill={color ?? "#33251A"}
      />
    </svg>
  );
}

export default ChangeFirstRow;
