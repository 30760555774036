import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageAsColorPreview from "../../CommonComponents/ImageAsColorPreview";
import { Color, YarnColor, YarnProfileVariant } from "../../store/pattern";
import { RootState } from "../../store/store";
import { setVariantsAmount } from "../../store/patternSlice";
import styles from "./YarnAmounts.module.css";

interface PropType {
  variants: YarnProfileVariant[];
  sizes: string[];
}

function YarnAmounts(props: PropType) {
  const { variants, sizes } = props;

  const dispatch = useDispatch();

  let colorsByIndex: Color[][] = [];
  for (let variant of variants) {
    for (let i = 0; i < variant.colors.length; i++) {
      colorsByIndex[i]
        ? colorsByIndex[i].push(variant.colors[i])
        : colorsByIndex.push([variant.colors[i]]);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.labelRow}>
        {sizes.map((size) => (
          <div key={size} className={styles.label}>
            {size}
          </div>
        ))}
      </div>
      {colorsByIndex.map((colorList, index) => (
        <div key={index} className={styles.amountRow}>
          <div className={styles.index}>
            Farge {index + 1 < 10 ? "0" + (index + 1) : index + 1}
          </div>
          <div className={styles.colors}>
            {colorList.map((color, i) => (
              <div key={i} className={styles.colorCell}>
                <div className={styles.colorContainer}>
                  {color.hex ? (
                    <div
                      className={styles.colorPreview}
                      style={{ backgroundColor: color.hex }}
                    />
                  ) : (
                    <ImageAsColorPreview color={color as YarnColor} />
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* <div>
            <button
              onClick={() => dispatch(deleteColor({ colorIndex: index }))}
            >
              Ikke i bruk, slett denne fargen?
            </button>
          </div> */}
          <div className={styles.spacer}> </div>
          <div className={styles.amounts}>
            {sizes.map((size) => (
              <input
                key={size}
                className={styles.amountInput}
                value={variants[0].amounts[size]?.[index]}
                onChange={(e) => {
                  const v = parseInt(e.target.value);
                  const newValue = isNaN(v) ? 0 : v;
                  dispatch(
                    setVariantsAmount({
                      size,
                      colorIndex: index,
                      amount: newValue,
                    })
                  );
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function AwareYarnAmounts() {
  const { variants, sizes } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  return <YarnAmounts variants={variants} sizes={sizes} />;
}

export default AwareYarnAmounts;
export { YarnAmounts };
