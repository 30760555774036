import React, { useState, useEffect } from "react";
import Alert, { AlertValues } from "./components/ui/Alert/Alert";
import { LoginUserCredentials, RegisterUserCredentials } from "./types/auth";
import LoginForm from "./components/forms/LoginForm";
import RegisterForm from "./components/forms/RegisterForm";
import Button from "./components/ui/Button/Button";

import { Cross1Icon } from "@radix-ui/react-icons";

import styles from "./Login.module.css";
import { useTranslation } from "react-i18next";
import { backendApiAddress } from "../backendApi";
import { Navigate } from "react-router";
import { useDispatch } from "react-redux";
import { fetchMe, login } from "./components/LoginState";

async function methodData(
  url = "",
  data = {},
  method = "",
  retrieveMethod = (it: any) => it
) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    ...(Object.keys(data).length === 0 ? {} : { body: JSON.stringify(data) }), // body data type must match "Content-Type" header
  });
  return retrieveMethod(response);
}
type Props = {
  handleClose?: () => void;
};

const AuthModalContent: React.FC<React.PropsWithChildren<Props>> = ({
  handleClose,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginStateIsLoading, setLoginStateIsLoading] = useState(false);
  const [registerStateIsLoading, setRegisterStateIsLoading] = useState(false);
  const dispatch = useDispatch();

  const loginn = (it: any) => {
    console.log("backendApiAddress:");
    console.log(backendApiAddress);
    const baseURL = `${backendApiAddress}/api`;
    dispatch(login(it));
    return methodData(
      `${baseURL}/auth/login_with_password`,
      it,
      "POST",
      async (it: any) => {
        console.log(it);
        return it;
      }
    );
  };
  const register = (it: any) => {
    const baseURL = `${backendApiAddress}/api`;

    return methodData(
      `${baseURL}/auth/register_with_password`,
      it,
      "POST",
      async (it: any) => {
        return it;
      }
    );
  };

  const { t } = useTranslation();

  const [type, setType] = useState<"login" | "register">("login");

  useEffect(() => {
    if (isLoggedIn) {
      console.log("logged in");
      const timer = setTimeout(() => {
        setLoginAlert(null);
        setRegisterAlert(null);
        handleClose && handleClose();
      }, 2000);
 
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn, handleClose]);

  const [loginAlert, setLoginAlert] = useState<AlertValues | null>(null);
  const handleLogin = (loginCredentials: LoginUserCredentials) => {
    setLoginStateIsLoading(true);
    loginn(loginCredentials)
      .then((it: any) => {
        console.log(it);
        console.log(it.ok);
        if (it.ok) {
          setIsLoggedIn(true);
          setLoginAlert({ severity: "success", message: t("successfulLogin") });
          
        } else {
          setLoginAlert({
            severity: "error",
            message: t("loginFailure"),
          });
        }
        setLoginStateIsLoading(false);
      })
      .catch((it: any) => setLoginStateIsLoading(false));
  };

  const [registerAlert, setRegisterAlert] = useState<AlertValues | null>(null);
  const handleRegister = (registerCredentials: RegisterUserCredentials) => {
    setRegisterStateIsLoading(true);
    register(registerCredentials)
      .then((it: any) => {
        if (it.ok) {
          setIsLoggedIn(true);
          setRegisterAlert({
            severity: "success",
            message: "Bruker ble registrert!",
          });
        } else {
          setRegisterAlert({
            severity: "error",
            message: "Kunne ikke registrere bruker.",
          });
        }
        setRegisterStateIsLoading(false);
      })
      .catch((it: any) => setRegisterStateIsLoading(false));
  };
  return (
    <>
      {handleClose && (
        <div
          style={{
            display: "flex",
            position: "sticky",
            top: "0",
            justifyContent: "flex-end",
          }}
        >
          <button style={{ padding: "1rem" }} onClick={handleClose}>
            <Cross1Icon />
          </button>
        </div>
      )}
      <div className={styles.innerModal}>
        {type === "login" && (
          <>
            <LoginForm onSubmit={handleLogin} loading={loginStateIsLoading} />
            {loginAlert && (
              <Alert severity={loginAlert.severity} className="mt-4">
                {loginAlert.message}
              </Alert>
            )}
            <div
              style={{
                display: "flex",
                marginTop: "2.5rem",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "0.75rem",
              }}
            >
              <p className="text-par-s-reg text-dark-brown">
                {t("noWoolitAccount")}
              </p>
              <Button
                style={{ width: "100%" }}
                variant="secondary"
                onClick={() => setType("register")}
              >
                {t("register")}
              </Button>
            </div>
          </>
        )}
        {type === "register" && (
          <>
            <RegisterForm
              onSubmit={handleRegister}
              loading={registerStateIsLoading}
            />
            {registerAlert && (
              <Alert severity={registerAlert.severity} className="mt-4">
                {registerAlert.message}
              </Alert>
            )}
            <div
              style={{
                display: "flex",
                marginTop: "2.5rem",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "0.75rem",
              }}
            >
              <p>{t("haveWoolitAccount")}</p>
              <Button
                style={{ width: "100%" }}
                variant="secondary"
                onClick={() => setType("login")}
              >
                {t("login")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AuthModalContent;
