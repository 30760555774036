import { PersonIcon } from "@radix-ui/react-icons";
import React from "react";
import styles from "./KnitterProfile.module.css";

type Props = {
  id?: number;
  name?: string;
};

const KnitterProfile: React.FC<Props> = ({ id, name }) => {
  return (
    <div className={styles.container}>
      <div className={styles.profilePicture} ><PersonIcon /></div>
      <span className={styles.text}>{`${id ?? ""}: ${
        name ?? "Strikker"
      }`}</span>
    </div>
  );
};

export default KnitterProfile;
