import React from "react";
import { PreviewSquare } from "./PreviewSquare";
import { useGridVariables } from "./utils/hooks";

interface PropType {
  direction: "left" | "right" | "top" | "bottom";
  operation: "add" | "remove";
  count: number;
  top: number;
  left: number;
}

export const emptyArray = (length: number) => new Array(length).fill("b");

export const PreviewBar = (props: PropType) => {
  const { direction, operation, count, top, left } = props;
  const { gridWidth, gridHeight, cellWidth, cellHeight } = useGridVariables();

  const renderVertical = (offset: number) => {
    const sign = operation === "remove" ? -1 : 1;
    const inverse = direction === "left" ? -1 : 1;
    return emptyArray(gridHeight).map((_, i) => (
      <PreviewSquare
        key={i}
        operation={operation}
        left={sign * inverse * offset * cellWidth}
        top={i * cellHeight}
      />
    ));
  };

  const renderHorizontal = (offset: number) => {
    const sign = operation === "remove" ? -1 : 1;
    const inverse = direction === "top" ? -1 : 1;
    return emptyArray(gridWidth).map((_, i) => (
      <PreviewSquare
        key={i}
        operation={operation}
        left={i * cellWidth}
        top={sign * inverse * offset * cellHeight}
      />
    ));
  };

  return (
    <div style={{ position: "absolute", top, left, zIndex: 30 }}>
      {emptyArray(Math.abs(count)).map((_, i) =>
        direction === "right" || direction === "left"
          ? renderVertical(i)
          : renderHorizontal(i)
      )}
    </div>
  );
};
