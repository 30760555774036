import { format, parse, set } from "date-fns";
import React, { useState } from "react";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";

const KnitAlongModal = ({
  setDateFn,
  toggle,
  existingDate: existingDateString,
}: {
  setDateFn: Function;
  toggle: Function;
  existingDate: string | undefined;
}) => {
  const existingDate = new Date(existingDateString || new Date());

  const [date, setDate] = useState(existingDate);

  return (
    <div>
      <input
        type="date"
        value={format(date, "yyyy-MM-dd")}
        onChange={(e) => {
          const newDate = new Date(e.target.value);
          const year = newDate.getFullYear();
          const month = newDate.getMonth();
          const dayOfMonth = newDate.getDate();
          setDate(set(date, { year, month, date: dayOfMonth }));
        }}
      />
      <input
        type="time"
        value={format(date, "HH:mm")}
        onChange={(e) => {
          const updatedTime = parse(e.target.value, "HH:mm", date);
          setDate(updatedTime);
        }}
      />
      <Button
        onClick={() => {
          setDateFn(date.toISOString());
          toggle();
        }}
      >
        Lagre
      </Button>
    </div>
  );
};

interface KnitAlongModalProps {
  headerText: string;
  setDateFn: Function;
  existingDate: string | undefined;
  isIntroduction: boolean;
}

function findSymbol(existingDate: string | undefined) {
  if (!existingDate) {
    return "⏰";
  }

  const date = new Date(existingDate);

  if (date < new Date()) {
    return "✅";
  } else {
    return "🗓️";
  }
}

export const KnitAlongModalButton: React.VFC<KnitAlongModalProps> = ({
  headerText,
  setDateFn,
  existingDate,
  isIntroduction,
}) => {
  const { isShown, toggle } = useModal();
  if (isIntroduction) {
    return (
      <ModalButton
        headerText={`Dette er introduksjonen til samstrikket.`}
        modalContent={
          <div>
            <p>
              Denne seksjonen vil alltid vises, skriv en introdukson til
              oppskriften.
            </p>
            <Button
              onClick={() => {
                toggle();
              }}
            >
              Lukk
            </Button>
          </div>
        }
        isShown={isShown}
        toggle={toggle}
      >
        ℹ️
      </ModalButton>
    );
  }
  return (
    <ModalButton
      headerText={`Sett publiseringstidspunkt for ${headerText}`}
      modalContent={
        <KnitAlongModal
          toggle={toggle}
          setDateFn={setDateFn}
          existingDate={existingDate}
        />
      }
      isShown={isShown}
      toggle={toggle}
    >
      {findSymbol(existingDate)}
    </ModalButton>
  );
};
