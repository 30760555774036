import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { backendApiAddress } from "../backendApi";

export const registerInterest = createApi({
  reducerPath: "registerInterest",
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendApiAddress}/api/wds/interests`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    updateRegisteredInterest: builder.mutation({
      query: (data: { email: string }) => ({
        url: "/register",
        method: "POST",
        body: JSON.stringify({ email: data.email }),
      }),
    }),
  }),
});

export const { useUpdateRegisteredInterestMutation } = registerInterest;
