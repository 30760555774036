import React from "react";
import {
  useAddKnitterToGroupMutation,
  useRemoveKnitterFromGroupMutation,
} from "../services/modelknit";
import { Knitter } from "../store/pattern";

type Props = {
  orgId: string;
  groupTag: string;
  hide: () => void;
  members: Knitter[];
  allKnitters: Knitter[];
};

const AddModelKnitterToGroupModal: React.FC<Props> = ({
  orgId,
  groupTag,
  members,
  allKnitters,
  hide,
}) => {
  const memberIds = members.map((member) => member.id);

  const [addKnitterToGroup] = useAddKnitterToGroupMutation();
  const [removeKnitterFromGroup] = useRemoveKnitterFromGroupMutation();

  const allKnittersSorted = [...allKnitters];
  allKnittersSorted.sort((a, b) => (a.id < b.id ? -1 : 1));

  return (
    <div>
      <ul>
        {allKnitters.map((knitter) => (
          <li key={knitter.id}>
            {knitter.name}
            {memberIds.includes(knitter.id) ? (
              <button
                onClick={() => {
                  removeKnitterFromGroup({
                    orgId,
                    userId: knitter.id,
                    groupTag,
                  });
                }}
              >
                Ta bort
              </button>
            ) : (
              <button
                onClick={() => {
                  addKnitterToGroup({ orgId, userId: knitter.id, groupTag });
                }}
              >
                Legg til
              </button>
            )}
          </li>
        ))}
      </ul>
      <button onClick={hide}>Ferdig</button>
    </div>
  );
};

export default AddModelKnitterToGroupModal;
