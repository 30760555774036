import useHotkeys from "@reecelucas/react-use-hotkeys";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

import ColorPreview from "../../CommonComponents/ColorPreview";
import { Color, YarnColor } from "../../store/pattern";
import { RootState } from "../../store/store";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";

export type ColorElementType = {
  type: "color";
  children: Text[];
  ref: number;
  commentId?: string;
};

export const colorNameFromYarnColor = (color: YarnColor, ref: number) => {
  let displayName = "";
  if (!color) {
    displayName = `Farge ${ref + 1}`;
  } else if (!color.name) {
    displayName = `Farge ${ref + 1}`;
  } else if (color.name && color.yarn) {
    displayName = `${color.yarn.name} - ${color.name}`;
  } else if (color.name) {
    // Hex colors can now also have custom names
    displayName = `${color.name} (${color.hex})`;
  } else {
    displayName = "FORSVUNNET FARGE";
  }

  return displayName;
};

const ColorFromRef = (ref: number) => {
  const yarnColors: Color[] = useSelector(
    (state: RootState) =>
      state.pattern.meta.variants.find(
        (variant) => variant.id === state.displayState.activeVariant
      )?.colors ?? []
  );

  return yarnColors[ref] as YarnColor;
};

export const ColorSelect = ({
  closeSelector,
  editor,
  action,
  element,
}: ElementSelectorProps) => {
  const yarnColors: Color[] = useSelector(
    (state: RootState) =>
      state.pattern.meta.variants.find(
        (variant) => variant.id === state.displayState.activeVariant
      )?.colors ?? []
  );

  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  const [arrowIndex, setArrowIndex] = useState(0);

  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
    console.log(arrowIndex);
  });

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    setArrowIndex(
      arrowIndex + 1 === yarnColors.length
        ? yarnColors.length - 1
        : arrowIndex + 1
    );
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    if (variants.length > 0) {
      insertColor(arrowIndex);
    }
  });

  const insertColor = (ref: number) => {
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "color",
          children: [{ text: "" }],
          ref,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    } else {
      const path = element ? ReactEditor.findPath(editor, element) : undefined;
      Transforms.setNodes(editor, { ref }, { at: path });
    }

    closeSelector();
  };

  return (
    <>
      {yarnColors.map((color, index) => {
        const yarnCol = color as YarnColor;
        const i = index + 1;
        return (
          <div
            onMouseOver={() => setArrowIndex(index)}
            key={index}
            style={{ background: arrowIndex === index ? "#faf6f2" : "" }}
            className={`${styles.hoverable} ${styles.color}`}
            onClick={() => insertColor(index)}
          >
            <p style={{ width: "32px" }}>{i <= 9 ? `0${i}` : i} | </p>
            <ColorPreview inline color={yarnCol} />
            <b>{yarnCol.name || yarnCol.hex}</b>
          </div>
        );
      })}
    </>
  );
};

type ColorElementProps = {
  attributes: any;
  children: Text[];
  element: ColorElementType;
  onClick: (event: React.MouseEvent, element: ColorElementType) => void;
};

export const ColorElement = ({
  attributes,
  children,
  element,
  onClick,
}: ColorElementProps) => {
  const color = ColorFromRef(element.ref);

  return (
    <span
      {...attributes}
      className={`${styles.void} ${styles.basicPatternElement} ${styles.nonEditable}`}
      onClick={(event) => onClick(event, element)}
      contentEditable={false}
    >
      <ColorPreview inline color={color ?? { hex: "#ffffff" }} />
      <b>{colorNameFromYarnColor(color, element.ref)}</b>
      {/* https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
    </span>
  );
};
