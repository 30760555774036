import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

import useTableRelationIndex from "../utils/useTableRelationIndex";

import TableRelationList from "./TableRelationList";

import styles from "./TableRelationModal.module.css";

type PropType = {
  relationId: string;
  tableId: string;
  isNew?: boolean;
};

function TableRelationModal(props: PropType) {
  const { relationId, tableId, isNew } = props;

  const relation = useSelector(
    (state: RootState) => state.calculations.relations[relationId]
  );

  const tables = useSelector((state: RootState) => state.calculations.tables);

  const isTop =
    relation.top.findIndex((entry) => entry.tableId === tableId) !== -1;

  const relationIndex = useTableRelationIndex();
  const tablesWithTopRelation = Object.entries(relationIndex)
    .filter(([_, relationEntry]) => relationEntry.before !== null)
    .map(([tableId]) => tableId);
  const tablesWithBottomRelation = Object.entries(relationIndex)
    .filter(([_, relationEntry]) => relationEntry.after !== null)
    .map(([tableId]) => tableId);

  const selectableTopTables = Object.keys(tables)
    .filter((tableId) => !tablesWithTopRelation.includes(tableId))
    .filter(
      (tableId) =>
        relation.bottom.findIndex(
          (relation) => relation.tableId === tableId
        ) === -1
    );
  const selectableBottomTables = Object.keys(tables)
    .filter((tableId) => !tablesWithBottomRelation.includes(tableId))
    .filter(
      (tableId) =>
        relation.top.findIndex((relation) => relation.tableId === tableId) ===
        -1
    );

  const [open, setOpen] = useState(isNew ?? false);

  useEffect(() => {
    // In case the redux state update happens before the other local state update in parent.
    if (isNew && !open) {
      setOpen(true);
    }
  });

  return (
    <div className={styles.inlineContainer}>
      <button onClick={() => setOpen(true)} className={styles.button}>
        Rediger kobling
      </button>
      Koblet til{" "}
      {isTop
        ? relation.bottom.map((entry) => tables[entry.tableId].label).join(", ")
        : relation.top
            .map((entry) => tables[entry.tableId].label)
            .join(", ")}{" "}
      {open && (
        <div className={styles.container}>
          <div
            className={styles.background}
            onClick={() => setOpen(false)}
          ></div>
          <div className={styles.modal}>
            <h2>Tabellkobling</h2>
            <h3>Første rad</h3>
            <TableRelationList
              selectedReferences={relation.top}
              selectableTableIds={selectableTopTables}
              tables={tables}
              relationId={relationId}
              dividendMeasure={"first"}
            />
            <h3>Siste rad</h3>
            <TableRelationList
              selectedReferences={relation.bottom}
              selectableTableIds={selectableBottomTables}
              tables={tables}
              relationId={relationId}
              dividendMeasure={"last"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TableRelationModal;
