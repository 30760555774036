import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../store/store";
import { fetchMe, login } from "./LoginState";
import styles from "./Login.module.css";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import Modal from "../Modal";
import Auth from "../Auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loadUserCallback = useCallback(() => dispatch(fetchMe()), [dispatch]);

  useEffect(() => {
    loadUserCallback();
  }, [loadUserCallback]);

  const loginState = useSelector((state: RootState) => state.loginState);
  const [showLogin, setShowLogin] = useState(true);

  return !showLogin ? (
    <Navigate to={"/projects"} />
  ) : (
    <div>
      <Modal
        open={showLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <Auth
          handleClose={() => {
            setShowLogin(false);
          }}
        />
      </Modal>
      {/* <form
        className={styles.form}
        onSubmit={(event) => {
          event?.preventDefault();
          dispatch(login({ email, password }));
        }}
      >
        <label htmlFor="email" className={styles.label}>
          {t("email")}
        </label>
        <input
          name="email"
          id="email"
          className={styles.textInput}
          type="text"
          placeholder={t("email")}
          autoComplete="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <label htmlFor="password" className={styles.label}>
          {t("password")}
        </label>
        <input
          name="password"
          id="password"
          className={styles.textInput}
          type="password"
          placeholder={t("password")}
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
        />

        {loginState.loginAttempted && loginState.loginFailed && (
          <div>
            <p>
              Vi kunne ikke logge deg inn. Kan du dobbeltsjekke at brukernavnet
              og passordet stemmer?
            </p>
            <p>
              Du kan lage nytt passord{" "}
              <a
                target="_blank"
                href="https://woolit.no/oppdrag"
                rel="noreferrer"
              >
                her
              </a>{" "}
              (trykk på "Jeg har ikke passord").
            </p>
          </div>
        )}
        <button>{t("login")}</button>
      </form> */}
    </div>
  );
}

export default Login;
