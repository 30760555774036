import React from "react";
import { useEffect } from "react";
import {
  useTable,
  useSortBy,
  Column,
  useGroupBy,
  useExpanded,
  useRowState,
  CellProps,
  useGlobalFilter,
  useFilters,
} from "react-table";
import { Pattern, PatternOverview } from "../store/pattern";
import styles from "./ProjectTable.module.css";
import Folder from "../CommonComponents/Icons/Folder";
import Document from "../CommonComponents/Icons/Document";
import { useLocalStorage } from "./useLocalStorage";
import { useDebounce } from "../utils/useDebounce";
import GlobalFilter from "./GlobalFilter";
import StatusButton from "./StatusButton";
import { Link } from "react-router-dom";
import { Modal } from "../CommonComponents/Modal";
import { NewPatternModal } from "./NewPatternModal";
import { useModal } from "../CommonComponents/useModal";
import { NewPatternButton } from "./NewPatternButton";
import { useTranslation } from "react-i18next";
import { PatternOptionModal } from "./PatternOptionModal";
interface Props {
  columns: Array<Column<object>>;
  data: Array<object>;
  title: string;
  patterns: PatternOverview[];
  organizationId?: number;
}
type PropType = {
  patterns: PatternOverview[];
  title: string;
  organizationId?: number;
  // pattern: Pattern;
};

const Table: React.FC<Props> = ({
  patterns,
  columns,
  data,
  title,
  organizationId,
}) => {
  const [initialState, setInitialState] = useLocalStorage(`tableState}`, {});

  const { t } = useTranslation();

  const instance = useTable(
    {
      columns,
      data,
      initialState,
      autoResetExpanded: false,
    },
    useGlobalFilter,
    useRowState,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = instance;

  const debouncedState = useDebounce(state, 500);

  useEffect(() => {
    const { sortBy, filters, pageSize, columnResizing } = debouncedState;
    const val = {
      sortBy,
      groupBy: ["meta.collection"],
      filters,
      pageSize,
      manualGlobalFilter: "manual",
      columnResizing,
      hiddenColumns: ["title"],
    };
    setInitialState(val);
  }, [setInitialState, debouncedState]);

  const firstPageRows = rows;

  const { isShown, toggle } = useModal();
  const onConfirm = () => toggle();
  const onCancel = () => toggle();

  return (
    <>
      <div className={styles.titleContainer}>
        <h1> {title}</h1>
        <GlobalFilter
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        {organizationId && (
          <Link to={`/salesOverview/${organizationId}`}>
            <button>
              <p>{t("sales_overview")}</p>
            </button>
          </Link>
        )}

        {!organizationId && (
          <Link to="/salesOverview">
            <button>
              <p>{t("sales_overview")}</p>
            </button>
          </Link>
        )}

        <NewPatternButton showModal={toggle} />

        <Modal
          isShown={isShown}
          hide={toggle}
          headerText={t("new pattern")}
          modalContent={
            <NewPatternModal
              patterns={patterns}
              onConfirm={onConfirm}
              onCancel={onCancel}
              message=""
              options={instance.groupedRows}
              organizationId={organizationId}
            />
          }
        />
      </div>
      <table {...getTableProps()} className={styles.container}>
        <thead className={styles.headerRow}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th
                className={styles.headerSpacing}
                style={{ borderRight: "none" }}
              />
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  className={styles.headerCell}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                </th>
              ))}
              <th className={styles.headerSpacing}></th>
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                <td className={styles.dataSpacing}></td>

                {row.cells.map((cell, i) => {
                  return (
                    //if
                    // canExpand
                    // -first td (after spacer)
                    // -- use canExpand
                    // - else normal cell
                    <td
                      className={i === 0 ? styles.folderCell : styles.dataCell}
                      style={{
                        borderRight: !row.canExpand
                          ? i === 0
                            ? "1px solid #ddd8d5"
                            : ""
                          : row.isExpanded
                          ? i === 0
                            ? "1px solid #ddd8d5"
                            : ""
                          : "",
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.isGrouped ? (
                        // <>
                        <span
                          className={` ${styles.folder}
                            row.isExpanded
                              ? styles.expandedSpan
                              : styles.closedSpan
                          }`}
                          {...row.getToggleRowExpandedProps()}
                        >
                          <Folder />
                        </span>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render("Aggregated")
                      ) : cell.isPlaceholder ? (
                        <PatternOptionModal
                          {...(cell.row.original as { id: string })}
                        />
                      ) : (
                        // display title instead of collection
                        // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
                <td className={styles.dataSpacing} colSpan={4}>
                  &nbsp;
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </>
  );
};

function ProjectTable(props: PropType) {
  const { t } = useTranslation();
  let { patterns, title, organizationId } = props;
  const data = patterns;
  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: t("collections and patterns"),
        Cell: ({ row }: CellProps<Pattern>) => (
          <>
            {row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps()}
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  color: "var(--darkest-brown)",
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                }}
              >
                {row.values["meta.collection"] +
                  " (" +
                  row.subRows.length +
                  ") "}
              </span>
            ) : (
              <span
                style={{
                  fontSize: "18px",
                  color: "var(--darkest-brown)",
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                }}
              >
                <Link
                  className={styles.patternTitle}
                  to={`/edit/${row.values["id"]}/write`}
                >
                  <Document />
                  <p>
                    {row.values["title"]
                      ? row.values["title"]
                      : `Oppskrift uten tittel (${row.values.id})`}
                  </p>
                </Link>
              </span>
            )}
          </>
        ),
      },
      {
        Header: "",
        accessor: "meta.collection",
        aggregate: "count",
        // isGrouped: true,
        Aggregated: ({ value }: { value: number }) => `${value} patterns`,
      },
      {
        Header: "Tittel",
        id: "title", //*
        accessor: (d: any) => d.meta.title,
        Cell: ({ row }: CellProps<PatternOverview>) => (
          <>{row.canExpand ? "" : "hei " + row.values["title"]}</>
        ),
        // accessor: "meta.title",
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: t("owner"),
        accessor: "owner.firstName",
      },
      {
        Header: t("size", { count: 2 }),
        accessor: "meta.sizes",
        // Aggregated: ({ value }: { value: number }) => `${value} str`,
        Cell: ({ row }: CellProps<PatternOverview>) => {
          const sizes: string[] | null = row.values["meta.sizes"];
          return (
            <>
              {sizes
                ?.map((size, index) =>
                  index % 2 === 0 ? `${size}` : `(${size})`
                )
                .join(" ") ?? ""}
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "meta.status",
        Cell: ({ row }: CellProps<PatternOverview>) =>
          row.canExpand ? (
            `${row.subRows.length} ${t("pattern", {
              count: row.subRows.length,
            })}`
          ) : (
            <StatusButton
              status={row.values["meta.status"]}
              patternId={row.values["id"]}
            />
          ),
      },
    ],
    []
  );

  return (
    <div>
      <Table
        patterns={patterns}
        columns={columns}
        data={data}
        title={title}
        organizationId={organizationId}
      />
    </div>
  );
}

export default ProjectTable;
