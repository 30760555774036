import { Measure } from "../store/pattern";

export const removeColorFromAmounts = (
  amounts: { [key: string]: Measure },
  colorIndex: number
) => {
  const amountEntries = Object.entries(amounts);

  const updatedAmountEntries = amountEntries.map(([size, sizeAmounts]) => {
    const updatedSizeAmounts = Object.fromEntries(
      Object.entries(sizeAmounts)
        .filter(([id, _]) => parseInt(id) !== colorIndex)
        .map(([id, amount]) => {
          const updatedId = parseInt(id) > colorIndex ? parseInt(id) - 1 : id;
          return [updatedId, amount];
        })
    );
    return [size, updatedSizeAmounts];
  });

  return Object.fromEntries(updatedAmountEntries);
};
