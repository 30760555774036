// Clamp a value into a range:
// *  [lowerBound, upperBound] for lower <= upper
// *  [upperBound, lowerBound] for lower >  upper

function clamp(value: number, lowerBound: number, upperBound: number) {
  if (lowerBound > upperBound) {
    // I'd rather just best effort fix these than raise an error within a redux action
    // Might be an indication of logic errors elsewhere, though, so I'll warn just in case.
    console.warn(
      "utils.clamp: Your lower bound is larger than your upper bound, you might want to fix that in your code. I switched them on my end, but you may have larger problems"
    );
    [lowerBound, upperBound] = [upperBound, lowerBound];
  }

  if (value < lowerBound) {
    return lowerBound;
  }

  if (upperBound < value) {
    return upperBound;
  }

  return value;
}

export default clamp;
