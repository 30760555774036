import { Popover } from "@headlessui/react";
import React, { useState } from "react";
import { Info, Fill } from "../CommonComponents/Icons/Info";
import styles from "./InfoItem.module.css";

interface PropType {
  comment: string;
}

export const InfoItem: React.FC<PropType> = (props) => {
  const { comment } = props;
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Popover className={styles.popover}>
      {({ open }) => (
        <>
          <Popover.Button
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={styles.button}
          >
            <Info fill={open ? Fill.CONTRAST : Fill.BOLD} />
          </Popover.Button>
          {(open || hover) && <div className={styles.panel}>{comment}</div>}
        </>
      )}
    </Popover>
  );
};
