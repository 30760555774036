import { getOptionLabel } from "react-select/src/builtins";
import { Global } from "./static/global";

export async function httpRequest(
    url = "",
    data = {},
    method = "",
    retrieveMethod = (it: any) => it
) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        credentials: "include",
        headers: {
        "Content-Type": "application/json",
        },
        ...(Object.keys(data).length === 0 ? {} : { body: JSON.stringify(data) }), // body data type must match "Content-Type" header
    });
    return retrieveMethod(response);
}

//wip
export function Save(key: string, jsonData: any){
    if (Global.postedKey === key){
        // Skip POST after 1st attempt
        SaveUpdate(key, jsonData)
        return;
    }
    // POST and PUT at 1st attempt
    console.log("The next POST will likely return 500.")
    const url = `https://woolit.no/api/3d/${key}`
    httpRequest(url, jsonData, "POST", (it) =>
        it.text()
    ).then((_key) => {
        if (_key === undefined) {
            alert(`Feil ved opprettelse av modell, kontakt kundesenter eller skru av "modell"`);
            return;
        }
        SaveUpdate(key, jsonData)
    });
    Global.postedKey = key;
}

function SaveUpdate(key: string, jsonData: any){
    const url = `https://woolit.no/api/3d/${key}`
    httpRequest(url, jsonData, "PUT", (it) =>
        it.text()
    ).then((_key) => {
        if (_key === undefined) {
            alert(`Feil ved oppdatering av modell, kontakt kundesenter eller skru av "modell"`);
            return;
        }
    });
}

//wip
export function Load(key: string, run: any) {
    const url = `https://woolit.no/api/3d/${key}`
    httpRequest(url, {}, "GET", async (it) => {
        const body = await it.json();
        run(body)
        /* UPDATE THESE
        Global.setSizeIndex(load["size"] ?? 2);
        Global.setShirtTypeIndex(load["knittingMethod"] ?? 0);
        setModelFromPath(body["design"]);
        makeModelScene(colors);
        setLoadModel(true);
        props.setSelectedSweaterPart(undefined);
        */
    });
  }