import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  addMetadataVariant,
  setMetadataVariants,
} from "../../store/patternSlice";
import { YarnProfileVariant } from "../../store/pattern";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import styles from "./VariantList.module.css";
import DrawerVariant from "./DrawerVariant";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

interface PropType {
  variants: YarnProfileVariant[];
}

export const reorder = <T,>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [spliced] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, spliced);

  return result;
};

function VariantList(props: PropType) {
  const { variants } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <DragDropContext
        onDragEnd={(res) => {
          if (!res.destination) return;
          dispatch(
            setMetadataVariants({
              variants: reorder(
                variants,
                res.source.index,
                res.destination.index
              ),
            })
          );
        }}
      >
        <Droppable droppableId="yarnVariantDroppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.listContainer}
            >
              {variants.map((variant, i) => (
                <DrawerVariant key={variant.id} variant={variant} index={i} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <hr className={styles.spacer} />
      <button
        className={styles.new}
        onClick={() => dispatch(addMetadataVariant())}
      >
        {t("new color variant")}
      </button>
    </div>
  );
}

function AwareVariantList() {
  const variants = useSelector(
    (state: RootState) => state.pattern.meta.variants
  );

  return <VariantList variants={variants} />;
}

export default AwareVariantList;
export { VariantList };
