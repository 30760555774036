import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

import CalculationElementInput from "./CalculationElementInput";

import styles from "./Cell.module.css";

interface PropType {
  edgeId: string;
}

function EdgeCell(props: PropType) {
  const { edgeId } = props;

  const edge = useSelector(
    (state: RootState) => state.calculations.graph.edges[edgeId]
  );

  const edgeChangeElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.horizontalChangeElement]
  );

  const edgeFrequencyElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.frequencyElement]
  );

  const edgeVerticalElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.verticalDistanceElement]
  );

  return (
    <div className={styles.cell}>
      <div className={styles.element}>
        <CalculationElementInput
          elementId={edgeChangeElement.id}
          disabled={edge.locked}
        />
      </div>
      <div className={styles.element}>
        <CalculationElementInput
          elementId={edgeFrequencyElement.id}
          disabled={edge.locked}
        />
      </div>
      <div className={styles.element}>
        <CalculationElementInput
          elementId={edgeVerticalElement.id}
          disabled={edge.locked}
        />
      </div>
    </div>
  );
}

export default EdgeCell;
