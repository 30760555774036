import { useState } from "react";
import { useUpdateRegisteredInterestMutation } from "../../services/registerInterest";
import styles from "../Styles/Sections.module.css";

function EmailInputForm() {
  const [email, setEmail] = useState("");
  const [updateInterests, responseStatus] =
    useUpdateRegisteredInterestMutation();
  const [validEmail, setValidEmail] = useState(true);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (email === "" || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const sendInterest = (arg: { email: string }) => {
    if (!emailValidation()) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
      updateInterests({ email });
      setEmail("");
    }
  };

  return (
    <div style={{ padding: "30px" }}>
      <form
        className={styles.emailInputBox}
        onSubmit={(event) => {
          event?.preventDefault();
          sendInterest({ email });
        }}
      >
        <input
          className={styles.emailInput}
          id="emailInputField"
          placeholder="Din e-post addresse"
          type="text"
          value={email}
          autoComplete="email"
          onChange={(event) => setEmail(event.target.value)}
        ></input>
        <input
          type="submit"
          value="Få tidlig tilgang"
          className={styles.submitBtn}
        />
      </form>
      {!validEmail && (
        <div style={{ color: "#f27900" }}> Ikke gyldig epost-adresse. </div>
      )}
      {responseStatus.isSuccess && validEmail && (
        <div style={{ color: "#3d8036" }}>Vi har registrert din interesse!</div>
      )}
      {responseStatus.isError && (
        <div style={{ color: "#f27900" }}>
          Noe gikk galt, du har ikke blitt registrert.
        </div>
      )}
    </div>
  );
}

export default EmailInputForm;
