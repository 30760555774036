import React from "react";

interface PropType {
  color?: string;
}

function LockClosed(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29935 3.94488C3.29935 3.00484 3.55939 2.2973 3.99437 1.82893C4.42442 1.36587 5.08092 1.07666 6.00084 1.07666C6.92124 1.07666 7.57682 1.36528 8.00589 1.82734C8.43992 2.29475 8.69935 3.00141 8.69935 3.94194V5.93313H3.29935V3.94488ZM2.29935 5.93313V3.94488C2.29935 2.82601 2.61059 1.84944 3.26163 1.14842C3.9176 0.442101 4.86185 0.0766602 6.00084 0.0766602C7.13962 0.0766602 8.08328 0.441095 8.73868 1.14688C9.3891 1.84732 9.69935 2.8233 9.69935 3.94194V5.93313L10.8327 5.93313C11.1088 5.93313 11.3327 6.15699 11.3327 6.43313V12.8998C11.3327 13.1759 11.1088 13.3998 10.8327 13.3998H1.16602C0.889874 13.3998 0.666016 13.1759 0.666016 12.8998V6.43313C0.666016 6.15699 0.889873 5.93313 1.16602 5.93313L2.29935 5.93313Z"
        fill={color ?? "#33251A"}
      />
    </svg>
  );
}

export default LockClosed;
