import React from "react";
import { select } from "d3-selection";
import ReactDOMServer from "react-dom/server";
import { isDynamic, symbolLookup } from "../CommonComponents/Icons/lookup";
import {
  Color,
  DiagramData,
  DynamicGridSymbol,
  GridSymbol,
  isDynamicGridSymbol,
  YarnProfileVariant,
} from "../store/pattern";
import { DynamicSymbol } from "@iterate/woolit-components";

const findDynamicSymbol = (
  symbol: DynamicGridSymbol,
  symbolSquare: { height: number; width: number }
) => {
  const length = symbol.length || 2;

  return React.createElement(DynamicSymbol, {
    symbol: symbol.key,
    iconProps: {
      height: symbolSquare.height,
      width: length * symbolSquare.width,
      fill: "black",
    },
  });
};

const findSymbolMaxLength = (symbols: (GridSymbol | DynamicGridSymbol)[]) => {
  return symbols.reduce((acc, symbol) => {
    const length = "length" in symbol ? symbol.length || 2 : 1;
    return length > acc ? length : acc;
  }, 1);
};

const extractColorsAndSymbols = (
  diagram: DiagramData,
  allColors: Color[],
  allGridSymbols: (GridSymbol | DynamicGridSymbol)[]
) => {
  const symbols: GridSymbol[] = [];
  const colorIndexes: number[] = [];
  const gridSymbols: (GridSymbol | DynamicGridSymbol)[] = [];

  diagram.grid.forEach((row) => {
    row.forEach((cell) => {
      if (
        !symbols.find((symbol) => symbol.key === cell.symbol) &&
        cell.symbol !== "stitch"
      ) {
        const symbol = allGridSymbols.find(
          (symOjb) => cell.symbol === symOjb.key
        );
        if (symbol && !isDynamic(symbol.key)) {
          symbols.push(symbol as GridSymbol);
          gridSymbols.push(symbol);
        }
      }
      if (!colorIndexes.includes(cell.color) && cell.color !== -1) {
        colorIndexes.push(cell.color);
      }
    });
  });

  Object.values(diagram.dynamicSymbols ?? {}).forEach((data) => {
    const length = data.end.x - data.start.x + 1;
    const isIncluded = gridSymbols.find(
      (d) => d.key === data.symbol && (!d.length || d.length === length)
    );
    if (isIncluded) return;
    const { label: labelTemplate } = allGridSymbols.find(
      (d) => d.key === data.symbol
    ) ?? {
      label: "",
    };

    const isLabelDynamic =
      labelTemplate.includes("{%}") || labelTemplate.includes("{%2}");
    const label = labelTemplate
      .replaceAll("{%}", length.toString() ?? "")
      .replaceAll("{%2}", Math.round(length / 2).toString() ?? "");

    gridSymbols.push({
      label,
      key: data.symbol,
      length: isLabelDynamic ? length : null,
    });
  });

  const yarn = colorIndexes.sort().map((colorIndex) => {
    return allColors[colorIndex] || { name: "Ukjent", hex: "#000000" };
  });

  const sortedGridSymbols = gridSymbols.sort((a, b) => {
    if (!isDynamicGridSymbol(a) || !a.length) {
      return -1;
    }
    if (!isDynamicGridSymbol(b) || !b.length) {
      return 1;
    }
    return a.length > b.length ? 1 : -1;
  });

  return { yarn, gridSymbols: sortedGridSymbols };
};

export const generateDiagramLegendSvg = (
  diagram: DiagramData,
  allGridSymbols: (GridSymbol | DynamicGridSymbol)[],
  variant: YarnProfileVariant
) => {
  const body = select(window.document.getElementById("diagram-export-root"));

  const { yarn, gridSymbols } = extractColorsAndSymbols(
    diagram,
    variant.colors,
    allGridSymbols
  );

  const symbolSquare = { width: 22, height: 22 };
  const listSpacing = 5;
  const symbolTextSpacing = 5;
  const width = 800;
  const height = 800;

  const margin = 5;
  const widthInner = width - margin * 2;
  const heightInner = height - margin * 2;

  const whiteishColor = "#faf6f2";

  const symbolMaxLength = findSymbolMaxLength(gridSymbols);
  const leftMargin = (symbolMaxLength - 1) * symbolSquare.width;

  const svg = body
    .append("svg")
    .attr("width", `${width}`)
    .attr("height", `${height}`)
    .attr("xmlns", "http://www.w3.org/2000/svg");

  const legendList = svg
    .append("g")
    .attr("transform", `translate(${margin}, ${margin})`);

  yarn.forEach((color, index) => {
    legendList
      .append("rect")
      .attr("x", leftMargin)
      .attr("y", index * (symbolSquare.height + listSpacing))
      .attr("width", `${symbolSquare.width}`)
      .attr("height", `${symbolSquare.height}`)
      .attr("fill", color.hex)
      .attr("stroke", whiteishColor)
      .attr("stroke-width", "1");

    legendList
      .append("text")
      .text(color.name || `Farge ${index + 1}`)
      .attr("text-anchor", "start")
      .attr("x", leftMargin + symbolSquare.width + symbolTextSpacing)
      .attr("y", 16 + index * (symbolSquare.height + listSpacing))
      .attr("font-family", "Arial, Helvetica, sans-serif")
      .attr("font-size", "12px");
  });

  const gridSymbolsList = legendList
    .append("g")
    .attr(
      "transform",
      `translate(0, ${yarn.length * (symbolSquare.height + listSpacing)})`
    );

  gridSymbols.forEach((symbol, index) => {
    console.log("symbol", symbol);
    const icon = isDynamic(symbol.key)
      ? ReactDOMServer.renderToString(
          findDynamicSymbol(symbol as DynamicGridSymbol, symbolSquare)
        )
      : ReactDOMServer.renderToString(
          symbolLookup({
            key: symbol.key,
            props: {
              fill: "black",
              height: symbolSquare.height,
              width: symbolSquare.width,
            },
          })
        );

    // If length is present, it is dynamic symbol, fallback length of 2
    // If length is not present, it is a regular symbol, length of 1
    const length = "length" in symbol ? symbol.length || 2 : 1;
    const leftMarginForSymbol = leftMargin - (length - 1) * symbolSquare.width;

    gridSymbolsList
      .append("rect")
      .attr("x", leftMarginForSymbol)
      .attr("y", index * (symbolSquare.height + listSpacing))
      .attr("width", `${length * symbolSquare.width}`)
      .attr("height", `${symbolSquare.height}`)
      .attr("fill", "transparent")
      .attr("stroke", whiteishColor)
      .attr("stroke-width", "1");

    gridSymbolsList
      .append("g")
      .attr(
        "transform",
        `translate(${leftMarginForSymbol}, ${
          index * (symbolSquare.height + listSpacing)
        })`
      )
      .html(icon);

    gridSymbolsList
      .append("text")
      .text(symbol.label)
      .attr("text-anchor", "start")
      .attr("x", leftMargin + symbolSquare.width + symbolTextSpacing)
      .attr("y", 16 + index * (symbolSquare.height + listSpacing))
      .attr("font-family", "Arial, Helvetica, sans-serif")
      .attr("font-size", "12px");
  });

  const html = body.html().slice();
  body.selectAll("*").remove();

  return new Blob([html], { type: "image/svg+xml" });
};
