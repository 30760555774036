import { useState } from "react";
import { useSelector } from "react-redux";
import { Text, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { RootState } from "../../store/store";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./SizeFilteredString.module.css";

export type SizeFilteredStringElementType = {
  type: "sizeFilteredString";
  children: Text[];
  // sizeString should ideally be { [size: string]: string },
  // but that doesn't seem to work with Slate
  sizeString: string;
  commentId?: string;
};

export const SizeFilteredStringSelect = ({
  closeSelector,
  editor,
  action,
  element,
}: ElementSelectorProps) => {
  const sizes = useSelector((state: RootState) => {
    const activeSizes = state.displayState.activeSizes;
    const allSizes = state.displayState.sizeOrder;
    return activeSizes.length > 0 ? activeSizes : allSizes;
  });

  const sizeFilteredElement = element as
    | SizeFilteredStringElementType
    | undefined;

  const sizeString =
    sizeFilteredElement &&
    sizeFilteredElement.sizeString &&
    JSON.parse(sizeFilteredElement.sizeString);

  const [currentTexts, setCurrentTexts] = useState<{ [key: string]: string }>(
    sizeString ?? Object.assign({}, ...sizes.map((size) => ({ [size]: "" })))
  );

  const [active, setActive] = useState(sizes[0]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "sizeFilteredString",
          children: [{ text: "" }],
          sizeString: JSON.stringify(currentTexts),
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    } else {
      const path = element ? ReactEditor.findPath(editor, element) : undefined;
      Transforms.setNodes(
        editor,
        { sizeString: JSON.stringify(currentTexts) },
        { at: path }
      );
    }

    closeSelector();
  };

  return (
    <form onSubmit={handleFormSubmit} className={styles.container}>
      {sizes.map((size, i) => {
        const text = currentTexts[size];
        return (
          <div
            key={i}
            className={`${styles.size} ${active === size ? styles.active : ""}`}
          >
            <div className={styles.label}>{size}</div>
            <input
              autoFocus={i === 0 && action === "add"}
              className={styles.input}
              type="text"
              value={text}
              onFocus={() => setActive(size)}
              onChange={(e) =>
                setCurrentTexts({ ...currentTexts, [size]: e.target.value })
              }
            />
          </div>
        );
      })}
      <button className={styles.button}>Sett inn</button>
    </form>
  );
};

type SizeFilteredStringElementProps = {
  attributes: any;
  children: Text[];
  element: SizeFilteredStringElementType;
  onClick: (
    event: React.MouseEvent,
    element: SizeFilteredStringElementType
  ) => void;
};

export const SizeFilteredStringElement = ({
  attributes,
  children,
  element,
  onClick,
}: SizeFilteredStringElementProps) => {
  const activeSize = useSelector(
    (state: RootState) => state.displayState.activeSizes
  );

  const allSizes = useSelector(
    (state: RootState) => state.displayState.sizeOrder
  );

  const sizes = activeSize.length > 0 ? activeSize : allSizes;

  const text = JSON.parse(element.sizeString);

  return (
    <span
      {...attributes}
      className={`${styles.void} ${styles.basicPatternElement} ${styles.nonEditable}`}
      contentEditable={false}
      onClick={(event) => onClick(event, element)}
    >
      {sizes
        .map((size, index) =>
          index % 2 ? `(${text[size] ?? "TOM"})` : text[size] ?? "TOM"
        )
        .join(" ")}
      {children}
    </span>
  );
};
