import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources: Resource = {
  en: {
    translation: {
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm password",
      newsletter: "I want to receive newsletter emails.",
      login: "Log in",
      loginFailure: "Wrong email or password",
      successfulLogin:"You have successfully logged in",
      logout: "Log out",
      "new pattern": "New pattern",
      pattern_one: "pattern",
      pattern_other: "patterns",
      menu: "Menu",
      project_one: "Project",
      project_other: "Projects",
      organization_one: "Organization",
      organization_other: "Organizations",
      needle_one: "Needle",
      needle_other: "Needles",
      "suggested needles_one": "Suggested needle",
      "suggested needles_other": "Suggested needles",
      "choose variant": "Choose variant",
      comments: "Comments",
      write: "Write",
      diagram: "Diagram",
      calculate: "Calculate",
      information: "Information",
      new: "New",
      add_size: "Add size",
      search: "Search",
      "collections and patterns": "Collections and patterns",
      owner: "Owner",
      size_one: "Size",
      size_other: "Sizes",
      designer: "Designer",
      title: "Title",
      create: "Create",
      cancel: "Cancel",
      design: "Design",
      collection: "Collection",
      "use template": "Use template",
      "empty pattern": "No template",
      "my organization_one": "My organization",
      "my organization_other": "My organizations",
      "start date": "Start date",
      ID: "ID",
      "color variant_one": "Color variant",
      "color variant_other": "Color variants",
      "new color variant": "New color variant",
      "delete color variant": "Remove color variant",
      "new color": "New color",
      Status: "Status",
      deadline: "Deadline",
      "test knit": "Test knits",
      "on time": "En route",
      delayed: "Delayed",
      "waiting for knitter": "Waiting for knitter",
      "knitter approved": "Knitter approved",
      "yarn received": "Yarn received",
      "sent back": "Sent back",
      received: "Received",
      name: "Name",
      add: "Add",
      "price ore": "Price (Øre NOK)",
      "new price": "New price",
      "new group": "New group",
      "add knitter": "Add knitter",
      "add knitter to org": "Add knitter to {{organization}}",
      "add named knitter to org":
        "Add {{knitter}} as model knitter for {{organization}}",
      "add new price": "Add new price",
      "test knitters in org": "Test knitters for {{organization}}",
      "price list": "Prices",
      "add group": "Add group",
      "price error": {
        name: "A price with that name already exists",
        amount: "A price must have an name and an amount larger than zero",
      },
      "not a previous user":
        "The provided email is not connected to a user. Fill out to add new user.",
      form: {
        "given-name": "Given name",
        "family-name": "Family name",
        adress: "Adress",
        "zip-code": "Postal code",
        postplace: "Post place",
        phonenr: "Phone number",
      },
      navigation: {
        previous: "Previous",
        next: "Next",
        edit: "Edit",
        delete: "Remove",
        close: "Close",
        save: "Save",
        options: "Options",
        cancel: "Cancel",
        "save changes": "Save changes",
      },
      "add group to org": "Add test knitter group to {{organization}}",
      date: {
        "updated at": "Last edited on: {{val, datetime}}",
      },
      datadrawer: {
        "pattern title": "Pattern title",
        "pattern description": "Description of the pattern",
        form: "Form*",
        "freeform": "Freeform",
        "sweater":  "Sweater",
        "knit method": "Knit method*",
        "raglan": "Raglan",
        "stitched sleeves": "Stitched sleeves",
        "overwrite model": "This will overwrite your current model, are you sure?",
        roles: "Roles",
        "roles description": "Noteworthy contributoros to the pattern",
        "clothing info": "Clothing information",
        "cloting info description": "Attributes of the pattern",
        media: "Media",
        "add media": "Add media",
        "upload media": "Upload media",
        "upload media from computer": "Upload media from computer",
        "from library": "From library",
        "knit along": "Knit along",
        "knit along description": "Is this a knit along?",
      },
      terms: {
        gauge: "Gauge",
        width: "Width",
        height: "Height",
        stitch_one: "Stitch",
        stitch_other: "Stitches",
        round_one: "Round",
        round_other: "Rounds",
        needles: "Suggested needles",
        yarn: "Yarn",
        "yarn consumption": "Yarn consumption",
      },
      tooltips: {
        "gauge height":
          "Set to 0 to not show height gauge in the displayed pattern",
        "delete variant": "Can not delete active color variant",
        "delete confirm": "Are you sure you want to delete?",
      },
      "choose yarn": "Choose yarn",
      draw: {
        top: "Top",
        bottom: "Bottom",
        left: "Left",
        right: "Right",
        "add symbol": "Add symbol",
        "edit symbol": "Edit symbol",
        "remove symbol": "Delete symbol",
        repeat: "Repeat",
        note: "Note",
        "note for sizes": "Note is applicable for sizes",
        placement: "Placement",
        "create diagram": "Create new diagram",
        new_diagram: "New diagram",
        my_diagrams: "My diagrams",
        grid: "Grid",
        "model": "Model",
        "knit together": "Knit together",
        view: "View",
        "symbol name": "Symbol name",
        menu: {
          options: "Options",
          "fill area": "Fill area",
          "mirror horizontally": "Mirror horizontally",
          "mirror vertically": "Mirror vertically",
          rotate: "Rotate",
          copy: "Copy",
          cut: "Cut",
          "empty area": "Clear area",
          row: "Row",
          column: "Column",
          paste: "Paste",
          "empty cell_one": "Clear cell",
          "empty cell_other": "Clear cells",
          fill: "Fill",
          "new row above": "New row above",
          "new row under": "New row under",
          "remove row": "Remove row",
          "add or remove line above or below": "{{action}} line {{placement}}",
          "new column right": "New column to the right",
          "new column left": "New column to the left",
          "remove column": "Slett kolonne",
          insert: "Insert",
          remove: "Remove",
          "to the right": "to the right",
          "to the left": "to the left",
          above: "over",
          below: "under",
        },
      },
      "show larger": "Show larger",
      "show more": "Show more",
      "show less": "Show less",
      reset: "reset",
      errors: {
        general: "Something went wrong",
        sentry_message: "The error has been logged",
      },
      writer: {
        "slash command prompt": `Type "/" for optons`,
        heading: "Heading",
        menu: {
          insert: "Insert",
          text: "Text",
          heading: "Heading",
          subheading: "Subheading",
          calculation: "Calculation",
          needle: "Needle",
          color: "Color",
          diagram: "Diagram",
          "diagram ref": "Reference to diagram",
          "size based text": "size dependent text",
          media: "Media",
          info: "Info",
        },
      },
      "preview pattern": "Preview pattern",
      "edit pattern": "Edit pattern",
      "my profile": "My profile",
      "gauge needle explanation": "Needle for gauge",
      export: {
        export: "Export",
        "export pattern": "Export pattern",
        "export pattern as": "Export pattern as",
        "export pattern as docx": "Export pattern as .docx",
        "export pattern as txt": "Export pattern as .docx",
      },
      loading: "Loading...",
      "delete pattern": "Delete pattern",
      "remove pattern from shop": "Remove pattern from shop",
      sales_overview: "Sales overview",
      my_patterns: "My pattern collection",
      draft: "Draft",
      publish: "Publish",
      diagram_name: "Diagram name",
      write_designer: "Name designer",
      role_name: "Role name",
      role_owner: "Role owner",
      name_design: "Design name",
      add_role: "Add a role",
      private: "Private",
      value: "Value",
      registerToWoolit: "Create a Woolit account",
      registerUser: "Register user",
      register: "Register new user",
      noWoolitAccount: "You don't have Woolit account?",
      haveWoolitAccount: "You already have Woolit account?",
      loginWoolit: "Log in with your Woolit account.",
      goToWDS:"Go to Woolit Design Studio Pro ➚",
      goToWDS3D:"Go to Woolit Design Studio ➚",
      or: "Or",
    },
  },
  no: {
    translation: {
      email: "E-post",
      password: "Passord",
      confirmPassword: "Bekreft passord",
      loginFailure: "Epost eller passord er feil.",
      newsletter: "Jeg ønsker oppdateringer på epost.",
      login: "Logg inn",
      successfulLogin:"Du ble logget inn!",
      logout: "Logg ut",
      "new pattern": "Ny oppskrift",
      pattern_one: "oppskrift",
      pattern_other: "oppskrifter",
      menu: "Meny",
      cancel: "Avbryt",
      project_one: "Prosjekt",
      project_other: "Prosjekter",
      organization_one: "Organisasjon",
      organization_other: "Organisasjoner",
      needle_one: "Pinne",
      needle_other: "Pinner",
      "suggested needles_one": "Veiledende pinnevalg",
      "suggested needles_other": "Veiledende pinnevalg",
      "choose variant": "Velg variant",
      comments: "Kommentarer",
      write: "Skrive",
      diagram: "Tegne",
      calculate: "Beregne",
      information: "Informasjon",
      new: "Ny",
      add_size: "Legg til størrelse",
      search: "Søk",
      "collections and patterns": "Kolleksjon- og oppskriftsnavn",
      owner: "Eier",
      size_one: "Størrelse",
      size_other: "Størrelser",
      create: "Opprett",
      designer: "Designer",
      title: "Tittel",
      design: "Design",
      collection: "Kolleksjon",
      "use template": "Bruk mal",
      "empty pattern": "Tom oppskrift",
      "my organization_one": "Min organisasjon",
      "my organization_other": "Mine organisasjoner",
      "start date": "Startdato",
      ID: "ID",
      "color variant_one": "Fargevariant",
      "color variant_other": "Fargevarianter",
      "new color variant": "Ny fargevariant",
      "delete color variant": "Slett fargevariant",
      "new color": "Lag ny farge",
      Status: "Status",
      deadline: "Frist",
      "test knit": "Modellstrikk",
      "on time": "I rute",
      delayed: "Forsinket",
      "waiting for knitter": "Venter på strikker",
      "knitter approved": "Strikker godkjent",
      "yarn received": "Garn mottatt",
      "sent back": "Sendt tilbake",
      received: "Mottatt",
      name: "Navn",
      add: "Legg til",
      "price ore": "Pris (øre)",
      "new price": "Ny pris",
      "new group": "Ny gruppe",
      "add knitter": "Legg til strikker",
      "add knitter to org": "Legg til strikker i {{organization}}",
      "add group to org": "Legg til modellstrikkergruppe i {{organization}}",
      "add named knitter to org":
        "Legg til {{knitter}} som modellstrikker i {{organization}}",
      "add new price": "Legg til ny pris",
      "test knitters in org": "Modellstrikkere i {{organization}}",
      "price list": "Prisliste",
      "price error": {
        name: "En pris med dette navnet eksisterer allerede.",
        amount: "En pris må ha et navn og pris større enn null.",
      },
      "not a previous user":
        "Bruker eksisterer ikke fra før av. Fyll ut brukerinformasjon.",
      form: {
        "given-name": "Fornavn",
        "family-name": "Etternavn",
        adress: "Adresse",
        "zip-code": "Postnummer",
        postplace: "Poststed",
        phonenr: "Telefonnummer",
      },
      navigation: {
        previous: "Forrige",
        next: "Neste",
        edit: "Rediger",
        delete: "Fjern",
        close: "Lukk",
        save: "Lagre",
        options: "Valg",
        cancel: "Avbryt",
        "save changes": "Lagre endringer",
      },
      "add group": "Legg til gruppe",
      date: {
        "updated at": "Sist oppdatert: {{val, datetime}}",
      },
      datadrawer: {
        "pattern title": "Oppskriftens tittel",
        "pattern description": "Beskrivelse av plagget",
        form: "Form*",
        "freeform": "Fri form",
        "sweater":  "Genser",
        "knit method": "Strikke metode*",
        "raglan": "Raglan",
        "stitched sleeves": "Isydd",
        "overwrite model": "Dette vil overskrive modellen din, er du sikker?",
        roles: "Roller",
        "roles description": "Bidragsytere til oppskriften",
        "clothing info": "Plagginformasjon",
        "cloting info description": "Generell informasjon om plagget",
        media: "Media",
        "add media": "Legg til media",
        "upload media": "Last opp media",
        "upload media from computer": "Last opp media fra datamaskinen",
        "from library": "Fra bibliotek",
        "delete image": "Ja, slett",
        "knit along": "Samstrikk",
        "knit along description": "Er dette et samstrikk?",
      },
      terms: {
        gauge: "Strikkefasthet",
        width: "Bredde",
        height: "Høyde",
        stitch_one: "Maske",
        stitch_other: "Masker",
        round_one: "Omgang",
        round_other: "Omganger",
        needles: "Pinneforslag",
        yarn: "Garn",
        "yarn consumption": "Garnforbruk",
      },
      tooltips: {
        "gauge height":
          "Sett 0 for å ikke vise i høyde mål i den ferdige oppskriften",
        "delete variant": "Kan ikke fjerne aktiv fargevariant",
        "delete confirm": "Er du sikker på at du vil slette?",
      },
      "choose yarn": "Velg garn",
      draw: {
        top: "Topp",
        bottom: "Bunn",
        left: "Venstre",
        right: "Høyre",
        "add symbol": "Legg til symbol",
        "edit symbol": "Rediger symbol",
        "remove symbol": "Slett symbol",
        repeat: "Repeter",
        note: "Merknad",
        "note for sizes": "",
        placement: "Plassering",
        "create diagram": "Opprett diagram",
        new_diagram: "Nytt diagram",
        my_diagrams: "Mine diagrammer",
        grid: "Rutenett",
        "model": "Modell",
        "knit together": "Strikk sammen",
        view: "Se",
        "symbol name": "Symbolnavn",
        menu: {
          options: "Valg",
          "fill area": "Fyll markering",
          "mirror horizontally": "Speil horisontalt",
          "mirror vertically": "Speil vertikalt",
          rotate: "Rotér",
          copy: "Kopier",
          cut: "Klipp ut",
          "empty area": "Tøm markering",
          row: "Rad",
          column: "Kolonne",
          paste: "Lim inn",
          "empty cell_one": "Tøm celle",
          "empty cell_other": "Tøm celler",
          fill: "Fyll",
          "new row above": "Ny rad over",
          "new row under": "Ny rad under",
          "remove row": "Fjern rad",
          "add or remove line above or below": "{{action}} linje {{placement}}",
          "new column right": "Ny kolonne til høyre",
          "new column left": "Ny kolonne til venstre",
          "remove column": "Slett kolonne",
          insert: "Sett inn",
          remove: "Fjern",
          "to the right": "til høyre",
          "to the left": "til vestre",
          above: "over",
          below: "under",
          "edit colors": "Endre farger",
          "choose cell": "Velge celle",
          eraser: "Viskelær",
          undo: "Angre",
          redo: "Gjør om",
          zoomin: "Zoom inn",
          zoomout: "Zoom ut",
        },
      },
      "show larger": "Vis større",
      "show more": "Vis mer",
      "show less": "Vis mindre",
      reset: "Prøv igjen",
      errors: {
        general: "Noe gikk galt",
        sentry_message:
          "Feilen er blitt logget. Ta kontakt dersom feilen vedvarer",
      },
      writer: {
        "slash command prompt": `Skriv "/" for valg`,
        heading: "Overskrift",
        menu: {
          insert: "Legg til",
          text: "Tekst",
          heading: "Overskrift",
          subheading: "Underoverskrift",
          calculation: "Beregning",
          needle: "Pinne",
          color: "Farge",
          diagram: "Diagram",
          "diagram ref": "Diagramreferanse",
          "size based text": "Tekst etter størrelse",
          media: "Media",
          info: "Info",
        },
      },
      "preview pattern": "Foråndsvis oppskrift",
      "edit pattern": "Rediger oppskrift",
      "my profile": "Min profil",
      "gauge needle explanation": "Pinne for strikkefasthet",
      export: {
        export: "Eksporter",
        "export pattern": "Eksporter oppskrift",
        "export pattern as": "Eksporter oppskrift som",
        "export pattern as docx": "Eksporter oppskrift som .docx",
        "export pattern as txt": "Eksporter oppskrift som .txt",
      },
      loading: "Laster inn...",
      "delete pattern": "Slett oppskrift",
      "remove pattern from shop": "Ta bort oppskrift fra butikken",
      sales_overview: "Salgsoversikt",
      my_patterns: "Mine oppskriftskolleksjoner",
      draft: "Utkast",
      publish: "Publiser",
      diagram_name: "Diagramnavn",
      write_designer: "Oppgi designer",
      role_name: "Rollenavn",
      role_owner: "Rolleinnehaver",
      name_design: "Oppgi design",
      add_role: "Legg til rolle",
      private: "Privat",
      value: "Verdi",
      registerToWoolit: "Registrer en Woolit-konto",
      registerUser: "Registrer bruker",
      register: "Registrer deg",
      noWoolitAccount: "Har du ikke en Woolit-konto?",
      haveWoolitAccount: "Har du allerede en Woolit-konto?",
      loginWoolit: "Logg inn med din Woolit-konto.",
      goToWDS:"Gå til Woolit Design Studio Pro ➚",
      goToWDS3D:"Gå til Woolit Design Studio ➚",
      or: "Eller",

    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "no",
    supportedLngs: ["no", "en"],
    interpolation: { escapeValue: false },
  });

export default i18n;
