import React, { useEffect, useState } from "react";

import styles from "./YarnVariantListItem.module.css";

import { YarnProfileVariant, OnlyColor, YarnColor } from "../store/pattern";
import { useDispatch, useSelector } from "react-redux";
import { setActiveVariant } from "./displayState";
import Button from "./Components/Button";
import ImageAsColorPreview from "../CommonComponents/ImageAsColorPreview";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import { ColorList } from "./ColorList";
import { RootState } from "../store/store";

interface PropTypes {
  variant: YarnProfileVariant;
  active: boolean;
  activeSizes: string[];
}

// TODO: Add support for different sizes
function constructStoreUrl(
  variant: YarnProfileVariant,
  customColors: (number | null)[],
  activeSize: string
) {
  const query = variant.colors.map((color, i) => {
    const amount = customColors[i] ?? variant.amounts[activeSize]?.[i];
    if (color.sku !== null) {
      return `${color.yarn.sku};${color.sku}:${amount}`;
    } else {
      return "";
    }
  });
  return query.join("+");
}

function YarnVariantListItem(props: PropTypes) {
  const { variant, active, activeSizes } = props;

  const customColors = useSelector(
    (state: RootState) => state.displayState.customAmounts
  );

  const [shopURL, setShopURL] = useState(
    constructStoreUrl(variant, customColors, activeSizes[0])
  );

  useEffect(() => {
    setShopURL(constructStoreUrl(variant, customColors, activeSizes[0]));
  }, [activeSizes, customColors]);

  let colorImages = [];
  for (let color of variant.colors) {
    colorImages.push(
      <div
        key={
          color.sku
            ? `${color.yarn.sku}-${color.sku}`
            : `nonYarnColor-${(color as OnlyColor).hex.slice(1)}`
        }
        className={styles.imageContainer}
      >
        {color.hex ? (
          <div
            className={styles.colorPreview}
            style={{ backgroundColor: color.hex }}
          />
        ) : (
          <ImageAsColorPreview color={color as YarnColor} />
        )}
      </div>
    );
  }

  const dispatch = useDispatch();

  const renderShopText = () => {
    if (activeSizes.length === 0) {
      return (
        <>
          <p>Velg en størrelse</p>
          <div>
            <Button active>Strikk til meg</Button>
            <Button disabled>Bestill garnpakke</Button>
          </div>
        </>
      );
    } else if (activeSizes.length > 1) {
      return (
        <>
          <p>Velg maks en størrelse</p>
          <div>
            <Button active>Strikk til meg</Button>
            <Button disabled>Bestill garnpakke</Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p>Kjøp valgt garn i vår nettbutikk </p>
          <div>
            <Button active>Strikk til meg</Button>
            <Button
              target={"_blank"}
              href={`https://woolit.no/butikk?yarn=${shopURL}`}
            >
              Bestill garnpakke
            </Button>
          </div>
        </>
      );
    }
  };

  if (active) {
    return (
      <div className={`${styles.variantListItem} ${styles.active}`}>
        <div className={styles.variantInformation}>
          <div className={styles.variantColors}>{colorImages}</div>
          <h3 className={styles.variantTitle}>{variant.name}</h3>
          <TriangleUpIcon />
        </div>
        <ColorList variant={variant} activeSizes={activeSizes} />
        <div className={styles.footer}>{renderShopText()}</div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => dispatch(setActiveVariant(variant.id))}
        className={`${styles.variantListItem} ${styles.inactive}`}
      >
        <div className={styles.variantInformation}>
          <div className={styles.variantColors}>{colorImages}</div>
          <h3 className={styles.variantTitle}>{variant.name}</h3>
          <TriangleDownIcon />
        </div>
      </div>
    );
  }
}

export default YarnVariantListItem;
