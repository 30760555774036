import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { adminFetchAllYarn, adminGenerateAllHexes } from "../store/adminSlice";

import YarnSection from "./YarnSection";

import styles from "./Yarn.module.css";

function YarnAdmin() {
  const { remote: yarn } = useSelector((state: RootState) => state.admin.yarn);

  const dispatch = useDispatch();

  const fetchYarn = useCallback(() => {
    dispatch(adminFetchAllYarn());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(yarn).length === 0) {
      fetchYarn();
    }
  }, [yarn, fetchYarn]);

  const yarnWithoutHex = Object.values(yarn)
    .map((yarn) => {
      return {
        ...yarn,
        colors: yarn.colors.filter((color) => color.hex === null),
      };
    })
    .filter((yarn) => yarn.colors.length !== 0)
    .sort((yarn1, yarn2) => {
      if (yarn1.name > yarn2.name) {
        return 1;
      } else if (yarn1.name < yarn2.name) {
        return -1;
      }
      return 0;
    });

  return (
    <div>
      <h3 className={styles.sectionTitle}>Yarn colors without hex</h3>
      <div className={styles.buttonRow}>
        <button
          className={styles.warningButton}
          onClick={() => dispatch(adminGenerateAllHexes())}
        >
          Generate hexes for all yarn (VERY SLOW – click once and be patient,
          please)
        </button>
      </div>
      <YarnSection yarnList={yarnWithoutHex} />
      <hr className={styles.spacer} />
      <h3 className={styles.sectionTitle}>All yarn colors</h3>
      <YarnSection
        yarnList={Object.values(yarn).sort((yarn1, yarn2) => {
          if (yarn1.name > yarn2.name) {
            return 1;
          } else if (yarn1.name < yarn2.name) {
            return -1;
          }
          return 0;
        })}
      />
    </div>
  );
}

export default YarnAdmin;
