import React from "react";
import * as d3 from "d3";

export const useD3 = (renderDiagram, dependency1) => {
  const ref = React.useRef();

  React.useEffect(() => {
    console.log("SVGD3");
    renderDiagram(d3.select(ref.current));
    return () => {};
  }, dependency1);
  return ref;
};
