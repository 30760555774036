import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Color } from "../store/pattern";
import {
  addSizeCategorySize,
  addSizeToState,
  addVariant,
  editSizeCategorySize,
  fetchPatternById,
  removeSizeFromState,
  setCategory,
} from "../store/patternSlice";
interface displayStateShape {
  activeVariant: string;
  activeSizes: string[];
  sizeOrder: string[];
  showShortened: boolean;
  showSymbols: boolean;
  customAmounts: Array<number | null>;
}
const initialState: displayStateShape = {
  activeVariant: "",
  activeSizes: [],
  sizeOrder: [],
  showShortened: false,
  showSymbols: false,
  customAmounts: [],
};
const displayStateSlice = createSlice({
  name: "DisplayState",
  initialState,
  reducers: {
    reset(_state) {
      return initialState;
    },
    toggleSize(state, action): displayStateShape {
      const { activeSizes } = state;
      const sizeToToggle = action.payload;
      if (activeSizes.includes(sizeToToggle)) {
        return {
          ...state,
          activeSizes: activeSizes.filter((size) => size !== sizeToToggle),
          // Reset custom amounts when unselecting size
          customAmounts: state.customAmounts.map((item) => null),
        };
      } else {
        const unorderedNewActiveSizes = [sizeToToggle, ...activeSizes];
        return {
          ...state,
          activeSizes: state.sizeOrder.filter((size) =>
            unorderedNewActiveSizes.includes(size)
          ),
        };
      }
    },
    setSizeOrder(state, action: PayloadAction<string[]>) {
      state.sizeOrder = action.payload;
    },
    toggleShortened(state): displayStateShape {
      return {
        ...state,
        showShortened: !state.showShortened,
      };
    },
    setActiveVariant(state, action): displayStateShape {
      return {
        ...state,
        activeVariant: action.payload,
      };
    },
    setCustomAmount(state, action): displayStateShape {
      return {
        ...state,
        customAmounts: action.payload,
      };
    },
  },
  extraReducers: {
    // Populates the active variant when a pattern is successfully fetched from API
    [fetchPatternById.fulfilled.type](state, action) {
      const activeVariant = action.payload.meta.variants?.[0];
      return {
        ...state,
        activeVariant: activeVariant ? activeVariant.id : "",
        customAmounts: activeVariant
          ? activeVariant.colors.map((_: Color) => null)
          : [],
        sizeOrder: action.payload.meta.sizes,
      };
    },
    [addVariant.type](state, action) {
      state.activeVariant = action.payload.id;
    },
    [editSizeCategorySize.fulfilled.type](state, action) {
      const { value = undefined, oldValue } = action.payload;
      if (oldValue === value) return;

      const idxOf = state.sizeOrder.indexOf(oldValue);

      if (idxOf === -1) {
        // changing (renaming or deleting) size that is not selected,
        // so it is not in sizeOrder list
        return;
      }

      if (value === undefined) {
        // Remove entry
        state.sizeOrder.splice(idxOf, 1);
      } else {
        // Update any active sizes
        state.sizeOrder.splice(idxOf, 1, value);
      }
    },
    [addSizeToState.type](state, action) {
      const { updatedSizes } = action.payload;
      state.sizeOrder = updatedSizes;
      return state;
    },
    [removeSizeFromState.type](state, action) {
      const { size: sizeToRemove } = action.payload;
      state.sizeOrder = state.sizeOrder.filter((size) => size !== sizeToRemove);
    },
    [setCategory.fulfilled.type](state, _action) {
      state.sizeOrder = [];
    },
  },
});
export { initialState, displayStateSlice };
export const {
  reset,
  toggleSize,
  toggleShortened,
  setActiveVariant,
  setCustomAmount,
  setSizeOrder,
} = displayStateSlice.actions;
