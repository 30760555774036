import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import Button from "../ISO/Components/Button";
import { YarnState } from "../services/yarnApi";
import { Color, YarnColor } from "../store/pattern";
import { colorFromApiColor } from "../Metadata/Components/YarnSelectorColor";
import { Modal } from "./Modal";
import styles from "./YarnColorPicker.module.css";

interface PropType {
  onChangeComplete: (col: Color) => void;
  color: Color;
  toggle: () => void;
  isShown: boolean;
  index: number;
  yarn: YarnState | undefined;
}

function YarnColorPicker(props: PropType) {
  const { onChangeComplete, color, isShown, toggle, index, yarn } = props;

  const isYarnColor = color.sku && color.sku !== null;

  const [col, setCol] = useState<string>(color.hex ?? "#fff");
  const [customName, setCustomName] = useState(
    color.name ?? `Farge ${index + 1}`
  );
  const [inputCol, setInputCol] = useState(color.hex ?? "#fff");
  const [newYarnColor, setNewYarnColor] = useState<YarnColor | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (inputCol !== col) {
      setInputCol(col);
    }
  }, [col]);

  const isValidHex = (hexCandidate: string): boolean => {
    if (hexCandidate.startsWith("#")) {
      const [, code] = hexCandidate.split("#");
      const h = parseInt(code, 16);
      return h.toString(16) === code;
    }
    return false;
  };

  const [persistYarnData, setPersistYarnData] = useState<boolean>(true);

  return (
    <Modal
      isShown={isShown}
      hide={toggle}
      headerText={
        color.name ? `Juster: ${color.name}` : `Endre: farge ${index + 1}`
      }
      modalContent={
        <>
          <div className={styles.modalContent}>
            <HexColorPicker color={col} onChange={setCol} />
            <div className={styles.inputContent}>
              <div>
                <label>Visningsnavn: </label>
                <input
                  value={customName}
                  onChange={(e) => setCustomName(e.target.value)}
                />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <label style={{ marginRight: 8 }}>Verdi:</label>
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      background: col,
                      marginRight: 8,
                    }}
                  />
                  <input
                    type="text"
                    value={inputCol}
                    onChange={(e) => setInputCol(e.target.value)}
                    onBlur={(e) => {
                      if (isValidHex(e.target.value)) {
                        setCol(e.target.value);
                      } else {
                        setInputCol("#");
                      }
                    }}
                  />
                </div>
              </div>
              {isYarnColor && yarn && yarn[(color as YarnColor).yarn.sku] ? (
                <div style={{ marginTop: 8 }}>
                  <label>Bevar garndata:</label>
                  <input
                    type="checkbox"
                    checked={persistYarnData}
                    onChange={() => setPersistYarnData(!persistYarnData)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {isYarnColor && yarn && (
            <div style={{ marginTop: 24 }}>
              {yarn[(color as YarnColor).yarn.sku] && (
                <>
                  <p style={{ paddingLeft: 4 }}>
                    Endre til andre farger av garn:{" "}
                    <b>{yarn[(color as YarnColor).yarn.sku].name}</b>
                  </p>

                  <div className={styles.colorOptionsContainer}>
                    {yarn[(color as YarnColor).yarn.sku].colors.map((c, i) => (
                      <button
                        key={i}
                        onClick={() => {
                          setCol(c.hex ?? "#fff");
                          setCustomName(c.color_tint);
                          const newColor = colorFromApiColor(
                            c,
                            yarn[(color as YarnColor).yarn.sku]
                          );
                          setNewYarnColor(newColor as YarnColor);
                        }}
                        title={c.name}
                        style={{
                          background: c.hex ?? "#fff",
                          width: 22,
                          height: 22,
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          <div className={styles.bottomContent}>
            <Button contrast onClick={() => toggle()}>
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                if (persistYarnData && isYarnColor) {
                  if (newYarnColor !== null) {
                    onChangeComplete({
                      ...newYarnColor,
                      name: customName,
                      hex: col,
                      customHex: true,
                    });
                  } else {
                    onChangeComplete({
                      ...color,
                      name: customName,
                      hex: col,
                      customHex: true,
                    });
                  }
                } else {
                  onChangeComplete({
                    sku: null,
                    name: customName,
                    hex: col,
                  });
                }
                toggle();
              }}
              active
            >
              {t("navigation.save")}
            </Button>
          </div>
        </>
      }
    />
  );
}

/*
type YarnColor = {
  name: string;
  code: string;
  sku: string;
  image: string;
  yarn: Yarn;
  hex: string | null;
};
*/

export default YarnColorPicker;
