import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setRepeatMode } from "../store/gridSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import Button from "../ISO/Components/Button";

export const RepeatMenu = () => {
  const dispatch = useDispatch();
  const { repeat } = useSelector((state: RootState) => state.grid);
  const { t } = useTranslation();
  const modes = ["NS", "EW"];

  const repeatButton = (mode: any) => (
    <Button
      key={mode}
      style={{
        display: "flex",
        justifyContent: "center",
        border: "none",
        backgroundColor: mode === repeat ? "var(--neutral-10)" : "#ffffff",
        aspectRatio: "1",
        height: "30px",
      }}
      onClick={() => dispatch(setRepeatMode({ mode }))}
    >
      <img
        style={{ margin: "auto" }}
        src={`/arrow-${mode}.svg`}
        alt={`Repeat ${mode}`}
      />
    </Button>
  );

  return (
    <div
      className={styles.toolContainer}
      style={{
        flexDirection: "row",
      }}
    >
      <div
        style={{
          flex: "1",
          margin: "auto",
          marginLeft: "10px",
          display: "flex",
        }}
      >
        {t("draw.repeat")}
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
        }}
      >
        {repeatButton(modes[0])}
        <hr />
        {repeatButton(modes[1])}
      </div>
    </div>
  );
};
