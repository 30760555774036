import { Cross1Icon, Pencil2Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ModalButton } from "../../CommonComponents/ModalButton";
import { useModal } from "../../CommonComponents/useModal";
import Button from "../../ISO/Components/Button";
import { SizeCategory, SizeGroup } from "../../store/pattern";
import {
  editSizeCategorySize,
  addSizeCategorySize,
} from "../../store/patternSlice";
import styles from "./SizeGroupModal.module.css";

interface PropType {
  category: SizeCategory | null;
  sizeGroup: SizeGroup;
}

export const EditSizeGroupModal: React.FC<PropType> = ({
  category,
  sizeGroup,
}) => {
  const { isShown, toggle } = useModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [group, setGroup] = useState<SizeGroup>(sizeGroup);

  useEffect(() => {
    setGroup(sizeGroup ?? { label: "", sizes: [] });
  }, [sizeGroup]);

  return (
    <ModalButton
      className={styles.modalButton}
      headerText={`${t("navigation.edit")}: ${category ?? ""}`}
      isShown={isShown}
      toggle={toggle}
      modalContent={
        <div className={styles.modalContent}>
          {sizeGroup?.sizes.map((size, i) => {
            const payload = {
              sizeGroup: sizeGroup,
              oldValue: size,
              index: i,
            };
            return (
              <div className={styles.sizeGroup} key={i}>
                <input
                  value={group.sizes[i]}
                  onChange={(e) => {
                    const tmp = [...group.sizes];
                    tmp.splice(i, 1, e.target.value);
                    setGroup({ ...sizeGroup, sizes: tmp });
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== size) {
                      dispatch(
                        editSizeCategorySize({
                          ...payload,
                          value: e.target.value,
                        })
                      );
                    }
                  }}
                />
                <Button
                  contrast
                  onClick={() =>
                    dispatch(
                      editSizeCategorySize({
                        ...payload,
                      })
                    )
                  }
                >
                  <Cross1Icon />
                </Button>
              </div>
            );
          })}
          <Button
            className={styles.addNewSize}
            onClick={() =>
              dispatch(addSizeCategorySize({ name: sizeGroup?.label ?? "" }))
            }
          >
            {t("add_size")}
          </Button>
          <hr />
          <div className={styles.bottom}>
            <Button onClick={toggle}>{t("navigation.close")}</Button>
          </div>
        </div>
      }
    >
      <Pencil2Icon aria-label="Endre størrelser" />
    </ModalButton>
  );
};
