const words = {
  maskesi: "maske",
  maskesid: "masken",
  maskepl: "masker",
  maskepld: "maskene",

  omgsi: "omgang",
  omgsid: "omgangen",
  omgpl: "omganger",
  omgpld: "omgangene",

  pinnesi: "pinne",
  pinnesid: "pinnen",
  pinnepl: "pinner",
  pinnepld: "pinnene",

  rettsi: "rett",
  rettsid: "retten",
  rettpl: "rette masker",
  rettpld: "rette maskene",

  vrangsi: "vrang",
  vrangsid: "vrangen",
  vrangpl: "vrange masker",
  vrangpld: "vrange maskene",
};

const shortenedWords: any = {
  maske: "m",
  masken: "m",
  masker: "m",
  maskene: "m",

  omgang: "omg",
  omgangen: "omg",
  omganger: "omg",
  omgangene: "omg",

  pinne: "p",
  pinnen: "p",
  pinner: "p",
  pinnene: "p",

  rett: "r",
  retten: "r",
  "rette masker": "r",
  "rette maskene": "r",

  vrang: "vr",
  vrangen: "vr",
  "vrange masker": "vr",
  "vrange maskene": "vr",
};

const wordsToShorten = new RegExp(
  Object.values(words)
    .reverse()
    .map((word) => word)
    .join("\\W|"),
  "g"
);

export { words, shortenedWords, wordsToShorten };
