import React from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../ISO/Components/Button";
import { RootState } from "../store/store";
import { toggleMenuDrawer } from "../store/writerSlice";
import styles from "./MenuDrawer.module.css";
import { OrgDrawer } from "./OrgDrawer";

export const MenuDrawer = () => {
  const dispatch = useDispatch();

  const { menuDrawerOpen } = useSelector((state: RootState) => state.writer);
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(toggleMenuDrawer());
  };

  return (
    <div
      className={`${styles.drawerContainer} ${
        menuDrawerOpen ? "" : styles.hidden
      }`}
    >
      <div className={styles.drawerBackground} onClick={handleClose} />
      <div className={styles.drawer}>
        <div className={styles.top}>
          <h3>{t("menu")}</h3>
          <Button onClick={handleClose}>
            <ArrowLeftIcon />
          </Button>
        </div>
        <OrgDrawer />
        <Link to="/projects">
          <Button onClick={handleClose} className={styles.menuButton}>
            <h4>{t("project", { count: 2 })}</h4>
          </Button>
        </Link>
        {/* <Link to="/salesOverview">
          <Button onClick={handleClose} className={styles.menuButton}>
            <h4>{t("Salgsoversikt", { count: 2 })}</h4>
          </Button>
        </Link>  */}
      </div>
    </div>
  );
};
