import React from "react";

import { useSelector } from "react-redux";

import { RootState } from "../../store/store";

import Media from "./Media";
import Variant from "./Variant";

function SubdrawerContent() {
  const { subdrawerContent } = useSelector((state: RootState) => state.writer);

  switch (subdrawerContent.type) {
    case "variant":
      return <Variant id={subdrawerContent.id as string} />;
    case "media":
      return <Media />;
    default:
      return (
        <div>Do I look like I know what a "{subdrawerContent.type}" is?</div>
      );
  }
}

export default SubdrawerContent;
