export const formatCents = (priceCents: number) => {
  let numberFormat = new Intl.NumberFormat(["nb-NO"], {
    style: "currency",
    currency: "NOK",
    currencyDisplay: "symbol",
  });
  return numberFormat.format(priceCents / 100);
};

export const formatCentsNOK = (priceCents: number) => {
  let numberFormat = new Intl.NumberFormat(["nb-NO"], {
    style: "currency",
    currency: "NOK",
    currencyDisplay: "code",
  });
  return numberFormat.format(priceCents / 100);
};
