import React from "react";
import { Symbols } from "@iterate/woolit-components";
import { symbolLookup } from "../CommonComponents/Icons/lookup";

import { useGridVariables } from "./utils/hooks";

interface PropType {
  fill: string;
  symbol: Symbols;
}

export const SymbolSelector = (props: PropType) => {
  const { cellWidth, cellHeight } = useGridVariables();
  const sizes = { width: cellWidth, height: cellHeight };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {symbolLookup({ key: props.symbol, props: { ...sizes, ...props } })}
    </div>
  );
};
