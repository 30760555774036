import React from "react";
import ReactSelect, { ActionMeta } from "react-select";

interface PropType {
  options: { value: string; label: string }[];
  value: { value: string; label: string } | undefined;
  onChange: (
    value: { label: string; value: string } | null,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => void;
}

const styleOverrides = {
  control: (provided: any) => ({
    ...provided,
    border: "2px solid var(--neutral-10)",
    flexGrow: 1,
    boxShadow: "none",
    minHeight: "initial",
    ":hover": {
      border: "2px solid var(--light-brown)",
    },
    width: "100%",
  }),
  container: (provided: any) => ({
    ...provided,
    flexGrow: 1,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: "12px",
    height: "20px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "var(--darkest-brown)",
    fontFamily: "var(--text-font-family)",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "0 8px",
    marginTop: "-1px",
    color: "var(--light-brown)",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "18px",
    margin: "4px 0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    height: "18px",
    width: "1px",
    backgroundColor: "var(--neutral-10)",
    margin: "0",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "white" : "var(--darkest-brown)",
    fontFamily: "var(--text-font-family)",
    fontSize: "12px",
    lineHeight: "20px",
    backgroundColor: state.isSelected ? "var(--light-brown)" : "white",
    ":hover": {
      backgroundColor: state.isSelected
        ? "var(--dark-brown)"
        : "var(--neutral-10)",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    border: "2px solid var(--neutral-10)",
    borderRadius: "4px",
    boxShadow: "none",
  }),
};

function Select(props: PropType) {
  const { options, onChange, value } = props;

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={styleOverrides}
    />
  );
}

export default Select;
