import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface NewPatternButtonProps {
  showModal: () => void;
}
export const NewPatternButton: FunctionComponent<NewPatternButtonProps> = (
  props
) => {
  const { t } = useTranslation();
  return <button onClick={props.showModal}>{t("new pattern")}</button>;
};
