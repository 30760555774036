import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";
import { setDisable } from "../store/dragSelectSlice";
import {
  setActiveDiagram,
  setDisableHotkeys,
  updateGrid,
  updateMetaData,
} from "../store/gridSlice";
import { DiagramData, emptyDiagramData } from "../store/pattern";
import {
  addEmptyDiagram,
  deleteDiagramById,
  setAllDiagrams,
  updateDiagramById,
} from "../store/patternSlice";
import { RootState } from "../store/store";
import { notInPattern } from "../utils/canDelete";
import styles from "./diagram.module.css";
import { NewDiagram } from "./NewDiagram";
import { createGridArray } from "../store/initialDiagramState";
import PatternCanvas from "../Visualizing3D/knittingeditor/PatternCanvas";
import { Util } from "../Visualizing3D/static/util";
import { ClickMenu } from "./ContextMenu/Click";
import { ContextMenu } from "./ContextMenu/RenderMenu";
import { shortUUID } from "../utils/uuid";
import { Global } from "../Visualizing3D/static/global";
import { Settings } from "../Visualizing3D/static/settings";
import { setMarginBottomModel } from "../store/modelSlice";
import { useTranslation } from "react-i18next";

export const DiagramSelector = () => {
  interface ContextMenuInfo {
    x: number;
    y: number;
    diagram: DiagramData;
  }
  const dispatch = useDispatch();
  const { diagrams, patternElements, id } = useSelector(
    (state: RootState) => state.pattern
  );
  const { activeDiagram } = useSelector((state: RootState) => state.grid);
  const color = useSelector((state: RootState) => state.color);
  const [contextMenuInfo, setContextMenuInfo] = useState<ContextMenuInfo>();

  const windowSize = useWindowSize();
  const diagramListSizeRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    const selectorWidth = (diagramListSizeRef.current?.clientWidth ?? 0) + 200;
    // +200 comes from add button. I tried to reference the width with the add button, but
    // it fills the component to the wrong width, and fixing its width caused scroll issues.
    const editorWidth = windowSize[0] / 2;
    const overflowing = editorWidth < selectorWidth;
    const marginBottomModel = overflowing ? Settings.diagramListHeight : 0;
    dispatch(setMarginBottomModel(marginBottomModel));
  }, [windowSize, diagrams]);

  const handleDispatch = (diagram: DiagramData) => {
    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));
  };

  const makeDiagram = (diagram: DiagramData) => {
    dispatch(
      addEmptyDiagram({
        diagramId: diagram.id,
        diagramData: diagram,
      })
    );
    dispatch(
      updateDiagramById({
        patternId: id,
        diagram: diagram,
      })
    );

    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));
    dispatch(setDisableHotkeys(false));
  };

  const newDiagram = () => {
    const newDiagram: DiagramData = {
      ...emptyDiagramData(),
      grid: createGridArray(10, 10),
      name: t("draw.new_diagram"),
      gridHeight: 10,
      gridWidth: 10,
    };
    makeDiagram(newDiagram);
  };

  const duplicateDiagram = (diagram: DiagramData) => {
    let duplicatedDiagram: DiagramData = {
      ...diagram,
      id: shortUUID(),
    };
    makeDiagram(duplicatedDiagram);
  };

  const deleteDiagram = (diagramKey: string, patternId: number) => {
    dispatch(
      deleteDiagramById({
        diagramId: diagramKey,
        patternId,
      })
    );
    const updatedDiagrams: any = { ...diagrams };
    delete updatedDiagrams[diagramKey];
    dispatch(setAllDiagrams(updatedDiagrams));
    if (Object.keys(updatedDiagrams).length > 0) {
      const first = diagrams[Object.keys(updatedDiagrams)[0]];
      dispatch(setActiveDiagram({ id: first.id }));
    }
  };

  return (
    <>
      {contextMenuInfo && (
        <div style={{ zIndex: 1000 }}>
          <ContextMenu
            x={contextMenuInfo.x}
            y={contextMenuInfo.y - 150}
            options={[
              {
                label: "Dupliser",
                icon: "/duplicate.svg",
                action: () => {
                  duplicateDiagram(contextMenuInfo.diagram);
                },
              },
              {
                label: "Slett diagram",
                icon: "/3D/ui/delete2.png",
                fontColor: "red",
                action: () => {
                  deleteDiagram(contextMenuInfo.diagram.id, id);
                },
              },
            ]}
            handleClose={() => {
              setContextMenuInfo(undefined);
            }}
            defaultVisibility={true}
            dontDispatchActions
          />
        </div>
      )}
      <div className={`${styles.bottom}`}>
        <p
          style={{
            marginLeft: "40px",
            marginBottom: "20px",
          }}
        >
          {t("draw.my_diagrams")}:
        </p>
        <div
          style={{
            margin: "40px",
            marginTop: "10px",
            gap: "20px",
          }}
        >
          <div className={`${styles.diagramList}`}>
            <Button
              style={{
                display: "flex",
                width: "134px", // 120px + Padding _ 5 _ 5 + border 2px
                height: "132px", // 120px + Padding 3 _ 5 _ + border 2px
                pointerEvents: "auto",
              }}
              onClick={() => {
                newDiagram();
              }}
            >
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  width: "100px",
                  height: "100px",
                  backgroundColor: "var(--neutral-10)",
                }}
              >
                <img
                  src="/plus_black.svg"
                  style={{
                    margin: "auto",
                    width: "50px",
                    height: "50px",
                  }}
                ></img>
              </div>
            </Button>
            <div
              ref={diagramListSizeRef}
              style={{
                overflowX: "auto",
                paddingTop: "50px", // To show the hover text
              }}
            >
              <div
                className={`${styles.diagramList}`}
                style={{
                  pointerEvents: "auto",
                }}
              >
                {Object.entries(diagrams)
                  .sort(([_, diagram1], [__, diagram2]) =>
                    diagram1.name.localeCompare(diagram2.name)
                  )
                  .map(([diagramKey, diagram]) => {
                    //const canDelete = notInPattern(diagramKey, patternElements);
                    return (
                      <Button
                        key={diagramKey}
                        className={`${styles.hasHoverContent} ${styles.noHoverColor}`}
                        active={activeDiagram === diagramKey}
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => {
                          handleDispatch(diagram);
                        }}
                        onContextMenu={(e: PointerEvent) => {
                          e.preventDefault();
                          setContextMenuInfo({
                            x: e.screenX,
                            y: e.screenY,
                            diagram: diagram,
                          });
                        }}
                        contrast
                      >
                        {/* display: visible on hover*/}
                        <div
                          className={styles.hoverContent}
                          style={{
                            fontWeight: 500,
                            marginTop: "5px",
                            marginBottom: "-5px",
                          }}
                        >
                          {diagram.name}
                        </div>
                        <div
                          style={{
                            width: "120px",
                            height: "120px",
                            padding: "5px 5px 3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <PatternCanvas
                            pattern={Util.diagramToPattern(diagram)}
                            colors={color.colors.map((it: any) => it.hex)}
                          />
                        </div>
                      </Button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiagramSelector;
