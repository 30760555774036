import React from "react";

import { YarnColor } from "../store/pattern";

import styles from "./ImageAsColorPreview.module.css";

interface PropType {
  color: YarnColor;
}

function ImageAsColorPreview(props: PropType) {
  const { color } = props;

  if (!color?.image) return null;

  return (
    <div className={styles.container}>
      <img
        key={`${color.yarn.name}-${color.name}-img`}
        src={color.image}
        alt={""}
      />
    </div>
  );
}

export default ImageAsColorPreview;
