import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { backendApiAddress } from "../backendApi";
import { RootState } from "./store";
import { Pattern } from "./pattern";
import { APIYarn } from "../services/yarnApi";

const adminFetchAllYarn = createAsyncThunk(
  "admin/fetchAllYarn",
  async (_arg, _thunkAPI) => {
    const response = await fetch(`${backendApiAddress}/api/yarn/all`, {
      credentials: "include",
    });
    return response.json();
  }
);

const adminSaveChangedYarn = createAsyncThunk(
  "admin/saveChangedYarn",
  async (_arg, thunkAPI) => {
    const {
      yarn: { remote, localChanges },
    } = (thunkAPI.getState() as RootState).admin;

    let responses = [];
    for (let { sku, hex, yarnId, yarnSku } of Object.values(localChanges)) {
      // "If hex has been changed locally"
      if (
        hex !== remote[yarnSku].colors.find((color) => sku === color.sku)?.hex
      ) {
        const request = fetch(`${backendApiAddress}/api/yarn/color/set_hex`, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ sku, hex, yarn_id: yarnId }),
        });
        responses.push(request);
      }
    }
    return Promise.allSettled(responses);
  }
);

const adminGenerateAllHexes = createAsyncThunk(
  "admin/generateAllHexes",
  async (_arg, thunkAPI) => {
    // Get all yarn from API with any color without associated hex color value
    let yarnToGenerate = Object.values(
      (thunkAPI.getState() as RootState).admin.yarn.remote
    )
      .map((yarn) => {
        return {
          ...yarn,
          colors: yarn.colors.filter((color) => color.hex === null),
        };
      })
      .filter((yarn) => yarn.colors.length !== 0);

    let results = [];
    for (const yarn of yarnToGenerate) {
      for (const color of yarn.colors) {
        // This won't work cuz async. Need promises to resolve on image loaded and possibly elsewhere.
        // Also aggregate this and store results in result list, then return Promise.allSettled(result)
        // in the end.

        if (color.image_swatch === null) {
          continue;
        }

        const width = 4;
        const height = 4;

        const promise = new Promise((resolve, reject) => {
          if (color.image_swatch === null) {
            reject(color.sku);
          } else {
            const image = new Image();
            image.crossOrigin = "use-credentials";
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", () => reject(color.sku));
            image.src =
              `${backendApiAddress}/images/v1/get/${color.image_swatch.id}/${color.image_swatch.token}` +
              `?width=${width}&height=${height}&crop=false`;
          }
          // eslint-disable-next-line
        }).then((image) => {
          let canvas = document.createElement("canvas");
          canvas.height = width;
          canvas.width = height;

          const context = canvas.getContext("2d");
          if (context === null) {
            return { hex: null };
          }

          context.drawImage(image as HTMLOrSVGImageElement, 0, 0);

          try {
            const data = context.getImageData(0, 0, width, height);

            let red = 0;
            let green = 0;
            let blue = 0;

            for (let i = 0; i < data.data.length; i += 4) {
              red += data.data[i];
              green += data.data[i + 1];
              blue += data.data[i + 2];
            }

            red = Math.floor(red / (width * height));
            green = Math.floor(green / (width * height));
            blue = Math.floor(blue / (width * height));

            const hex = `#${("0" + red.toString(16)).slice(-2)}${(
              "0" + green.toString(16)
            ).slice(-2)}${("0" + blue.toString(16)).slice(-2)}`;

            return { hex, sku: color.sku, yarnId: yarn.id, yarnSku: yarn.sku };
          } catch (e) {
            // CORS error, image from different domain
            return { hex: null };
          }
        });

        results.push(promise);
      }
    }

    return Promise.allSettled(results);
  }
);

const adminFetchAllPatterns = createAsyncThunk(
  "admin/fetchAllPatterns",
  async (_arg, _thunkAPI) => {
    const response = await fetch(`${backendApiAddress}/api/pattern`, {
      credentials: "include",
    });
    return response.json();
  }
);

const adminSavePattern = createAsyncThunk(
  "admin/savePattern",
  async (args: { patternId: number; pattern: Pattern }, thunkAPI) => {
    const { patternId, pattern } = args;

    if (pattern === undefined) {
      return new Promise((_, reject) =>
        reject(`Pattern ${patternId} has not been changed`)
      );
    }

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}`,
      {
        method: "PUT",
        body: JSON.stringify(pattern),
        credentials: "include",
      }
    );
    return response.json();
  }
);

interface AdminType {
  yarn: YarnAdminType;
  pattern: PatternAdminType;
}

interface YarnAdminType {
  open: boolean;
  remote: { [key: string]: APIYarn };
  localChanges: { [key: string]: APIYarnColorChange };
}

interface APIYarnColorChange {
  sku: string;
  hex: string | null;

  yarnId: number;
  yarnSku: string;
}

interface PatternAdminType {
  open: boolean;
  remote: { [key: number]: Pattern };
  localChanges: { [key: number]: string };
}

const initialState: AdminType = {
  yarn: {
    open: false,
    remote: {},
    localChanges: {},
  },
  pattern: {
    open: false,
    remote: {},
    localChanges: {},
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    toggleYarnOpen(state) {
      state.yarn.open = !state.yarn.open;
    },
    clearYarn(state) {
      state.yarn.remote = {};
      state.yarn.localChanges = {};
    },
    clearYarnChanges(state) {
      state.yarn.localChanges = {};
    },
    changeYarnColor(state, action) {
      const { sku, hex, yarnId, yarnSku } = action.payload;
      state.yarn.localChanges[sku] = { sku, hex, yarnId, yarnSku };
    },
    togglePatternOpen(state) {
      state.pattern.open = !state.pattern.open;
    },
    clearPattern(state) {
      state.pattern.remote = {};
      state.pattern.localChanges = {};
    },
    setPatternLocalChanges(state, action) {
      const { id, change }: { id: number; change: string } = action.payload;

      state.pattern.localChanges = {
        ...state.pattern.localChanges,
        [id]: change,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminFetchAllYarn.fulfilled, (state, action) => {
      const yarnMap: { [key: string]: APIYarn } = action.payload;
      state.yarn.remote = yarnMap;
    });
    builder.addCase(adminSaveChangedYarn.fulfilled, (state, action) => {
      // Reset yarn state, triggering new fetch from API
      state.yarn = {
        ...initialState.yarn,
        open: state.yarn.open,
      };
    });
    builder.addCase(adminGenerateAllHexes.fulfilled, (state, action) => {
      const promises = action.payload;

      let validHexes: { [key: string]: APIYarnColorChange } = {};
      for (let promise of promises) {
        if (promise.status === "fulfilled" && promise.value.hex !== null) {
          const change = promise.value;
          validHexes[change.sku] = change;
        }
      }

      // Automatically generated hexes are overwritten by existing changes
      state.yarn.localChanges = {
        ...validHexes,
        ...state.yarn.localChanges,
      };
    });
    builder.addCase(adminFetchAllPatterns.fulfilled, (state, action) => {
      const patterns: Pattern[] = action.payload;

      state.pattern.remote = Object.fromEntries(
        patterns.map((pattern) => [pattern.id, pattern])
      );
    });
  },
});

export {
  adminSlice,
  adminFetchAllYarn,
  adminSaveChangedYarn,
  adminGenerateAllHexes,
  adminFetchAllPatterns,
  adminSavePattern,
};

export const {
  toggleYarnOpen,
  clearYarn,
  clearYarnChanges,
  changeYarnColor,
  togglePatternOpen,
  clearPattern,
  setPatternLocalChanges,
} = adminSlice.actions;
