import React, { useEffect, useState } from "react";
import Button from "../../ISO/Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setDisableModel, setKnittingMethod } from "../../store/modelSlice";
import { RootState } from "../../store/store";
import { setDiagramView } from "../../store/gridSlice";
import { Load } from "../../Visualizing3D/SaveLoad";
import { useTranslation } from "react-i18next";

export const KnitMethodCard = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { knittingMethod } = useSelector((state: RootState) => state.model);
  const { id } = useSelector((state: RootState) => state.pattern);
  const checkIfDesignExists = (action: any) => {
    Load(`${id}`, (load: any) => {
      const exists = load && load["design"];
      if (exists && !window.confirm(t("datadrawer.overwrite model"))) {
        return;
      }
      action();
    });
  };
  return (
    <Button
      active={props.knitMethod === knittingMethod}
      onClick={() => {
        if (props.knitMethod === knittingMethod) return;
        checkIfDesignExists(() =>
          dispatch(setKnittingMethod(props.knitMethod))
        );
      }}
      style={{
        minWidth: "180px",
        maxWidth: "180px",
        height: "50px",
        display: "flex",
        paddingRight: "200px",
      }}
      contrast
    >
      <img src={props.src} style={{ height: "40px" }}></img>
      <p
        style={{
          margin: "auto",
        }}
      >
        {props.text}
      </p>
    </Button>
  );
};
