// Assumes color on format #RRBBGG

const toDec = (hex: string) => parseInt(hex, 16);

export const useDarkColor = (color: string) => {
  if (!color) return true; // Fallback to dark color contract is color is not defined
  const red = toDec(color.substring(1, 3));
  const green = toDec(color.substring(3, 5));
  const blue = toDec(color.substring(5, 7));

  //console.log(red, green, blue, red * 0.299 + green * 0.587 + blue * 0.114);
  //https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return true;
  } else return false;
};
