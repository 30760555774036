import React, { FunctionComponent } from "react";

interface ChangeStatusButtonProps {
  showModal: () => void;
  statusMsg: string;
}
export const ChangeStatusButton: FunctionComponent<ChangeStatusButtonProps> = (
  props
) => {
  return <button onClick={props.showModal}>{props.statusMsg}</button>;
};
