import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backendApiAddress } from "../backendApi";
import { DesignerSales } from "../types/backend-generated/DesignerSales";

export const salesOverviewApi = createApi({
  reducerPath: "salesOverviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendApiAddress}/api/wds/sales`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getAllSales: builder.query<DesignerSales, number | null>({
      query: (orgId: number) => {
        if (orgId) {
          return `/${orgId}`;
        } else {
          return "/";
        }
      },
    }),
  }),
});
export const { useGetAllSalesQuery } = salesOverviewApi;
