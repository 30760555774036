import React, { useState } from "react";
import { PatternSales } from "../types/backend-generated/PatternSales";
import { formatCentsNOK } from "../utils/currencyFormat";
import Sales from "./Sales";
import styles from "./SalesOverview.module.css";
import { filterPatternSales, findTotalPatternSaleSum } from "./SalesUtils";

const PatternSaleOverview = ({
  patternSales,
  month,
  year,
}: {
  patternSales: PatternSales;
  month: number;
  year: number;
}) => {
  const { patternId, title, sales } = patternSales;
  const [expanded, setToExpand] = useState(false);
  const [buttonText, setButtonText] = useState("Detaljer ▼");
  const [theseSales, numberOfSales] = filterPatternSales(sales, month, year);
  const totalSum = findTotalPatternSaleSum(theseSales);

  const expandRow = () => {
    setToExpand(!expanded);
    if (!expanded) {
      setButtonText("Skjul ▲");
    } else {
      setButtonText("Detaljer ▼");
    }
  };

  return (
    <div>
      <div className={styles.patternRow}>
        <div className={styles.tableItem}>{title}</div>
        <div className={styles.tableItem}>{patternId}</div>
        <div className={styles.tableItemSales}>{numberOfSales}</div>
        <div className={styles.tableItemSum}>{formatCentsNOK(totalSum)}</div>
        <button className={styles.expandButton} onClick={expandRow}>
          {buttonText}
        </button>
      </div>
      {expanded && <Sales sales={theseSales} />}
    </div>
  );
};

export default PatternSaleOverview;
