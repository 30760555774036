import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useDarkColor } from "../utils/colorContrast";
import { SymbolSelector } from "./SymbolSelector";
import { useGridVariables } from "./utils/hooks";

interface PropType {
  x: number;
  y: number;
}

export const CellClone = (props: PropType) => {
  const { x, y } = props;
  const { cellWidth, cellHeight } = useGridVariables();

  const cellStyle = {
    width: cellWidth,
    height: cellHeight,
    border: "1px solid black",
  };

  const { color, symbol, colorIndex } = useSelector((state: RootState) => {
    const colorIndex = state.grid.grid.grid[y][x].color;
    return {
      color: state.color.colors[colorIndex]?.hex ?? "#ffffff",
      symbol: state.grid.grid.grid[y][x].symbol,
      colorIndex: colorIndex,
    };
  });

  const symbolColor = useDarkColor(color) ? "black" : "white";

  return (
    <div
      style={{
        ...cellStyle,
        position: "absolute",
        left: x * cellWidth,
        top: y * cellHeight,
        backgroundColor: color,
        border: `1px ${colorIndex !== -1 ? "#000000" : "#faf6f2"} solid`,
        zIndex: colorIndex === -1 ? 0 : 1,
      }}
    >
      {symbol !== "stitch" && (
        <SymbolSelector symbol={symbol} fill={symbolColor} />
      )}
    </div>
  );
};
