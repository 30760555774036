import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import styles from "./PublishPatternModal.module.css";
import CtaButton from "../CommonComponents/CtaButton";
import {
  Published,
  usePatternPublishedQuery,
  useUpdatePatternPriceMutation,
} from "../services/patternApi";
import { formatCents } from "../utils/currencyFormat";
import { publishPattern } from "../store/patternSlice";
import { Pattern } from "../store/pattern";
import { PublishDate } from "../ISO/PublishDate";
import { formatDate } from "../utils/dateFormat";
import { RootState, useAppDispatch } from "../store/store";
import {
  checkPatternVariant,
  checkPatternTitle,
  checkPatternDescription,
  checkPatternMedia,
  checkPatternMediaVariantProportion,
  checkOrganizationID,
  checkNeedles,
  buttonDisabled,
  checkSizeVariantYarnConsumption,
  warningPresent,
  checkAmountsHasAllColorsForVariants,
} from "./publishChecks";

interface PropType {
  toggle: () => void;
  pattern: Pattern;
}

interface PatternPrice {
  designerCutExVat: number;
  designerCutVat: number;
  woolitCut: number;
  totalPrice: number;
}

const WOOLIT_CUT_AMOUNT = 2000;
const WOOLIT_CUT_PERCENT = 0.2;
const VAT_RATE = 0.25;

export const PublishPatternModal = (props: PropType) => {
  const pattern = props.pattern;
  const patternId = props.pattern.id.toString();

  const user = useSelector((state: RootState) => state.loginState.user);
  const { isLoading, data } = usePatternPublishedQuery(patternId, {
    skip: props.pattern.id === -1,
  });

  const dispatch = useAppDispatch();

  const [updatePrice] = useUpdatePatternPriceMutation();

  const [showPublishedList, setShowPublishedList] = useState(false);
  const [log, setLog] = useState<Published[]>([]);
  const [comment, setComment] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [finishedPublishing, setIsFinishedPublishing] = useState(false);
  const [publishError, setPublishError] = useState(false);

  const onPublishClicked = async () => {
    try {
      await dispatch(publishPattern({ pattern, comment })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      setPublishError(true);
    } finally {
      setIsFinishedPublishing(true);
    }
  };

  useEffect(() => {
    if (data?.published && data.published.length > 0) {
      setIsPublished(true);
    }
    if (data?.priceDetails) {
      if (data?.priceDetails) {
        const price =
          data.priceDetails.designer_excl_vat + data.priceDetails.designer_vat;
        setPricing(`${price / 100}`);
      }
    }
    if (data?.published && data.published.length > 1) {
      setLog([...data.published].reverse());
    }
  }, [isLoading, data?.priceDetails, data?.published]);

  const [pricing, setPricing] = useState<string>("");

  const [priceDetails, setPriceDetails] = useState<PatternPrice>({
    designerCutExVat: 0,
    designerCutVat: 0,
    woolitCut: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    const parsed = pricing.replace(",", ".");
    if (isNaN(parseInt(parsed))) {
      setPriceDetails({
        designerCutExVat: 0,
        designerCutVat: 0,
        woolitCut: WOOLIT_CUT_AMOUNT,
        totalPrice: WOOLIT_CUT_AMOUNT,
      });
    } else {
      const designerCutIncVat = Math.trunc(parseFloat(parsed) * 100);
      const designerCutExVat = designerCutIncVat / (1 + VAT_RATE);
      const designerCutVat = designerCutIncVat - designerCutExVat;
      const woolitCut = Math.max(
        WOOLIT_CUT_AMOUNT,
        designerCutIncVat * WOOLIT_CUT_PERCENT
      );

      const totalPrice = designerCutIncVat + woolitCut;

      setPriceDetails({
        designerCutExVat,
        designerCutVat,
        woolitCut,
        totalPrice,
      });
    }
  }, [pricing]);

  return (
    <>
      {finishedPublishing && !publishError && (
        <section className={styles.publishModal}>
          <div className={styles.succesfulPublishing}>
            Oppskriften din er publisert!
          </div>
        </section>
      )}

      {finishedPublishing && publishError && (
        <section className={styles.publishModal}>
          <div className={styles.unsuccesfulPublishing}>
            Publisering av oppskriften er ikke vellykket. Vennligst prøv på
            nytt.
          </div>
        </section>
      )}

      {!finishedPublishing && (
        <div className={styles.publishModal}>
          <section>
            <span className={styles.priceHeader}>
              <h2 className={styles.h2} style={{ display: "inline-block" }}>
                Oppskriftens pris:
              </h2>
              <input
                className={styles.priceInput}
                value={pricing}
                min="0"
                max="1000"
                type="number"
                onChange={(e) => setPricing(e.target.value)}
              />
            </span>
            <div className={styles.priceDetail}>
              <span>Mva</span>
              <span>25%</span>
              <span>{formatCents(priceDetails.designerCutVat)}</span>
            </div>
            <div className={styles.priceDetail}>
              <span>Du får</span>
              <span className={styles.pricePositive}>
                {formatCents(priceDetails.designerCutExVat)}
              </span>
            </div>
            <div className={styles.priceDetail}>
              <span>Woolit legger på</span>
              <span>{formatCents(priceDetails.woolitCut)}</span>
            </div>
            <div className={styles.priceSum}>
              <span>Total utsalgspris</span>
              <span className={styles.pricePositive}>
                {formatCents(priceDetails.totalPrice)}
              </span>
            </div>
          </section>
          {user?.isAdmin && (
            <button
              onClick={() =>
                setPriceDetails({
                  designerCutExVat: 0,
                  designerCutVat: 0,
                  woolitCut: 0,
                  totalPrice: 0,
                })
              }
            >
              Gratis
            </button>
          )}
          {/* <section>
                  <h2 className={styles.h2}>Fargevarianter:</h2>
                </section> */}
          {/* <section>
                  <h2 className={styles.h2}>Størrelser:</h2>
                </section> */}
          <section className={styles.previousPublishedSection}>
            <PublishDate patternId={patternId} />
            {showPublishedList && log.length > 0 && (
              <div className={styles.changeContainer}>
                <p className={styles.label}>Publiseringsoversikt: </p>
                <button
                  onClick={() => setShowPublishedList(!showPublishedList)}
                >
                  Skjul
                </button>
                {log.map((item, i) => (
                  <div className={styles.change} key={i}>
                    <p className={styles.changeComment}>
                      {item.comment ?? "Endring uten kommentar"}
                    </p>
                    <p className={styles.changeDate}>
                      {formatDate(item.created)}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {!showPublishedList && log.length > 0 && (
              <button onClick={() => setShowPublishedList(!showPublishedList)}>
                Publiseringsoversikt
              </button>
            )}
          </section>
          <section className={styles.commentSection}>
            {isPublished && (
              <TextareaAutosize
                className={styles.textArea}
                minRows={3}
                placeholder="Skriv hva som endret i oppskriften"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            )}
          </section>
          <section className={styles.publishSection}>
            <div>Publiseres umiddelbart</div>

            <CtaButton
              onClick={() => {
                updatePrice({
                  id: props.pattern.id,
                  price: {
                    designer:
                      priceDetails.designerCutExVat +
                      priceDetails.designerCutVat,
                    woolit: priceDetails.woolitCut,
                    currency: "nok",
                  },
                });

                onPublishClicked();

                //props.toggle();
              }}
              disabled={buttonDisabled(
                pattern.meta.title,
                pattern.meta.variants,
                pattern.meta.description,
                pattern.meta.sizes
              )}
            >
              Publiser oppskrift
            </CtaButton>
          </section>

          <section>
            {buttonDisabled(
              pattern.meta.title,
              pattern.meta.variants,
              pattern.meta.description,
              pattern.meta.sizes
            ) && (
              <section className={styles.publishErrorContainer}>
                <div>Dette hindrer deg i å publisere oppskriften din: </div>
                {!checkPatternTitle(pattern.meta.title) && (
                  <div className={styles.publishError}>
                    {" "}
                    Oppskriften har ingen tittel
                  </div>
                )}
                {!checkPatternVariant(pattern.meta.variants) && (
                  <div className={styles.publishError}>
                    {" "}
                    Oppskirften har ingen variant
                  </div>
                )}
                {!checkPatternDescription(pattern.meta.description) && (
                  <div className={styles.publishError}>
                    {" "}
                    Oppskriften har ingen beskrivelse
                  </div>
                )}
                {!checkSizeVariantYarnConsumption(
                  pattern.meta.variants,
                  pattern.meta.sizes
                ) && (
                  <div className={styles.publishError}>
                    {" "}
                    Garnforbruk mangler for en eller flere størrelser.
                  </div>
                )}
                {!checkAmountsHasAllColorsForVariants(
                  pattern.meta.variants,
                  pattern.meta.sizes
                ) && (
                  <div className={styles.publishError}>
                    {" "}
                    Garnforbruk mangler for en eller flere farger.
                  </div>
                )}
              </section>
            )}

            {warningPresent(
              pattern.media,
              pattern.meta.variants,
              pattern.organizationId,
              pattern.needles
            )}
            <section className={styles.publishErrorContainer}>
              <div>Vær obs på følgende:</div>
              {!checkPatternMedia(pattern.media) && (
                <div className={styles.publishWarning}>
                  {" "}
                  Vil du publisere uten bilder?
                </div>
              )}
              {!checkPatternMediaVariantProportion(
                pattern.meta.variants,
                pattern.media
              ) && (
                <div className={styles.publishWarning}>
                  {" "}
                  Ikke alle varianter har et bilde.
                </div>
              )}
              {!checkOrganizationID(pattern.organizationId) && (
                <div className={styles.publishWarning}>
                  {" "}
                  Vil du publisere oppskriften som privat person?
                </div>
              )}
              {!checkNeedles(pattern.needles) && (
                <div className={styles.publishWarning}>
                  {" "}
                  Vil du publisere uten pinneforslag?
                </div>
              )}
            </section>
          </section>
        </div>
      )}
    </>
  );
};
