import { RGBToRGBString, hexToRgb } from "../knittingpreview/colorutil";

//Global variables: const
export class Settings {
  static startMasksPer10Cm = 18;
  static sizes = ["XS", "S", "M", "L", "XL"];
  static lengthCM = [60, 62, 65, 67, 70] // Kun for display
  static circumfencesCM = [93, 98, 107, 116, 124] // Kun for display
  static circumfences = [168, 176, 192, 208, 224]
  static baseCircumfence = 192
  static shirtTypes = ["raglan", "stitched_sleeves"]
  static maskHeight = Math.round(17 * (16 / 17) * (18 / 23));
  static maskWidth = 16;
  static maxPatternWidth = 36;
  static maxPatternHeight = 36;
  static verbose = true;
  static borderColorOutline = "#B4917B";
  static borderColorOutlineSnap = "#F1C40F";
  static borderColorOutlineRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutline)
  );
  static borderColorOutlineSnapRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutlineSnap)
  );
  static defaultPatternHeightVH = 20 * (18/23);

  static colorsAll: String[] = []

  static version = "v6";
  static versionStorageName = "version";
  static sizeIndexStorageName = "size";
  static knittingMethodIndexStorageName = "knittingMethod";
  static sweaterStorageName = "sweater";
  static patternStorageName = "pattern_v5"; // _v5 Since I dont want the users to lose these patterns
  static colorsStorageName = "colors";

  static knitabilityWarningColor = "#f1c40f";
  static knitabilityWarningColorDisplay = "#FBAE3B"; //text display on white background
  static knitabilityErrorColor = "#ff0000";
  static emphasisColor = "#cc4e00";

  static limitMirror = true; //false is missing: delete - areagroup -> selected only
  static limitRepeat = true;

  static mirrorArmsDifferently = true;

  static totalYarnSweater = 12;
  static reverseY = true;

  static diagramListHeight = 160;
}
