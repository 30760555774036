import { DownloadIcon } from "@radix-ui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import { ExportModal } from "./ExportModal";

export const ExportButton = () => {
  const { isShown, toggle } = useModal();
  const { t } = useTranslation();
  return (
    <ModalButton
      modalContent={<ExportModal />}
      isShown={isShown}
      toggle={toggle}
      headerText={t("export.export")}
    >
      <DownloadIcon />
    </ModalButton>
  );
};
