import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pattern } from "../store/pattern";
import { RootState } from "../store/store";
import { adminSavePattern } from "../store/adminSlice";
import { JsonEditor as Editor } from "jsoneditor-react";
//import "jsoneditor-react/es/editor.min.css";

import styles from "./PatternSection.module.css";

interface PropType {
  patterns: Pattern[];
}

function PatternSection(props: PropType) {
  const { patterns } = props;

  const localChanges = useSelector(
    (state: RootState) => state.admin.pattern.localChanges
  );

  const [inputs, setInputs] = useState(localChanges);
  const [expand, setExpand] = useState(-1);
  const [editedPattern, setEditedPattern] = useState<Pattern | null>(null);

  const localChangeCallback = useCallback(() => {
    setInputs(localChanges);
  }, [localChanges]);

  useEffect(localChangeCallback, [localChanges, localChangeCallback]);

  const dispatch = useDispatch();

  return (
    <div>
      {patterns.map((pattern) => (
        <div>
          <h4>
            {pattern.id} — {pattern.meta.title}
            {expand === pattern.id && editedPattern ? " (Redigert)" : ""}
          </h4>
          {editedPattern && expand === pattern.id ? (
            <button
              key={pattern.id}
              onClick={() => {
                dispatch(
                  adminSavePattern({
                    patternId: pattern.id,
                    pattern: editedPattern,
                  })
                );
              }}
              className={styles.saveButton}
            >
              Save edited pattern
            </button>
          ) : (
            <></>
          )}
          {expand === pattern.id ? (
            <>
              <Editor
                value={inputs[pattern.id] ?? pattern}
                onChange={(edit: any) => {
                  setEditedPattern(edit);
                }}
              />
              <button
                onClick={() => {
                  setExpand(-1);
                  setEditedPattern(null);
                }}
              >
                Close
              </button>
            </>
          ) : (
            <button onClick={() => setExpand(pattern.id)}>
              Show pattnern {pattern.id}
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default PatternSection;
