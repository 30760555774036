import { Color } from "../store/pattern";

function constructYarnString(colors: Color[]) {
  interface intermediaryShape {
    [key: string]: {
      name: string;
      colorNames: string[];
    };
  }

  let yarnGroups: intermediaryShape = {
    null: { name: "Fargekode uten garn", colorNames: [] },
  };
  for (let color of colors) {
    if (color.sku === null) {
      // If the selected color is not from a yarn type,
      // add it to the "colors only" record
      yarnGroups["null"].colorNames.push(color.hex);
    } else if (yarnGroups[color.yarn.sku]) {
      yarnGroups[color.yarn.sku].colorNames.push(color.name);
    } else {
      yarnGroups[color.yarn.sku] = {
        name: color.yarn.name,
        colorNames: [color.name],
      };
    }
  }

  if (!yarnGroups.null.colorNames.length) {
    // If there are no colours without yarn, delete the label
    delete yarnGroups.null;
  }

  let partialStrings = [];
  for (let sku in yarnGroups) {
    const { name, colorNames } = yarnGroups[sku];
    partialStrings.push(`${name} ${colorNames.join(", ")}`);
  }

  return partialStrings.join(" – ");
}

export default constructYarnString;
