import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";
import {
  useDeletePatternMutation,
  useRemoveFromShopMutation,
} from "../services/patternApi";
import styles from "./Projects.module.css";

interface Props {
  id: string;
}

export const PatternOptionModal: React.FC<Props> = ({ id }) => {
  const { isShown, toggle } = useModal();
  const { t } = useTranslation();
  return (
    <div className={styles.patternOptionWrapper}>
      <ModalButton
        className={styles.patternOptionModalButton}
        isShown={isShown}
        toggle={toggle}
        headerText={t("navigation.options")}
        modalContent={<ModalContent id={parseInt(id)} toggle={toggle} />}
      >
        <DotsHorizontalIcon />
      </ModalButton>
    </div>
  );
};

interface ModalContentProps {
  toggle: Function;
  children?: never;
  id: number;
}

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const { t } = useTranslation();
  const { id } = props;

  const [deletePattern] = useDeletePatternMutation();
  const [removeFromShop] = useRemoveFromShopMutation();

  const { toggle } = props;
  return (
    <div className={styles.modalContainer}>
      <Button
        className={styles.modalActionButton}
        contrast
        onClick={() => {
          removeFromShop({ id });
          toggle();
        }}
      >
        {t("remove pattern from shop")}
      </Button>
      <Button
        className={styles.modalActionButton}
        contrast
        onClick={() => {
          deletePattern({ id });
          toggle();
        }}
      >
        {t("delete pattern")}
      </Button>
    </div>
  );
};
