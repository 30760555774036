import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { GridClone } from "./GridClone";
import { emptyArray } from "./PreviewBar";
import { useGridVariables } from "./utils/hooks";

export const RepeatDiagram = () => {
  const { repeat } = useSelector((state: RootState) => state.grid);
  const {
    gridHeight,
    gridWidth,
    cellHeight,
    cellWidth,
    offsetX,
    offsetY,
  } = useGridVariables();

  const repeatCount = 7;

  const width = gridWidth * cellWidth;
  const height = gridHeight * cellHeight;
  const start = repeat === "EW" ? -3 * width : -3 * height;
  return repeat ? (
    <>
      {emptyArray(repeatCount).map((_, i) => (
        <div
          style={{
            position: "absolute",
            left: repeat === "EW" ? offsetX + start + i * width : offsetX,
            top: repeat === "NS" ? offsetY + start + i * height : offsetY,
            width,
            height,
          }}
        >
          {i !== Math.floor(repeatCount / 2) && <GridClone />}
        </div>
      ))}
    </>
  ) : null;
};
