import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

import { RootState } from "../../store/store";
import { shortUUID } from "../../utils/uuid";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";

export type DiagramReferenceElementType = {
  type: "diagram";
  children: Text[];
  ref: string;
  id?: string;
  commentId?: string;
};

export const DiagramReferenceSelect = ({
  closeSelector,
  editor,
  action,
  element,
}: ElementSelectorProps) => {
  const diagrams = useSelector((state: RootState) => state.pattern.diagrams);

  const [arrowIndex, setArrowIndex] = useState(0);

  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
  });

  const diagramList = Object.values(diagrams);

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    setArrowIndex(
      arrowIndex + 1 === diagramList.length
        ? diagramList.length - 1
        : arrowIndex + 1
    );
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    upsertDiagramReference(diagramList[arrowIndex].id);
  });

  const upsertDiagramReference = (ref: string) => {
    const uuid = shortUUID();
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "diagram",
          children: [{ text: "" }],
          id: uuid,
          ref,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    } else {
      const path = element ? ReactEditor.findPath(editor, element) : undefined;
      Transforms.setNodes(editor, { ref, id: uuid }, { at: path });
    }
    closeSelector();
  };

  return (
    <>
      {diagramList.map((diagram, index) => (
        <div
          key={index}
          style={{ background: arrowIndex === index ? "#faf6f2" : "" }}
          onClick={() => upsertDiagramReference(diagram.id)}
          onMouseOver={() => setArrowIndex(index)}
          className={styles.hoverable}
        >
          {diagram.name}
        </div>
      ))}
    </>
  );
};

type DiagramElementProps = {
  attributes: any;
  children: Text[];
  element: DiagramReferenceElementType;
  onClick: (
    event: React.MouseEvent,
    element: DiagramReferenceElementType
  ) => void;
};

export const DiagramReferenceElement = ({
  attributes,
  children,
  element,
  onClick,
}: DiagramElementProps) => {
  const { diagrams } = useSelector((state: RootState) => state.pattern);

  return (
    <a
      {...attributes}
      className={`${styles.link} ${styles.void}  ${styles.nonEditable}`}
      onClick={(event) => onClick(event, element)}
      href={`#${element.ref}`}
    >
      {diagrams[element.ref].name}
      {children}
    </a>
  );
};
