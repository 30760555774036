import React, { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
}

export const Modal: FC<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
}) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isShown) {
      hide();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown]);

  const modal = (
    <>
      <div className={styles.backdrop} onClick={hide} />
      <div
        className={styles.wrapper}
        aria-modal
        aria-labelledby={headerText}
        tabIndex={-1}
        role="dialog"
      >
        <div className={styles.styledModal}>
          {headerText && (
            <div className={styles.header}>
              <div className={styles.headerText}>{headerText}</div>
              {/*<div
                className={styles.closeButton}
                onClick={hide}
                data-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>*/}
            </div>
          )}
          <div className={styles.content}>{modalContent}</div>
        </div>
      </div>
    </>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
