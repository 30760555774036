import { useState } from "react";
import { animated, useSpring } from "react-spring";
import { Text } from "slate";

import styles from "./ParagraphBreak.module.css";

export type ParagraphBreakElementType = {
  type: "paragraphBreak";
  children: Text[];
  commentId?: string;
};

type ParagraphBreakElementProps = {
  attributes: any;
  children: Text[];
  element: ParagraphBreakElementType;
  showDelete?: boolean;
  onClick: (element: ParagraphBreakElementType) => void;
};

export const ParagraphBreakElement = ({
  attributes,
  children,
  element,
  showDelete,
  onClick,
}: ParagraphBreakElementProps) => {
  const [isHover, setIsHover] = useState(false);

  const style = useSpring({
    opacity: isHover ? 1 : 0,
    duration: 50,
  });

  return (
    <animated.div
      {...attributes}
      className={styles.paragraphBreak}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      style={style}
    >
      {showDelete && (
        <button
          className={styles.paragraphButton}
          onClick={() => onClick(element)}
        >
          <img height="16" src="/x.svg" alt="Fjern" />
        </button>
      )}
      <hr className={styles.paragraphLine} />
      {children}
    </animated.div>
  );
};
