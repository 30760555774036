import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGridWidth,
  setGridHeight,
  addRowsOrCols,
  removeRowsOrCols,
  setDisableHotkeys,
} from "../store/gridSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import { useTranslation } from "react-i18next";

type GridButton = {
  label: string;
  index: number;
  direction: "row" | "col";
  count: number;
  rmIndex?: number;
};

const GridButtons = () => {
  const dispatch = useDispatch();

  const { gridWidth, gridHeight } = useSelector(
    (state: RootState) => state.grid.grid
  );

  useEffect(() => {
    setNewHeight(gridHeight);
  }, [gridHeight]);

  useEffect(() => {
    setNewWidth(gridWidth);
  }, [gridWidth]);

  const [newHeight, setNewHeight] = useState(gridHeight);
  const [newWidth, setNewWidth] = useState(gridWidth);
  const count = 1;
  const { t } = useTranslation();

  const buttons: GridButton[] = [
    { label: t("draw.top"), index: 0, direction: "row", count },
    {
      label: t("draw.right"),
      index: gridWidth,
      rmIndex: gridWidth - 1,
      direction: "col",
      count,
    },
    {
      label: t("draw.bottom"),
      index: gridHeight,
      rmIndex: gridHeight - 1,
      direction: "row",
      count,
    },
    { label: t("draw.left"), index: 0, direction: "col", count },
  ];
  

  return (
    <div className={styles.toolContainer}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div className={styles.input}>
          <label>{t("terms.width") + ":"} </label>
          <input
            value={newWidth}
            onChange={(e) => setNewWidth(parseInt(e.target.value))}
            type="number"
            onFocus={() => dispatch(setDisableHotkeys(true))}
            onBlur={() => {
              dispatch(setDisableHotkeys(false));
              if (gridWidth - newWidth !== 0) {
                dispatch(setGridWidth({ newWidth }));
              }
            }}
          />
        </div>
        <div className={styles.input}>
          <label>{t("terms.height") + ":"} </label>
          <input
            value={newHeight}
            onChange={(e) => setNewHeight(parseInt(e.target.value))}
            type="number"
            onFocus={() => dispatch(setDisableHotkeys(true))}
            onBlur={() => {
              dispatch(setDisableHotkeys(false));
              if (gridHeight - newHeight !== 0) {
                dispatch(setGridHeight({ newHeight }));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GridButtons;
