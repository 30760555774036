import { EyeOpenIcon } from "@radix-ui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../store/store";
import EditNavbar from "./EditNavbar";
import { MenuDrawerButton } from "./MenuDrawerButton";
import styles from "./Top.module.css";

interface PropType {
  transparent?: boolean;
}

const Top: React.FC<PropType> = (props) => {
  const { t } = useTranslation();
  const { updatedAt, id } = useSelector((state: RootState) => state.pattern);

  const { pathname } = useLocation();
  const [, , patternId, mode] = pathname.split("/");
  const { grid } = useSelector((state: RootState) => state);
  return (
    <nav className={`${styles.nav} ${props.transparent ? "" : styles.filled}`}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.menu}>
            <MenuDrawerButton />
            <div className={styles.vl} />
            <div>
              <p className={styles.updated}>{grid.grid.name}</p>
              {grid.selectedView !== "grid" && (
                <p
                  style={{
                    position: "absolute",
                    fontSize: "12px",
                  }}
                >
                  {t("draw.grid")}
                </p>
              )}
            </div>
          </div>
          <EditNavbar />
          <div className={styles.preview}>
            {mode === "write" && (
              <Link to={`/pattern/${id}`}>
                <button>
                  <EyeOpenIcon aria-label="Preview" />
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {props.children}
    </nav>
  );
};

export default Top;
