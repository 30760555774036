import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BBox } from "../Diagram/utils/aabb";

interface ModelState {
  disableModel: boolean
  knittingMethod: "raglan" | "stitched_sleeves"
  marginBottomModel: number;
}

const initialState: ModelState = {
  knittingMethod: "raglan",
  disableModel: false,
  marginBottomModel: 0, // Use the full window if diagrams dont overflow into the window
};

const modelSlice = createSlice({
  name: "Model",
  initialState,
  reducers: {
    setKnittingMethod(state, action: PayloadAction<"raglan" | "stitched_sleeves">){
      state.knittingMethod = action.payload;
    },
    setMarginBottomModel(state, action: PayloadAction<number>) {
      state.marginBottomModel = action.payload;
    },
    setDisableModel(state, action: PayloadAction<boolean>) {
      state.disableModel = action.payload;
    },
  },
});

export const { setKnittingMethod, setMarginBottomModel, setDisableModel } = modelSlice.actions;

export { modelSlice };
