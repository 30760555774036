import React from "react";
import { useTranslation } from "react-i18next";
import { ModelKnitState } from "../store/pattern";
import styles from "./ModelKnitStateTags.module.css";

type Props = {
  state: ModelKnitState;
};

const ModelKnitStateTags: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const getSchedule = (schedule: ModelKnitState["schedule"]) => {
    switch (schedule) {
      case "on-time":
        return <div className={styles.positive}>{t("on time")}</div>;
      case "delayed":
        return <div className={styles.contrast}>{t("delayed")}</div>;
      case "none":
        return null;
    }
  };

  const getProgress = (progress: ModelKnitState["progress"]) => {
    switch (progress.status) {
      case "waiting-for-knitter":
        return (
          <div className={styles.contrast}>{t("waiting for knitter")}</div>
        );
      case "knitter-accepted":
        return <div className={styles.positive}>{t("knitter approved")}</div>;
      case "yarn-received":
        return <div className={styles.positive}>{t("yarn received")}</div>;
      case "knitting":
        return (
          <div
            className={styles.positive}
          >{`Strikker ${progress.details.toLowerCase()}`}</div>
        );
      case "sent-back":
        return <div className={styles.positive}>{t("sent back")}</div>;
      case "received":
        return <div className={styles.positive}>{t("received")}</div>;
    }
  };

  return (
    <div className={styles.container}>
      {getSchedule(state.schedule)}
      {getProgress(state.progress)}
    </div>
  );
};

export default ModelKnitStateTags;
