import React from "react";
import { CellClone } from "./CellClone";
import { emptyArray } from "./PreviewBar";
import { useGridVariables } from "./utils/hooks";

export const GridClone = () => {
  const { gridHeight, gridWidth } = useGridVariables();
  return (
    <>
      {emptyArray(gridHeight).map((_, y) =>
        emptyArray(gridWidth).map((_, x) => {
          return <CellClone x={x} y={y} />;
        })
      )}
    </>
  );
};
