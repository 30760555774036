import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import { setCursorMode } from "../../store/gridSlice";
import { RootState } from "../../store/store";
import { CursorArrowIcon, Pencil1Icon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { Eraser } from "../../CommonComponents/Icons/Eraser";

export const CursorMode = () => {
  const { t } = useTranslation();
  const { cursorMode } = useSelector((state: RootState) => state.grid);
  const dispatch = useDispatch();

  return (
    <>
      <Button
        title={t("draw.menu.edit colors")}
        active={cursorMode === "color"}
        onClick={() => dispatch(setCursorMode({ mode: "color" }))}
        contrast
      >
        <Pencil1Icon />
      </Button>
      <Button
        title={t("draw.menu.choose cell")}
        active={cursorMode === "cursor"}
        onClick={() => dispatch(setCursorMode({ mode: "cursor" }))}
        contrast
      >
        <CursorArrowIcon />
      </Button>
      <Button
        title={t("draw.menu.eraser")}
        active={cursorMode === "erase"}
        onClick={() => dispatch(setCursorMode({ mode: "erase" }))}
        contrast
      >
        <Eraser />
      </Button>
    </>
  );
};
