import React, { useEffect, useState } from "react";
import {
  Color,
  OnlyColor,
  YarnColor,
  YarnProfileVariant,
} from "../store/pattern";
import styles from "./YarnVariantListItem.module.css";
import Button from "./Components/Button";
import { setCustomAmount } from "./displayState";
import ImageAsColorPreview from "../CommonComponents/ImageAsColorPreview";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";

interface PropType {
  color: Color;
  index: number;
  activeSizes: string[];
  variant: YarnProfileVariant;
}

export const ColorItem = (props: PropType) => {
  const { color, index, activeSizes, variant } = props;
  const dispatch = useDispatch();

  const customColors = useSelector(
    (state: RootState) => state.displayState.customAmounts
  );

  const [amount, setAmount] = useState(
    (customColors[index] || variant.amounts[activeSizes[0]]?.[index]) ?? 0
  );

  useEffect(() => {
    setAmount(variant.amounts[activeSizes[0]]?.[index]);
  }, [activeSizes]);

  useEffect(() => {
    const newAmounts = [...customColors];
    newAmounts[index] = amount;
    dispatch(setCustomAmount(newAmounts));
  }, [amount]);

  return (
    <div
      className={styles.bodyItem}
      key={
        color.sku === null
          ? `nonYarnColor-${color.hex.slice(1)}`
          : `${color.yarn.name}-${color.name}-img`
      }
    >
      <div className={styles.imageText}>
        {
          <div
            key={
              color.sku
                ? `${color.yarn.sku}-${color.sku}`
                : `nonYarnColor-${(color as OnlyColor).hex.slice(1)}`
            }
            className={styles.imageContainer}
          >
            {color.hex ? (
              <div
                className={styles.colorPreview}
                style={{ backgroundColor: color.hex }}
              />
            ) : (
              <ImageAsColorPreview color={color as YarnColor} />
            )}
          </div>
        }
        <p className={styles.colorText}>
          {color.sku === null
            ? `Farge – ${color.hex}`
            : `${color.yarn.name} – ${color.name} ${color.code}`}
        </p>
      </div>
      {activeSizes.length === 1 && (
        <div className={styles.customYarnAmount}>
          <div className={styles.customYarnAmountLabel}>
            {amount} {amount === 1 ? "nøste" : "nøster"}
          </div>
          <Button onClick={() => setAmount(amount + 1)}>
            <PlusIcon />
          </Button>
          <Button onClick={() => setAmount(amount - 1 < 0 ? 0 : amount - 1)}>
            <MinusIcon />
          </Button>
        </div>
      )}
    </div>
  );
};
