import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import { toggleYarnOpen, togglePatternOpen } from "../store/adminSlice";

import YarnAdmin from "./Yarn";
import PatternAdmin from "./Pattern";

import styles from "./Admin.module.css";
import { ColorPicker } from "./ColorPicker";

function Admin() {
  const admin = useSelector((state: RootState) => state.admin);

  const dispatch = useDispatch();

  return (
    <div className={styles.background}>
      {admin.yarn.open && <ColorPicker />}
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Admin</h1>
        <h2 className={styles.sectionHeader}>Yarn</h2>
        <div className={styles.toggleBox}>
          <button
            className={styles.toggleButton}
            onClick={() => dispatch(toggleYarnOpen())}
          >
            {admin.yarn.open ? "Hide yarn admin" : "Load yarn admin"}
          </button>
          {admin.yarn.open && (
            <>
              <YarnAdmin />
              <button
                className={styles.toggleButton}
                onClick={() => dispatch(toggleYarnOpen())}
              >
                Hide yarn admin
              </button>
            </>
          )}
        </div>
        <h2 className={styles.sectionHeader}>Pattern</h2>
        <div className={styles.toggleBox}>
          <button
            className={styles.toggleButton}
            onClick={() => dispatch(togglePatternOpen())}
          >
            {admin.pattern.open ? "Hide pattern admin" : "Load pattern admin"}
          </button>
          {admin.pattern.open && (
            <>
              <PatternAdmin />
              <button
                className={styles.toggleButton}
                onClick={() => dispatch(togglePatternOpen())}
              >
                Hide pattern admin
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
