import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import LogoutButton from "../Login/LogoutButton";
import styles from "./Projects.module.css";
import ProjectTable from "./ProjectTable";
import { reset as patternReset } from "../store/patternSlice";
import { reset as gridReset } from "../store/gridSlice";
import { MenuDrawerButton } from "../CommonComponents/MenuDrawerButton";
import { useFetchLibraryQuery } from "../services/patternApi";
import { Wrapper } from "../CommonComponents/Wrapper";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useFetchLibraryQuery();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(patternReset());
    dispatch(gridReset());
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.projectContainer}>
        <div className={styles.buttonGroup}>
          <MenuDrawerButton />
          <LogoutButton />
        </div>
      </div>
      <Wrapper withLoadingAnimation isLoading={isLoading} error={error}>
        {data && (
          <>
            <div className={styles.projectContainer}>
              <ProjectTable patterns={data.mine} title={t("my_patterns")} />
            </div>
            {data.organizations.map((organization) => (
              <div key={organization.id} className={styles.projectContainer}>
                <ProjectTable
                  title={organization.name}
                  patterns={organization.patterns ?? []}
                  organizationId={organization.id}
                />
              </div>
            ))}
            {data.edit.length > 0 && (
              <div className={styles.projectContainer}>
                <ProjectTable
                  title={"Oppskrifter med skrivetilgang"}
                  patterns={data.edit}
                />
              </div>
            )}
            {data.comment.length > 0 && (
              <div className={styles.projectContainer}>
                <ProjectTable
                  title={"Oppskrifter som du kan kommentere"}
                  patterns={data.comment}
                />
              </div>
            )}
            {data.all && data.all.length > 0 ? (
              <div className={styles.projectContainer}>
                <ProjectTable
                  title={"Alle oppskrifter (admin)"}
                  patterns={data.all}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Wrapper>
    </div>
  );
};
export { Projects };
