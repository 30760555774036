import React from "react";
import styles from "../Styles/Sections.module.css";
import mobileImgShop from "../../Visualizing/LandingPage/ShopWoolit.png";
import mobileImageOppskrift from "../../Visualizing/LandingPage/OppskriftPrototype.png";
import verticalLine from "../../Visualizing/LandingPage/VerticalLine.png";
import arrow from "../../Visualizing/LandingPage/arrow.svg";

function WoolitShopSection() {
  return (
    <div className={styles.woolitShopSection}>
      <div style={{ justifyItems: "center" }}>
        <div className={styles.shopPictureContainer}>
          <img
            className={styles.topImage}
            src={mobileImgShop}
            alt="Mobilt skjermbilde av Woolit Shop"
          />
          <img
            className={styles.bottomImage}
            src={mobileImageOppskrift}
            alt="Mobilt skjermbilde av Woolit Design Studio Pro"
          />
        </div>
        <div
          style={{
            color: "var(--whiteish)",
            textAlign: "center",
          }}
        >
          Utforsk{" "}
          <a
            href="https://shop.woolit.no"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Woolit Shop
          </a>{" "}
          <img src={arrow} alt="Pil"></img>{" "}
        </div>
      </div>
      <img
        className={styles.verticalLine}
        src={verticalLine}
        alt="Vannrett linje"
      />

      <div className={styles.shopDescriptionContainer}>
        <div
          style={{
            color: "var(--whiteish)",
            fontSize: "36px",
          }}
        >
          Woolit Shop lar deg tjene på din hobby
        </div>
        <ul>
          <li>Mer kreativitet, mindre teknalitet og administrasjon</li>
          <li> Enkel publisering fra Design Studio</li>
          <li> Oppskrifter automatisk integrert med garnpakke utvalg</li>
        </ul>
      </div>
    </div>
  );
}

export default WoolitShopSection;
