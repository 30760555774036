import { SweaterPart } from "../SweaterPart";
import { Settings } from "../static/settings";
import { Pattern } from "../Pattern";
import { DrawTypes, SweaterPartAreaGroup } from "../enums";
import { mixColors } from "../knittingpreview/colorutil";
import { Util } from "../static/util";
import { colorsScene, getSweaterParts } from "../knittingpreview/scene";
import { Global } from "../static/global";
import { Vector2 } from "three";
import { shortUUID } from "../../utils/uuid";

let shirt_uv: HTMLImageElement;
export let prevSetupSweaterPart: SweaterPart | undefined;

let grid: any[][];
let last_previews: any[][] = [];

export let hasLoadedImages: boolean;

export function setHasLoadedImages(newValue: boolean){
  hasLoadedImages = newValue
}

export let lastVisitedBucket: any = {};

function make2DArray(x: number, y: number, fillWith: any = -2) {
  return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
}

//For redo/undo. Drawing should use draw()
export function setGrid2(newValue: any) {
  grid = newValue;
}

export function getGrid() {
  //return [...grid] // New reference for SetGridSlow
  if (!grid) return undefined;
  const shallowGrid = [];
  for (let innerGrid of grid) {
    shallowGrid.push([...innerGrid]);
  }
  return shallowGrid;
}

function loadImages(knittingMethod: string) {
  let waitForLoad = [];

  shirt_uv = new Image(4096, 4096);
  shirt_uv.src = `/3D/shirt/diffuse_sweater_${knittingMethod}.png`;
  waitForLoad.push(shirt_uv);

  return waitForLoad;
}

export type drawSelectionProps = {
  sweaterParts: SweaterPart[];
  pattern: Pattern;
  x: any;
  y: any;
  gridHTML: HTMLDivElement;
  drawType?: DrawTypes;
  sweaterPartInGrid?: SweaterPart;
  preview?: boolean;
  gap?: number;
  mirror?: number;
  repeat?: boolean
};

export function drawSelection({
  sweaterParts,
  pattern,
  x,
  y,
  gridHTML,
  drawType,
  sweaterPartInGrid,
  preview = false,
  gap = 0,
  repeat = false
}: drawSelectionProps) {
  _clearPreview();
  const anchor = pattern.anchor();
  const anchorX = anchor[0];
  const anchorY = anchor[1];
  pattern.groupID = shortUUID()
  for (let sweaterPart of sweaterParts) {

    const isSelected = sweaterPart === sweaterPartInGrid;
    if (!isSelected && preview) continue;

    const [dxCross, dyCross] = crossAlign(sweaterPart, sweaterPartInGrid!)

    const pos = new Vector2(x - anchorX, y - anchorY)
    pos.x += dxCross
    pos.y += dyCross

    sweaterPart.savePattern(pos.x, pos.y, pattern, preview);

    sweaterPart.drawPattern(
      pos,
      pattern,
      preview,
      isSelected ? gridHTML : undefined,
    );
  }
  const pos = x - anchorX - sweaterPartInGrid!.dx;
  let target = sweaterPartInGrid!.sizeX / 2;
  const m = pattern.sizeX() + gap;
  if (gap > 0) {
    target += Math.floor(gap / 2);
  }
  return (
    Util.mod(pos - target, m) === 0 ||
    Util.mod(pos - target, m) === Math.ceil(pattern.sizeX() / 2)
  );
}

/*export function addSelection(sweaterPart: any, x: any, y: any) {
    let selectedX = x
    let selectedY = y
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2, true)
}

/*export function removeSelection(sweaterPart: any) {
    let selectedX = state.selectedTilePos[0]
    let selectedY = state.selectedTilePos[1]
    let temp = [...state.selectedTilePos]
    state.selectedTilePos = [-999, -999]
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2)
    state.selectedTilePos = [...temp]
}*/

export function clearGrid() {
  grid = make2DArray(Global.gridSizeX, Global.gridSizeY);
}

function calculateGridSize(sweaterPart: any) {
  const dx = sweaterPart.dx;
  const dy = sweaterPart.dy;
  const sizeX = sweaterPart.sizeX;
  const sizeY = sweaterPart.sizeY;

  const startX = dx;
  const startY = dy;
  const endX = sizeX + dx;
  const endY = sizeY + dy;

  let highestX = 0;
  let highestY = 0;
  let lowestX = 99;
  let lowestY = 99;
  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (sweaterPart.isMask(x, y)) {
        //Do four corner checks
        if (x > highestX) {
          highestX = x;
        }
        if (y > highestY) {
          highestY = y;
        }
        if (x < lowestX) {
          lowestX = x;
        }
        if (y < lowestY) {
          lowestY = y;
        }
      }
    }
  }
  const diffx = lowestX;
  if (diffx !== 0) {
    console.log(
      "Error: Missaligned sweaterpart, expected 0, but got x:" + diffx
    );
  }
  const diffy = lowestY;
  if (diffy !== 0) {
    //console.log("Error: Missaligned sweaterpart, expected 0, but got y:" + diffy)
  }
  highestX += dx + 1;
  highestY += dy + 1;
  return [highestX, highestY];
}

function setupSweaterPart(sweaterPart: SweaterPart) {
  prevSetupSweaterPart = sweaterPart;
  const gridSizes = calculateGridSize(sweaterPart);

  Global.gridSizeX = gridSizes[0];
  Global.gridSizeY = gridSizes[1];
  //Should be performed if:
  //* Size is changed
  //* Draw is performed on a new selectedSweaterPart
  clearGrid();
}

export function updateGridHTML(
  x: number,
  y: number,
  newValue: number,
  gridHTML: HTMLDivElement | undefined,
  preview: boolean,
  dirs: any[],
  isClearPreview: boolean = false
) {
  const borderColorOutlineRGBstring = Settings.borderColorOutlineRGBstring;
  const borderColorOutlineSnapRGBstring =
    Settings.borderColorOutlineSnapRGBstring;
  const borderPosColors = [
    "borderTopColor",
    "borderLeftColor",
    "borderBottomColor",
    "borderRightColor",
  ] as const;
  const borderColor = Util.calculateBorderColor(
    x,
    y,
    Util.colorIndexToColor(colorsScene, newValue),
    newValue < -1,
  );
  const hasUpdatedBorder = !dirs.every((it) => it === false);

  const oldValue = grid[y][x];
  // Draw/preview new color
  if (oldValue !== newValue) {
    grid[y][x] = newValue;
    if (gridHTML) {
      const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
      const colorDraw = Util.calculateGridColor(
        x,
        y,
        colorsScene,
        newValue
      );
      const color = preview ? mixColors([colorDraw, "#ffffff"]) : colorDraw;
      gridCell.style.backgroundColor = color;

      for (let borderPosColor of borderPosColors) {
        const isOutline =
          gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
          gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
        if (!isOutline) {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  // Draw/preview brush outline
  if (hasUpdatedBorder && gridHTML) {
    const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
    const borderOutlineColor = "#000000";
    for (let n = 0; n < 4; n++) {
      const borderPosColor = borderPosColors[n];
      const isOutline =
        gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
        gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
      const isPatternOutline = dirs[n];
      if (!isOutline && isPatternOutline) {
        if (!isClearPreview) {
          gridCell.style[borderPosColor] = borderOutlineColor;
        } else {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  if (preview) {
    last_previews.push([x, y, oldValue, gridHTML, dirs]);
  }
  //Ugly: Necessary to clear eraser outline
  if (!preview && hasUpdatedBorder && !isClearPreview) {
    last_previews.push([x, y, newValue, gridHTML, dirs]);
  }
}
export function crossAlign(sweaterPart: SweaterPart, sweaterPartInGrid: SweaterPart){
  const isSweater = sweaterPart.isSweaterPart();
  if (isSweater) {
    const selectedSweaterPart = sweaterPartInGrid as SweaterPart;
    if (selectedSweaterPart.areaGroup() !== sweaterPart.areaGroup()) {
      const [_dx, _dx2] = [sweaterPart.dx, selectedSweaterPart.dx];
      const [_dy, _dy2] = [sweaterPart.dy, selectedSweaterPart.dy];
      const dxDiff = _dx - _dx2;
      const dyDiff = _dy - _dy2;

      const [_sizeX, _sizeX2] = [sweaterPart.sizeX, selectedSweaterPart.sizeX];
      const sizeXDiff = _sizeX - _sizeX2;
      const mirrorXDiff = sizeXDiff / 2;

      const topArmYDiff = (sweaterPart.connectY - selectedSweaterPart.connectY)
      
      const dxCross = dxDiff + mirrorXDiff;
      const dyCross = dyDiff + topArmYDiff;
      return [dxCross, dyCross]
    }
  }
  return [0,0]
}

export function _clearPreview() {
  if (last_previews.length !== 0){
    lastVisitedBucket = {};
    // Opposite order, or else overlapping drawn areas will override incorrectly
    for (let n = last_previews.length - 1; n >= 0; n--) {
      const last_preview_info = last_previews[n];
      const x = last_preview_info[0];
      const y = last_preview_info[1];
      const oldBrushColor = last_preview_info[2];
      const gridHTML = last_preview_info[3];
      const dirs = last_preview_info[4];
      updateGridHTML(
        x,
        y,
        oldBrushColor,
        gridHTML,
        false,
        dirs,
        true
      );
    }
    last_previews = [];
  } 
  let sweaterParts = getSweaterParts()
  if (sweaterParts){
    for (let sweaterPart of sweaterParts){
      sweaterPart.clearPreview();
    }
  }
}

export function drawGrid(
  sweaterPart: SweaterPart,
  startX: number = 0,
  startY: number = 0,
  endX: number = Infinity,
  endY: number = Infinity
) {
  _clearPreview();

  setupSweaterPart(sweaterPart);

  const dx = sweaterPart?.dx ?? 0;
  const dy = sweaterPart?.dy ?? 0;

  const sizeX = sweaterPart?.sizeX ?? 0;
  const sizeY = sweaterPart?.sizeY ?? 0;

  endX = Math.min(sizeX + dx, endX);
  endY = Math.min(sizeY + dy, endY);

  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (y < dy || x < dx) {
        continue;
      }
      if (sweaterPart.isMask(x, y)) {
        let drawColor = sweaterPart.grid[y][x];
        updateGridHTML(
          x,
          y,
          drawColor,
          undefined,
          false,
          [false, false, false, false],
          false
        );
      }
    }
  }
}

export function onLoadImages(sweaterPart: SweaterPart, setGrid: any) {
  let canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = 4096;
  canvas.height = 4096;
  let ctx = canvas.getContext("2d")!!;
  ctx.drawImage(shirt_uv, 0, 0);
  Global.imageData = ctx.getImageData(0, 0, 4096, 4096);

  _loadGrid(sweaterPart, setGrid);

  hasLoadedImages = true;
}

function _loadGrid(sweaterPart: SweaterPart, setGrid: any) {
  drawGrid(sweaterPart);
  setGrid(getGrid());
}

export function loadGrid(sweaterPart: SweaterPart, setGrid: any, knittingMethod: string) {
  if (!hasLoadedImages) {
    let waitForLoad = loadImages(knittingMethod);
    for (let image of waitForLoad) {
      image.onload = () => {
        waitForLoad.pop();
        if (waitForLoad.length === 0) {
          onLoadImages(sweaterPart, setGrid);
        }
      };
    }
  } else {
    _loadGrid(sweaterPart, setGrid);
  }
}
