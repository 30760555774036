import React, { useState } from "react";
import { useModelKnittersQuery } from "../../services/modelknit";
import { Knitter, ModelKnitGroup } from "../../store/pattern";
import { Listbox } from "@headlessui/react";
import styles from "./modelknit.module.css";
import { TriangleUpIcon, TriangleDownIcon } from "@radix-ui/react-icons";

interface PropType {
  orgId: number;
  tag: string;
  setKnitters: (id: number) => void;
}

export const KnitterSelect = (props: PropType) => {
  const { tag, orgId, setKnitters } = props;
  const [activeKnitter, setActiveKnitter] = useState<Knitter>({
    name: "Alle strikkere",
    tag: "all",
    id: -1,
  });

  const { data, isLoading } = useModelKnittersQuery(`${orgId}`);

  if (isLoading || !data) {
    return <select />;
  }
  const knitGroup = data.find((group: ModelKnitGroup) => group.tag === tag);
  const options = [
    { name: "Alle", tag: "all" },
    ...(knitGroup?.knitters ?? []),
  ];

  return (
    <div style={{ position: "relative" }}>
      <Listbox
        onChange={(knitter) => {
          if (!knitter) return;
          setKnitters(knitter.id);
          setActiveKnitter(knitter);
        }}
        value={activeKnitter}
      >
        {({ open }) => (
          <>
            <Listbox.Button className={styles.listBoxButton}>
              {activeKnitter.name}
              {open ? <TriangleUpIcon /> : <TriangleDownIcon />}
            </Listbox.Button>
            <Listbox.Options className={styles.listBoxOptions}>
              {options.map((knitter, i) => (
                <Listbox.Option key={i} value={knitter}>
                  {({ selected, active }) => (
                    <div
                      className={`${active ? styles.listBoxActive : ""} ${
                        styles.listBoxOption
                      }`}
                    >
                      {knitter.name}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};
