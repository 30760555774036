import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./MetadataDrawer.module.css";
import { setMetadata } from "../store/patternSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { RootState } from "../store/store";
import { useTranslation } from "react-i18next";

const DrawerTitle: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { title } = useSelector((state: RootState) => state.pattern.meta);
  const { drawerOpen } = useSelector((state: RootState) => state.writer);
  const [titleInput, setTitleInput] = useState(title);
  const [colorStyle, setColorStyle] = useState("var(--neutral-40)");
  const dispatch = useDispatch();

  useEffect(() => {
    setTitleInput(title);
  }, [title]);

  if (drawerOpen) {
    setDisableHotkeys(true);
  }

  // Handling input onChange event
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitleInput(event.target.value);
  };

  // Handling input onFocus event
  const focusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setDisableHotkeys(true);
    setColorStyle("var(--positive)");
  };

  // Handling input onBlur event
  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setDisableHotkeys(false);
    dispatch(setMetadata({ title: titleInput }));
    titleInput
      ? setColorStyle("var(--darkest-brown)")
      : setColorStyle("var(--neutral-40)");
  };

  return (
    <div className="container" style={{ width: "100%", marginTop: "4vh" }}>
      {title ? (
        <p className={styles.smallSubtitle}>{t("datadrawer.pattern title")}</p>
      ) : (
        <></>
      )}
      <input
        type="text"
        onFocus={focusHandler}
        onBlur={blurHandler}
        value={titleInput}
        onChange={changeHandler}
        style={{ color: colorStyle }}
        className={styles.drawerTitle}
        placeholder={t("datadrawer.pattern title")}
      />
    </div>
  );
};

export default DrawerTitle;
