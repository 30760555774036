import React from "react";

interface PropType {
  color?: string;
}

function CaretRight(props: PropType) {
  const { color = "#563E2C" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M13 10L8 7.113v5.774L13 10zm-5 .5h.5v-1H8v1z"
      ></path>
    </svg>
  );
}

export default CaretRight;
