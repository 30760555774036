import React from "react";
import process1 from "../../Visualizing/LandingPage/designstudio_process_1.png";
import process2 from "../../Visualizing/LandingPage/designstudio_process_2.png";
import process3 from "../../Visualizing/LandingPage/designstudio_process_3.png";
import styles from "../Styles/Sections.module.css";

function ProcessSection() {
  return (
    <div className={styles.processSection}>
      <div className={styles.processImageContainer}>
        <div className={styles.processImageCard}>
          <img
            width="270"
            height="220"
            src={process1}
            style={{ alignSelf: "center" }}
            alt="Illustrasjon av Woolit Design Studio Pro"
          />
          <div className={styles.processImageDescription}>
            <div className={styles.processIcon}>1</div>
            <div>
              Skap digitale oppskrifter i Woolit Design Studio Pro.<br></br>
            </div>
          </div>
        </div>

        <div className={styles.processImageCard}>
          <img
            width="270"
            height="220"
            src={process2}
            alt="Strikket genser og oppskrift  til salg."
          />
          <div className={styles.processImageDescription}>
            <div className={styles.processIcon}>2</div>
            <div>
              Publiser strikkeoppskrifter med et klikk!<br></br>
              <a
                href="https://shop.woolit.no"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "var(--contrast" }}
              >
                Utforsk shop ➚
              </a>
            </div>
          </div>
        </div>

        <div className={styles.processImageCard}>
          <img width="270" height="220" src={process3} alt="Jordkloden" />
          <div className={styles.processImageDescription}>
            <div className={styles.processIcon}>3</div>
            <div>Få dine design ut i verden!</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessSection;
