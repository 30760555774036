import { PatternSales } from "../types/backend-generated/PatternSales";
import { formatCentsNOK } from "../utils/currencyFormat";
import PatternSaleOverview from "./PatternSaleOverview";
import styles from "./SalesOverview.module.css";
import { filterDesignerSales } from "./SalesUtils";

function MonthOverview({
  monthName,
  month,
  year,
  sales,
}: {
  monthName: string;
  year: number;
  month: number;
  sales: PatternSales[] | undefined;
}) {
  const [salesSet, monthsTotalSum] = sales
    ? filterDesignerSales(sales, month, year)
    : [null, 0];

  return (
    <div>
      <div className={styles.monthTitle}>
        {monthName}, {year}
      </div>
      <div className={styles.monthOverview}>
        <div className={styles.monthTableHeader}>
          <div className={styles.tableItem}>Produkt navn:</div>
          <div className={styles.tableItem}>ID:</div>
          <div className={styles.tableItemSales}>Antall salg:</div>
          <div className={styles.tableItemSum}>Inntjening:</div>
          <div className={styles.tableItemDetails}>Detaljer:</div>
        </div>
        <div className={styles.monthOverviewTable}>
          {salesSet &&
            Array.from(salesSet).map((pattern: PatternSales) => {
              return (
                <PatternSaleOverview
                  patternSales={pattern}
                  key={pattern.patternId}
                  month={month}
                  year={year}
                />
              );
            })}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className={styles.totalMonthSum}>
            Total: {formatCentsNOK(monthsTotalSum)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthOverview;
