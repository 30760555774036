import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Bottom from "./CommonComponents/Bottom";
import { Loading } from "./CommonComponents/Loading";
import Top from "./CommonComponents/Top";
import { fetchMe } from "./Login/components/LoginState";
import { fetchPatternById } from "./store/patternSlice";
import { RootState } from "./store/store";
import styles from "./Writer.module.css";
import SlateWriter from "./Writer/SlateWriter";

export default function Writer() {
  // URL parameter
  const { patternId } = useParams();

  // State
  const { pattern, loginState } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  const getUserCallback = useCallback(
    () => (loginState.isLoggedIn ? null : dispatch(fetchMe())),
    [dispatch, loginState.isLoggedIn]
  );

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  useEffect(() => {
    getUserCallback();
  }, [getUserCallback]);

  if (!patternId) {
    return null;
  }

  return (
    <>
      <Top />
      {pattern.id && pattern.id !== -1 ? (
        <div className={styles.writer}>
          <SlateWriter
            patternElements={pattern.patternElements}
            patternId={patternId}
          />
        </div>
      ) : (
        <Loading />
      )}
      {pattern.id && pattern.id !== -1 && <Bottom pattern={pattern} />}
    </>
  );
}
