import { formatCents } from "../utils/currencyFormat";
import styles from "./SalesOverview.module.css";

function MainOverview({
  totalSum,
  monthSum,
  monthItemNumber,
  monthDifference,
}: {
  totalSum: any;
  monthSum: any;
  monthItemNumber: any;
  monthDifference: number;
}) {
  return (
    <div className={styles.mainOverview}>
      <div className={styles.overviewCard}>
        <div>Totalinntekt</div>
        <div className={styles.overviewStatisticValue}>
          {formatCents(totalSum)}{" "}
        </div>
      </div>
      <div className={styles.overviewCard}>
        <div>Inntekt siste 30 dager</div>
        <div className={styles.overviewStatisticValue}>
          {formatCents(monthSum)}{" "}
        </div>
      </div>

      <div className={styles.overviewCard}>
        <div>Salg siste 30 dager</div>
        <div className={styles.overviewStatisticValue}>{monthItemNumber}</div>
      </div>
      <div className={styles.overviewCard}>
        <div>Endring fra forrige periode</div>
        <div className={styles.periodChangeValue}>
          {monthDifference.toFixed(1)}%
        </div>
      </div>
    </div>
  );
}

export default MainOverview;
