import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { toggleDrawer } from "../store/writerSlice";
import styles from "./EditNavbar.module.css";
import { RootState } from "../store/store";

const EditNavbar = () => {
  const { pathname } = useLocation();
  const [, , patternId, mode] = pathname.split("/");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      <div>
        <button
          style={{ marginRight: 16 }}
          onClick={() => {
            dispatch(setDisableHotkeys(true));
            dispatch(toggleDrawer());
            dispatch(setDisable(true));
          }}
          className={styles.navButton}
        >
          {t("information")}
        </button>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link to={`/edit/${patternId}/write`}>
          <button
            className={`${mode === "write" ? styles.active : ""} ${
              styles.left
            } ${styles.navButton}`}
          >
            {t("write")}
          </button>
        </Link>
        <Link to={`/edit/${patternId}/draw`}>
          <button
            className={`${mode === "draw" ? styles.active : ""} ${
              styles.middle
            } ${styles.navButton}`}
          >
            {t("diagram")}
          </button>
        </Link>
        <Link to={`/edit/${patternId}/calculate`}>
          <button
            className={`${mode === "calculate" ? styles.active : ""} ${
              styles.right
            } ${styles.navButton}`}
          >
            {t("calculate")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default EditNavbar;
