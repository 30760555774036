import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column, useTable } from "react-table";
import { Modal } from "../CommonComponents/Modal";
import { useModal } from "../CommonComponents/useModal";
import {
  Price,
  useDeletePriceMutation,
  usePriceListQuery,
  useUpdatePriceMutation,
} from "../services/modelknit";
import Dropdown from "./Dropdown";
import NewPriceModal from "./NewPriceModal";
import Table from "./Table";
import styles from "./Table.module.css";

type Props = {
  orgId: string;
};

const PriceListTable: React.FC<Props> = ({ orgId }) => {
  const { t } = useTranslation();
  const priceListQuery = usePriceListQuery(orgId);
  const [deletePrice] = useDeletePriceMutation();
  const [updatePrice] = useUpdatePriceMutation();
  const data = priceListQuery.data ?? [];

  const newPriceModal = useModal();
  const [editingPriceId, setEditingPriceId] = useState(-1);
  const updatedPrice = useRef<Price>();

  const dropdownOptions = [t("navigation.edit"), t("navigation.delete")];
  const onOptionSelect = (optionIndex: number, priceId: number) => {
    if (optionIndex === 0) {
      setEditingPriceId(priceId);
    }
    if (optionIndex === 1) {
      deletePrice({ orgId, priceId });
    }
  };

  const handleUpdatePrice = () => {
    if (!updatedPrice.current) {
      return;
    }
    if (
      data.find(
        (price) =>
          price.name === updatedPrice.current?.name &&
          price.id !== updatedPrice.current.id
      )
    ) {
      alert(t("price error.name"));
      return;
    }
    if (
      updatedPrice.current.name.length === 0 ||
      updatedPrice.current.priceOre <= 0
    ) {
      alert(t("price error.amount"));
      return;
    }
    updatePrice({ orgId, ...updatedPrice.current })
      .unwrap()
      .then(exitEditPrice);
  };

  const exitEditPrice = () => {
    setEditingPriceId(-1);
    updatedPrice.current = undefined;
  };

  const columns: Column<Price>[] = useMemo(() => {
    const columns: Column<Price>[] = [
      { Header: "ID", accessor: "id" },
      {
        Header: `${t("name")}`,
        accessor: "name",
        Cell: ({ row }: CellProps<Price>) => {
          return row.values["id"] === editingPriceId ? (
            <input
              type="text"
              defaultValue={row.values["name"]}
              onChange={(e) =>
                updatedPrice.current
                  ? (updatedPrice.current.name = e.target.value)
                  : (updatedPrice.current = {
                      ...row.original,
                      name: e.target.value,
                    })
              }
            />
          ) : (
            row.values["name"]
          );
        },
      },
      {
        Header: `${t("price ore")}`,
        accessor: "priceOre",
        Cell: ({ row }: CellProps<Price>) => {
          return row.values["id"] === editingPriceId ? (
            <input
              type="number"
              defaultValue={row.values["priceOre"]}
              onChange={(e) =>
                updatedPrice.current
                  ? (updatedPrice.current.priceOre = parseInt(e.target.value))
                  : (updatedPrice.current = {
                      ...row.original,
                      priceOre: parseInt(e.target.value),
                    })
              }
            />
          ) : (
            row.values["priceOre"]
          );
        },
      },
      {
        id: "options",
        accessor: "id",
        Cell: ({ row }: CellProps<Price>) =>
          row.values["id"] === editingPriceId ? (
            <div>
              <button onClick={handleUpdatePrice}>
                <CheckIcon />
              </button>
              <button onClick={exitEditPrice}>
                <Cross1Icon />
              </button>
            </div>
          ) : (
            <Dropdown
              onOptionSelect={(optionIndex) =>
                onOptionSelect(optionIndex, row.values["id"])
              }
              options={dropdownOptions}
            />
          ),
      },
    ];
    return columns;
  }, [editingPriceId]);

  const instance = useTable({ columns, data });
  return (
    <>
      <div className={styles.titleContainer}>
        <h1>{t("price list")}</h1>
        <button onClick={() => newPriceModal.setIsShown(true)}>
          {t("new price")}
        </button>
        <Modal
          isShown={newPriceModal.isShown}
          hide={() => newPriceModal.setIsShown(false)}
          modalContent={
            <NewPriceModal
              orgId={orgId}
              onSubmit={() => newPriceModal.setIsShown(false)}
            />
          }
          headerText=""
        />
      </div>
      <Table instance={instance} />
    </>
  );
};

export default PriceListTable;
