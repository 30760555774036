import { PatternSale } from "../types/backend-generated/PatternSale";
import { formatCents } from "../utils/currencyFormat";
import { formatLocaleDate } from "../utils/dateFormat";
import styles from "./SalesOverview.module.css";

function Sales({ sales }: { sales: PatternSale[] }) {
   
  return (
    <div className={styles.salesTable}>
      <div className={styles.tableHeader}>
        <div className={styles.headerItem}><div className={styles.date}>Dato:</div></div>
        <div className={styles.headerItem}> <div className={styles.pattern}>Oppskrift:</div></div>
        <div className={styles.headerItem}><div className={styles.yarn}>Garn:</div></div>
        <div className={styles.headerItem}><div className={styles.total}>Totalt:</div></div>
      </div>
      {sales.map((sale: PatternSale) => {
        
        return (
          <div className={styles.tableRow} key={sale.created}>
            <div className={styles.columnItem}>
             
             <div className={styles.date}> {formatLocaleDate(sale.created)} </div>
              
            </div>
            <div className={styles.columnItem}>
              {" "}
              <div className={styles.pattern}>{formatCents(sale.patternDesignerAmountExclVat)} </div>
            </div>
            <div className={styles.columnItem}>
              {" "}
              <div className={styles.yarn}>{`${formatCents(sale.yarnCutAmountExclVat)} (${sale.yarn_cut_percent ? sale.yarn_cut_percent : 0 }%)`}</div>
            </div>
            <div className={styles.columnItem}>
              {" "}
              <div className={styles.total}>{formatCents(sale.totalAmountExclVat ? sale.totalAmountExclVat : 0 )}{" "}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Sales;
