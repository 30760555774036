type FlatCalculationGraph = {
  root: string;
  nodes: { [key: string]: FlatGraphNode }; //{ [key: string]: FlatGraphNode | undefined };
  edges: { [key: string]: FlatGraphEdge }; //{ [key: string]: FlatGraphEdge | undefined };
  elements: { [key: string]: MeasureElement };
  shouldTraverseFullGraph: boolean;
};

type FlatGraphNode = {
  id: string;
  element: string;
  edges: string[];
  locked: boolean;
};

type FlatGraphEdge = {
  id: string;
  horizontalChangeElement: string;
  frequencyElement: string; //avstand mellom endringer og ikke frekvens
  verticalDistanceElement: string;
  parentNode: string;
  childNode: string;
  locked: boolean;
};

type MeasureElement = {
  id: string;
  value: number;
  unit: Unit;
  direction: Direction;
  shouldRound: RoundKind;
  displayValue: number;
  changed: boolean;
};

type Unit = "Mask" | "Cm";
type Direction = "Horizontal" | "Vertical";

type RoundKind = RoundClosest | RoundUp | RoundDown | null;

type RoundClosest = "RoundClosest";
type RoundUp = "RoundUp";
type RoundDown = "RoundDown";

type Change = AtomicChange;

type AtomicChange = {
  id: string;
  elementId: string;
  newValue: number;
  source: ChangeSource;
};

type ChangeSource =
  | NodeDifference
  | EdgeChange
  | RoundChange
  | VerticalChange
  | SleeveBindOffChange
  | TableRelationChange;

type NodeDifference = {
  kind: "NodeDifference"; // childNode - parentNode !== edgeChange
  parentNode: string;
  childNode: string;
};

type EdgeChange = ForwardEdgeChange | BackwardEdgeChange;

type ForwardEdgeChange = {
  kind: "ForwardEdgeChange"; // parentNode + edgeChange !== childNode
  parentNode: string;
  edgeChange: string;
};

type BackwardEdgeChange = {
  kind: "BackwardEdgeChange"; // childNode - edgeChange !== parentNode
  edgeChange: string;
  childNode: string;
};

type RoundChange =
  | RoundTotalChange
  | RoundDistanceChange
  | RoundTotalDistanceChange;

type RoundTotalChange = {
  kind: "RoundTotalChange"; // totalDistance / perChangeDistance !== horizontalChange
  perChangeDistance: string;
  totalDistance: string;
};

type RoundTotalDistanceChange = {
  kind: "RoundTotalDistanceChange"; // horizontalChange * perChangeDistance !== totalDistance
  horizontalChange: string;
  perChangeDistance: string;
};

type RoundDistanceChange = {
  kind: "RoundDistanceChange"; // totalDistance / horizontalChange !== perChangeDistance
  totalDistance: string;
  horizontalChange: string;
};

type VerticalChange =
  | VerticalTotalChange
  | VerticalDistanceChange
  | VerticalTotalDistanceChange;

type VerticalTotalChange = {
  kind: "VerticalTotalChange"; // perChangeMasks * totalDistance / perChangeDistance !== horizontalChange
  totalDistance: string;
  perChangeDistance: string;
  perChangeMasks: number;
};

type VerticalTotalDistanceChange = {
  kind: "VerticalTotalDistanceChange"; // horizontalChange * perChangeDistance / perChangeMasks !== totalDistance
  horizontalChange: string;
  perChangeDistance: string;
  perChangeMasks: number;
};

type VerticalDistanceChange = {
  kind: "VerticalDistanceChange"; // perChangeMasks / horizontalChange * totalDistance !== perChangeDistance
  horizontalChange: string;
  totalDistance: string;
  perChangeMasks: number;
};

type SleeveBindOffChange =
  | SleeveBindOffDistanceBetweenMarksChange
  | SleeveBindOffTotalChange;

type SleeveBindOffDistanceBetweenMarksChange = {
  kind: "SleeveBindOffDistanceBetweenMarksChange";
  marks: number;
  totalDistance: string;
};

type SleeveBindOffTotalChange = {
  kind: "SleeveBindOffTotalChange";
  parentNode: string;
  childNode: string;
};

type TableRelationChange =
  | ForwardTableRelationChange
  | BackwardTableRelationChange;

type ForwardTableRelationChange = {
  kind: "ForwardTableRelationChange";
  relationId: string;
  bottomSum: number;
  scalar: number;
};

type BackwardTableRelationChange = {
  kind: "BackwardTableRelationChange";
  relationId: string;
  topSum: number;
  scalar: number;
};

type CalculationTable = {
  id: string;
  label: string;
  marks: 0 | 1 | 2 | 4; // Ingen, erme, bol, raglan? Flere tilfeller?
  order: string[]; //<Id>[]
  measures: {
    [key: string]: TableMeasure;
  };
};

type TableMeasure = NodeTableMeasure | EdgeTableMeasure;

type NodeTableMeasure = {
  id: string;
  label: string;
  kind: "node";
  nodes: {
    [size: string]: string; // GraphNode or GraphEdge id, according to MeasureKind
  };
};

type EdgeTableMeasure = {
  id: string;
  label: string;
  kind: "edge";
  edges: {
    [size: string]: string; // GraphEdge id
  };
  changeKind:
    | "HorizontalFirst"
    | "HorizontalLast"
    | "Vertical"
    | "SleeveBindOff";
};

type GlobalGauge = {
  horizontal: CalculationGauge;
  vertical: CalculationGauge;
};

type CalculationGauge = {
  manual: boolean;
  stitches: number;
  length: number;
};

type CalculationSliceType = {
  graph: FlatCalculationGraph;
  tables: { [tableId: string]: CalculationTable };
  tableOrder: string[];
  relations: {
    [relationId: string]: TableRelation;
  };
  changes: {
    [changeId: string]: Change;
  };
  gauge: GlobalGauge;
};

type TableRelation = LinearEqualityRelation; // | SomeOtherRelation
type LinearEqualityRelation = {
  id: string;
  kind: "linearEquality";
  top: RelationTableReference[]; //First row in the table (always a node row)
  bottom: RelationTableReference[]; //Last row in the table (always a node row)
};

type RelationTableReference = {
  tableId: string;
  scalar: number;
  dividendMeasure: "first" | "last"; //TODO remove? redundant bcuz LinearEqualityRelation.top and .bottom
};

const emptyCalculationSlice: CalculationSliceType = {
  tables: {},
  tableOrder: [],
  relations: {},
  changes: {},
  gauge: {
    horizontal: {
      manual: false,
      stitches: 18,
      length: 10,
    },
    vertical: {
      manual: false,
      stitches: 18,
      length: 10,
    },
  },
  graph: {
    root: "rootNode",
    nodes: {
      rootNode: {
        id: "rootNode",
        element: "rootElement",
        edges: [],
        locked: true,
      },
    },
    elements: {
      rootElement: {
        id: "rootElement",
        value: 0,
        unit: "Mask",
        direction: "Horizontal",
        shouldRound: null,
        displayValue: 0,
        changed: false,
      },
    },
    edges: {},
    shouldTraverseFullGraph: false,
  },
};

export { emptyCalculationSlice };

export type {
  FlatCalculationGraph,
  FlatGraphNode,
  FlatGraphEdge,
  MeasureElement,
  Unit,
  Direction,
  Change,
  RoundKind,
  CalculationTable,
  TableMeasure,
  CalculationSliceType,
  NodeTableMeasure,
  EdgeTableMeasure,
  TableRelation,
  RelationTableReference,
  CalculationGauge,
  AtomicChange,
  GlobalGauge,
};
