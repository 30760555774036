import { SaleMonth } from "react-table";
import { MenuDrawerButton } from "../CommonComponents/MenuDrawerButton";
import LogoutButton from "../Login/LogoutButton";
import { useGetAllSalesQuery } from "../services/salesOverview";
import { getAllMonths } from "../utils/dateFormat";
import MainOverview from "./MainOverview";
import MonthOverview from "./MonthOverview";
import projectStyles from "../Projects/Projects.module.css";
import {
  calculateSalesLast30Days,
  calculateSalesDifference,
  calculateTotalSum,
  findMonthYearCombinations,
} from "./SalesUtils";
import { Wrapper } from "../CommonComponents/Wrapper";

import { useParams } from "react-router";

import styles from "./SalesOverview.module.css";
import { useTranslation } from "react-i18next";

function SalesOverview() {
  const { orgId } = useParams();

  const { data, isLoading, error } = useGetAllSalesQuery(Number(orgId));
  const patternSales = data?.patternSales;
  const [thisMonthsSum, thisMonthsSales] = patternSales
    ? calculateSalesLast30Days(patternSales)
    : [0, 0];
  const allMonths = patternSales
    ? findMonthYearCombinations(patternSales)
    : null;
  const salesDifference = patternSales
    ? calculateSalesDifference(patternSales)
    : 0;
  const monthNames = getAllMonths();
  const { t } = useTranslation();

  return (
    <div className={styles.overviewContainer}>
      <div
        className={projectStyles.projectContainer}
        style={{ background: "white" }}
      >
        <div className={projectStyles.buttonGroup}>
          <MenuDrawerButton />
          <LogoutButton />
        </div>
      </div>
      <h1 style={{ padding: "20px" }}>{t("sales_overview")}</h1>
      <Wrapper withLoadingAnimation isLoading={isLoading} error={error}>
        <MainOverview
          totalSum={patternSales ? calculateTotalSum(patternSales) : 0}
          monthSum={thisMonthsSum}
          monthItemNumber={thisMonthsSales}
          monthDifference={salesDifference}
        ></MainOverview>

        <div>
          {allMonths &&
            allMonths.map((saleMonth: SaleMonth) => {
              return (
                <div key={saleMonth.month + saleMonth.year}>
                  <MonthOverview
                    monthName={monthNames[saleMonth.month]}
                    month={saleMonth.month}
                    year={saleMonth.year}
                    sales={patternSales}
                  ></MonthOverview>
                </div>
              );
            })}
          {allMonths && allMonths.length === 0 && (
            <div> Du har ingen salg.</div>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

export default SalesOverview;
