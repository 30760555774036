import React from "react";
import { useEffect } from "react";

const SplitView = (props: any) => {
  return !props.children[1] ? (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
      }}
    >
      {props.children[0]}
    </div>
  ) : (
    <>
      <div
        style={{
          position: "relative",
          width: "50vw",
          height: "100vh",
        }}
      >
        {props.children[0]}
      </div>
      <div
        style={{
          position: "relative",
          width: "50vw",
          height: "100vh",
        }}
      >
        {props.children[1]}
      </div>
    </>
  );
};

export default SplitView;
