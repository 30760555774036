import {
  MeasureElement,
  CalculationGauge,
  Unit,
} from "../CalculationGraph/calculations";

function normaliseToCm(element: MeasureElement, gauge: CalculationGauge) {
  // Normalises value of element into cm.
  switch (element.unit) {
    case "Cm":
      return element.value;

    case "Mask":
      return maskToCm(element.value, gauge);
  }
}

function normaliseToMasks(element: MeasureElement, gauge: CalculationGauge) {
  // Normalises value of element into masks
  switch (element.unit) {
    case "Cm":
      return cmToMask(element.value, gauge);

    case "Mask":
      return element.value;
  }
}

function normaliseByUnit(
  element: MeasureElement,
  gauge: CalculationGauge,
  unit: Unit
) {
  // Normalises value of element into the given unit
  switch (unit) {
    case "Cm":
      return normaliseToCm(element, gauge);

    case "Mask":
      return normaliseToMasks(element, gauge);
  }
}

function maskToCm(value: number, gauge: CalculationGauge) {
  // Transforms a value from mask to cm using given gauge
  return (value / gauge.stitches) * gauge.length;
}

function cmToMask(value: number, gauge: CalculationGauge) {
  // Transforms a value from cm to mask using given gauge
  return (value / gauge.length) * gauge.stitches;
}

function maskToUnit(value: number, gauge: CalculationGauge, unit: Unit) {
  // Normalises given value from masks into the given kind
  switch (unit) {
    case "Cm":
      return maskToCm(value, gauge);

    case "Mask":
      return value;
  }
}

function cmToUnit(value: number, gauge: CalculationGauge, unit: Unit) {
  // Normalises given value from cm into the given kind
  switch (unit) {
    case "Cm":
      return value;

    case "Mask":
      return cmToMask(value, gauge);
  }
}

function unitToUnit(
  value: number,
  gauge: CalculationGauge,
  fromUnit: Unit,
  toUnit: Unit
) {
  // Normalises given value from one kind into another
  switch (fromUnit) {
    case "Cm":
      return cmToUnit(value, gauge, toUnit);

    case "Mask":
      return maskToUnit(value, gauge, toUnit);
  }
}

function roundIfMask(value: number, unit: Unit) {
  // If kind is masks, round value
  switch (unit) {
    case "Cm":
      return value;
    case "Mask":
      return Math.round(value);
  }
}

function matchSign(value: number, valueToMatch: number) {
  // force booleans before checking equality
  if (!!(value < 0) !== !!(valueToMatch < 0)) {
    // If sign does not match, flip value
    return -value;
  }
  return value;
}

function lesserOf(firstValue: number, secondValue: number) {
  if (firstValue < secondValue) {
    return firstValue;
  }
  return secondValue;
}

export {
  normaliseToCm,
  normaliseToMasks,
  normaliseByUnit,
  maskToCm,
  cmToMask,
  maskToUnit,
  cmToUnit,
  unitToUnit,
  roundIfMask,
  matchSign,
  lesserOf,
};
