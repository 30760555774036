import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import Square from "./Square";
import styles from "./diagram.module.css";
import {
  copyMarkedArea,
  cutMarkedArea,
  pasteMarkedArea,
  setDisableHotkeys,
  setMarkedArea,
  updateExclusions,
} from "../store/gridSlice";
import { useGridVariables } from "./utils/hooks";
import Draggable from "react-draggable";
import { aabb } from "./utils/aabb";
import { DragSelectionBox } from "./DragSelectionBox";
import { DragMenu } from "./ContextMenu/Drag";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { GridCursor } from "./GridCursor";
import { setBbox, setDisable } from "../store/dragSelectSlice";
import { useEffect } from "react";
import { AddButtons } from "./AddButtons";
import { setDiagram, updateDiagramById } from "../store/patternSlice";
import { RepeatDiagram } from "./RepeatDiagram";
import { AddDiagramCommentModal } from "./AddDiagramCommentModal";
import { Fill, Info } from "../CommonComponents/Icons/Info";
import { DiagramCommentBorder } from "../CommonComponents/DiagramCommentBorder";
import { DynamicSymbol } from "@iterate/woolit-components";
import { useDarkColor } from "../utils/colorContrast";
import { DynamicDiagramSymbol, GridCell } from "../store/pattern";

const Grid = () => {
  const { zoomCount, repeat, cursorMode } = useSelector(
    (state: RootState) => state.grid
  );
  const { sizes } = useSelector((state: RootState) => state.pattern.meta);
  const dispatch = useDispatch();
  const [cellRuler, setCellRuler] = useState({ cellX: 0, cellY: 0 });
  const [modalData, setModalData] = useState<{
    index: number;
    direction: "row" | "col";
  }>({
    index: -1,
    direction: "row",
  });

  useEffect(() => {
    if (modalData.index !== -1) {
      dispatch(setDisable(true));
      dispatch(setDisableHotkeys(true));
    } else {
      dispatch(setDisable(false));
      dispatch(setDisableHotkeys(false));
    }
  }, [modalData]);

  const [numberRuler, setNumberRuler] = useState({
    direction: "",
    position: 0,
    index: 0,
  });

  const { initialX, initialY, offsetX, offsetY, cellHeight, cellWidth } =
    useGridVariables();

  const { id } = useSelector((state: RootState) => state.pattern);
  const { data, disableHotkeys, markedArea, contextMenuCoords } = useSelector(
    (state: RootState) => ({
      data: state.grid.grid,
      disableHotkeys: state.grid.disableHotKeys,
      markedArea: state.grid.markedArea,
      contextMenuCoords: state.grid.squareMenu,
    })
  );

  const { excluded, diagramComments } = data;
  const diagramPatterns = data.patterns;

  const [canDrag, setDrag] = useState<Boolean>(false);

  const dragSelection = useSelector(
    (state: RootState) => state.dragSelect.bbox
  );

  const [dragSelectionBox, setDragSelectionBox] = useState({
    top: 0,
    width: 0,
    left: 0,
    height: 0,
    area: [{ col: 0, row: 0 }],
  });

  const scrollRef = useRef<HTMLDivElement>(null);
  const { selectedView } = useSelector((state: RootState) => state.grid);

  useHotkeys(["Meta+c", "Control+c"], (e) => {
    if (disableHotkeys) return;
    e.preventDefault();
    dispatch(copyMarkedArea());
  });
  useHotkeys(["Meta+x", "Control+x"], (e) => {
    if (disableHotkeys) return;
    e.preventDefault();
    dispatch(cutMarkedArea());
  });
  useHotkeys(["Meta+v", "Control+v"], (e) => {
    if (disableHotkeys) return;
    e.preventDefault();
    dispatch(pasteMarkedArea());
  });

  const handleCellHover = (cellX: number, cellY: number) => {
    setCellRuler({ cellX, cellY });
  };

  const handleAnnotationDialog = (index: number, direction: "row" | "col") => {
    setModalData({ index, direction });
  };

  const selectHighlightedSquares = () => {
    // Dont run if there is a marking or contextMenu is active
    if (markedArea.a.x !== -1 || contextMenuCoords[0] !== -1) return;

    const hits = [];
    let colMin = Infinity,
      colMax = -1,
      rowMin = Infinity,
      rowMax = -1;
    for (let i = 0; i < data.grid.length; i++) {
      const row = data.grid[i];
      for (let j = 0; j < row.length; j++) {
        const bbox = document
          .getElementById(`${i}_${j}`)
          ?.getBoundingClientRect();
        if (
          bbox &&
          aabb(
            {
              top: bbox.top + window.scrollY,
              left: bbox.left + window.scrollX,
              width: bbox.width,
              height: bbox.height,
            },
            dragSelection
          )
        ) {
          hits.push({ row: i, col: j });
          if (i < rowMin) rowMin = i;
          if (i > rowMax) rowMax = i;
          if (j < colMin) colMin = j;
          if (j > colMax) colMax = j;
        }
      }
    }

    if (rowMin !== Infinity && rowMax !== -1) {
      dispatch(setBbox({ top: -1, left: -1, width: -1, height: -1 }));
      setDragSelectionBox({
        top: offsetY + rowMin * cellHeight - 1,
        left: offsetX + colMin * cellWidth - 1,
        width: (colMax - colMin + 1) * cellWidth - 1,
        height: (rowMax - rowMin + 1) * cellHeight - 1,
        area: hits,
      });
      dispatch(setMarkedArea({ grid: hits }));
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  }, [scrollRef.current, selectedView]);

  useEffect(() => {
    if (dragSelection.left !== -1) {
      selectHighlightedSquares();
    }
  }, [dragSelection]);

  useEffect(() => {
    if (id !== -1 && data.id !== "#test") {
      dispatch(
        setDiagram({
          diagramId: data.id,
          diagramString: JSON.stringify(data),
        })
      );
      dispatch(updateDiagramById({ patternId: id, diagram: data }));
    }
  }, [data]);

  useEffect(() => {
    if (cursorMode === "color" || cursorMode === "erase") {
      dispatch(setMarkedArea({ grid: [] }));
    }
  }, [cursorMode]);

  const renderDragMenu = () => {
    const { x1, x2, y1, y2 } = {
      x1: markedArea.a.x,
      x2: markedArea.c.x,
      y1: markedArea.a.y,
      y2: markedArea.c.y,
    };

    return (
      <>
        <DragSelectionBox
          bbox={{
            top: offsetY + y1 * cellHeight,
            left: offsetX + x1 * cellWidth,
            width: (x2 - x1 + 1) * cellWidth - 2,
            height: (y2 - y1 + 1) * cellHeight - 2,
          }}
        />
        <DragMenu
          x={offsetX + x2 * cellWidth}
          y={offsetY + (y1 - 1) * cellHeight}
          handleClose={() => {
            dispatch(setMarkedArea({ grid: [] }));
          }}
          area={dragSelectionBox.area}
        />
      </>
    );
  };

  return (
    <Draggable grid={canDrag ? [cellWidth, cellHeight] : [0, 0]}>
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.altKey) {
            dispatch(setDisable(true));
            setDrag(true);
          }
        }}
        onKeyUp={() => {
          dispatch(setDisable(false));
          setDrag(false);
        }}
        style={{
          cursor: canDrag ? "grab" : "default",
          top: `${initialY}px`,
          left: `${initialX - 2 * cellWidth}px`,
          width: `${cellWidth * (data.gridWidth + 6)}px`,
          height: `${cellHeight * (data.gridHeight + 6)}px`,
          position: "absolute",
        }}
      >
        <div
          // This is used to get a reference of the left side of the grid
          // And is scrolled into view at the start
          ref={scrollRef}
          style={{
            position: "absolute",
            top: "50%",
          }}
        ></div>
        <AddDiagramCommentModal
          {...modalData}
          isShown={modalData.index !== -1}
          toggle={() => setModalData({ index: -1, direction: "row" })}
        />
        <RepeatDiagram />
        {markedArea.a.x !== -1 && cursorMode === "cursor" && renderDragMenu()}
        {numberRuler.direction === "y" && (
          <div
            style={{
              position: "absolute",
              width: cellWidth,
              height: 1200,
              top: -400,
              left: numberRuler.position + 9,
              backgroundColor: "#DE5900",
              zIndex: 8,
              opacity: 0.3,
            }}
          />
        )}
        {numberRuler.direction === "x" && (
          <div
            style={{
              position: "absolute",
              width: 12000,
              height: cellHeight,
              top: numberRuler.position - 8,
              left: -4000,
              backgroundColor: "#DE5900",
              opacity: 0.3,
              zIndex: 8,
            }}
          />
        )}
        {cellRuler.cellX !== 0 && (
          <div>
            <div
              id={"cellRulerX"}
              style={{
                position: "absolute",
                height: cellHeight,
                width: cellWidth * (data.gridWidth + 6) - cellRuler.cellX,
                top: cellRuler.cellY,
                left: cellRuler.cellX,
                border: "1px solid #CCC5C0",
              }}
            />
            <div
              id={"cellRulerY"}
              style={{
                position: "absolute",
                width: cellWidth,
                height: cellHeight * (data.gridHeight + 6) - cellRuler.cellY,
                top: cellRuler.cellY,
                left: cellRuler.cellX,
                border: "1px solid #CCC5C0",
              }}
            />
          </div>
        )}
        {repeat === null && <AddButtons />}
        <GridCursor />
        {data.grid.map((row: any[], rowNum: number) =>
          row.map((cell, cellIndex) => {
            const x = offsetX + cellIndex * cellWidth;
            const y = offsetY + rowNum * cellHeight;
            return (
              <Square
                key={`${rowNum}_${cellIndex}`}
                color={cell.color}
                x={x}
                y={y}
                cellSize={{ cellWidth, cellHeight }}
                symbol={cell.symbol}
                onMouseOver={() => handleCellHover(x, y)}
                onMouseOut={() => setCellRuler({ cellX: 0, cellY: 0 })}
                cell={cellIndex}
                row={rowNum}
              />
            );
          })
        )}
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: `${cellHeight * (data.gridHeight + 5)}px`,
            fontSize: 10 + zoomCount,
            textAlign: "center",
            left: offsetX,
            zIndex: 12,
            height: 240,
            width: cellWidth * data.gridWidth,
          }}
        >
          {/* Bottom cols */}
          {repeat === null &&
            data.grid[0].map((_, index) => (
              <div
                key={index}
                style={{ width: `${cellWidth}px`, height: 200 }}
                onMouseEnter={() =>
                  setNumberRuler({
                    direction: "y",
                    position: offsetY + index * cellWidth,
                    index,
                  })
                }
                onMouseLeave={() =>
                  setNumberRuler({ direction: "", position: 0, index: -1 })
                }
              >
                <p className={styles.textHover}>
                  {data.gridWidth - index > 9
                    ? `${data.gridWidth - index}`
                    : `0${data.gridWidth - index}`}
                </p>
                {diagramPatterns?.colPatterns[index] && (
                  <DiagramCommentBorder
                    position="start"
                    start={diagramPatterns?.colPatterns[index].start}
                    end={diagramPatterns?.colPatterns[index].end}
                    orientation="bottom"
                    showIcon={
                      !(
                        numberRuler.direction === "y" &&
                        numberRuler.index === index
                      )
                    }
                  />
                )}
                {diagramComments?.colComments[index] &&
                  !(
                    numberRuler.index === index && numberRuler.direction === "y"
                  ) && (
                    <Info
                      fill={Fill.CONTRAST}
                      orientation="bottom"
                      direction={diagramComments?.colComments[index].relation}
                    />
                  )}
                {numberRuler.direction === "y" &&
                numberRuler.index === index ? (
                  <>
                    <div
                      style={{ marginTop: 3 }}
                      onClick={() => handleAnnotationDialog(index, "col")}
                    >
                      <Info fill={Fill.CONTRAST} />
                    </div>
                    {sizes.map((size, i) => (
                      <p
                        key={i}
                        style={{
                          color: excluded?.[`col_${index}`]?.includes(size)
                            ? "#de5900"
                            : "",
                        }}
                        onClick={() => {
                          let excludedSizes = excluded?.[`col_${index}`]
                            ? excluded[`col_${index}`]
                            : [];

                          if (excludedSizes.includes(size)) {
                            excludedSizes = excludedSizes.filter(
                              (currentSize) => currentSize !== size
                            );
                          } else {
                            excludedSizes = [...excludedSizes, size];
                          }
                          dispatch(
                            updateExclusions({
                              direction: "col",
                              index,
                              sizes: excludedSizes,
                            })
                          );
                        }}
                      >
                        {size}
                      </p>
                    ))}
                  </>
                ) : (
                  excluded?.[`col_${index}`]?.map((size, i) => (
                    <p key={i}>{size}</p>
                  ))
                )}
              </div>
            ))}
        </div>
        {/* Right rows  */}
        <div
          style={{
            position: "absolute",
            left: `${cellWidth * (data.gridWidth + 5)}px`,
            top: `${offsetY}px`,
            height: cellHeight * data.gridHeight,
            zIndex: 12,
          }}
        >
          {repeat === null &&
            data.grid.map((row, index) => (
              <div
                key={index}
                onMouseOver={() =>
                  setNumberRuler({
                    direction: "x",
                    position: offsetX + index * cellHeight,
                    index,
                  })
                }
                onMouseLeave={() =>
                  setNumberRuler({ direction: "", position: 0, index: -1 })
                }
                style={{
                  height: `${cellHeight}px`,
                  fontSize: 10 + zoomCount,
                  display: "flex",
                }}
              >
                <div className={styles.textHover} style={{ display: "flex" }}>
                  {data.gridHeight - index > 9
                    ? `${data.gridHeight - index}`
                    : `0${data.gridHeight - index}`}
                  {diagramPatterns?.rowPatterns[index] && (
                    <DiagramCommentBorder
                      position="start"
                      start={diagramPatterns?.rowPatterns[index].start}
                      end={diagramPatterns?.rowPatterns[index].end}
                      orientation="right"
                      showIcon={
                        !(
                          numberRuler.index === index &&
                          numberRuler.direction === "x"
                        )
                      }
                    />
                  )}
                  {diagramComments?.rowComments[index] &&
                    !(
                      index === numberRuler.index &&
                      numberRuler.direction === "x"
                    ) && (
                      <div style={{ marginLeft: 6 }}>
                        <Info
                          fill={Fill.CONTRAST}
                          orientation="right"
                          direction={
                            diagramComments?.rowComments[index].relation
                          }
                        />
                      </div>
                    )}
                  {numberRuler.index === index &&
                  numberRuler.direction === "x" ? (
                    <div style={{ display: "flex", zIndex: 1 }}>
                      <p
                        style={{ margin: "0 4px 0 8px" }}
                        onClick={() => handleAnnotationDialog(index, "row")}
                      >
                        <Info fill={Fill.CONTRAST} />
                      </p>
                      {sizes.map((size, i) => (
                        <p
                          key={i}
                          style={{
                            color: excluded?.[`row_${index}`]?.includes(size)
                              ? "#de5900"
                              : "",
                            margin: "0 2px",
                          }}
                          onClick={() => {
                            let excludedSizes = excluded?.[`row_${index}`]
                              ? excluded[`row_${index}`]
                              : [];
                            if (excludedSizes.includes(size)) {
                              excludedSizes = excludedSizes.filter(
                                (currentSize) => currentSize !== size
                              );
                            } else {
                              excludedSizes = [...excludedSizes, size];
                            }
                            dispatch(
                              updateExclusions({
                                direction: "row",
                                index,
                                sizes: excludedSizes,
                              })
                            );
                          }}
                        >
                          {size}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      {excluded?.[`row_${index}`]?.map((size, i) => (
                        <span key={i} style={{ margin: "0 2px" }}>
                          {size}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        {/* Lines */}
        {Object.entries(data.lines ?? {}).map(([key, line]) => {
          const [i, direction] = key.split("_");
          const index = parseInt(i);
          if (direction === "row") {
            return (
              <div
                key={key}
                style={{
                  position: "absolute",
                  top: offsetY + cellHeight * (index + 1),
                  width: cellWidth * (data.gridWidth + 8),
                  height: "1px",
                  zIndex: 2,
                  backgroundColor: line.color ?? "#000",
                }}
              />
            );
          } else {
            return (
              <div
                key={key}
                style={{
                  position: "absolute",
                  left: offsetX + cellWidth * (index + 1),
                  width: "1px",
                  height: cellHeight * (data.gridHeight + 8),
                  zIndex: 2,
                  backgroundColor: line.color ?? "#000",
                }}
              />
            );
          }
        })}
        {/* DyanmicSymbols */}
        {Object.entries(data.dynamicSymbols ?? {}).map(([key, value]) => {
          return (
            <DynamicSymbolColorAware
              key={key}
              value={value}
              grid={data.grid}
              offsetY={offsetY}
              cellHeight={cellHeight}
              offsetX={offsetX}
              cellWidth={cellWidth}
            />
          );
        })}
      </div>
    </Draggable>
  );
};

export default Grid;

const DynamicSymbolColorAware: React.FC<{
  key: string;
  value: DynamicDiagramSymbol;
  grid: GridCell[][];
  offsetY: number;
  offsetX: number;
  cellHeight: number;
  cellWidth: number;
}> = (props) => {
  const { key, value, grid, offsetY, offsetX, cellHeight, cellWidth } = props;
  const { x: x1, y: y1 } = value.start;
  const { x: x2, y: y2 } = value.end;

  const cellColor = grid[y1][x1].color;
  const color = useSelector(
    (state: RootState) => state.color.colors[cellColor]
  );
  const symbolColor = useDarkColor(color?.hex) ? "black" : "white";

  return (
    <div
      key={key}
      style={{
        position: "absolute",
        zIndex: 2,
        lineHeight: 1,
        top: offsetY + cellHeight * y1,
        left: offsetX + cellWidth * x1,
      }}
    >
      <DynamicSymbol
        symbol={value.symbol}
        iconProps={{
          width: (x2 - x1 + 1) * cellWidth,
          height: (y2 - y1 + 1) * cellHeight,
          fill: symbolColor,
        }}
      />
    </div>
  );
};
