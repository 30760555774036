import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setMeasureLabel,
  toggleMeasureLocked,
  updateCalculations,
} from "../store/calculationSlice";

import { EdgeTableMeasure } from "./calculations";
import { RootState } from "../store/store";

import EdgeCell from "./EdgeCell";
import MeasureKindSelect from "./MeasureKindSelect";
import ChangeKindSelector from "./ChangeKindSelector";
import LockOpen from "../CommonComponents/Icons/LockOpen";
import LockClosed from "../CommonComponents/Icons/LockClosed";

import styles from "./EdgeRow.module.css";
import { useTranslation } from "react-i18next";

type PropType = {
  tableId: string;
  measure: EdgeTableMeasure;
  sizes: string[];
};

function EdgeRow(props: PropType) {
  const { tableId, measure, sizes } = props;

  const measureLocked = useSelector((state: RootState) => {
    const edges = state.calculations.graph.edges[measure.edges[sizes[0]]];
    return edges ? edges.locked : false;
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [shouldSave, setShouldSave] = useState(false);
  useEffect(() => {
    setShouldSave(true);
  }, [measure.label]);

  return (
    <div key={measure.id} className={styles.row}>
      <div className={styles.lockContainer}>
        <button
          className={styles.lock}
          onClick={() =>
            dispatch(toggleMeasureLocked({ tableId, measureId: measure.id }))
          }
        >
          {measureLocked ? <LockClosed /> : <LockOpen />}
        </button>
      </div>
      <div className={styles.label}>
        <div className={styles.labelControl}>
          <input
            className={styles.input}
            placeholder={"Navn på rad..."}
            type="text"
            value={measure.label}
            onChange={(e) =>
              dispatch(
                setMeasureLabel({
                  tableId,
                  measureId: measure.id,
                  label: e.target.value,
                })
              )
            }
            onBlur={() => {
              if (shouldSave) {
                dispatch(updateCalculations());
                setShouldSave(false);
              }
            }}
          />
          <ChangeKindSelector tableId={tableId} measure={measure} />
        </div>
        <div className={styles.edgeLabels}>
          <div>Total endring</div>
          <div>Mellom endringer</div>
          <div>{t("terms.height")}</div>
        </div>
      </div>
      <div className={styles.rowCells}>
        {sizes.map((size) => {
          const edgeId = measure.edges[size];

          return <EdgeCell key={size} edgeId={edgeId} />;
        })}
      </div>
      <div className={styles.measureType}>
        <MeasureKindSelect measure={measure} tableId={tableId} />
      </div>
    </div>
  );
}

export default EdgeRow;
