import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { addVariant, deleteMetadataVariant } from "./patternSlice";

type CommentPopover = {
  isShown: boolean;
  top: number;
};

interface WriterType {
  currentSection: number | null;
  drawerOpen: boolean;
  commentDrawerOpen: boolean;
  menuDrawerOpen: boolean;
  subdrawerOpen: boolean;
  subdrawerContent: {
    type: SubdrawerContentType;
    id: string | number | null;
  };
  sidebarOpen: boolean;
  sidebarContent: "information" | "video" | "calculation" | null;
  commentPopover: CommentPopover;
  highlightedComment: string | null;
  disableHotkeys: boolean;
}

type SubdrawerContentType =
  | "variant"
  | "usage"
  | "yarn"
  | "information"
  | "video"
  | "media"
  | null;

const initialState: WriterType = {
  currentSection: null,
  drawerOpen: false, //!usually false
  commentDrawerOpen: false,
  menuDrawerOpen: false,
  subdrawerOpen: false,
  subdrawerContent: { type: null, id: null },
  sidebarOpen: false,
  sidebarContent: null,
  commentPopover: {
    isShown: false,
    top: 0,
  },
  highlightedComment: null,
  disableHotkeys: false,
};

const writerSlice = createSlice({
  name: "writer",
  initialState,
  reducers: {
    setCurrentSection(state, action) {
      const currentSection: number | null = action.payload;
      return {
        ...state,
        currentSection,
      };
    },
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
    },
    openSubdrawer(state, action) {
      const {
        content: { id, type },
      } = action.payload;
      return {
        ...state,
        subdrawerOpen: true,
        subdrawerContent: { id, type },
      };
    },
    closeSubdrawer(state) {
      return {
        ...state,
        subdrawerOpen: false,
        subdrawerContent: { type: null, id: null },
      };
    },
    openSidebar(state, action) {
      const { content } = action.payload;
      return {
        ...state,
        sidebarOpen: true,
        sidebarContent: content,
      };
    },
    closeSidebar(state) {
      return {
        ...state,
        sidebarOpen: false,
        sidebarContent: null,
      };
    },
    openMediaSubDrawer(state) {
      return {
        ...state,
        subdrawerOpen: true,
        subdrawerContent: {
          type: "media" as SubdrawerContentType,
          id: "media",
        },
      };
    },
    openCommentDrawer(state) {
      return {
        ...state,
        commentDrawerOpen: true,
      };
    },
    closeCommentDrawer(state) {
      return {
        ...state,
        commentDrawerOpen: false,
      };
    },
    toggleMenuDrawer(state) {
      return {
        ...state,
        menuDrawerOpen: !state.menuDrawerOpen,
      };
    },
    toggleCommentPopover(state, action: PayloadAction<CommentPopover>) {
      return {
        ...state,
        commentPopover: action.payload,
      };
    },
    setHighlightedComment(state, action: PayloadAction<string | null>) {
      const commentRef = action.payload;
      return {
        ...state,
        highlightedComment: commentRef,
      };
    },
    setDisableWriterHotkeys(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        disableHotkeys: action.payload,
      };
    },
  },
  extraReducers: {
    [deleteMetadataVariant.pending.type](state, _action) {
      return {
        ...state,
        subdrawerOpen: false,
        subdrawerContent: { type: null, id: null },
      };
    },
    [addVariant.type](state, action) {
      const { id: variantId } = action.payload;
      return {
        ...state,
        subdrawerOpen: true,
        subdrawerContent: {
          type: "variant" as SubdrawerContentType,
          id: variantId,
        },
      };
    },
  },
});

export const {
  setCurrentSection,
  toggleDrawer,
  closeDrawer,
  openSubdrawer,
  closeSubdrawer,
  openSidebar,
  closeSidebar,
  openMediaSubDrawer,
  openCommentDrawer,
  closeCommentDrawer,
  toggleMenuDrawer,
  toggleCommentPopover,
  setHighlightedComment,
  setDisableWriterHotkeys,
} = writerSlice.actions;

export { writerSlice };
