import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isDynamic } from "../../CommonComponents/Icons/lookup";
import {
  addRowsOrCols,
  removeRowsOrCols,
  fillArea,
  updateCell,
  pasteMarkedArea,
  addLine,
  removeLine,
  MarkedArea,
} from "../../store/gridSlice";
import { RootState } from "../../store/store";
import { ContextMenu, Option } from "./RenderMenu";

interface PropType {
  cell: number;
  row: number;

  // Neeeded for RenderMenu
  x: number;
  y: number;
  handleClose: Function;
}

export const ClickMenu = (props: PropType) => {
  const { t } = useTranslation();
  const { activeIndex, activeSymbol } = useSelector(
    (state: RootState) => state.color
  );

  const [color, setColor] = useState<string>("#33251a");

  const { gridWidth, lines } = useSelector(
    (state: RootState) => state.grid.grid
  );

  const cellData = { symbol: activeSymbol, color: activeIndex };

  const rowArea: MarkedArea = {
    a: { x: 0, y: props.cell },
    b: { x: gridWidth - 1, y: props.cell },
    c: { x: gridWidth - 1, y: props.cell + 1 },
    d: { x: 0, y: props.cell + 1 },
  };

  const options: Option[] = [
    {
      label: t("draw.menu.row"),
      options: [
        {
          label: t("draw.menu.fill"),
          action: (props: PropType) =>
            isDynamic(activeSymbol)
              ? () => {}
              : fillArea({
                  area: rowArea,
                  cellData: { ...cellData, symbol: activeSymbol },
                }),
        },
        {
          label: t("draw.menu.new row above"),
          action: (props: PropType) =>
            addRowsOrCols({ direction: "row", index: props.row }),
        },
        {
          label: t("draw.menu.new row under"),
          action: (props: PropType) =>
            addRowsOrCols({ direction: "row", index: props.row + 1 }),
        },
        {
          label: t("draw.menu.remove row"),
          action: () =>
            removeRowsOrCols({ direction: "row", index: props.row }),
        },

        lines?.[`${props.row - 1}_row`]
          ? {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.remove"),
                placement: t("draw.menu.above"),
              }),
              action: () =>
                removeLine({ index: props.row - 1, direction: "row" }),
            }
          : {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.insert"),
                placement: t("draw.menu.above"),
              }),
              includeColorPicker: true,
              action: () =>
                addLine({ index: props.row - 1, direction: "row", color }),
            },

        lines?.[`${props.row}_row`]
          ? {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.remove"),
                placement: t("draw.menu.to the right"),
              }),
              action: () => removeLine({ index: props.row, direction: "row" }),
            }
          : {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.insert"),
                placement: t("draw.menu.below"),
              }),
              includeColorPicker: true,
              action: () =>
                addLine({ index: props.row, direction: "row", color }),
            },
      ],
    },
    {
      label: t("draw.menu.column"),
      options: [
        {
          label: "Fyll",
          action: () =>
            isDynamic(activeSymbol)
              ? () => {}
              : fillArea({
                  area: rowArea,
                  cellData: { ...cellData, symbol: activeSymbol },
                }),
        },
        {
          label: t("draw.menu.new column right"),
          action: (props: PropType) =>
            addRowsOrCols({ direction: "col", index: props.cell + 1 }),
        },
        {
          label: t("draw.menu.new column left"),
          action: (props: PropType) =>
            addRowsOrCols({ direction: "col", index: props.cell }),
        },
        {
          label: t("draw.menu.remove column"),
          action: () =>
            removeRowsOrCols({ index: props.cell, direction: "col" }),
        },
        lines?.[`${props.cell}_col`]
          ? {
              label: t("draw.menu.add or remove line avbove or below", {
                action: t("draw.menu.remove"),
                placement: t("draw.menu.to the right"),
              }),
              action: () => removeLine({ index: props.cell, direction: "col" }),
            }
          : {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.insert"),
                placement: t("draw.menu.to the right"),
              }),
              includeColorPicker: true,
              action: () =>
                addLine({ index: props.cell, direction: "col", color }),
            },
        lines?.[`${props.cell - 1}_col`]
          ? {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.remove"),
                placement: t("draw.menu.to the right"),
              }),
              action: () =>
                removeLine({ index: props.cell - 1, direction: "col" }),
            }
          : {
              label: t("draw.menu.add or remove line above or below", {
                action: t("draw.menu.insert"),
                placement: t("draw.menu.to the left"),
              }),
              includeColorPicker: true,
              action: () =>
                addLine({ index: props.cell - 1, direction: "col", color }),
            },
      ],
    },
    {
      label: t("draw.menu.paste"),
      hotkey: "⌘ + v",
      action: (props: PropType) => pasteMarkedArea(),
    },
    {
      label: t("empty cell", { count: 1 }),
      action: (props: PropType) =>
        updateCell({
          row: props.row,
          col: props.cell,
          cellData: { color: 0, symbol: "stitch" },
        }),
    },
  ];

  return (
    <ContextMenu
      defaultVisibility={true}
      {...props}
      color={color}
      setColor={setColor}
      options={options}
      offsetSquare
      showCloseButton
    />
  );
};
