import React, { useMemo } from "react";
import { CellProps, Column, useTable } from "react-table";
import KnitterProfile from "./KnitterProfile";
import { ModelKnitAssignment } from "../store/pattern";
import ModelKnitStateTags from "./ModelKnitStateTags";
import { useOrganizationModelKnitAssignmentsQuery } from "../services/modelknit";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import { formatLocaleDate } from "../utils/dateFormat";

type Props = {
  orgId: string;
};

const ModelKnitAssignmentsTable: React.FC<Props> = ({ orgId }) => {
  const { t } = useTranslation();
  const modelKnitsQuery = useOrganizationModelKnitAssignmentsQuery(orgId);
  const data = modelKnitsQuery.data ?? [];

  const columns: Column<ModelKnitAssignment>[] = useMemo(() => {
    const columns: Column<ModelKnitAssignment>[] = [
      {
        Header: `${t("start date")}`,
        accessor: "created",
        Cell: ({ row }: CellProps<ModelKnitAssignment>) =>
          `> ${
            row.values["created"]
              ? formatLocaleDate(row.values["created"])
              : "Mangler startdato"
          }`,
      },
      {
        Header: `${t("ID")}`,
        accessor: "knitterName",
        Cell: ({ row }: CellProps<ModelKnitAssignment>) => (
          <>
            {console.log(row)}
            <KnitterProfile
              name={row.values["knitterName"]}
              id={row.original.knitterId}
            />
          </>
        ),
      },
      {
        Header: `${t("pattern", { count: 1 })}`,
        accessor: "patternId",
      },
      {
        Header: `${t("color variant", { count: 1 })}`,
        accessor: "variantId",
      },
      {
        Header: `${t("size", { count: 1 })}`,
        accessor: "size",
      },
      {
        Header: `${t("status")}`,
        accessor: "state",
        Cell: ({ row }: CellProps<ModelKnitAssignment>) => (
          <ModelKnitStateTags state={row.values["state"]} />
        ),
      },
      {
        Header: `${t("deadline")}`,
        accessor: "deadline",
        Cell: ({ row }: CellProps<ModelKnitAssignment>) =>
          `${
            row.values["deadline"]
              ? `${formatLocaleDate(row.values["deadline"])} <`
              : "Ingen sluttdato"
          }`,
      },
    ];
    return columns;
  }, []);
  const instance = useTable({ columns, data });

  return (
    <div>
      <Table instance={instance} title={t("test knit")} />
    </div>
  );
};

export default ModelKnitAssignmentsTable;
