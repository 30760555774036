import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

type RelationIndexType = {
  [tableId: string]: RelationIndexEntry;
};

type RelationIndexEntry = {
  before: string | null;
  after: string | null;
};

function useTableRelationIndex(): RelationIndexType {
  const relations = useSelector(
    (state: RootState) => state.calculations.relations
  );

  const [relationIndex, setRelationIndex]: [
    RelationIndexType,
    Function
  ] = useState({});

  useEffect(() => {
    const relationIndex: RelationIndexType = {};

    for (const relation of Object.values(relations)) {
      for (const tableReference of relation.top) {
        if (relationIndex[tableReference.tableId] === undefined) {
          relationIndex[tableReference.tableId] = {
            before: relation.id,
            after: null,
          };
        } else {
          relationIndex[tableReference.tableId].before = relation.id;
        }
      }

      for (const tableReference of relation.bottom) {
        if (relationIndex[tableReference.tableId] === undefined) {
          relationIndex[tableReference.tableId] = {
            before: null,
            after: relation.id,
          };
        } else {
          relationIndex[tableReference.tableId].after = relation.id;
        }
      }
    }

    setRelationIndex(relationIndex);
  }, [relations]);

  return relationIndex;
}

export default useTableRelationIndex;
export type { RelationIndexType, RelationIndexEntry };
