import { Descendant, Text } from "slate";
import { PatternElement } from "../store/pattern";
import { CustomElement } from "./SlateWriter";

export const serializePattern = (
  slatePatternElements: Descendant[]
): PatternElement[] => {
  const elements: PatternElement[] = slatePatternElements
    .filter((element) => !Text.isText(element))
    .filter((element): element is CustomElement => "type" in element)
    // eslint-disable-next-line array-callback-return
    .map((element) => {
      switch (element.type) {
        case "span":
          return {
            type: element.type,
            markdown: element.children.map((child) => child.text).join(" "),
            style: element.style,
            commentId: element.commentId,
          };
        case "heading":
          return {
            type: element.type,
            markdown: element.children.map((child) => child.text).join(" "),
            style: element.style,
            commentId: element.commentId,
            publishDate: element.publishDate,
          };
        case "subheading":
          return {
            type: element.type,
            markdown: element.children.map((child) => child.text).join(" "),
            commentId: element.commentId,
          };
        case "textlink":
          return {
            type: element.type,
            markdown: element.children.map((child) => child.text).join(" "),
            link: element.link,
            commentId: element.commentId,
          };
        case "calculationResult":
          return {
            type: element.type,
            tableId: element.tableId,
            measureId: element.measureId,
            row1: element.row1,
            row2: element.row2,
            edgeKind: element.edgeKind,
            commentId: element.commentId,
          };
        case "sizeFilteredString":
          return {
            type: element.type,
            text: JSON.parse(element.sizeString),
            commentId: element.commentId,
          };
        case "color":
          return {
            type: element.type,
            ref: element.ref,
            commentId: element.commentId,
          };
        case "needle":
          return {
            type: element.type,
            ref: element.ref,
            commentId: element.commentId,
          };
        case "diagram":
          return {
            type: element.type,
            ref: element.ref,
            id: element.id,
            commentId: element.commentId,
          };
        case "diagramSection":
          return {
            type: element.type,
            id: element.id,
            diagram: element.diagram,
            commentId: element.commentId,
          };
        case "paragraphBreak":
          return {
            type: element.type,
            commentId: element.commentId,
          };
        case "figure":
          return {
            type: element.type,
            id: element.id,
            figureId: element.figureId,
            inline: element.inline,
            commentId: element.commentId,
          };
      }
    });

  return elements;
};
