import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import { setDiagramView } from "../store/gridSlice";
import { useTranslation } from "react-i18next";

const ChangeDiagramView = () => {
  const dispatch = useDispatch();
  const { selectedView } = useSelector((state: RootState) => state.grid);
  const { t } = useTranslation();
  const { disableModel } = useSelector((state: RootState) => state.model);
  return (
    <div className={styles.toolContainer}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "100%",
        }}
      >
        {disableModel || (
          <>
            <button
              className={`${styles.toolButton}
          ${selectedView === "model" ? styles.contrast : ""}`}
              onClick={() => {
                dispatch(
                  setDiagramView(selectedView !== "model" ? "model" : "grid")
                );
              }}
            >
              {t("draw.model")}
            </button>
            <hr />
          </>
        )}
        <button
          onClick={() => {
            dispatch(setDiagramView(selectedView !== "fell" ? "fell" : "grid"));
          }}
          className={`${styles.toolButton}
            ${selectedView === "fell" ? styles.contrast : ""}`}
        >
          {t("draw.knit together")}
        </button>
      </div>
    </div>
  );
};

export default ChangeDiagramView;
