import React, { useState, useRef, useEffect } from "react";
import { Cross1Icon, DropdownMenuIcon } from "@radix-ui/react-icons";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import { RootState } from "../../store/store";
import { useGridVariables } from "../utils/hooks";
import styles from "./../diagram.module.css";
export interface Option {
  label: string;
  options?: Option[];
  action?: Function;
  hotkey?: string; // Description
  includeColorPicker?: boolean;

  icon?: string;
  fontColor?: string;
}

interface PropType {
  x: number;
  y: number;
  handleClose: Function;
  options: Option[];
  area?: { col: number; row: number }[];
  color?: string;
  setColor?: Function;
  offsetSquare?: boolean;
  showCloseButton?: boolean;
  dontDispatchActions?: boolean;
  defaultVisibility: boolean;
}

export const ContextMenu = (props: PropType) => {
  const { options, defaultVisibility } = props;
  const { t } = useTranslation();
  const { cellHeight, cellWidth } = useGridVariables();
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(defaultVisibility);

  const [activeMenu, setActiveMenu] = useState("");
  const menuRef = useRef<HTMLDivElement>(null);

  const [showColorPicker, toggleColorPicker] = useState<boolean>(false);
  const { lines } = useSelector((state: RootState) => state.grid.grid);

  const uniqueLineColors = Array.from(
    new Set(
      Object.values(lines ?? {})
        .map((line) => line.color ?? "")
        .filter((c) => c !== "")
    )
  );

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        props.handleClose();
      }
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [props.area]);

  const renderOption = (option: Option, depth: number) => (
    <div
      onMouseOver={() => setActiveMenu(option.label)}
      key={`${option.label}_${depth}`}
    >
      {option.label === activeMenu && option.options && (
        <div
          className={styles.nestedMenu}
          style={{ position: "absolute", left: depth + 1 * 120, width: 120 }}
        >
          {option.options?.map((optionChild) =>
            renderOption(optionChild, depth + 1)
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "6px 2px",
          alignItems: "center",
        }}
        className={styles.hoverRow}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            zIndex: 30,
            cursor: "pointer",
          }}
          onClick={() => {
            if (option.action) {
              if (props.dontDispatchActions) {
                option.action();
              } else {
                dispatch(option.action(props));
              }
              props.handleClose();
            }
          }}
        >
          <div style={{ display: "flex", gap: "5px" }}>
            {option.icon && <img height="16" src={option.icon} />}
            <p style={{ fontSize: 12, color: option.fontColor ?? "" }}>
              {option.label}
            </p>
          </div>
          {option.options && (
            <img height="16" src="/chevron-right.svg" alt="right arrow" />
          )}
          {option.hotkey && (
            <p style={{ fontSize: 11, color: "gray" }}>{option.hotkey}</p>
          )}
        </div>
        {option.includeColorPicker && (
          <button
            onClick={() => toggleColorPicker(!showColorPicker)}
            style={{ backgroundColor: props.color ?? "steelblue" }}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      ref={menuRef}
      style={{
        position: "fixed",
        left: props.x + (props.offsetSquare ? 20 : 0),
        top: props.y + (props.offsetSquare ? 16 : 0),
        zIndex: 15,
      }}
    >
      {showMenu ? (
        <div className={styles.nestedMenu}>
          {props.showCloseButton && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid black",
                alignItems: "center",
              }}
            >
              <p style={{ padding: "2px", marginLeft: "4px" }}>
                {t("draw.menu.options")}
              </p>
              <Button onClick={props.handleClose} style={{ border: "none" }}>
                <Cross1Icon />
              </Button>
            </div>
          )}
          {options.map((option) => renderOption(option, 0))}
          {showColorPicker && (
            <div style={{ position: "absolute", left: 245, top: 60 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {uniqueLineColors.map((color) => (
                    <button
                      aria-label={color}
                      key={color}
                      onClick={() => props.setColor && props.setColor(color)}
                      style={{
                        background: color,
                        height: "32px",
                        width: "32px",
                      }}
                    />
                  ))}
                </div>
                <Button contrast onClick={() => toggleColorPicker(false)}>
                  <Cross1Icon aria-label="Close color picker" />
                </Button>
              </div>
              <HexColorPicker
                color={props.color ?? "#f00"}
                onChange={(newColor) =>
                  props.setColor && props.setColor(newColor)
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className={styles.dropDownMenu}
          style={{ height: cellHeight, width: cellWidth - 2 }}
          onMouseOver={() => setShowMenu(true)}
        >
          <DropdownMenuIcon fill="black" />
        </div>
      )}
    </div>
  );
};
