import {
  DiagramSymbol,
  DynamicSymbols,
  dynamicSymbols,
  symbols,
  Symbols,
} from "@iterate/woolit-components";

export interface IconProps {
  fill?: string;
  width?: number;
  height?: number;
}

export const symbolKeys = Object.keys(symbols).map((key) => key as Symbols);
export const dynamicSymbolKeys = Object.values(dynamicSymbols).map((v) => v);

export const symbolLookup = ({
  key,
  props,
}: {
  key: Symbols;
  props?: IconProps;
}) => {
  const iconProps = {
    fill: "black",
    width: 12,
    height: 14,
    ...props,
  };

  return <DiagramSymbol symbol={key} iconProps={iconProps} />;
};

export const isDynamic = (
  key: Symbols | DynamicSymbols
): key is DynamicSymbols => {
  if (dynamicSymbols[key]) {
    return true;
  }
  return false;
};
