import React from "react";

import styles from "./TableHead.module.css";

type PropType = {
  sizes: string[];
};

function TableHead(props: PropType) {
  const { sizes } = props;

  return (
    <div className={`${styles.head}`}>
      <div className={styles.name}>Navn</div>
      <div className={`${styles.label}`}>Forklaring</div>
      <div className={styles.sizes}>
        {sizes.map((size) => (
          <div key={size} className={styles.cell}>
            {size}
          </div>
        ))}
      </div>
      <div className={`${styles.cell} ${styles.unit}`}>Enhet</div>
    </div>
  );
}

export default TableHead;
