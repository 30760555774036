import { ContainerIcon } from "@radix-ui/react-icons";
import Imgix from "react-imgix";
import { BaseEditor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { imgixUrl } from "../imgixUrl";
import Button from "../ISO/Components/Button";
import { FigureSection, PatternElement } from "../store/pattern";
import { FigureElementType } from "../Writer/CustomElements/Figure";
import styles from "./MediaSection.module.css";

interface PropType {
  element: PatternElement;
  showOption: boolean;
  figureElement?: FigureElementType;
  editor?: BaseEditor & ReactEditor;
}

// For now we only use Imgix
export const MediaSection = ({
  element,
  showOption,
  editor,
  figureElement,
}: PropType) => {
  const media = element as FigureSection;

  return (
    <>
      {showOption && (
        <div className={styles.buttonGroup}>
          <Button
            active={media.inline}
            onClick={() => {
              if (editor && figureElement) {
                const path = ReactEditor.findPath(editor, figureElement);

                Transforms.setNodes(
                  editor,
                  {
                    inline: !media.inline,
                  },
                  { at: path }
                );
              }
            }}
          >
            <ContainerIcon aria-label="Pass til bredde" />
          </Button>
        </div>
      )}
      <div
        className={`${styles.center} ${
          media.inline ? styles.inline : styles.overflow
        }`}
      >
        <Imgix
          className={`${
            media.inline ? styles.imgixInline : styles.imgixOverflow
          }`}
          src={`${imgixUrl}/${media.figureId}`}
        />
      </div>
    </>
  );
};
