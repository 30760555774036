import { backendApiAddress } from "../backendApi";
import { ImageIdentification } from "../services/yarnApi";

function constructImageString(
  imageData: ImageIdentification,
  width: number,
  height: number,
  crop: boolean
): string {
  return (
    `${backendApiAddress}/images/v1/get/${imageData.id}/${imageData.token}` +
    `?width=${width}&height=${height}&crop=${crop}`
  );
}

export default constructImageString;
