import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setMeasureLabel,
  toggleMeasureLocked,
  deleteMeasure,
  updateCalculations,
} from "../store/calculationSlice";

import { NodeTableMeasure } from "./calculations";
import { RootState } from "../store/store";

import MeasureKindSelect from "./MeasureKindSelect";
import NodeCell from "./NodeCell";
import NewRowBetween from "./NewRowBetween";
import LockOpen from "../CommonComponents/Icons/LockOpen";
import LockClosed from "../CommonComponents/Icons/LockClosed";
import Cross from "../CommonComponents/Icons/Cross";
import { Modal } from "../CommonComponents/Modal";
import { useModal } from "../CommonComponents/useModal";

import styles from "./NodeRow.module.css";

type PropType = {
  tableId: string;
  measure: NodeTableMeasure;
  measureIndex: number;
  sizes: string[];
  last: boolean;
};

function NodeRow(props: PropType) {
  const { measure, tableId, sizes, measureIndex, last } = props;

  const measureLocked = useSelector((state: RootState) => {
    const nodes = state.calculations.graph.nodes[measure.nodes[sizes[0]]];
    return nodes ? nodes.locked : false;
  });

  const dispatch = useDispatch();

  const [shouldSave, setShouldSave] = useState(false);
  useEffect(() => {
    setShouldSave(true);
  }, [measure.label]);

  const { isShown, toggle } = useModal();

  return (
    <div key={measure.id} className={styles.row}>
      <div className={styles.lockContainer}>
        <button
          className={styles.lock}
          onClick={() =>
            dispatch(toggleMeasureLocked({ tableId, measureId: measure.id }))
          }
        >
          {measureLocked ? <LockClosed /> : <LockOpen />}
        </button>
      </div>
      <div className={styles.label}>
        <input
          className={styles.input}
          placeholder={"Navn på rad..."}
          type="text"
          value={measure.label}
          onChange={(e) =>
            dispatch(
              setMeasureLabel({
                tableId,
                measureId: measure.id,
                label: e.target.value,
              })
            )
          }
          onBlur={() => {
            if (shouldSave) {
              dispatch(updateCalculations());
              setShouldSave(false);
            }
          }}
        />
      </div>
      <div className={styles.pusher}>&nbsp;</div>
      <div className={styles.cells}>
        {sizes.map((size) => {
          return <NodeCell key={size} nodeId={measure.nodes[size]} />;
        })}
      </div>
      <div className={styles.unit}>
        <MeasureKindSelect measure={measure} tableId={tableId} />
      </div>
      <div className={styles.deleteContainer}>
        <button className={styles.delete} onClick={toggle}>
          <Cross width={16} height={16} fill={"var(--negative)"} />
        </button>
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText={"Er du sikker på at du vil slette raden?"}
          modalContent={
            <div className={styles.modalButtonContainer}>
              <button
                className={styles.confirmDelete}
                onClick={() =>
                  dispatch(deleteMeasure({ tableId, measureId: measure.id })) &&
                  toggle()
                }
              >
                Ja
              </button>

              <button onClick={toggle}>Nei</button>
            </div>
          }
        />
      </div>
      {!last && (
        <NewRowBetween tableId={tableId} measureIndex={measureIndex + 1} />
      )}
    </div>
  );
}

export default NodeRow;
