import { useState, useEffect } from "react";

export const useMousePos = () => {
  const [mousePos, setMousePos] = useState({ posX: -1, posY: -1 });
  const [isMouseDown, setMouseDown] = useState(false);
  useEffect(() => {
    const getMousePos = (e: MouseEvent) => {
      const posX = e.pageX;
      const posY = e.pageY;
      if (isMouseDown) {
        window.getSelection()?.removeAllRanges();
      }
      setMousePos({ posX, posY });
    };
    document.addEventListener("mousemove", getMousePos);
    return function cleanup() {
      document.removeEventListener("mousemove", getMousePos);
    };
  });

  useEffect(() => {
    const eventType: "mouseup" | "mousedown" = "mousedown";
    const mouseDown = (e: MouseEvent) => {
      if (e.buttons === 1) {
        setMouseDown(true);
      }
    };
    document.addEventListener(eventType, mouseDown);
    return function cleanup() {
      document.removeEventListener(eventType, mouseDown);
    };
  });
  useEffect(() => {
    const eventType: "mouseup" | "mousedown" = "mouseup";
    const mouseUp = (e: MouseEvent) => {
      setMouseDown(false);
    };
    document.addEventListener(eventType, mouseUp);
    return function cleanup() {
      document.removeEventListener(eventType, mouseUp);
    };
  });
  return { ...mousePos, isMouseDown };
};
