import React from "react";
import styles from "../Styles/Sections.module.css";
import EmailInputForm from "./EmailInputForm";
import example_sweather from "../../Visualizing/LandingPage/hikaru_sweather_illustration.png";
import ipad_wds from "../../Visualizing/LandingPage/ipad_WDS.png";
import { Link } from "react-router-dom";
import { t } from "i18next";

function HeaderSection() {
  const description: string =
    "Bli en del av Woolits økosystem med direkte tilgang til strikkere, garn, app og verktøy for design og publisering. Å leve av strikkedesign har aldri vært lettere!";
  return (
    <div >
      <div className={styles.wdsBanner}> Ønsker du å designe for privat bruk?<Link
        style={{ color: "#33251a", margin: "12px", cursor: "pointer"}}
        to="https://3d.woolit.no/"
      >
       <text>Gå til <text style={{ fontWeight: 500}}>Woolit Design Studio! ➚</text></text>
      </Link></div>
      <div className={styles.headerSection} id="header-section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          {" "}
          <div style={{ alignSelf: "center", position: "relative" }}>
            <img
              width="350"
              height="460"
              src={ipad_wds}
              style={{ alignSelf: "center" }}
              alt="Illustrasjon av Woolit Design Studio Pro"
            />
            
          </div>
          
          
        </div>
          {/* <div style={{ alignSelf: "center", position: "relative" }}>
            <img
              width="350"
              height="350"
              src={example_sweather}
              style={{ alignSelf: "center" }}
              alt="Illustrasjon av Woolit Design Studio Pro"
            />
            <div style={{ position: "absolute", bottom: "8px", right: "16px" }}>
              by Hikaru Nakamura
            </div>
          </div>
          
          <text
            style={{
              maxWidth: "350px",
              marginTop:"10px"
            }}
          >
            <text
              style={{
                fontWeight: "bold",
                flexWrap: "wrap",
                wordBreak: "break-word",
              }}
            >
              As seen on Norway Chess!
            </text>{" "}
            <text
              style={{
                color: "#f27900",
                flexWrap: "wrap",
                wordBreak: "break-word",
              }}
            >
              Sweater Design Program
            </text>{" "}
            makes it simple for anyone to create their own sweater.
          </text>
        </div> */}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              background: "#ffffff",
            }}
          >
            <div className={styles.headerTitle}>
              Design, publiser og selg dine strikkeoppskrifter
            </div>
            <div className={styles.headerDescription}>{description}</div>
          </div>
          <EmailInputForm />
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
