import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Wrapper } from "../CommonComponents/Wrapper";
import { useGetAllOrgsQuery, Organization } from "../services/organization";

import styles from "./organization.module.css";

export const MyOrganizations = () => {
  const { data, error, isLoading } = useGetAllOrgsQuery();
  const { t } = useTranslation();
  return (
    <Wrapper error={error} isLoading={isLoading}>
      <div className={styles.orgListWrapper}>
        <div className={styles.orgList}>
          <h2>{t("my organization", { count: data?.length ?? 1 })}</h2>
          <ul>
            {data?.map((org: Organization) => (
              <Link key={org.id} to={`organization/${org.id}`}>
                <li>{org.name}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};
