import { Pencil2Icon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import { ModalButton } from "../../CommonComponents/ModalButton";
import Select from "../../CommonComponents/Select";
import { useModal } from "../../CommonComponents/useModal";
import Button from "../../ISO/Components/Button";
import { useGetAllOrgsQuery } from "../../services/organization";
import { useAddToOrganizationMutation } from "../../services/patternApi";
import { Organization } from "../../services/organization";
import styles from "./MoveOrg.module.css";

export const MoveOrg = () => {
  const { isShown, toggle } = useModal();
  const { data } = useGetAllOrgsQuery();

  return (
    <ModalButton
      style={{ marginLeft: "16px" }}
      isShown={isShown}
      toggle={toggle}
      headerText="Flytt oppskrift"
      modalContent={<ModalContent toggle={toggle} orgs={data ?? []} />}
    >
      <Pencil2Icon />
    </ModalButton>
  );
};

interface PropType {
  orgs: Organization[];
  toggle: () => void;
}
const empty = { label: "Velg org", value: "-1" };

const ModalContent = (props: PropType) => {
  const { orgs, toggle } = props;

  const [updateOrg] = useAddToOrganizationMutation();
  const [newOrg, setNewOrg] = useState(empty);
  const { patternId } = useParams();
  if (!patternId) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <Select
        onChange={(selected) => {
          if (selected === null) return;
          setNewOrg(selected);
        }}
        value={newOrg}
        options={orgs.map((org, i) => ({
          label: org.name,
          value: `${org.id}`,
        }))}
      />
      <div className={styles.buttonGroup}>
        <Button onClick={toggle} contrast>
          Avbryt
        </Button>
        <Button
          onClick={() => {
            updateOrg({ newOrgId: newOrg.value, id: patternId });
            toggle();
          }}
          active
        >
          Endre organisasjon
        </Button>
      </div>
    </div>
  );
};
