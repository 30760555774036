import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import {
  TableMeasure,
  FlatGraphEdge,
  MeasureElement,
  Unit,
} from "./calculations";

import { setMeasureElementKind } from "../store/calculationSlice";

import styles from "./MeasureKindSelect.module.css";

interface PropType {
  tableId: string;
  measure: TableMeasure;
}

function isMaskKind(unit: Unit): boolean {
  switch (unit) {
    case "Mask":
      return true;
    case "Cm":
    default:
      return false;
  }
}

function MeasureKindSelect(props: PropType) {
  const { measure, tableId } = props;

  const element = useSelector((state: RootState) => {
    switch (measure.kind) {
      case "node":
        const node =
          state.calculations.graph.nodes[Object.values(measure.nodes)[0]];
        return state.calculations.graph.elements[node.element];
      case "edge":
        return state.calculations.graph.edges[Object.values(measure.edges)[0]];
    }
  });

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      {measure.kind === "node" ? (
        <div className={styles.element}>
          <button
            className={
              isMaskKind((element as MeasureElement).unit)
                ? styles.selected
                : ""
            }
            onClick={() =>
              dispatch(
                setMeasureElementKind({
                  tableId,
                  measureId: measure.id,
                  unit: "Mask",
                })
              )
            }
          >
            m
          </button>
          <button
            className={
              !isMaskKind((element as MeasureElement).unit)
                ? styles.selected
                : ""
            }
            onClick={() =>
              dispatch(
                setMeasureElementKind({
                  tableId,
                  measureId: measure.id,
                  unit: "Cm",
                })
              )
            }
          >
            cm
          </button>
        </div>
      ) : (
        <EdgeSelect
          edge={element as FlatGraphEdge}
          measureId={measure.id}
          tableId={tableId}
        />
      )}
    </div>
  );
}

interface EdgePropType {
  tableId: string;
  measureId: string;
  edge: FlatGraphEdge;
}

function EdgeSelect(props: EdgePropType) {
  const { edge, measureId, tableId } = props;

  const horizontalChangeElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.horizontalChangeElement]
  );
  const frequencyElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.frequencyElement]
  );
  const verticalDistanceElement = useSelector(
    (state: RootState) =>
      state.calculations.graph.elements[edge.verticalDistanceElement]
  );

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <button
          className={
            isMaskKind(horizontalChangeElement.unit) ? styles.selected : ""
          }
          onClick={() =>
            dispatch(
              setMeasureElementKind({ tableId, measureId, unit: "Mask" })
            )
          }
        >
          m
        </button>
        <button
          className={
            !isMaskKind(horizontalChangeElement.unit) ? styles.selected : ""
          }
          onClick={() =>
            dispatch(setMeasureElementKind({ tableId, measureId, unit: "Cm" }))
          }
        >
          cm
        </button>
      </div>

      <div className={styles.element}>
        <button
          className={isMaskKind(frequencyElement.unit) ? styles.selected : ""}
          onClick={() =>
            dispatch(
              setMeasureElementKind({
                tableId,
                measureId,
                unit: "Mask",
                edgeElementKind: "frequency",
              })
            )
          }
        >
          m
        </button>
        <button
          className={!isMaskKind(frequencyElement.unit) ? styles.selected : ""}
          onClick={() =>
            dispatch(
              setMeasureElementKind({
                tableId,
                measureId,
                unit: "Cm",
                edgeElementKind: "frequency",
              })
            )
          }
        >
          cm
        </button>
      </div>

      <div className={styles.element}>
        <button
          className={
            isMaskKind(verticalDistanceElement.unit) ? styles.selected : ""
          }
          onClick={() =>
            dispatch(
              setMeasureElementKind({
                tableId,
                measureId,
                unit: "Mask",
                edgeElementKind: "vertical",
              })
            )
          }
        >
          m
        </button>
        <button
          className={
            !isMaskKind(verticalDistanceElement.unit) ? styles.selected : ""
          }
          onClick={() =>
            dispatch(
              setMeasureElementKind({
                tableId,
                measureId,
                unit: "Cm",
                edgeElementKind: "vertical",
              })
            )
          }
        >
          cm
        </button>
      </div>
    </div>
  );
}

export default MeasureKindSelect;
