import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Imgix from "react-imgix";
import { RootState } from "../../store/store";
import styles from "./Media.module.css";
import drawerStyles from "../../Metadata/MetadataDrawer.module.css";
import { imgixUrl } from "../../imgixUrl";
import { ImageUpload } from "./ImageUpload";
import { deleteFile } from "../../store/patternSlice";
import { ModalButton } from "../../CommonComponents/ModalButton";
import { useModal } from "../../CommonComponents/useModal";
import { closeSubdrawer } from "../../store/writerSlice";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

function Media() {
  const { t } = useTranslation();
  const { id: patternId, media } = useSelector(
    (state: RootState) => state.pattern
  );

  const dispatch = useDispatch();

  return (
    <div className={`${styles.drawerContainer}`}>
      <div className={drawerStyles.closeSubdrawerButton}>
        <button onClick={() => dispatch(closeSubdrawer())}>
          <ArrowLeftIcon />
        </button>
      </div>
      <h1 className={styles.sectionTitle}>{t("datadrawer.upload media")}</h1>
      <ImageUpload patternId={patternId} />
      <hr className={styles.sectionRuler} />
      <h1 className={styles.sectionTitle}>{t("datadrawer.from library")}</h1>
      {Object.keys(media).map((image, index) => (
        <ImageComponent
          image={image}
          index={index}
          key={index}
          patternId={patternId}
        />
      ))}
    </div>
  );
}

const ImageComponent: React.FC<{
  image: string;
  index: number;
  patternId: number;
}> = ({ image, index, patternId }) => {
  const { t } = useTranslation();
  const { isShown, toggle } = useModal();
  const dispatch = useDispatch();

  return (
    <div key={index} className={styles.imageContainer}>
      <Imgix src={`${imgixUrl}/${image}`} width={96} />
      <ModalButton
        isShown={isShown}
        toggle={toggle}
        headerText={""}
        modalContent={
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <p>{t("tooltips.delete confirm")}</p>
              <button
                style={{ marginTop: 16 }}
                onClick={() => {
                  dispatch(deleteFile({ patternId, fileId: image }));
                  toggle();
                }}
              >
                {t("navigation.delete")}
              </button>
            </div>
          </div>
        }
        className={styles.deleteButton}
      >
        {t("navigation.delete")}
      </ModalButton>
    </div>
  );
};

export default Media;
