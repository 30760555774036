import { useCallback, useEffect, useRef, useState } from "react";
import { DiagramData, GridCell } from "../../store/pattern";
import { Pattern } from "../Pattern";
import { SweaterPart } from "../SweaterPart";
import { hexToRgb, rgbToHex } from "../knittingpreview/colorutil";
import { getSweaterParts } from "../knittingpreview/scene";
import { Settings } from "./settings";
import { crossAlign } from "../knittingeditor/gridcanvas";
import { Vector2 } from "three";

//Help functions. E.x. math
export class Util {
  static colorIndexToColor(colors: string[], colorIndex: number) {
    let color = "";
    if (colorIndex === -2) {
      // Kind of a bad solution. But these must be non-hex colors, since
      // this information is used to determine if the color originated from === -2
      // Edit: Im not sure if this is longer a issue
      color = "var(--whiteish)";
    } else if (colorIndex === -1) {
      color = "#ffffff";
      //return (x + y) % 2 === 1 ? "#f0f0f0" : "#e0e0e0";
    } else {
      color = colors[colorIndex];
    }
    return color;
  }
  static calculateGridColor(
    x: any,
    y: any,
    colors: any,
    colorIndex: any,
  ) {
    const color = this.colorIndexToColor(colors, colorIndex);
    //if (warningOverlay) return mixColors([color, "#000000"])
    return color;
    //return "var(--whiteish)"
  }
  static calculateBorderColor(
    x: number,
    y: number,
    _color: any,
    isOutside: boolean = false,
    warningOverlay: boolean = false
  ) {
    let color = "";
    if (isOutside) {
      color = "var(--whiteish)";
    } else {
      const rgb = hexToRgb(_color);
      /*if (rgb.r + rgb.g + rgb.b > 700 && rgb.r + rgb.g + rgb.b < 730) {
                return "#ffffff"
            }*/
      if (rgb.r + rgb.g + rgb.b >= 730) {
        color = "#f0f0f0";
      } else {
        const darkenAmount = 0.75;
        rgb.r = rgb.r * (1 - darkenAmount);
        rgb.g = rgb.g * (1 - darkenAmount);
        rgb.b = rgb.b * (1 - darkenAmount);
        color = rgbToHex(rgb);
      }
    }
    //if (warningOverlay) return mixColors([color, "#000000"])
    return color;
  }

  static clamp(num: number, min: number, max: number) {
    return Math.max(min, Math.min(max, num));
  }

  static patternIsEmpty(grid: number[][]) {
    const colors = new Set(grid.flat(1));
    colors.delete(-1);
    return colors.size === 0;
  }

  static make2DArray(x: number, y: number, fillWith: any = -2) {
    return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
  }

  static patternIsMonocolored(pattern: Pattern) {
    const uniqueColors = new Set(pattern.grid.flat(1));
    uniqueColors.delete(-1);
    return uniqueColors.size === 1;
  }

  static sum(array: number[]) {
    return array.reduce((a, b) => a + b, 0);
  }

  static mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }

  //string representation of vector2. Thus can be used as a key in dictionaries
  static point(x: number, y: number) {
    return x + "," + y;
  }

  static mergeDicts(dicts: any) {
    const mergedDict: { [id: string]: number } = {};
    for (let dict of dicts) {
      for (let key of Object.keys(dict)) {
        if (!(key in mergedDict)) {
          mergedDict[key] = 0;
        }
        mergedDict[key] += dict[key];
      }
    }
    return mergedDict;
  }

  static titleCase(str: string) {
    if (!str) return "undefined"
    var splitStr = str.toLowerCase().replace("_", " ").split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  static makeGrid(size: number) {
    let grid: number[][] = [];
    for (let y = 0; y < size; y++) {
      let grid_int: [] = [];
      grid.push(grid_int);
      for (let x = 0; x < size; x++) {
        grid[y].push(0);
      }
    }
    return grid;
  }


  static isKey(pathName: string) {
    if (pathName.length !== 8) return false;
    return pathName.match(/^[A-Za-z0-9]*$/) !== null;
  }

  // priorizitedItem is placed at front
  static sortPriority(array: any[], prioritizedItem: any){
    return array.sort((a, _) => 0.5 - Number(a === prioritizedItem))
  }

  static diagramToPattern(diagram: DiagramData){
    const grid = diagram.grid.map((row: GridCell[]) =>
        row.map((cell: GridCell) => cell["color"])
      );
      const pattern = new Pattern(grid);
      pattern.name = diagram.name;
      pattern.id = diagram.id;
      return pattern;
  }

  static makeSetOccupiedPos(setOccupiedPos: any, sweaterPartOverlap: any, sweaterPartInGrid: any, grid: any, clickY: any){
    const [_pattern, _isOutside, sweaterPart] = sweaterPartOverlap;
    const [_, dyCross] = crossAlign(
      sweaterPart as SweaterPart,
      sweaterPartInGrid
    );
    if (_pattern) {
      const text = "Occupied in another sweater part";
      setOccupiedPos([_pattern.topY() - dyCross, _pattern.bottomY() - dyCross, text]);
    }
    if (_isOutside) {
      const oppositeGridLength = (sweaterPart as SweaterPart).sizeY;
      const text =
        "Is out of bounds on another sweater part.\nChange repeat mode.";
      const northSide = clickY < grid.length / 2;
      if (northSide) {
        setOccupiedPos([0, -dyCross, text]);
      } else {
        setOccupiedPos([
          -dyCross + oppositeGridLength,
          grid.length,
          text,
        ]);
      }
    }
  }

  static move(
    posDiff: Vector2,
    sweaterPart: any,
    pointerPattern: Pattern,
    gridHTML: any,
    render: any,
    setOccupiedPos: any,
    grid: any

  ){
    let moved = sweaterPart.moveGroup(
      pointerPattern,
      posDiff,
      gridHTML
    );
    render();
    if (!moved) {
      const sweaterPartOverlap = sweaterPart.getOverlapMoveGroup(
        pointerPattern,
        posDiff
      );
      const [pattern, isOutside, _sweaterPart] = sweaterPartOverlap;
      if (_sweaterPart !== sweaterPart) {
        Util.makeSetOccupiedPos(
          setOccupiedPos,
          sweaterPartOverlap,
          sweaterPart,
          grid,
          pointerPattern.pos.y + pointerPattern.middleY()
        );
      }
      return false;
    }
    return true;
  }
}
