import React from "react";

interface PropType {
  color?: string;
}

function ChangeLastRow(props: PropType) {
  const { color } = props;
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9336 1.86621L0.066926 1.86621L0.0669261 0.26621L13.9336 0.266211L13.9336 1.86621Z"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="10.667"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 10.667)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="10.667"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 10.667)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="12.7998"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 12.7998)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="12.7998"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 12.7998)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="8.5332"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 8.5332)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="8.5332"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 8.5332)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="9.66602"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 9.66602 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="11.7988"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 11.7988 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="5.40039"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 5.40039 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="3.26562"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 3.26562 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="6.40039"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 6.40039)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="6.40039"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 6.40039)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="7.5332"
        y="4.2666"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 7.5332 4.2666)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="1.13281"
        y="4.2666"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 1.13281 4.2666)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="10.667"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 10.667)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="12.7998"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 12.7998)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="8.5332"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 8.5332)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="14.9336"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 14.9336)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="6.40039"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 6.40039)"
        fill={color ?? "#33251A"}
      />
      <rect
        x="13.9336"
        y="4.2666"
        width="1.06667"
        height="1.06667"
        rx="0.533333"
        transform="rotate(-180 13.9336 4.2666)"
        fill={color ?? "#33251A"}
      />
    </svg>
  );
}

export default ChangeLastRow;
