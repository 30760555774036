import React from "react";
import { YarnProfileVariant } from "../store/pattern";
import styles from "./Hero.module.css";

interface PropType {
  variant: YarnProfileVariant;
}

export const SingleYarnVariant = (props: PropType) => {
  const { variant } = props;
  return (
    <div className={styles.colorContainer}>
      {variant?.colors.map((color, index) => (
        <div
          key={index}
          className={styles.color}
          style={{ background: color.hex ?? "red" }}
        />
      ))}
    </div>
  );
};
