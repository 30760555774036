import React, { useEffect } from "react";
import { Symbols } from "@iterate/woolit-components";
import { useDispatch, useSelector } from "react-redux";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import { setActiveColorIndex, setActiveSymbol } from "../store/colorSlice";
import {
  fillArea,
  increaseBottom,
  increaseLeft,
  increaseRight,
  increaseTop,
  insertDynamicSymbol,
  MarkedArea,
  setGridCursor,
  setMarkedArea,
  updateCell,
} from "../store/gridSlice";
import { RootState } from "../store/store";
import { useGridVariables } from "./utils/hooks";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { splitIntoSingleAndDynamicSymbols } from "./Symbols";

const emptyCell: { color: number; symbol: Symbols } = {
  color: 0,
  symbol: "stitch",
};

export const GridCursor = () => {
  const dispatch = useDispatch();

  const { cursorX, cursorY, activeDiagram, enabled, cursorMode, markedArea } =
    useSelector((state: RootState) => ({
      enabled: !state.grid.disableHotKeys,
      cursorX: state.grid.gridCursor.x,
      cursorY: state.grid.gridCursor.y,
      activeDiagram: state.grid.activeDiagram,
      cursorMode: state.grid.cursorMode,
      markedArea: state.grid.markedArea,
    }));

  const convertToAreaList = (
    markedArea: MarkedArea
  ): { row: number; col: number }[] => {
    console.log(markedArea);
    const areaList: { row: number; col: number }[] = [];
    for (let y = markedArea.a.y; y < markedArea.c.y + 1; y++) {
      for (let x = markedArea.a.x; x < markedArea.c.x + 1; x++) {
        areaList.push({ row: y, col: x });
      }
    }
    return areaList;
  };

  const removeSelectionIfSingleSquare = () => {
    if (
      markedArea.a.x === markedArea.c.x &&
      markedArea.a.y === markedArea.c.y
    ) {
      dispatch(setMarkedArea({ grid: [] }));
    }
  };

  useEffect(() => {
    dispatch(setGridCursor({ x: 0, y: 0 }));
  }, [activeDiagram]);

  const colorCount = useSelector((state: RootState) => {
    const { variants } = state.pattern.meta;
    let max = 0;
    variants.forEach((variant) => {
      if (variant.colors.length > max) max = variant.colors.length;
    });
    return max;
  });

  const { activeIndex, activeSymbol } = useSelector(
    (state: RootState) => state.color
  );

  const { symbols } = useSelector((state: RootState) => state.pattern);

  const { singleSymbols } = splitIntoSingleAndDynamicSymbols(symbols);

  const singleSymbolsHotkeysArray = new Array(singleSymbols.length)
    .fill("b")
    .map((_, i) => `Shift+Control+${i + 1}`);

  useHotkeys(
    singleSymbolsHotkeysArray,
    (e) => {
      e.preventDefault();
      dispatch(
        setActiveSymbol({
          symbol: singleSymbols[parseInt(e.key) - 1].key,
          type: "single",
        })
      );
    },
    { enabled }
  );

  const colorsHotkeysArray = new Array(colorCount)
    .fill("b")
    .map((_, i) => `Control+${i + 1}`);

  useHotkeys(
    colorsHotkeysArray,
    (e: KeyboardEvent) => {
      dispatch(setActiveColorIndex(parseInt(e.key) - 1));
      e.preventDefault();
    },
    { enabled }
  );

  const { cellHeight, cellWidth, offsetX, offsetY } = useGridVariables();

  // Regular movement
  useHotkeys(
    "ArrowUp",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(setGridCursor({ x: cursorX, y: cursorY - 1 }));
      removeSelectionIfSingleSquare();
    },
    { enabled }
  );

  useHotkeys(
    "ArrowDown",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(setGridCursor({ x: cursorX, y: cursorY + 1 }));
      removeSelectionIfSingleSquare();
    },
    { enabled }
  );

  useHotkeys(
    "ArrowLeft",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(setGridCursor({ x: cursorX - 1, y: cursorY }));
      removeSelectionIfSingleSquare();
    },
    { enabled }
  );

  useHotkeys(
    "ArrowRight",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(setGridCursor({ x: cursorX + 1, y: cursorY }));
      removeSelectionIfSingleSquare();
    },
    { enabled }
  );

  // Shift movement
  useHotkeys(
    "Shift+ArrowUp",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(increaseTop({ x: cursorX, y: cursorY }));
    },
    { enabled }
  );

  useHotkeys(
    "Shift+ArrowDown",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(increaseBottom({ x: cursorX, y: cursorY }));
    },
    { enabled }
  );

  useHotkeys(
    "Shift+ArrowLeft",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(increaseLeft({ x: cursorX, y: cursorY }));
    },
    { enabled }
  );

  useHotkeys(
    "Shift+ArrowRight",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(increaseRight({ x: cursorX, y: cursorY }));
    },
    { enabled }
  );

  useHotkeys(
    " ",
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (cursorMode === "erase") {
        dispatch(
          updateCell({
            row: cursorY,
            col: cursorX,
            cellData: { color: -1, symbol: "stitch" },
          })
        );
      } else if (cursorMode === "color") {
        dispatch(
          updateCell({
            row: cursorY,
            col: cursorX,
            cellData: { color: activeIndex, symbol: activeSymbol },
          })
        );
      } else {
        // Insert
        if (markedArea.a.x === -1) {
          dispatch(
            updateCell({
              row: cursorY,
              col: cursorX,
              cellData: { color: activeIndex, symbol: activeSymbol },
            })
          );
        } else {
          // Check symboltype
          if (isDynamic(activeSymbol)) {
            dispatch(
              insertDynamicSymbol({
                area: convertToAreaList(markedArea),
                cellData: { color: 0, symbol: activeSymbol },
              })
            );
          } else {
            dispatch(
              fillArea({
                area: markedArea,
                cellData: {
                  color: activeIndex,
                  symbol: activeSymbol as Symbols,
                },
              })
            );
          }
        }
      }
    },
    { enabled }
  );

  // TODO: Add remove area on backspace
  useHotkeys(
    "Backspace",
    (e: KeyboardEvent) => {
      e.preventDefault();
      dispatch(updateCell({ row: cursorY, col: cursorX, cellData: emptyCell }));
    },
    { enabled }
  );

  return (
    <div
      style={{
        position: "absolute",
        top: offsetY + cellHeight * cursorY,
        left: offsetX + cellWidth * cursorX,
        height: cellHeight - 1,
        width: cellWidth - 1,
        border: "1px #FF490D solid",
        zIndex: 2,
        pointerEvents: "none",
      }}
    />
  );
};
