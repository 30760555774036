import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constructImageString from "../utils/constructImageString";
import { RootState } from "../store/store";

import { adminSaveChangedYarn, changeYarnColor } from "../store/adminSlice";

import ColorPreview from "../CommonComponents/ColorPreview";

import styles from "./YarnSection.module.css";
import { APIYarn } from "../services/yarnApi";

interface PropType {
  yarnList: APIYarn[];
}

function YarnSection(props: PropType) {
  const { yarnList } = props;

  const { localChanges } = useSelector((state: RootState) => state.admin.yarn);

  const dispatch = useDispatch();

  const [yarnOpen, setAllYarnOpen]: [{ [key: string]: boolean }, Function] =
    useState(Object.fromEntries(yarnList.map((yarn) => [yarn.sku, false])));

  const toggleYarnOpen = (sku: string) => {
    setAllYarnOpen({ ...yarnOpen, [sku]: !yarnOpen[sku] });
  };

  return (
    <div className={styles.container}>
      {yarnList.map((yarn) => (
        <div>
          <h4>{yarn.name}</h4>
          {yarnOpen[yarn.sku] ? (
            <div>
              <div className={styles.buttonRow}>
                <button
                  className={styles.toggleButton}
                  onClick={() => toggleYarnOpen(yarn.sku)}
                >
                  Hide colours of {yarn.name}
                </button>
                <button
                  onClick={() => dispatch(adminSaveChangedYarn())}
                  className={styles.warningButton}
                >
                  Save all changed hexes
                </button>
              </div>
              <div className={styles.colors}>
                {yarn.colors.map((color) => (
                  <div className={styles.colorRow}>
                    <div className={styles.colorText}>
                      {color.color_tint + " " + color.color_tint_code}
                    </div>
                    {color.image_base && (
                      <img
                        className={styles.colorImage}
                        src={constructImageString(
                          color.image_base,
                          240,
                          240,
                          false
                        )}
                        alt={"Large base"}
                      />
                    )}
                    {color.image_swatch && (
                      <>
                        <img
                          className={styles.colorImage}
                          src={constructImageString(
                            color.image_swatch,
                            240,
                            240,
                            false
                          )}
                          alt={"Swatch, large"}
                        />
                        <ColorPreview
                          color={{
                            sku: color.sku,
                            name: color.name,
                            code: color.color_tint_code,
                            yarn: { name: yarn.name, sku: yarn.sku },
                            hex: null,
                            image: constructImageString(
                              color.image_swatch,
                              4,
                              4,
                              false
                            ),
                          }}
                        />
                      </>
                    )}
                    <input
                      type={"text"}
                      value={localChanges[color.sku]?.hex ?? color.hex ?? ""}
                      placeholder={"Mangler hex: #fafafa"}
                      onChange={(e) =>
                        dispatch(
                          changeYarnColor({
                            sku: color.sku,
                            hex: e.target.value,
                            yarnId: yarn.id,
                            yarnSku: yarn.sku,
                          })
                        )
                      }
                    />
                    <ColorPreview
                      color={{
                        sku: null,
                        hex:
                          localChanges[color.sku]?.hex ??
                          color.hex ??
                          "#fafafa",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <button
              className={styles.toggleButton}
              onClick={() => toggleYarnOpen(yarn.sku)}
            >
              Show {yarn.colors.length} colours
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default YarnSection;
