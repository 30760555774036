import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPatternTags } from "../../store/patternSlice";
import { RootState } from "../../store/store";
import styles from "./Tags.module.css";

function TagsInput() {
  const tags = [
    "genser",
    "bukse",
    "kofte",
    "lue",
    "votter",
    "kjole",
    "vest",
    "hals",
    "skjerf",
  ];

  const [allTags, setAllTags] = useState<string[]>(tags);
  const patternTags = useSelector((state: RootState) => state.pattern.tags);

  useEffect(() => {
    updateAllTags();
  }, [patternTags]);

  const dispatch = useDispatch();

  const handleTagInput = (e: React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    const element = e.currentTarget as HTMLTextAreaElement;
    const value = element.value;
    if (patternTags.includes(value)) {
      element.value = "";
      return;
    }

    updateAllTags();
    dispatch(addPatternTags([...patternTags, value]));
    element.value = "";
  };

  const chooseTag = (element: string) => {
    updateAllTags();
    if (patternTags.includes(element)) {
      return;
    }
    dispatch(addPatternTags([...patternTags, element]));
  };

  const removeTag = (index: number, element: string) => {
    updateAllTags();
    dispatch(addPatternTags(patternTags.filter((el, i) => i !== index)));
  };

  const updateAllTags = () => {
    setAllTags(tags.filter((tag) => !patternTags.includes(tag)));
  };

  return (
    <div>
      <div className={styles.tagsInputContainer}>
        {patternTags.map((tag, index) => (
          <div
            className={styles.chosenTagItem}
            key={index}
            onClick={() => removeTag(index, tag)}
          >
            <span className={styles.text}>
              {tag.charAt(0).toUpperCase() + tag.slice(1)}

            </span>
            <span className={styles.close}> x</span>
          </div>
        ))}

        <input
          type="text"
          className={styles.tagsInput}
          onKeyDown={handleTagInput}
          placeholder="Legg til en kategori"
        ></input>
      </div>
      <div >Kategoriforslag:</div>
      <div className={styles.tagsInputContainer}>
        {allTags.map((tag, index) => (
          <div
            className={styles.tagItem}
            key={index}
            onClick={() => chooseTag(tag)}
          >
            <span className={styles.text}>
              {tag.charAt(0).toUpperCase() + tag.slice(1)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TagsInput;
