import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import Button from "./Components/Button";
import { toggleSize } from "./displayState";
import styles from "./SizeSelector.module.css";

interface PropType {
  allSizes: string[];
  selectedSizes: string[];
  onSizeToggle: (size: string) => void;
}

function SizeSelector(props: PropType) {
  const { allSizes, selectedSizes, onSizeToggle } = props;

  let buttons = [];
  for (let size of allSizes) {
    buttons.push(
      <Button
        key={size}
        active={selectedSizes?.includes(size)}
        onClick={() => onSizeToggle(size)}
      >
        {size}
      </Button>
    );
  }

  return <div className={styles.buttonContainer}>{buttons}</div>;
}

function AwareSizeSelector() {
  const [allSizes, selectedSizes] = useSelector((state: RootState) => [
    state.pattern.meta.sizes,
    state.displayState.activeSizes,
    state.displayState.sizeOrder,
  ]);
  const dispatch = useDispatch();

  const onToggleSize = (size: string) => {
    dispatch(toggleSize(size));
  };

  return (
    <SizeSelector
      allSizes={allSizes}
      selectedSizes={selectedSizes}
      onSizeToggle={onToggleSize}
    />
  );
}

export default SizeSelector;

export { AwareSizeSelector };
