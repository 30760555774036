import React, { useState } from "react";

import styles from "./Bottom.module.css";
import { ModalCtaButton } from "./ModalButton";
import { Pattern } from "../store/pattern";
import { ModelKnitButton } from "../Writer/ModelKnit/ModelKnitButton";
import { AwareSizeSelector } from "../ISO/SizeSelector";
import { AwareYarnVariantSelector } from "../ISO/YarnVariantSelector";
import Button from "../ISO/Components/Button";
import { PublishPatternModal } from "../Writer/PublishPatternModal";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { openCommentDrawer } from "../store/writerSlice";
import { useDispatch } from "react-redux";
import { ExportButton } from "../Export/ExportButton";
import { AwareShortenedSelector } from "../ISO/ShortenedSelector";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

interface PropType {
  transparent?: boolean;
  pattern: Pattern;
}

const Bottom: React.FC<PropType> = (props) => {
  const { pattern, transparent } = props;
  const dispatch = useDispatch();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const togglePublishModal = () => setShowPublishModal(!showPublishModal);

  const { pathname } = useLocation();
  const isPreview = pathname.includes("pattern");
  const { t } = useTranslation();

  return (
    <footer className={`${styles.footer} ${transparent ? "" : styles.filled}`}>
      <div className={styles.wrapper}>
        <div>
          <Button
            disabled={Object.keys(pattern.comments).length === 0}
            onClick={() => dispatch(openCommentDrawer())}
            className={styles.commentButton}
          >
            {Object.keys(pattern.comments).length > 0 && (
              <div className={styles.notification}>
                {Object.keys(pattern.comments).length}
              </div>
            )}
            <ChatBubbleIcon />
          </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <AwareShortenedSelector /> */}
          <AwareYarnVariantSelector />
          <div style={{ margin: "0 12px" }} />
          <AwareSizeSelector />
          <div style={{ margin: "0 12px" }} />
          {isPreview && <AwareShortenedSelector />}
        </div>
        <div className={styles.right}>
          <ExportButton />
          <ModelKnitButton />
          <ModalCtaButton
            headerText={"Publiser oppskrift"}
            isShown={showPublishModal}
            toggle={togglePublishModal}
            modalContent={
              <PublishPatternModal
                pattern={pattern}
                toggle={togglePublishModal}
              />
            }
          >
            {t("publish")}
          </ModalCtaButton>
        </div>
      </div>
    </footer>
  );
};

export default Bottom;
