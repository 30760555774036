import React from "react";
import styles from "./Header.module.css";
import Button from "./Components/Button";
import { Link } from "react-router-dom";
import { EyeNoneIcon } from "@radix-ui/react-icons";

const Header: React.FC<{ id: string }> = ({ id }) => (
  <header className={styles.header}>
    <p>Woolit</p>
    <img className={styles.image} src="/logo.svg" alt="" />
    <Link to={`/edit/${id}/write`}>
      <Button>
        <EyeNoneIcon aria-label="Edit" />
      </Button>
    </Link>
  </header>
);

export default Header;
