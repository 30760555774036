import React from "react";
import { YarnProfileVariant } from "../store/pattern";
import { ColorItem } from "./ColorItem";

interface PropType {
  variant: YarnProfileVariant;
  activeSizes: string[];
}

export const ColorList = (props: PropType) => {
  const { variant, activeSizes } = props;

  return (
    <>
      {variant.colors.map((color, index) => (
        <ColorItem
          key={index}
          variant={variant}
          color={color}
          index={index}
          activeSizes={activeSizes}
        />
      ))}
    </>
  );
};
