import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setActiveDiagram,
  setDisableHotkeys,
  updateGrid,
} from "../store/gridSlice";
import { createGridArray } from "../store/initialDiagramState";
import { DiagramData, emptyDiagramData } from "../store/pattern";
import { addEmptyDiagram, updateDiagramById } from "../store/patternSlice";
import styles from "./diagram.module.css";
import { useTranslation } from "react-i18next";

interface PropType {
  patternId: number;
  toggle: () => void;
}

export const NewDiagram = (props: PropType) => {
    const { t } = useTranslation();
  const [diagramName, setDiagramName] = useState("New Diagram");
  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setDisableHotkeys(true));
    return () => {
      dispatch(setDisableHotkeys(false));
    };
  }, []);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <label>{t("diagram_name")}</label>
        <input
          value={diagramName}
          onChange={(e) => setDiagramName(e.target.value)}
          placeholder={t("diagram_name")}
        />
        <label>{t("terms.width")}</label>
        <input
          type="number"
          placeholder={t("terms.width")}
          onChange={(e) => setWidth(parseInt(e.target.value))}
          value={width}
        />
        <label>{t("terms.height")}</label>
        <input
          type="number"
          placeholder={t("terms.height")}
          onChange={(e) => setHeight(parseInt(e.target.value))}
          value={height}
        />
      </div>
      <button
        style={{ width: "100%" }}
        onClick={() => {
          const newDiagram: DiagramData = {
            ...emptyDiagramData(),
            grid: createGridArray(width, height),
            name: diagramName,
            gridHeight: height,
            gridWidth: width,
          };
          dispatch(
            addEmptyDiagram({
              diagramId: newDiagram.id,
              diagramData: newDiagram,
            })
          );
          dispatch(
            updateDiagramById({
              patternId: props.patternId,
              diagram: newDiagram,
            })
          );

          dispatch(setActiveDiagram({ id: newDiagram.id }));
          dispatch(updateGrid({ grid: newDiagram }));
          dispatch(setDisableHotkeys(false));
          props.toggle();
        }}
      >
        {t("create diagram")}
      </button>
    </div>
  );
};
