import PatternCanvas from "../knittingeditor/PatternCanvas";
import React from "react";

// Pretty ugly. Would rewrite this if necessary.
function GridButton(props: any) {
  return (
    <>
      <button
        id={props.id}
        className={
          "gridButton" +
          (props.small ? " small" : "") +
          (props.isSelectedBrown ? " hoverBrown" : "")
        }
        style={{
          margin: "0px",
          pointerEvents: "auto",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          ...(props.grayBorder
            ? {
                border: "0.5px solid gray",
              }
            : {}),
          ...(props.isSelected
            ? {
                border: `3px solid ${
                  props.isSelectedBrown
                    ? "var(--light-brown)"
                    : "rgba(127,161,117,1)"
                }`,
              }
            : {}),
          ...(props.customStyle ? props.customStyle : {}),
        }}
        onClick={() => {
          props.onClick();
        }}
      >
        {props.pattern?.name}
        {props.pattern && (
          <PatternCanvas pattern={props.pattern} colors={props.colors} />
        )}
        {props.imageURL && (
          <img
            alt={props.title}
            src={props.imageURL}
            style={{
              height: (props.size ?? 30) + "px",
              opacity: props.opacity,
            }}
          ></img>
        )}
        {props.color && (
          <div
            style={{
              backgroundColor: props.color,
              minWidth: "20px",
              maxWidth: "20px",
              height: "20px",
              border: "0.5px solid black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        )}
        {props.children}
      </button>
    </>
  );
}

export default GridButton;
