import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { Loading } from "../../CommonComponents/Loading";
import { RootState } from "../../store/store";
import { fetchMe } from "./LoginState";

function AdminRoute({ children, ...rest }: any) {
  let auth = useSelector((state: RootState) => state.loginState);

  const dispatch = useDispatch();

  const loadUserCallback = useCallback(() => dispatch(fetchMe()), [dispatch]);

  useEffect(() => {
    loadUserCallback();
  }, [loadUserCallback, auth.loginAttempted, auth.isLoggedIn]);

  if (auth.user?.isAdmin ?? false) {
    return children;
  } else if (auth.loginFailed || !(auth.user?.isAdmin ?? true)) {
    return <Navigate to={"/"} />;
  } else {
    return <Loading />;
  }
}

export default AdminRoute;
