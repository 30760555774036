import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backendApiAddress } from "../backendApi";
import { User } from "../Login/components/LoginState";

export interface Organization {
  id: number;
  created: Date;
  updated_at: Date;
  owner: User;
  name: string;
  slug: string | null;
}

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendApiAddress}/api/organizations`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getAllOrgs: builder.query<Organization[], void>({
      query: () => "/mine",
    }),
    getOrganizationById: builder.query<Organization, string>({
      query: (id) => `/${id}`,
    }),
    getUsers: builder.query<User[], string>({
      query: (id) => `${id}/users`,
    }),
  }),
});

export const {
  useGetAllOrgsQuery,
  useGetOrganizationByIdQuery,
  useGetUsersQuery,
} = organizationApi;
