import React from "react";
import { Listbox } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { YarnProfileVariant } from "../store/pattern";
import { RootState } from "../store/store";
import { setActiveVariant } from "./displayState";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import styles from "./YarnVariantSelector.module.css";

interface PropType {
  variants: YarnProfileVariant[];
  activeVariantId: string;
  onVariantChange: (variantId: string) => void;
}

const YarnVariantSelector = (props: PropType) => {
  const { variants, activeVariantId, onVariantChange } = props;

  const activeVariant = variants.find(
    (variant) => variant.id === activeVariantId
  );

  return (
    <div style={{ margin: 2, position: "relative" }}>
      <Listbox
        value={activeVariantId}
        onChange={(selected) => onVariantChange(selected)}
      >
        {({ open }) => (
          <>
            <Listbox.Options
              style={{ marginTop: `-${variants.length * 34}px` }}
              className={styles.listBoxOptions}
            >
              {variants.map((variant, i) => (
                <Listbox.Option key={i} value={variant.id}>
                  {({ active }) => (
                    <VariantRow active={active} variant={variant} />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
            <Listbox.Button className={styles.listBoxButton}>
              <VariantRow variant={activeVariant} />
              {open ? <TriangleDownIcon /> : <TriangleUpIcon />}
            </Listbox.Button>
          </>
        )}
      </Listbox>
    </div>
  );
};

const VariantRow: React.FC<{ variant?: YarnProfileVariant; active?: boolean }> =
  ({ variant, active }) => {
    if (!variant) return null;
    return (
      <div className={`${styles.listBoxOption} ${active ? styles.active : ""}`}>
        <div style={{ margin: "2px 4px 0 0", display: "flex" }}>
          {variant.colors.map((color, i) => (
            <div
              key={i}
              style={{ background: color.hex ?? "" }}
              className={styles.color}
            />
          ))}
        </div>
        {variant.name}
      </div>
    );
  };

const AwareYarnVariantSelector = () => {
  const variants = useSelector(
    (state: RootState) => state.pattern.meta.variants
  );

  const dispatch = useDispatch();

  const activeVariantId = useSelector(
    (state: RootState) => state.displayState.activeVariant
  );

  const handleVariantChange = (variantId: string) => {
    dispatch(setActiveVariant(variantId));
  };

  if (variants.length === 0) return null;

  return (
    <YarnVariantSelector
      variants={variants}
      activeVariantId={activeVariantId}
      onVariantChange={handleVariantChange}
    />
  );
};

export default YarnVariantSelector;
export { AwareYarnVariantSelector };
