import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  NewPrice,
  useNewPriceMutation,
  usePriceListQuery,
} from "../services/modelknit";

type Props = {
  orgId: string;
  onSubmit?: () => void;
};

const NewPriceModal: React.FC<Props> = ({ orgId, onSubmit }) => {
  const priceListQuery = usePriceListQuery(orgId);
  const [addPrice] = useNewPriceMutation();
  const { t } = useTranslation();

  const newPrice = useRef<NewPrice>({ name: "", priceOre: 0 });

  const handleSubmit = () => {
    if (
      priceListQuery.data?.find((price) => price.name === newPrice.current.name)
    ) {
      alert(t("price error.name"));
      return;
    }
    if (newPrice.current.name.length === 0 || newPrice.current.priceOre <= 0) {
      alert(t("price error.amount"));
      return;
    }
    addPrice({ orgId, ...newPrice.current });
    onSubmit && onSubmit();
  };

  return (
    <div>
      <h1>{t("add new price")}</h1>
      <div>
        <input
          type="text"
          placeholder={t("name")}
          onChange={(e) => (newPrice.current.name = e.target.value)}
        />
        <input
          type="number"
          placeholder={t("price ore")}
          onChange={(e) =>
            (newPrice.current.priceOre = parseInt(e.target.value))
          }
        />
      </div>
      <button onClick={handleSubmit}>{t("add new price")}</button>
    </div>
  );
};

export default NewPriceModal;
