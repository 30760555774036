import { Transition } from "@headlessui/react";
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";

import React, { useMemo } from "react";

type AlertSeverity = "error" | "warning" | "info" | "success";

export type AlertValues = {
  severity?: AlertSeverity;
  message?: string;
};

type Props = {
  severity?: AlertSeverity;
  className?: string;
  children?: React.ReactNode;
};

const Alert: React.FC<React.PropsWithChildren<Props>> = ({
  severity = "info",
  className,
  children,
}) => {
  //EDIT severity !== "success" && logEvent("User alerted", { severity });

  const icon = useMemo(() => {
    switch (severity) {
      case "info":
        return <InfoCircledIcon />;
      case "success":
        return <CheckCircledIcon />;
      case "warning":
        return <ExclamationTriangleIcon />;
      case "error":
        return <ExclamationTriangleIcon />;
    }
  }, [severity]);

  return (
    <Transition
      show
      appear
      as="div"
      enter="ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
     
      style={{display: "flex",
      padding: "0.5rem",
      alignItems: "center",
      width: "100%",
      borderRadius: "0.25rem",
      columnGap: "0.5rem",}}
    >
      {icon}
      <div style={{width: "100%", alignItems: "center",}}
        
      >
        {children}
      </div>
    </Transition>
  );
};

export default Alert;
