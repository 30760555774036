import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface WindowSize {
  windowWidth: number;
  windowHeight: number;
}

// Hook
export function useGridVariables() {
  const [{ windowHeight, windowWidth }, setWindowSize] = useState<WindowSize>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  const { cellHeight, cellWidth, activeDiagram, zoomCount } = useSelector(
    (state: RootState) => state.grid
  );

  const { gridHeight, gridWidth } = useSelector(
    (state: RootState) => state.grid.grid
  );

  const [{ initialX, initialY }, setInitial] = useState({
    initialY: Math.max(
      (Math.ceil(windowHeight / (cellHeight * 2)) - Math.ceil(gridHeight / 2)) *
        cellHeight,
      192
    ),
    initialX:
      (Math.ceil(windowWidth / (cellWidth * 2)) -
        Math.floor(gridWidth / 2) -
        1) *
      cellWidth,
  });

  const [{ offsetX, offsetY }, setOffset] = useState({
    offsetX: 2 * cellWidth,
    offsetY: 1.6 * cellWidth,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [activeDiagram]);

  useEffect(() => {
    const x =
      (Math.ceil(windowWidth / (cellWidth * 2)) -
        Math.floor(gridWidth / 2) -
        1) *
      cellWidth;
    const y = Math.max(
      (Math.ceil(windowHeight / (cellHeight * 2)) - Math.ceil(gridHeight / 2)) *
        cellHeight,
      192
    );
    setInitial({ initialX: x, initialY: y });
  }, [
    cellHeight,
    cellWidth,
    windowWidth,
    windowHeight,
    activeDiagram,
    gridWidth,
    gridHeight,
  ]);

  useEffect(() => {
    let extraOffset = 0;
    if (zoomCount === 2) extraOffset = -2;
    else if (zoomCount === 4) extraOffset = -16;
    else if (zoomCount === 6) extraOffset = -12;

    setOffset({
      offsetX: 2 * cellWidth,
      offsetY: 2 * cellHeight + extraOffset,
    });
  }, [cellHeight, cellWidth, activeDiagram, zoomCount]);

  return {
    windowHeight,
    windowWidth,
    initialX,
    initialY,
    offsetX,
    offsetY,
    cellHeight,
    cellWidth,
    gridHeight,
    gridWidth,
  };
}
