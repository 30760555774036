import React from "react";
import { useDispatch } from "react-redux";

import { setMeasureChangeKind } from "../store/calculationSlice";

import { EdgeTableMeasure } from "./calculations";

import ChangeFirstRow from "../CommonComponents/Icons/ChangeFirstRow";
import ChangeLastRow from "../CommonComponents/Icons/ChangeLastRow";
import ChangeEvenly from "../CommonComponents/Icons/ChangeEvenly";

import styles from "./ChangeKindSelector.module.css";

type PropType = {
  tableId: string;
  measure: EdgeTableMeasure;
};

function ChangeKindSelector(props: PropType) {
  const { tableId, measure } = props;

  const dispatch = useDispatch();

  return (
    <div className={styles.buttonContainer}>
      <button
        className={
          measure.changeKind === "HorizontalFirst" ? styles.active : undefined
        }
        onClick={() =>
          dispatch(
            setMeasureChangeKind({
              tableId,
              measureId: measure.id,
              changeKind: "HorizontalFirst",
            })
          )
        }
        title="Første omgang"
      >
        <ChangeFirstRow />
        {measure.changeKind === "HorizontalFirst" && "Første omgang"}
      </button>
      <button
        className={
          measure.changeKind === "Vertical" ? styles.active : undefined
        }
        onClick={() =>
          dispatch(
            setMeasureChangeKind({
              tableId,
              measureId: measure.id,
              changeKind: "Vertical",
            })
          )
        }
        title="Jevnt fordelt"
      >
        <ChangeEvenly />
        {measure.changeKind === "Vertical" && "Jevnt fordelt"}
      </button>
      <button
        className={
          measure.changeKind === "HorizontalLast" ? styles.active : undefined
        }
        onClick={() =>
          dispatch(
            setMeasureChangeKind({
              tableId,
              measureId: measure.id,
              changeKind: "HorizontalLast",
            })
          )
        }
        title="Siste omgang"
      >
        <ChangeLastRow />
        {measure.changeKind === "HorizontalLast" && "Siste omgang"}
      </button>
      <button
        className={
          measure.changeKind === "SleeveBindOff" ? styles.active : undefined
        }
        onClick={() =>
          dispatch(
            setMeasureChangeKind({
              tableId,
              measureId: measure.id,
              changeKind: "SleeveBindOff",
            })
          )
        }
        title="Ermehullsfelling"
      >
        <ChangeEvenly />
        {measure.changeKind === "SleeveBindOff" && "Ermehullsfelling"}
      </button>
    </div>
    /* TODO New SVG icon for this  */
  );
}

export default ChangeKindSelector;
