import React, { useEffect, useState } from "react";
import { usePatternPublishedQuery } from "../services/patternApi";
import { formatDate } from "../utils/dateFormat";
import styles from "./PublishDate.module.css";
interface PropType {
  patternId: string;
}

export const PublishDate = (props: PropType) => {
  const { data, isLoading } = usePatternPublishedQuery(`${props.patternId}`, {
    skip: parseInt(props.patternId) === -1,
  });

  const [first, setFirst] = useState<string | null>(null);
  const [latest, setLatest] = useState<string | null>(null);

  useEffect(() => {
    const firstDate = data?.published[0]?.created;
    const latestDate = data?.published[data.published.length - 1]?.created;

    if (firstDate) {
      setFirst(firstDate);
    }
    if (latestDate) {
      setLatest(latestDate);
    }
  }, [isLoading]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        {first === null
          ? "Oppskrift ikke publisert"
          : `Publisert ${formatDate(first)}`}
        {latest === null ? "" : ` og sist oppdatert ${formatDate(latest)}`}
      </p>
    </div>
  );
};
