import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";
import { setDisable } from "../store/dragSelectSlice";
import {
  setActiveDiagram,
  setDisableHotkeys,
  updateGrid,
  updateMetaData,
} from "../store/gridSlice";
import { DiagramData, emptyDiagramData } from "../store/pattern";
import {
  addEmptyDiagram,
  deleteDiagramById,
  setAllDiagrams,
  updateDiagramById,
} from "../store/patternSlice";
import { RootState } from "../store/store";
import { notInPattern } from "../utils/canDelete";
import styles from "./diagram.module.css";
import { NewDiagram } from "./NewDiagram";
import { createGridArray } from "../store/initialDiagramState";
import PatternCanvas from "../Visualizing3D/knittingeditor/PatternCanvas";
import { Util } from "../Visualizing3D/static/util";
import { ClickMenu } from "./ContextMenu/Click";
import { ContextMenu } from "./ContextMenu/RenderMenu";
import { shortUUID } from "../utils/uuid";

export const DiagramDelete = () => {
  const { diagrams, id } = useSelector((state: RootState) => state.pattern);
  const { activeDiagram } = useSelector((state: RootState) => state.grid);
  const dispatch = useDispatch();
  const deleteDiagram = (diagramKey: string, patternId: number) => {
    dispatch(
      deleteDiagramById({
        diagramId: diagramKey,
        patternId,
      })
    );
    const updatedDiagrams: any = { ...diagrams };
    delete updatedDiagrams[diagramKey];
    dispatch(setAllDiagrams(updatedDiagrams));
    const first = diagrams[Object.keys(updatedDiagrams)[0]];
    dispatch(setActiveDiagram({ id: first.id }));
  };

  return (
    <button
      title={"Delete"}
      className={styles.stateButton}
      disabled={Object.keys(diagrams).length === 0}
      onClick={() => {
        const prompt = window.confirm("Delete pattern?");
        if (prompt) {
          deleteDiagram(activeDiagram, id);
        }
      }}
      style={{ pointerEvents: "auto" }}
    >
      <img
        src="/3D/ui/delete2.png"
        style={{
          width: "16px",
          height: "16px",
        }}
        alt="reset"
      />
    </button>
  );
};

export default DiagramDelete;
