import React from "react";
import { Color, YarnColor } from "../store/pattern";
import ImageAsColorPreview from "./ImageAsColorPreview";

import styles from "./ColorPreview.module.css";
import { SymbolSelector } from "../Diagram/SymbolSelector";
import { useDarkColor } from "../utils/colorContrast";
import { Symbols } from "@iterate/woolit-components";

interface PropType {
  color: Color;
  onClickHandler?: any;
  inline?: boolean;
  fillParent?: boolean;
  symbol?: Symbols;
}

function ColorPreview(props: PropType) {
  const { color, onClickHandler, inline, fillParent, symbol } = props;
  const symbolColor = useDarkColor(color?.hex ?? "#ffffff") ? "black" : "white";

  if (color?.hex) {
    return (
      <div
        className={`${inline ? styles.inlineContainer : styles.container} ${
          fillParent && styles.fillParent
        }`}
        style={{ backgroundColor: color.hex as string }}
        onClick={onClickHandler}
      >
        {symbol && symbol !== "stitch" && (
          <SymbolSelector fill={symbolColor} symbol={symbol} />
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${inline ? styles.inlineContainer : styles.container} ${
          fillParent && styles.fillParent
        }`}
        onClick={onClickHandler}
      >
        <ImageAsColorPreview color={color as YarnColor} />
      </div>
    );
  }
}

export default ColorPreview;
