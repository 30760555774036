import React, { CSSProperties } from "react";
import { useGridVariables } from "../Diagram/utils/hooks";
import styles from "./DiagramCommentBorder.module.css";
import { Fill, Info } from "./Icons/Info";

interface PropType {
  start: number;
  end: number;
  orientation: "bottom" | "right";
  position: "start" | "end";
  showIcon: boolean;
}

export const DiagramCommentBorder = (props: PropType) => {
  const { start, end, orientation, position, showIcon } = props;
  const { cellHeight, cellWidth } = useGridVariables();

  const diff = Math.abs(start - end) + 1;

  let style: CSSProperties = {};
  let subStyle: CSSProperties = {};
  if (orientation === "bottom") {
    style = {
      width: cellWidth * diff,
      height: cellHeight * 1.3,
    };
    subStyle = {
      marginTop: cellHeight * 0.8,
      justifyContent: position === "start" ? "flex-start" : "flex-end",
    };
  } else {
    // Right
    style = {
      height: cellHeight * diff,
      width: cellWidth * 1.4,
    };
    subStyle = {
      marginLeft: cellWidth * 1.1,
      position: "absolute",
      height: "100%",
      alignItems: position === "start" ? "flex-start" : "flex-end",
    };
  }

  return (
    <div style={style} className={styles[orientation]}>
      {showIcon && (
        <div style={{ display: "flex", ...subStyle }}>
          <Info fill={Fill.CONTRAST} />
        </div>
      )}
    </div>
  );
};
