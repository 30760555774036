// @ts-ignore
import { hexToRgb } from "./colorutil.ts";
import { SweaterPart } from "../SweaterPart";
import { Settings } from "../static/settings";
import { Global } from "../static/global";

let maskWidth = Settings.maskWidth;
let maskHeight = Settings.maskHeight;

let prerender: { [x: string]: HTMLCanvasElement } = {};

let image_base: HTMLImageElement;
let image_mask: HTMLImageElement;

let color_to_image: { [x: string]: ImageData } = {};

export function loadImages() {
  let waitForLoad: HTMLImageElement[] = [];

  image_base = new Image(maskWidth, maskHeight);
  image_base.src = "/3D/shirt/patterns2.png";
  waitForLoad.push(image_base);

  image_mask = new Image(maskWidth, maskHeight);
  image_mask.src = "/3D/shirt/patterns2_regions.png";
  waitForLoad.push(image_mask);

  return waitForLoad;
}

export function createCanvas() {
  let canvas = document.createElement("canvas");

  canvas.width = Global.canvasWidth;
  canvas.height = Global.canvasHeight;

  return canvas;
}

export function darkenCanvas(
  canvas: HTMLCanvasElement,
  canvas_backup: HTMLCanvasElement
) {
  let ctx = canvas.getContext("2d")!!;
  ctx.clearRect(0, 0, Global.canvasWidth, Global.canvasHeight);
  ctx.drawImage(canvas_backup, 0, 0);
}

export function lightenCanvas(
  canvas: HTMLCanvasElement,
  selectedSweaterPart: SweaterPart,
  canvas_backup: HTMLCanvasElement
) {
  let ctx_backup = canvas_backup.getContext("2d")!!;
  ctx_backup.drawImage(canvas, 0, 0);

  let ctx = canvas.getContext("2d")!!;
  //ctx.fillStyle = "rgba(0, 50, 150, 0.5)"
  ctx.fillStyle = "rgba(255, 255, 255, 0.25)";
  const minX = selectedSweaterPart.corner1X * Global.canvasWidth;
  const minY = selectedSweaterPart.corner1Y * Global.canvasHeight;
  const maxX = selectedSweaterPart.corner2X * Global.canvasWidth;
  const maxY = selectedSweaterPart.corner2Y * Global.canvasHeight;
  ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
}

export function drawCanvas(
  canvas: any,
  sweaterParts_arg: SweaterPart[],
  colors: any[],
  repeatY: boolean,
  selectedSweaterPart: SweaterPart | undefined
) {
  for (const color of colors) {
    if (!(color in prerender)) {
      prerender[color] = prerenderCanvas(maskWidth, maskHeight, color);
    }
  }

  let ctx = canvas.getContext("2d");

  for (let sweaterPart of sweaterParts_arg) {
    let width = (sweaterPart.corner2X - sweaterPart.corner1X) * canvas.width;
    let height = (sweaterPart.corner2Y - sweaterPart.corner1Y) * canvas.height;

    let mask_n_x = Math.ceil(width / maskWidth);
    let mask_n_y = Math.floor(height / maskHeight); //NB: Fixes "pattern i collar, så bucket remove = bug, litt svart rundt", but not well tested

    for (let x = 0; x < mask_n_x; x++) {
      for (let y = 0; y < mask_n_y; y++) {
        if (sweaterPart.dirtyPositionsGrid[y][x]) {
          let color = colors[sweaterPart.grid[y][x]];
          let x_draw = x;
          let y_draw = y;
          if (sweaterPart.inverted) {
            x_draw = mask_n_x - 1 - x;
            x_draw = (x_draw + Math.round(mask_n_x / 2)) % mask_n_x; //offset x by 50%
            y_draw = mask_n_y - 1 - y;
          }
          ctx.drawImage(
            prerender[color],
            x_draw * maskWidth + sweaterPart.corner1X * canvas.width,
            y_draw * maskHeight + sweaterPart.corner1Y * canvas.height
          );
          sweaterPart.dirtyPositionsGrid[y][x] = false;
        }
      }
    }
  }
}

export function prerenderCanvas(
  maskWidth: number,
  maskHeight: number,
  color: string
) {
  let canvas = document.createElement("canvas");

  canvas.width = maskWidth;
  canvas.height = maskHeight * 2;

  let ctx = canvas.getContext("2d")!!;

  if (!(color in color_to_image)) {
    throw new Error("Color: " + color + " is not prerendered");
  }

  ctx.putImageData(color_to_image!![color], 0, 0);

  return canvas;
}

function color_image_mask(
  canvas: HTMLCanvasElement,
  imageDataMask: any,
  color: string
) {
  let ctx = canvas.getContext("2d")!!;
  let w = maskWidth;
  let h = maskHeight;
  let colored_image = image_base!!;
  ctx.drawImage(colored_image, 0, 0, w, h);
  ctx.drawImage(colored_image, 0, h, w, h);
  let imageData = ctx.getImageData(0, 0, w, h * 2);
  let rgb = hexToRgb(color)!!;
  const darknessRed = 1 - rgb.r / 255;
  const darknessGreen = 1 - rgb.g / 255;
  const darknessBlue = 1 - rgb.b / 255;
  const darkness = darknessRed * darknessGreen * darknessBlue;
  const lightenAmount = 0.1 * darkness; // Necessary, or else fully black colors wont have visible masks
  rgb.r = rgb.r * (1 - lightenAmount) + lightenAmount * 255;
  rgb.g = rgb.g * (1 - lightenAmount) + lightenAmount * 255;
  rgb.b = rgb.b * (1 - lightenAmount) + lightenAmount * 255;
  for (let i = 0; i < imageData.data.length; i += 4) {
    let me;
    let other;
    if (i > imageData.data.length / 2) {
      me = imageDataMask.data[i] / 255.0;
      other = imageDataMask.data[i + 2] / 255.0;
    } else {
      me = imageDataMask.data[i + 2] / 255.0;
      other = imageDataMask.data[i] / 255.0;
    }
    let background = imageDataMask.data[i + 1] / 255.0;
    let max = Math.max(me, background, other);
    if (max === background) {
      max *= 0.75;
    } else if (max === other) {
      imageData.data[i + 3] = 0;
    }
    imageData.data[i] *= (max * rgb.r) / 255.0;
    imageData.data[i + 1] *= (max * rgb.g) / 255.0;
    imageData.data[i + 2] *= (max * rgb.b) / 255.0;
  }
  color_to_image[color] = imageData;
}

export function renderAfterLoad(canvas: any, colors: any) {
  let ctx = canvas.getContext("2d")!!;
  let w = maskWidth;
  let h = maskHeight;
  ctx.drawImage(image_mask!!, 0, 0, w, h);
  ctx.drawImage(image_mask!!, 0, h, w, h);
  let region_image_mask = ctx.getImageData(0, 0, w, h * 2);
  for (let color of colors) {
    color_image_mask(canvas, region_image_mask, color);
  }
}
