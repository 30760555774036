import React from "react";
import styles from "./MetaSection.module.css";
import isoStyles from "../ISO.module.css";
import { Pattern } from "../store/pattern";

import YarnVariantChooser from "./YarnVariantChooser";
import { AwareSizeSelector } from "./SizeSelector";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import { PublishDate } from "./PublishDate";

interface PropType {
  data: Pattern["meta"];
}

function MetaSection(props: PropType) {
  const {
    sizes,
    keyMeasures,
    title,
    description,
    design,
    designer,
    variants,
    roles,
    sizeCategory,
    clothingMetaData,
    collection,
    gaugeNeedle,
  } = props.data;

  const { needles, id } = useSelector((state: RootState) => state.pattern);

  const { horizontal, vertical } = useSelector(
    (state: RootState) => state.calculations.gauge
  );

  let keyMeasureCells = [];
  for (let { name, measure } of keyMeasures) {
    keyMeasureCells.push(
      <p key={name}>
        <i>{name}</i>: {Object.values(measure).join(", ")} cm
      </p>
    );
  }

  return (
    <div className={styles.wrapper}>
      <p>
        <span className={styles.positive}>{designer}</span> - {design}
      </p>
      <h1 style={{ lineHeight: "1.17em" }}>{title}</h1>
      {description &&
        description
          .split(/\n/)
          .map((text, i) => (text === "" ? <br /> : <p key={i}>{text}</p>))}
      <PublishDate patternId={`${id}`} />
      <div className={styles.sizeSelector}>
        <p>Velg størrelse:</p>
        <AwareSizeSelector />
      </div>
      <h3>Velg fargevariant</h3>
      <hr className={styles.smallHr} />
      <div className={styles.variantContainer}>
        <YarnVariantChooser variants={variants} />
      </div>
      <hr className={isoStyles.hr} />
      <h2>Informasjon</h2>
      <div className={styles.metaContainer}>
        {roles.map(({ title, value }, i) => (
          <div key={i} className={styles.meta}>
            <p className={styles.label}>{title}</p>
            <p className={styles.value}>{value}</p>
          </div>
        ))}
        {collection && (
          <div className={styles.meta}>
            <p className={styles.label}>Kolleksjon</p>
            <p className={styles.value}>{collection}</p>
          </div>
        )}
        {design && (
          <div className={styles.meta}>
            <p className={styles.label}>Design</p>
            <p className={styles.value}>{design}</p>
          </div>
        )}
        {clothingMetaData.map(({ title, value }, i) => (
          <div key={i} className={styles.meta}>
            <p className={styles.label}>{title}</p>
            <p className={styles.value}>{value}</p>
          </div>
        ))}
        <div className={styles.meta}>
          <p className={styles.label}>Strikkefasthet</p>
          <p className={styles.value}>
            <b>
              {horizontal.stitches} masker = {horizontal.length}cm
            </b>{" "}
            i bredden
          </p>
          {vertical.stitches !== 0 && vertical.length !== 0 && (
            <p className={styles.value}>
              <b>
                {vertical.stitches} omganger = {vertical.length}cm
              </b>{" "}
              i høyden
            </p>
          )}
          {gaugeNeedle && needles[gaugeNeedle] && (
            <p className={styles.value}>
              på pinne nr. {needles[gaugeNeedle].diameter}
            </p>
          )}
        </div>

        <div className={styles.meta}>
          <b className={styles.label}>Størrelser: {sizeCategory}</b>
          <p className={styles.value}>
            {sizes
              .map((size, i) => (i % 2 === 0 ? size : `(${size})`))
              .join(" ")}
          </p>
        </div>
      </div>
      <hr className={isoStyles.hr} />
      <div>
        <h3>Veiledende pinnevalg</h3>
        {Object.values(needles).map(({ type, diameter, size }, i) => (
          <p key={i} className={styles.value} style={{ marginBottom: 12 }}>
            <span className={styles.capitalize}>{type}</span>{" "}
            {size ? `${size} cm` : ""} nr. {diameter}
          </p>
        ))}
      </div>
    </div>
  );
}

export default MetaSection;
