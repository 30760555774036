import React from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import styles from "./Media.module.css";
import Upload from "../../CommonComponents/Icons/Upload";
import { uploadFiles } from "../../store/patternSlice";

interface PropType {
  patternId: number;
}

export const ImageUpload = (props: PropType) => {
  const { patternId } = props;
  const dispatch = useDispatch();
  return (
    <Dropzone
      onDrop={(files) => {
        dispatch(uploadFiles({ patternId, files }));
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={styles.uploadContainer}>
            <h2 className={styles.h2}>Last opp fra datamaskin:</h2>
            <button className={styles.uploadButton}>
              <Upload />
            </button>
          </div>
        </div>
      )}
    </Dropzone>
  );
};
