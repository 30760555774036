export const monthNames = [
  "januar",
  "februar",
  "mars",
  "april",
  "mai",
  "juni",
  "juli",
  "august",
  "september",
  "oktober",
  "november",
  "desember",
];

export const getAllMonths = () =>{
  return monthNames.map ( (month) => {
    return month[0].toLocaleUpperCase()+month.substring(1);
  })
}

export const formatDate = (dateString: Date | string) => {
  const currentDate = new Date();
  const date = new Date(dateString);

  if (datesAreOnSameDay(currentDate, date)) {
    return `i dag ${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`;
  } else {
    return `${date.getDate()}. ${monthNames[date.getMonth()]}`;
  }
};

const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const options: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

export const formatLocaleDate = (datum: Date | string) => {
  const date = new Date(datum);
  return date.toLocaleDateString("NO-nb", options);
};
