import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from "@radix-ui/react-icons";
import React, { useState } from "react";
import Button from "../../ISO/Components/Button";
import ModelKnitStateTags from "../../Organization/ModelKnitStateTags";
import { ModelKnitAssignment, YarnProfileVariant } from "../../store/pattern";
import { formatLocaleDate } from "../../utils/dateFormat";
import styles from "./modelknit.module.css";

interface PropType {
  data: ModelKnitAssignment[] | undefined;
  variants: YarnProfileVariant[];
}

const toTimestamp = (timestring: string | undefined) => {
  if (!timestring) {
    return Math.floor(new Date().getTime() / 1000);
  } else {
    return Math.floor(new Date(timestring).getTime() / 1000);
  }
};

export const ModelAssignmentList: React.FC<PropType> = ({ data, variants }) => {
  const [seeAll, toggleSeeAll] = useState<boolean>(true);

  if (!data) {
    return null;
  }

  const today = toTimestamp(new Date().toString());
  const sorted = [...data].sort(
    (a, b) => toTimestamp(a.created) - toTimestamp(b.created)
  );

  const pastDeadline = sorted.filter(
    (assignment) => toTimestamp(assignment.deadline) < today
  );

  const ongoing = sorted.filter(
    (assignment) =>
      assignment.deadline === null || toTimestamp(assignment.deadline) > today
  );

  return (
    <div>
      <p>Pågående</p>
      {ongoing.length > 0 ? (
        <GridWrapper>
          {sorted.map((assignment, i) => (
            <ModelAssignmentItem
              variants={variants}
              model={assignment}
              key={i}
            />
          ))}
        </GridWrapper>
      ) : (
        <h3>Ingen pågående oppdrag</h3>
      )}
      {pastDeadline.length > 0 && (
        <>
          <button onClick={() => toggleSeeAll(!seeAll)}>
            Se {seeAll ? "færre" : "flere"}
          </button>
          {seeAll && (
            <GridWrapper>
              {pastDeadline.map((assignment, i) => (
                <ModelAssignmentItem
                  variants={variants}
                  model={assignment}
                  key={i}
                />
              ))}
            </GridWrapper>
          )}
        </>
      )}
      <hr className={styles.hr} />
    </div>
  );
};

const ModelAssignmentItem: React.FC<{
  model: ModelKnitAssignment;
  variants: YarnProfileVariant[];
}> = ({ model, variants }) => {
  const variant = variants.find((v) => v.id === model.variantId);

  return (
    <>
      <div className={styles.created}>
        {model.created && (
          <>
            <ChevronRightIcon />
            {formatLocaleDate(new Date(model.created))}
          </>
        )}
      </div>
      <div className={styles.knitter}>
        {model.knitterId
          ? `${model.knitterName} (${model.knitterId})`
          : "Ingen strikker"}
      </div>
      <div className={styles.colorGroup}>
        {variant?.colors.map((color, i) => (
          <div
            key={i}
            style={{ background: color.hex ?? "", height: "9px", width: "9px" }}
            className={styles.color}
          />
        ))}
      </div>
      <div className={styles.size}>{model.size}</div>
      <div className={styles.tags}>
        <ModelKnitStateTags state={model.state} />
      </div>
      <div className={styles.deadlineItem}>
        {model.deadline && (
          <>
            {formatLocaleDate(new Date(model.deadline))}
            <ChevronLeftIcon />
          </>
        )}
      </div>
      <div className={styles.assignmentOption}>
        <Button>
          <DotsHorizontalIcon />
        </Button>
      </div>
    </>
  );
};

const GridWrapper: React.FC = ({ children }) => {
  return <div className={styles.assignmentsGrid}>{children}</div>;
};
