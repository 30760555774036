import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { backendApiAddress } from "../../backendApi";

interface LoginState {
  isLoggedIn: boolean;
  user?: User;
  loginFailed: boolean;
  loginAttempted: boolean;
  checkedUserLogin: boolean;
}

type WdsModule = "model_knit" | "knit_along";

interface User {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  isAdmin: boolean;
  subscriber: boolean;
  modules: WdsModule[];
  usedTrial: boolean;
  subsription: Subscription;
 

}

interface Subscription {
  
  substriptionType: string;
 
  isTrial: boolean;
 
  modules: WdsModule[];
 
 

}

const initialState: LoginState = {
  isLoggedIn: false,
  user: undefined,
  loginFailed: false,
  loginAttempted: false,
  checkedUserLogin: false,
};

const login = createAsyncThunk(
  "login",
  async (data: { email: string; password: string }, _thunkAPI) => {
    const response = await fetch(
      `${backendApiAddress}/api/auth/login_with_password`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ email: data.email, password: data.password }),
      }
    );

    return response.status;
  }
);

const logout = createAsyncThunk("logout", async (_thunkAPI) => {
  const response = await fetch(`${backendApiAddress}/api/logout`, {
    method: "POST",
    credentials: "include",
  });

  return response.ok;
});

const fetchMe = createAsyncThunk("me", async (_thunkAPI) => {
  const response = await fetch(`${backendApiAddress}/api/pattern/me`, {
    credentials: "include",
  });
  return response.json();
});

const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchMe.fulfilled.type](state, action) {
      const loginState = action.payload.error
        ? {
            isLoggedIn: false,
            checkedUserLogin: true,
          }
        : {
            isLoggedIn: true,
            loginFailed: false,
            user: action.payload,
            checkedUserLogin: true,
          };
      return {
        ...state,
        ...loginState,
      };
    },
    [login.pending.type](state, _action) {
      return { ...state, loginAttempted: true };
    },
    [login.fulfilled.type](state, action) {
      let status = action.payload;
      switch (status) {
        case 200:
          return {
            ...state,
            isLoggedIn: true,
            loginFailed: false,
          };
        default:
          return {
            ...state,
            isLoggedIn: false,
            loginFailed: true,
          };
      }
    },
    [logout.fulfilled.type](_state, action) {
      let logout_successful = action.payload;
      if (logout_successful) {
        return initialState;
      } else {
        // Could not logout, maybe already logged out? Pretend like it worked.
        return initialState;
      }
    },
  },
});

export default user;

export { fetchMe, login, logout };

export type { User, WdsModule };
