import React, { useEffect, useState } from "react";
import { Modal } from "../CommonComponents/Modal";
import { AnnotationSelectCol } from "./AnnotationSelectCol";
import { AnnotationSelectRow } from "./AnnotationSelectRow";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./diagramComment.module.css";
import Button from "../ISO/Components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  removeDiagramComment,
  removeDiagramPattern,
  setPatternNote,
} from "../store/gridSlice";
import { RootState } from "../store/store";
import { useGridVariables } from "./utils/hooks";

interface PropType {
  isShown: boolean;
  toggle: () => void;
  index: number;
  direction: "row" | "col";
}

export const AddDiagramCommentModal = (props: PropType) => {
  const { isShown, toggle, direction, index } = props;

  const { oldPattern, oldComment } = useSelector((state: RootState) => ({
    oldPattern:
      state.grid.grid.patterns?.[
        direction === "row" ? "rowPatterns" : "colPatterns"
      ][index],
    oldComment:
      state.grid.grid.diagramComments?.[
        direction === "row" ? "rowComments" : "colComments"
      ][index],
  }));

  useEffect(() => {
    if (oldPattern?.comment) {
      setComment(oldPattern.comment);
    } else if (oldComment) {
      setComment(oldComment.comment);
    }
  }, [oldPattern, oldComment]);

  // Clear text-field on close
  useEffect(() => {
    if (!isShown) {
      setComment("");
    }
  }, [isShown]);

  const { sizes } = useSelector((state: RootState) => state.pattern.meta);
  const [commentSizes, setCommentSizes] = useState<string[]>(sizes);
  const [comment, setComment] = useState<string>("");
  const [text, setText] = useState<string>(`${index}`);
  const [to, setTo] = useState<number | null>(null);
  const [relation, setRelation] = useState<"upper" | "lower" | "middle">(
    "middle"
  );

  const { gridHeight, gridWidth } = useGridVariables();

  const calculateInterval = () => {
    const a = index;
    const b =
      to === 0 || to === null
        ? 0
        : direction === "row"
        ? gridHeight - (to ?? 0)
        : gridWidth - (to ?? 0);

    if (to === null) {
      return { from: index, to: null };
    } else {
      return { from: Math.min(a, b), to: Math.max(a, b) };
    }
  };

  const dispatch = useDispatch();

  return (
    <Modal
      modalContent={
        <div className={styles.modalContent}>
          <TextareaAutosize
            className={styles.commentInput}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            placeholder={"Merknad..."}
            minRows={4}
          />
          <div className={styles.intervalSection}>
            <label className={styles.label}>Plassering</label>
            {direction === "row" ? (
              <AnnotationSelectRow
                text={text}
                setText={setText}
                index={gridHeight - index}
                setTo={setTo}
                relation={relation}
                setRelation={setRelation}
                rows={gridHeight}
              />
            ) : (
              <AnnotationSelectCol
                text={text}
                setText={setText}
                index={gridWidth - index}
                setTo={setTo}
                relation={relation}
                setRelation={setRelation}
                cols={gridWidth}
              />
            )}
          </div>
          <div className={styles.sizes}>
            <label className={styles.label}>Merknad gjelder for: </label>
            <div className={styles.sizeButtons}>
              {sizes.map((size, i) => (
                <button
                  key={i}
                  className={`${
                    commentSizes.includes(size) && styles.activeSize
                  }`}
                  onClick={() => {
                    if (commentSizes.includes(size)) {
                      const tmp = [...commentSizes];
                      tmp.splice(commentSizes.indexOf(size), 1);
                      setCommentSizes(tmp);
                    } else {
                      const tmp = [...commentSizes];
                      tmp.push(size);
                      setCommentSizes(tmp);
                    }
                  }}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div style={{ marginTop: 12 }}>
              <Button onClick={() => toggle()}>Avbryt</Button>
              <Button
                onClick={() => {
                  dispatch(removeDiagramPattern({ direction, index }));
                  dispatch(removeDiagramComment({ direction, index }));
                  toggle();
                }}
              >
                Slett
              </Button>
            </div>
            <Button
              onClick={() => {
                const { from, to } = calculateInterval();
                dispatch(
                  setPatternNote({
                    from,
                    to,
                    direction,
                    comment,
                    sizes,
                    relation,
                  })
                );
                toggle();
              }}
            >
              Lagre
            </Button>
          </div>
        </div>
      }
      headerText={"Merknad"}
      isShown={isShown}
      hide={toggle}
    />
  );
};
