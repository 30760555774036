import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

import { Needle } from "../../store/pattern";
import { RootState } from "../../store/store";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";

export type NeedleElementType = {
  type: "needle";
  children: Text[];
  ref: string;
  commentId?: string;
};

const NeedleFromRef = (ref: string) => {
  const needles = useSelector((state: RootState) => state.pattern.needles);

  return needles[ref];
};

const needleString = (needle: Needle) => {
  const needleSize = needle.type === "rundpinne" ? `${needle.size} cm ` : "";
  return `${needle.type} ${needleSize}nr. ${needle.diameter}`;
};

export const NeedleSelect = ({
  closeSelector,
  editor,
  action,
  element,
}: ElementSelectorProps) => {
  const { needles, needleOrder } = useSelector((state: RootState) => ({
    needles: state.pattern.needles,
    needleOrder: state.pattern.meta.needleOrder,
  }));

  const needleList =
    needleOrder.length > 0 ? needleOrder : Object.keys(needles);

  const [arrowIndex, setArrowIndex] = useState(0);

  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
  });

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    setArrowIndex(
      arrowIndex + 1 === needleList.length
        ? needleList.length - 1
        : arrowIndex + 1
    );
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    insertNeedle(needleList[arrowIndex]);
  });

  const insertNeedle = (ref: string) => {
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "needle",
          children: [{ text: "" }],
          ref,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    } else {
      const path = element ? ReactEditor.findPath(editor, element) : undefined;
      Transforms.setNodes(editor, { ref }, { at: path });
    }

    closeSelector();
  };

  return (
    <>
      {needleList.map((key, index) => {
        const value = needles[key];

        if (!value) return null;

        return (
          <div
            onClick={() => insertNeedle(key)}
            style={{ background: arrowIndex === index ? "#faf6f2" : "" }}
            onMouseOver={() => setArrowIndex(index)}
            key={index}
            className={styles.hoverable}
          >
            {index + 1 <= 9 ? `0${index + 1}` : index + 1} | {value.type}{" "}
            {value.size && `${value.size}cm`} nr. {value.diameter}
          </div>
        );
      })}
    </>
  );
};

type NeedleElementProps = {
  attributes: any;
  children: Text[];
  element: NeedleElementType;
  onClick: (event: React.MouseEvent, element: NeedleElementType) => void;
};

export const NeedleElement = ({
  attributes,
  children,
  element,
  onClick,
}: NeedleElementProps) => {
  const needle = NeedleFromRef(element.ref);

  return (
    <span
      {...attributes}
      className={`${styles.void} ${styles.basicPatternElement} ${styles.nonEditable}`}
      onClick={(event) => onClick(event, element)}
      contentEditable={false}
    >
      <b>{needleString(needle)}</b>
      {/* https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
    </span>
  );
};
