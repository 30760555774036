import { PlacementLineType } from "../enums";
import { Settings } from "../static/settings";

function PlacementLine(props: any) {
  if (!props.placementLineType) {
    return <></>;
  }
  const textLine = (top: boolean) => (
    <div
      style={{
        position: "absolute",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid " + (props.illegal ? "red" : "black"),
          height: "0px",
          width: "4px",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          marginLeft: "-20px",
          height: props.gridSize + "px",
          top: top ? "0px" : "-10px",
        }}
      >
        {(top || !props.small) && (
          <p
            style={{
              fontSize: "10px",
              color: props.illegal ? "red" : "black",
            }}
          >
            {Settings.reverseY ? props.gridSizeY - props.y : props.y}
          </p>
        )}
      </div>
    </div>
  );
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 300,
          marginLeft: "-25px",
          marginTop: props.gridSize * props.y,
        }}
      >
        {props.placementLineType == PlacementLineType.Top && textLine(true)}
        <div
          style={{
            marginLeft: "1.5px",
            borderLeft: "1px solid " + (props.illegal ? "red" : "black"),
            width: "0px",
            height: props.gridSize + "px",
          }}
        ></div>
        {props.placementLineType == PlacementLineType.Bottom && textLine(false)}
      </div>
    </div>
  );
}

export default PlacementLine;
