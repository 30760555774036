export const imgixUrl = (() => {
  if (window.location.hostname === "localhost") {
    return "https://woolit-test.imgix.net/";
  }
  if (window.location.hostname.endsWith(".dev.woolit.no")) {
    return "https://woolit-test.imgix.net/";
  }
  if (window.location.hostname.endsWith(".test.iterate.no")) {
    return "https://woolit-test.imgix.net/";
  }
  if (window.location.hostname.endsWith("testdesign.woolit.no")) {
    return "https://woolit-test.imgix.net/";
  }
  if (window.location.hostname.endsWith(".app.woolit.no")) {
    return "https://woolit-prod.imgix.net/";
  }
  if (window.location.hostname.endsWith("design.woolit.no")) {
    return "https://woolit-prod.imgix.net/";
  }
  return "https://woolit-prod.imgix.net/";
})();
