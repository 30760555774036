import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { YarnProfileVariant } from "../store/pattern";

import YarnVariantListItem from "./YarnVariantListItem";

interface PropTypes {
  variants: YarnProfileVariant[];
}

function YarnVariantChooser(props: PropTypes) {
  const { variants } = props;

  const activeVariant = useSelector(
    (state: RootState) => state.displayState.activeVariant
  );

  const activeSizes = useSelector(
    (state: RootState) => state.displayState.activeSizes
  );

  let variantList = [];
  for (let variant of variants) {
    variantList.push(
      <YarnVariantListItem
        key={`${variant.id}-${variant.name}`}
        variant={variant}
        active={variant.id === activeVariant}
        activeSizes={activeSizes}
      />
    );
  }
  return <>{variantList}</>;
}

export default YarnVariantChooser;
