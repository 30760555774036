import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { setDisableWriterHotkeys, toggleDrawer } from "../store/writerSlice";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";

import AwareVariantList from "./Components/VariantList";
import AwareMedia from "./Components/MediaList";
import AwareNeedles from "./Components/Needles";
import AwareSizeSelector from "./Components/SizeSelector";
import Gauge from "./Components/Gauge";
import AwareYarnAmounts from "./Components/YarnAmounts";
import AwareRoles from "./Components/Roles";
import SubdrawerContent from "./Components/Subdrawer";
import { ClothingData } from "./Components/ClothingData";

import DrawerTitle from "./DrawerTitle";
import DrawerDescription from "./DrawerDescription";

import { ArrowLeftIcon } from "@radix-ui/react-icons";

import styles from "./MetadataDrawer.module.css";
import { useTranslation } from "react-i18next";
import { KnitAlong } from "./Components/KnitAlong";
import TagsInput from "./Components/TagsInput";
import { FormCard } from "./Components/FormCard";
import Button from "../ISO/Components/Button";
import { KnitMethodCard } from "./Components/KnitMethodCard";

function MetadataDrawer() {
  const { t } = useTranslation();

  const { drawerOpen, subdrawerOpen } = useSelector(
    (state: RootState) => state.writer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (drawerOpen) {
      dispatch(setDisableHotkeys(true));
      dispatch(setDisableWriterHotkeys(true));
      dispatch(setDisable(true));
    }
  }, [drawerOpen]);

  const handleClose = () => {
    dispatch(setDisableHotkeys(false));
    dispatch(toggleDrawer());
    dispatch(setDisable(false));
    dispatch(setDisableWriterHotkeys(false));
  };

  const { disableModel } = useSelector((state: RootState) => state.model);

  return (
    <div
      className={`${styles.drawerContainer} ${drawerOpen ? "" : styles.hidden}`}
    >
      <div className={styles.drawerBackground} onClick={() => handleClose()} />
      <div className={styles.drawer}>
        <div className={styles.drawerTitleContainer}>
          <DrawerTitle />
          <button onClick={() => handleClose()}>
            <ArrowLeftIcon aria-label={t("navigation.close")} />
          </button>
        </div>
        <div className={styles.drawerTitleContainer}>
          <DrawerDescription />
        </div>
        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("datadrawer.form")}</h2>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              gap: "10px",
              marginBottom: "-20px",
            }}
          >
            <FormCard
              disableModel={true}
              text={t("datadrawer.freeform")}
              src="/Freeform.png"
            />
            <FormCard
              disableModel={false}
              text={t("datadrawer.sweater")}
              src="/Sweater.png"
            />
          </div>
        </div>
        {disableModel || (
          <div className={styles.drawerSection}>
            <h2 className={styles.subtitle}>{t("datadrawer.knit method")}</h2>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                marginBottom: "-20px",
                gap: "10px",
              }}
            >
              <KnitMethodCard
                text={t("datadrawer.raglan")}
                knitMethod="raglan"
                src="/3D/ui/Genser-ikon-1.png"
              />
              <KnitMethodCard
                text={t("datadrawer.stitched sleeves")}
                knitMethod="stitched_sleeves"
                src="/3D/ui/Genser-ikon-2.png"
              />
            </div>
          </div>
        )}
        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("datadrawer.roles")}
            <p className={styles.smallSubtitle}>
              {t("datadrawer.roles description")}
            </p>
          </h2>
          <AwareRoles />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("datadrawer.clothing info")}
            <p className={styles.smallSubtitle}>
              {t("datadrawer.cloting info description")}
            </p>
          </h2>
          <ClothingData />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>Kategorier</h2>
          <TagsInput />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("size", { count: 2 })}</h2>
          <AwareSizeSelector />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("color variant", { count: 2 })}
          </h2>
          <AwareVariantList />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.yarn consumption")}</h2>
          <AwareYarnAmounts />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.gauge")}</h2>
          <Gauge />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.needles")}</h2>
          <AwareNeedles />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>Media</h2>
          <AwareMedia />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            Samstrikk{" "}
            <p className={styles.smallSubtitle}>
              {t("datadrawer.knit along description")}
            </p>
          </h2>

          <KnitAlong />
        </div>

        <div style={{ marginBottom: "10vw" }} />
      </div>
      <div
        className={`${styles.subdrawer} ${subdrawerOpen ? "" : styles.hidden}`}
      >
        <SubdrawerContent />
      </div>
    </div>
  );
}

export { MetadataDrawer };
