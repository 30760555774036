import { Cross2Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import { useGetOrganizationByIdQuery } from "../../services/organization";
import { ClothingMetaData } from "../../store/pattern";
import {
  addClothingMetaData,
  removeClothingMetaData,
  setClothingMetaData,
  setMetadata,
} from "../../store/patternSlice";
import { RootState } from "../../store/store";
import { MoveOrg } from "./MoveOrg";
import styles from "./Roles.module.css";
import { useTranslation } from "react-i18next";

export const ClothingData = () => {
  const { organizationId, meta } = useSelector(
    (state: RootState) => state.pattern
  );
  const { clothingMetaData, collection, design } = meta;
  const { data } = useGetOrganizationByIdQuery(`${organizationId ?? -1}`, {
    skip: !organizationId || organizationId === -1,
  });

  const dispatch = useDispatch();

  const [clothingData, setClothingData] = useState<ClothingMetaData[]>(
    clothingMetaData ?? []
  );

  function updateState(index: number, item: ClothingMetaData) {
    let tmp = [...clothingData];
    tmp.splice(index, 1, item);
    setClothingData(tmp);
  }

  useEffect(() => setClothingData(clothingMetaData), [clothingMetaData]);
  const { t } = useTranslation();

  return (
    <div className={styles.roles}>
      <div className={styles.role}>
        {data ? (
          <>
            <input
              disabled
              className={styles.roleTitle}
              value={t("organization_one")}
            />
            <input className={styles.roleValue} value={data.name} disabled />
          </>
        ) : (
          <>
            <input
              disabled
              className={styles.roleTitle}
              value={t("organization_one")}
            />
            <input disabled className={styles.roleValue} value={t("private")} />
          </>
        )}
        <MoveOrg />
      </div>

      <div className={styles.role}>
        <input disabled className={styles.roleTitle} value={"Design"} />
        <input
          placeholder={t("name_design")}
          className={styles.roleValue}
          value={design}
          onChange={(e) => dispatch(setMetadata({ design: e.target.value }))}
        />
        <button className={styles.removeItem} disabled>
          <img height={14} src="/x.svg" alt="Remove" />
        </button>
      </div>

      <div className={styles.role}>
        <input disabled className={styles.roleTitle} value={t("collection")} />
        <input
          placeholder={t("collection")}
          className={styles.roleValue}
          value={collection}
          onChange={(e) =>
            dispatch(setMetadata({ collection: e.target.value }))
          }
        />
        <button className={styles.removeItem} disabled>
          <img height={14} src="/x.svg" alt="Remove" />
        </button>
      </div>

      {clothingData.map(({ value, title }, i) => (
        <div key={i} className={styles.role}>
          <input
            className={styles.roleTitle}
            placeholder={"Variabel (eks. vanskelighetsgrad)"}
            onBlur={() =>
              dispatch(setClothingMetaData({ index: i, item: clothingData[i] }))
            }
            onChange={(e) => updateState(i, { value, title: e.target.value })}
            value={title}
          />
          <input
            className={styles.roleValue}
            placeholder={t("value")}
            onBlur={() =>
              dispatch(setClothingMetaData({ index: i, item: clothingData[i] }))
            }
            onChange={(e) => updateState(i, { value: e.target.value, title })}
            value={value}
          />
          <Button
            contrast
            className={styles.removeItem}
            onClick={() => dispatch(removeClothingMetaData(i))}
          >
            <Cross2Icon />
          </Button>
        </div>
      ))}
      <hr className={styles.spacer} />
      <div>
        <button
          className={styles.new}
          onClick={() => dispatch(addClothingMetaData())}
        >
          {t("add")}
        </button>
      </div>
    </div>
  );
};
