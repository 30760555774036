import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isDynamic } from "../../CommonComponents/Icons/lookup";
import {
  copyMarkedArea,
  cutMarkedArea,
  fillArea,
  hFlipArea,
  rotateArea,
  vFlipArea,
  insertDynamicSymbol,
  MarkedArea,
  removeDynamicSymbols,
} from "../../store/gridSlice";
import { RootState } from "../../store/store";
import { aabb } from "../utils/aabb";
import { ContextMenu, Option } from "./RenderMenu";

interface PropType {
  area: { col: number; row: number }[];
  //Needed for menu
  x: number;
  y: number;
  handleClose: Function;
}

export const DragMenu = (props: PropType) => {
  const { area } = props;
  const len = area.length - 1;
  const markedArea: MarkedArea = {
    a: { x: area[0].col, y: area[0].row },
    b: { x: area[len].col, y: area[0].row },
    d: { x: area[0].col, y: area[len].row },
    c: { x: area[len].col, y: area[len].row },
  };

  const { activeIndex, activeSymbol } = useSelector(
    (state: RootState) => state.color
  );

  const { dynamicSymbols } = useSelector((state: RootState) => state.grid.grid);

  const { t } = useTranslation();

  const cellData = { symbol: activeSymbol, color: activeIndex };

  const findOverlappingSymbols = (): string[] => {
    const overlap = Object.keys(dynamicSymbols ?? {}).filter((key) => {
      const [x1, y1, x2, y2] = key.split("_").map((n) => parseInt(n));
      const { a, c } = markedArea;
      return aabb(
        { left: a.x, top: a.y, width: c.x - a.x + 1, height: c.y - a.y + 1 },
        { left: x1, top: y1, width: x2 - x1 + 1, height: y2 - y1 + 1 }
      );
    });

    return overlap;
  };

  const dragOptions: Option[] = [
    {
      label:
        findOverlappingSymbols().length > 0
          ? "Fjern markerte symboler"
          : "Sett inn symbol",
      action: () => {
        const overlappingSymbols = findOverlappingSymbols();
        if (overlappingSymbols.length > 0) {
          return removeDynamicSymbols({ keys: overlappingSymbols });
        } else {
          return insertDynamicSymbol({ area: props.area, cellData });
        }
      },
    },
    {
      label: t("draw.menu.fill area"),
      action: (props: PropType) =>
        isDynamic(activeSymbol)
          ? () => {}
          : fillArea({
              area: markedArea,
              cellData: { ...cellData, symbol: activeSymbol },
            }),
    },
    {
      label: t("draw.menu.mirror horizontally"),
      action: (props: PropType) => hFlipArea({ area: props.area }),
    },
    {
      label: t("draw.menu.mirror vertically"),
      action: (props: PropType) => vFlipArea({ area: props.area }),
    },
    {
      label: t("draw.menu.rotate"),
      options: [
        {
          label: "90°",
          action: (props: PropType) =>
            rotateArea({ area: props.area, degrees: 90 }),
        },
        {
          label: "180°",
          action: (props: PropType) =>
            rotateArea({ area: props.area, degrees: 180 }),
        },
        {
          label: "270°",
          action: (props: PropType) =>
            rotateArea({ area: props.area, degrees: 270 }),
        },
      ],
    },
    {
      label: t("draw.menu.copy"),
      hotkey: "⌘ + c",
      action: (props: PropType) => copyMarkedArea(),
    },
    {
      label: t("draw.menu.cut"),
      hotkey: "⌘ + x",
      action: (props: PropType) => cutMarkedArea(),
    },
    {
      label: t("draw.menu.empty area"),
      action: (props: PropType) =>
        fillArea({
          area: markedArea,
          cellData: { color: 0, symbol: "stitch" },
        }),
    },
  ];

  return (
    <ContextMenu {...props} options={dragOptions} defaultVisibility={false} />
  );
};
