import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Range,
  Transforms,
  Text,
} from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";

import { Cross1Icon } from "@radix-ui/react-icons";
import { withHistory } from "slate-history";
import { PatternElement } from "../store/pattern";
import { setPatternElementsById } from "../store/patternSlice";
import { RootState } from "../store/store";
import { toggleCommentPopover } from "../store/writerSlice";
import { getCaretCoordinates } from "../utils/caretPosition";
import { hasAccess } from "../utils/hasAccess";
import { commands } from "../utils/writerCommands";
import CommentWriter from "./Comment/CommentWriter";
import { KnitAlongModalButton } from "./KnitAlongModal";
import {
  CalculationResultElement,
  CalculationResultElementType,
} from "./CustomElements/CalculationResult";
import { ColorElement, ColorElementType } from "./CustomElements/Color";
import {
  DiagramReferenceElement,
  DiagramReferenceElementType,
} from "./CustomElements/DiagramReference";
import {
  DiagramSectionElement,
  DiagramSectionElementType,
} from "./CustomElements/DiagramSection";
import ElementSelector from "./CustomElements/ElementSelector";
import { FigureElement, FigureElementType } from "./CustomElements/Figure";
import { NeedleElement, NeedleElementType } from "./CustomElements/Needle";
import {
  ParagraphBreakElement,
  ParagraphBreakElementType,
} from "./CustomElements/ParagraphBreak";
import {
  SizeFilteredStringElement,
  SizeFilteredStringElementType,
} from "./CustomElements/SizeFilteredString";
import { TextLinkElementType } from "./CustomElements/TextLink";
import { serializePattern } from "./PatternSerializer";
import styles from "./SlateWriter.module.css";
import { useTranslation } from "react-i18next";

type SpanElementType = {
  type: "span";
  children: Text[];
  style?: string;
  commentId?: string;
  selected?: boolean;
};

type HeadingElementType = {
  type: "heading";
  children: Text[];
  style?: string;
  publishDate?: string;
  commentId?: string;
  index?: number;
};

type SubHeadingElementType = {
  type: "subheading";
  children: Text[];
  style?: string;
  commentId?: string;
};

export type CustomElement =
  | SpanElementType
  | HeadingElementType
  | SubHeadingElementType
  | TextLinkElementType
  | CalculationResultElementType
  | SizeFilteredStringElementType
  | ColorElementType
  | NeedleElementType
  | DiagramReferenceElementType
  | DiagramSectionElementType
  | FigureElementType
  | ParagraphBreakElementType;

declare module "slate" {
  //eslint-disable-next-line no-unused-vars
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
  }
}

interface PropType {
  patternElements: PatternElement[];
  patternId: string;
}

type TextHighlightBarData = {
  left: number;
  top: number;
} | null;

const SlateWriter = ({ patternElements, patternId }: PropType) => {
  const dispatch = useDispatch();

  const [suggestionPos, setSuggestionPos] = useState({
    x: -1,
    y: -1,
    cursorY: -1,
    screenY: -1,
    text: "",
  });

  const [showTextHighlightBar, setShowTextHightlightBar] =
    useState<TextHighlightBarData>(null);

  type ShowSelector = {
    command: string;
    x: number;
    y: number;
    screenY: number;
    action: "" | "add" | "replace";
    element?: CustomElement;
  };

  const [showSelector, setShowSelector] = useState<ShowSelector>({
    command: "",
    x: 0,
    y: 0,
    screenY: 0,
    action: "",
    element: undefined,
  });

  const [elementChoice, setElementChoice] = useState(0);

  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  const { commentPopover } = useSelector((state: RootState) => state.writer);

  Editor.isInline(editor, (element: CustomElement) => {
    const inlineElements = [
      "span",
      "textlink",
      "calculationResult",
      "sizeFilteredString",
      "color",
      "needle",
      "diagram",
    ];
    return inlineElements.includes(element.type);
  });

  Editor.isVoid(editor, (element: CustomElement) => {
    const voidElements = [
      "calculationResult",
      "sizeFilteredString",
      "color",
      "needle",
      "diagram",
      "diagramSection",
      "figure",
      "paragraphBreak",
    ];
    return voidElements.includes(element.type);
  });

  const content: Descendant[] = patternElements.map((element, index) => {
    switch (element.type) {
      case "span":
        return {
          type: element.type,
          children: [{ text: element.markdown }],
          style: element.style,
          commentId: element.commentId,
        };
      case "heading":
        return {
          type: element.type,
          children: [{ text: element.markdown }],
          style: element.style,
          publishDate: element.publishDate,
          commentId: element.commentId,
          index,
        };
      case "subheading":
        return {
          type: element.type,
          children: [{ text: element.markdown }],
          commentId: element.commentId,
        };
      // case "textlink":
      //   return {
      //     type: element.type,
      //     children: [{ text: element.markdown }],
      //     link: element.link,
      //     commentId: element.commentId,
      //   };
      case "calculationResult": {
        return {
          type: element.type,
          children: [{ text: "" }],
          tableId: element.tableId,
          measureId: element.measureId,
          row1: element.row1,
          row2: element.row2,
          edgeKind: element.edgeKind,
          commentId: element.commentId,
        };
      }
      case "sizeFilteredString": {
        return {
          type: element.type,
          children: [{ text: "" }],
          // text: element.text,
          sizeString: JSON.stringify(element.text),
          commentId: element.commentId,
        };
      }
      case "color":
        return {
          type: element.type,
          children: [{ text: "" }],
          ref: element.ref,
          commentId: element.commentId,
        };
      case "needle":
        return {
          type: element.type,
          children: [{ text: "" }],
          ref: element.ref,
          commentId: element.commentId,
        };

      case "diagram":
        return {
          type: element.type,
          children: [{ text: "" }],
          ref: element.ref,
          id: element.id,
          commentId: element.commentId,
        };
      case "diagramSection":
        return {
          type: element.type,
          children: [{ text: "" }],
          id: element.id,
          diagram: element.diagram,
          commentId: element.commentId,
        };
      case "figure":
        return {
          type: element.type,
          children: [{ text: "" }],
          id: element.id,
          figureId: element.figureId,
          inline: element.inline,
          commentId: element.commentId,
        };
      case "paragraphBreak":
        return {
          type: element.type,
          children: [{ text: "" }],
        };

      default:
        return {
          type: "span",
          children: [{ text: "Unknown element" }],
        };
    }
  });
  // .filter((element) => element.type !== "paragraph");

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "span":
        return <SpanElement {...props} />;
      case "heading":
        return <HeadingElement {...props} editor={editor} />;
      case "subheading":
        return <SubHeadingElement {...props} />;
      // case "textlink":
      //   return <TextLinkElement {...props} />;
      case "calculationResult":
        return (
          <CalculationResultElement
            {...props}
            onClick={(event, element) =>
              replaceElement(event, element, "calculationResult")
            }
          />
        );
      case "sizeFilteredString":
        return (
          <SizeFilteredStringElement
            {...props}
            onClick={(event, element) =>
              replaceElement(event, element, "sizeFilteredString")
            }
          />
        );
      case "color":
        return (
          <ColorElement
            {...props}
            onClick={(event, element) =>
              replaceElement(event, element, "color")
            }
          />
        );
      case "needle":
        return (
          <NeedleElement
            {...props}
            onClick={(event, element) =>
              replaceElement(event, element, "needle")
            }
          />
        );
      case "diagram":
        return (
          <DiagramReferenceElement
            {...props}
            onClick={(event, element) =>
              replaceElement(event, element, "diagram")
            }
          />
        );
      case "diagramSection":
        return <DiagramSectionElement {...props} onClick={removeElement} />;
      case "figure":
        return (
          <FigureElement {...props} editor={editor} onClick={removeElement} />
        );
      case "paragraphBreak":
        return (
          <ParagraphBreakElement
            {...props}
            showDelete
            onClick={removeElement}
          />
        );

      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const slashRef = useRef<HTMLDivElement>(null);

  const replaceElement = (
    event: React.MouseEvent,
    element: CustomElement,
    command: string
  ) => {
    Transforms.deselect(editor);

    setShowSelector({
      x: event.pageX,
      y: event.pageY,
      screenY: event.screenY,
      command,
      action: "replace",
      element,
    });
  };

  const removeElement = (element: CustomElement) => {
    const path = element ? ReactEditor.findPath(editor, element) : undefined;

    Transforms.removeNodes(editor, { at: path });
  };

  const closeSelector = () => {
    setShowSelector({
      command: "",
      x: 0,
      y: 0,
      action: "",
      screenY: 0,
      element: undefined,
    });
  };

  const textStyleRef = useRef<HTMLDivElement>(null);

  // Handle close menus
  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (
        textStyleRef.current &&
        !textStyleRef.current.contains(e.target as Node)
      ) {
        setShowTextHightlightBar(null);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  const TextHighlightBar = ({
    editor,
    data,
  }: {
    editor: BaseEditor & ReactEditor;
    data: TextHighlightBarData;
  }) => {
    if (!data) {
      return null;
    }

    const { left, top } = data;

    const textStyles: {
      label: string;
      command?: "heading" | "subheading" | "span" | "textlink";
      style?: "bold" | "italic";
      useImg?: boolean;
    }[] = [
      { label: "H1", command: "heading" },
      { label: "H2", command: "subheading" },
      { label: "P", command: "span" },
      { label: "B", style: "bold" },
      { label: "I", style: "italic" },
      // { label: "link.svg", command: "textlink", useImg: true },
    ];

    return (
      <div
        ref={textStyleRef}
        className={styles.controlBar}
        style={{
          position: "absolute",
          left: left,
          top: top - 42,
        }}
      >
        {textStyles.map((btn, i) => (
          <button
            key={i}
            onClick={() => {
              const { done } = Editor.nodes(editor, {
                match: (n: any) =>
                  (!!btn.command && n.type === btn.command) ||
                  (!!btn.style && n.style === btn.style),
                mode: "all",
              }).next();

              const nodeUpdate: any = {}; //Partial<CustomElement> = {};
              if (btn.command) {
                nodeUpdate.type = btn.command;
              }
              if (btn.style) {
                nodeUpdate.style = done ? btn.style : "";
              }
              Transforms.setNodes(editor, nodeUpdate, { split: true });
              setShowTextHightlightBar(null);
            }}
            className={`${styles.textStyleButton} ${
              btn.style ? styles[btn.style] : ""
            }`}
          >
            {btn.useImg ? (
              <img src={`/${btn.label}`} alt={btn.label} />
            ) : (
              btn.label
            )}
          </button>
        ))}
        <button
          className={styles.textStyleButton}
          onClick={() => {
            dispatch(
              toggleCommentPopover({
                isShown: true,
                top,
              })
            );
          }}
        >
          <img src="/chat-bubble.svg" alt={"A chat bubble"} />
        </button>
        <button
          className={styles.textStyleButton}
          onClick={() => setShowTextHightlightBar(null)}
        >
          <Cross1Icon />
        </button>
      </div>
    );
  };

  const suggestLen = Object.entries(commands).length;

  return (
    <div className={styles.textContainer}>
      {/* {active === -1 && !dragSelectionDisabled && (
        <DragSelect disabled={dragSelectionDisabled} />
      )} */}
      {/* {textLinkHelper.index !== -1 && renderTextLinkHelper()} */}
      {suggestionPos.x !== -1 && (
        <div
          ref={slashRef}
          style={{
            position: "absolute",
            left: suggestionPos.x,
            top: suggestionPos.y + 24,
          }}
          className={styles.suggestionTab}
        >
          <div className={styles.suggestionTop}>
            <p>Legg til:</p>
            <button
              style={{ border: 0 }}
              onClick={() =>
                setSuggestionPos({
                  x: -1,
                  y: -1,
                  text: "",
                  cursorY: -1,
                  screenY: -1,
                })
              }
            >
              <img src="/x.svg" alt="lukk valg" />
            </button>
          </div>
          {Object.entries(commands).map(([key, label], index) => (
            <button
              key={key}
              className={`${styles.suggestionButton} ${
                index === elementChoice ? styles.highlightedButton : ""
              }`}
              onMouseOver={() => setElementChoice(index)}
              onClick={() => {
                setShowSelector({
                  command: key,
                  x: suggestionPos.x,
                  y: suggestionPos.cursorY,
                  screenY: suggestionPos.screenY,
                  action: "add",
                  element: undefined,
                });
                setSuggestionPos({
                  x: -1,
                  y: -1,
                  text: "",
                  cursorY: -1,
                  screenY: -1,
                });
              }}
            >
              {label}
            </button>
          ))}
        </div>
      )}
      {commentPopover.isShown && <CommentWriter editor={editor} />}
      {showSelector.x !== 0 && (
        <ElementSelector
          x={showSelector.x}
          y={showSelector.y}
          screenY={showSelector.screenY}
          action={showSelector.action}
          paragraphElementType={showSelector.command}
          closeSelector={closeSelector}
          editor={editor}
          element={showSelector.element}
        />
      )}
      <Slate
        editor={editor}
        value={content}
        onChange={(value) => {
          const isAstChange = editor.operations.some(
            (operation) => "set_selection" !== operation.type
          );

          const isSelectionChange = editor.operations.some(
            (operation) => "set_selection" === operation.type
          );

          if (isAstChange) {
            const patternElementsSerialized = serializePattern(value);
            dispatch(
              setPatternElementsById({
                patternId,
                patternElements: patternElementsSerialized,
              })
            );
          }

          if (isSelectionChange) {
            //reset
            Transforms.setNodes(
              editor,
              { selected: false },
              {
                at: [],
                match: (node, _) =>
                  "type" in node &&
                  node.type === "span" &&
                  node.selected === true,
              }
            );

            const selection = editor.selection;

            if (selection) {
              if (Range.isCollapsed(selection)) {
                Transforms.setNodes(editor, { selected: true });
              } else {
                const domSelection = window.getSelection();

                if (domSelection === null) {
                  setShowTextHightlightBar(null);
                } else {
                  const bbox = domSelection
                    .getRangeAt(0)
                    .getBoundingClientRect();

                  setShowTextHightlightBar({
                    left: bbox.x,
                    top: bbox.y + window.scrollY,
                  });
                }
              }
            }
          }
        }}
      >
        <TextHighlightBar editor={editor} data={showTextHighlightBar} />
        <Editable
          renderElement={renderElement}
          onKeyDown={(event) => {
            switch (event.key) {
              case "/": {
                event.preventDefault();

                const textContent = event.currentTarget.textContent;

                if (textContent === null) {
                  console.log("no textContent");
                  return;
                }

                const menuHeight = Object.keys(commands).length * 36 + 60;
                const innerHeight = window.innerHeight - 72; // 72px is the offset from the bottom
                const { x, y, screenY } = getCaretCoordinates();

                setSuggestionPos({
                  x,
                  y:
                    screenY + menuHeight > innerHeight
                      ? y - menuHeight + innerHeight - screenY
                      : y,
                  cursorY: y,
                  screenY,
                  text: textContent,
                });
                return;
              }
              case "Backspace":
                // If pressiong backspace and suggestion menu is visible, hide it.
                if (suggestionPos.x !== -1) {
                  event.preventDefault();
                  setSuggestionPos({
                    x: -1,
                    y: -1,
                    text: "",
                    cursorY: -1,
                    screenY: -1,
                  });
                }
                return;
              case "Enter":
                if (suggestionPos.x !== -1) {
                  event.preventDefault();
                  setShowSelector({
                    command: Object.keys(commands)[elementChoice],
                    x: suggestionPos.x,
                    y: suggestionPos.y,
                    screenY: suggestionPos.screenY,
                    action: "add",
                    element: undefined,
                  });
                  setSuggestionPos({
                    x: -1,
                    y: -1,
                    text: "",
                    cursorY: -1,
                    screenY: -1,
                  });
                } else if (showSelector.x !== 0) {
                  event.preventDefault();
                } else {
                  event.preventDefault();
                  Transforms.insertNodes(editor, [
                    {
                      type: "paragraphBreak",
                      children: [{ text: "" }],
                    },
                    {
                      type: "span",
                      children: [{ text: "" }],
                    },
                  ]);
                }
                return;
              case "ArrowUp":
                if (suggestionPos.x !== -1) {
                  event.preventDefault();
                  setElementChoice(
                    elementChoice < 1 ? suggestLen - 1 : elementChoice - 1
                  );
                }
                return;
              case "ArrowDown":
                if (suggestionPos.x !== -1) {
                  event.preventDefault();
                  setElementChoice((elementChoice + 1) % suggestLen);
                }
                return;
            }
          }}
        />
      </Slate>
    </div>
  );
};

const SpanElement = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: Text[];
  element: SpanElementType;
}) => {
  return (
    <span
      {...attributes}
      className={
        // ${
        //   element.commentId === highlightedComment
        //     ? styles[comments[element.commentId]?.commentType]
        //     : ""
        //   }
        `${styles.basicPatternElement} ${styles.span}
          ${
            element.children.length > 0 && element.children[0].text === ""
              ? styles.empty
              : ""
          } ${
          element.style && styles[element.style] ? styles[element.style] : 0
        } ${element.selected ? styles.selected : ""}`
      }
      data-placeholder={`Tast "/" for valg`}
    >
      {children}
    </span>
  );
};

const HeadingElement = ({
  attributes,
  children,
  element,
  editor,
}: {
  attributes: any;
  children: Text[];
  element: HeadingElementType;
  editor: BaseEditor & ReactEditor;
}) => {
  const user = useSelector((state: RootState) => state.loginState.user);
  const { meta } = useSelector((state: RootState) => state.pattern);

  const setDateFn = (publishDate: string) => {
    const path = element ? ReactEditor.findPath(editor, element) : undefined;
    Transforms.setNodes(editor, { publishDate }, { at: path });
  };
  const headerText = children.map((child) => child.text).join("");
  const { t } = useTranslation();

  return (
    <div {...attributes} className={`${styles.headingContainer}`}>
      {hasAccess(user, "knit_along") && meta.knitAlong && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <KnitAlongModalButton
            setDateFn={setDateFn}
            headerText={headerText}
            existingDate={element.publishDate}
            isIntroduction={element.index === 0}
          />
        </div>
      )}
      <h2>
        <span
          className={`${styles.heading} ${
            element.children.length > 0 && element.children[0].text === ""
              ? styles.headerPlaceholder
              : ""
          } ${styles.basicPatternElement} `}
          data-placeholder = {t("writer.heading") + "..."}
          
        >
          {children}
        </span>
      </h2>
    </div>
  );
};

const SubHeadingElement = ({
  attributes,
  children,
}: {
  attributes: any;
  children: Text[];
}) => {
  return (
    <h3
      {...attributes}
      className={`${styles.subheading} ${styles.basicPatternElement}`}
    >
      {children}
    </h3>
  );
};

const DefaultElement = (props: any) => {
  return <p {...props.attributes}>{props.children}</p>;
};

export default SlateWriter;
