import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BBox } from "../Diagram/utils/aabb";

interface DragSelectState {
  bbox: BBox;
  disabled: boolean;
}

const initialState: DragSelectState = {
  bbox: { left: 0, top: 0, height: 0, width: 0 },
  disabled: false,
};

const dragSelectSlice = createSlice({
  name: "dragSelect",
  initialState,
  reducers: {
    setBbox(state, action) {
      state.bbox = action.payload;
    },
    setDisable(state, action: PayloadAction<boolean>) {
      state.disabled = action.payload;
    },
  },
});

export const { setBbox, setDisable } = dragSelectSlice.actions;

export { dragSelectSlice };
